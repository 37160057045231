import toast from "@/lib/client/toast";

const handleTRPCMutationSuccess =
  <T extends any>(
    { mutationPastPrinciple }: { mutationPastPrinciple: string },
    next?: (res: T) => void,
  ) =>
  (res: T) => {
    next?.(res);
    toast.success(`Successfully ${mutationPastPrinciple}!`);
  };

export default handleTRPCMutationSuccess;
