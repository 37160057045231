import { useNbmeHighlightSettings } from "@/utils/stores/nbmeLayoutStore";
import { NbmeSettingsBarSection } from "./NbmeSettingsBarSection";
import { NbmeSettingsSegment } from "./NbmeSettingsSegment";
import { NbmeSettingsSwitch } from "./NbmeSettingsSwitch";
import { NbmeSettingsSlider } from "./NbmeSettingsSlider";

export const NbmeSettingsHighlightsSection = () => {
  const [settings, setSettings] = useNbmeHighlightSettings();
  return (
    <NbmeSettingsBarSection title={"Highlights"}>
      <NbmeSettingsSegment>
        <p className="text-xs">Show as bold</p>
        <NbmeSettingsSwitch
          checked={settings?.bold}
          onCheckedChange={(newValue) =>
            setSettings({ ...settings, bold: newValue })
          }
        />
      </NbmeSettingsSegment>
      <NbmeSettingsSegment>
        <p className="text-xs">Show with shadows</p>
        <NbmeSettingsSwitch
          checked={settings?.shadow}
          onCheckedChange={(newValue) =>
            setSettings({ ...settings, shadow: newValue })
          }
        />
      </NbmeSettingsSegment>
      <NbmeSettingsSegment
        disabled={!settings?.shadow}
        className="flex flex-col justify-start items-start gap-2"
      >
        <p className="text-xs">Shadow intensity</p>

        <NbmeSettingsSlider
          value={settings?.shadowIntensity}
          onValueChange={(v) =>
            setSettings({ ...settings, shadowIntensity: v })
          }
          max={100}
          min={0}
        />
      </NbmeSettingsSegment>
      <NbmeSettingsSegment>
        <p className="text-xs">Shine in dark mode</p>
        <NbmeSettingsSwitch
          checked={settings?.shine}
          onCheckedChange={(newValue) =>
            setSettings({ ...settings, shine: newValue })
          }
        />
      </NbmeSettingsSegment>
      <NbmeSettingsSegment
        disabled={!settings?.shine}
        className="flex flex-col justify-start items-start gap-2"
      >
        <p className="text-xs">Shine intensity</p>

        <NbmeSettingsSlider
          value={settings?.shineIntensity ?? 30}
          onValueChange={(v) => setSettings({ ...settings, shineIntensity: v })}
          max={100}
          min={0}
        />
      </NbmeSettingsSegment>
    </NbmeSettingsBarSection>
  );
};
