import { PropsWithClassName } from "@/types";
import { cn } from "@/lib/utils";
import React, { PropsWithChildren } from "react";
import useTriggerableContext from "./hooks/use-triggerable-context";
import {
  AnimatePresence,
  HTMLMotionProps,
  motion,
  TargetAndTransition,
  Variants,
} from "framer-motion";
import { smoothSpring } from "@/assets/framer/springs";

type TriggerableBodyProps = PropsWithChildren &
  PropsWithClassName &
  Omit<HTMLMotionProps<"div">, "variants" | "animate" | "initial" | "exit"> & {
    animate?: TargetAndTransition;
    initial?: TargetAndTransition;
    exit?: TargetAndTransition;
  };
const TriggerableBody = React.forwardRef<
  HTMLDivElement | null,
  TriggerableBodyProps
>(({ children, className, ...props }, ref) => {
  const { isOpen } = useTriggerableContext();
  const variants: Variants = {
    visible: { opacity: 1, pointerEvents: "auto", ...props.animate },
    invisible: {
      opacity: 0,
      pointerEvents: "none",
      ...props.initial,
      ...props.exit,
    },
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          {...props}
          ref={ref}
          variants={variants}
          initial={"invisible"}
          animate={"visible"}
          exit={"invisible"}
          transition={smoothSpring}
          className={cn(className)}
        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  );
});

export default TriggerableBody;
