import { Button } from "@/components/Button";
import { useUsersDashboard } from "../../../hooks";

interface AllowResetButtonProps {
  userId: string;
  lastResetAt: Date | null;
}
export const AllowResetButton = ({
  userId,
  lastResetAt,
}: AllowResetButtonProps) => {
  const { allowReset } = useUsersDashboard((state) => ({
    allowReset: state.allowReset,
  }));
  if (!lastResetAt) {
    return (
      <div className="w-full text-center flex flex-row justify-center items-center font-bold text-sm">
        Can reset
      </div>
    );
  }
  return (
    <Button
      className="text-sm md:text-sm lg:text-sm"
      onClick={() => allowReset({ userId })}
    >
      Allow Reset
    </Button>
  );
};
