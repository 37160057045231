import useQuestionBankSubjects from "@/hooks/subject-hooks/use-question-bank-subjects";
import useQuestionBankQuestionHeaders from "./use-question-bank-question-headers";
import useQuestionBankSystems from "@/hooks/system-hooks/use-question-bank-systems";
import useQuestionBankTopics from "@/hooks/topic-hooks/use-question-bank-topics";
import mergeFlags from "@/utils/common/merge-flags";
import ArrayMapUtils from "@/types/common/ArrayConvertableMap";
import { QuestionBankIdentifier } from "@/types/question-bank-types";
import { useCallback } from "react";

const useGetQuestionBankQuestionHeaderWithArticles = (
  args: QuestionBankIdentifier,
) => {
  const questionHeadersQuery = useQuestionBankQuestionHeaders(args);
  const { data: questionHeaders } = questionHeadersQuery;
  const subjectsQuery = useQuestionBankSubjects(args);
  const { data: subjects } = subjectsQuery;
  const systemsQuery = useQuestionBankSystems(args);
  const { data: systems } = systemsQuery;
  const topicsQuery = useQuestionBankTopics(args);
  const { data: topics } = topicsQuery;
  const mergedQuery = mergeFlags({
    source: questionHeadersQuery,
    destinations: [subjectsQuery, systemsQuery, topicsQuery],
  });
  const getQuestionHeaderWithArticles = useCallback(
    (questionId: number) => {
      const questionHeader = ArrayMapUtils.find(questionHeaders, questionId);
      if (!questionHeader) return undefined;
      const subject = ArrayMapUtils.find(subjects, questionHeader.subjectId);
      const system = ArrayMapUtils.find(systems, questionHeader.systemId);
      const topic = ArrayMapUtils.find(topics, questionHeader.topicId);
      if (!subject) return undefined;
      if (!system) return undefined;
      if (!topic) return undefined;
      return { ...questionHeader, subject, system, topic };
    },
    [questionHeaders, subjects, systems, topics],
  );

  return { ...mergedQuery, data: getQuestionHeaderWithArticles };
};

export default useGetQuestionBankQuestionHeaderWithArticles;
