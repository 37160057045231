import { AnimatePresence, motion } from "framer-motion";
import {
  NbmeBackButton,
  NbmeClickToRevealButton,
  NbmeEndTestNavbarButton,
  NbmeFeedbackNavbarButton,
  NbmeFlashcardsNavbarButton,
  NbmeNavbarBase,
  NbmeNotebookNavbarButton,
  NbmeSuspendTestNavbarButton,
} from ".";
import { twMerge } from "tailwind-merge";
import { fadeAnimation } from "@/assets/framer";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { useBreakPoint } from "@/utils/hooks";
import { BreakPoints } from "@/types";

export interface NbmeBottomNavbarProps<T = "test" | "question"> {
  variant: T;
  fontSizeFactor: number;
  isNotebookVisible: boolean;
  onIsNotebookVisibleChange: (v: boolean) => void;
  isFlashcardsVisible: boolean;
  onIsFlashcardsVisibleChange: (v: boolean) => void;
  isFeedbackVisible: boolean;
  onIsFeedbackVisibleChange: (v: boolean) => void;
  qBankEndpoint: string;

  isTestSubmitted?: T extends "test" ? boolean : undefined;
  testId?: T extends "test" ? number : undefined;
  testTimeRemaining?: T extends "test" ? string : undefined;
  testTimeElapsed?: T extends "test" ? string : undefined;
  isTimerVisible?: T extends "test" ? boolean : undefined;
  onIsTimerVisibleChange?: T extends "test" ? (v: boolean) => void : undefined;
  isTestTimed?: T extends "test" ? boolean : undefined;
  isTestTutor?: T extends "test" ? boolean : undefined;
  isSuspendTestDialogVisible?: T extends "test" ? boolean : undefined;
  onIsSuspendTestDialogVisibleChange?: T extends "test"
    ? (v: boolean) => void
    : undefined;
  isEndTestDialogVisible?: T extends "test" ? boolean : undefined;
  onIsEndTestDialogVisibleChange?: T extends "test"
    ? (v: boolean) => void
    : undefined;
}

export const NbmeBottomNavbar = <T = "test" | "question",>({
  testId,
  variant,
  isTestTutor,
  isTestTimed,
  isTimerVisible,
  fontSizeFactor,
  isTestSubmitted,
  testTimeElapsed,
  testTimeRemaining,
  onIsTimerVisibleChange,
  isNotebookVisible,
  onIsNotebookVisibleChange,
  isFlashcardsVisible,
  onIsFlashcardsVisibleChange,
  isFeedbackVisible,
  isSuspendTestDialogVisible,
  onIsSuspendTestDialogVisibleChange,
  onIsFeedbackVisibleChange,
  isEndTestDialogVisible,
  onIsEndTestDialogVisibleChange,
  qBankEndpoint,
}: NbmeBottomNavbarProps<T>) => {
  const [isMobileMenuVisible, setIsMobileMenuVisible] =
    useState<boolean>(false);
  const breakPoint = useBreakPoint();
  const isScreenSmall = breakPoint < BreakPoints.Medium;

  return (
    <NbmeNavbarBase className="sticky bottom-0 min-h-[55px] z-[900] md:z-[900] lg:z-[900]">
      <div className="flex flex-col justify-center items-start font-bold md:text-sm text-xs text-white gap-1">
        {variant === "test" && (
          <>
            {isTestSubmitted ? (
              <>
                <p>Test Id: {testId}</p>
                <p>REVIEW</p>
              </>
            ) : (
              <>
                <AnimatePresence mode="wait">
                  <motion.p
                    className={twMerge(
                      "transition-colors",
                      !isTimerVisible && "text-nbme-primary-200",
                    )}
                    key={isTimerVisible ? "timer" : "timer-hidden"}
                    {...fadeAnimation}
                  >
                    {isTimerVisible ? (
                      `${
                        isTestTimed
                          ? `Block Time Remaining:`
                          : `Block Time Elapsed:`
                      } ${isTestTimed ? testTimeRemaining : testTimeElapsed}`
                    ) : (
                      <NbmeClickToRevealButton
                        onClick={() =>
                          onIsTimerVisibleChange?.(!isTimerVisible)
                        }
                        className="text-nbme-primary-300 dark:text-nbme-primary-dark-400"
                        fontSizeFactor={fontSizeFactor}
                      />
                    )}
                  </motion.p>
                </AnimatePresence>
                <p className="text-xs">
                  {isTestTutor ? "TUTOR" : "UNTUTORED"}
                  {isTestTimed && ",TIMED"}
                </p>
              </>
            )}
          </>
        )}
      </div>
      <div
        className={twMerge(
          "flex flex-col-reverse md:flex-row justify-start md:justify-center items-center gap-2 md:relative md:right-auto md:bottom-auto fixed right-0 bottom-3  z-[999] min-w-[5.7rem] md:min-w-0",
        )}
      >
        <button
          type="button"
          className="text-nbme-primary-500 dark:text-nbme-primary-dark-900 bg-white px-2 py-1 rounded-full md:hidden"
          onClick={() => setIsMobileMenuVisible((prev) => !prev)}
        >
          <FontAwesomeIcon
            icon={faChevronUp}
            className={twMerge(
              "transition-transform",
              isMobileMenuVisible && "rotate-180",
            )}
          />
        </button>
        <div
          className={twMerge(
            "flex flex-col md:flex-row justify-start md:justify-center items-center gap-2 bg-nbme-primary-700 dark:bg-nbme-primary-dark-800 rounded-t-primary overflow-hidden transition-colors",
            !isMobileMenuVisible &&
              isScreenSmall &&
              "hidden bg-transparent pointer-events-none",
          )}
        >
          <NbmeNotebookNavbarButton
            isNotebookVisible={isNotebookVisible}
            onIsNotebookVisibleChange={onIsNotebookVisibleChange}
          />

          <NbmeFlashcardsNavbarButton
            isFlashcardsVisible={isFlashcardsVisible}
            onIsFlashcardsVisibleChange={onIsFlashcardsVisibleChange}
          />

          {variant === "test" && (
            <>
              <NbmeFeedbackNavbarButton
                isFeedbackVisible={isFeedbackVisible}
                onIsFeedbackVisibleChange={onIsFeedbackVisibleChange}
              />
              <NbmeSuspendTestNavbarButton
                isSuspendTestDialogVisible={isSuspendTestDialogVisible!}
                onIsSuspendTestDialogVisibleChange={
                  onIsSuspendTestDialogVisibleChange!
                }
              />
              <NbmeEndTestNavbarButton
                isTestSubmitted={isTestSubmitted!}
                isEndTestDialogVisible={isEndTestDialogVisible!}
                onIsEndTestDialogVisibleChange={onIsEndTestDialogVisibleChange!}
              />
            </>
          )}
          {variant === "question" && <NbmeBackButton />}
        </div>
      </div>
    </NbmeNavbarBase>
  );
};
