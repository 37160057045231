import { PropsWithChildren } from "react";
import TriggerableContextProvider from "./triggerable-context-provider";
import useTriggerableState, {
  TriggerableState,
} from "./hooks/use-triggerable-state";
import { ArgumentTypes } from "@/types";
import { PropsWithAs, PropsWithClassName } from "@/types";
import { cn } from "@/lib/utils";

type TriggerableRootProps = PropsWithAs &
  PropsWithChildren &
  PropsWithClassName &
  ArgumentTypes<typeof useTriggerableState>[0] & { state?: TriggerableState };

const TriggerableRoot = ({
  children,
  isOpen,
  onOpenChanged,
  state: externalState,
  className,
  as,
}: TriggerableRootProps) => {
  const internalState = useTriggerableState({ isOpen, onOpenChanged });
  const state = externalState ?? internalState;
  const Parent = as ?? "div";

  return (
    <TriggerableContextProvider value={state}>
      <Parent
        className={cn("group/triggerable", className)}
        data-is-open={state.isOpen}
      >
        {children}
      </Parent>
    </TriggerableContextProvider>
  );
};

export default TriggerableRoot;
