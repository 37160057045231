import {
  useNoteActions,
  useNotebookDraft,
  useSelectedNote,
} from "@/hooks/notebook";
import { markdownToHtml } from "@/utils/common/markdownToHtml";
import { useEffect, useState } from "react";

export const useSelectedNoteContent = () => {
  const { edit } = useNoteActions();
  const selectedNote = useSelectedNote();
  const [draft, setDraft] = useNotebookDraft();
  const [content, internalSetContent] = useState(getSelectedNoteContent());

  const setContent = (newContent: string) => {
    if (selectedNote) {
      internalSetContent(newContent);
      edit({ id: selectedNote.id, newContent });
    }
  };
  const resetDraft = () => {
    setDraft(selectedNote?.html ?? "");
    internalSetContent(getSelectedNoteContent());
  };

  useEffect(() => {
    setDraft(selectedNote?.html ?? "");
    internalSetContent(getSelectedNoteContent());
  }, [selectedNote]);
  return {
    content,
    draft,
    setDraft,
    setContent,
    selectedNote,
    resetDraft,
  };

  function getSelectedNoteContent() {
    return (
      (selectedNote?.markdown ? markdownToHtml(selectedNote.markdown) : "") +
      (selectedNote?.html ?? "")
    );
  }
};
