import { useState } from "react";
import { useGesture } from "@use-gesture/react";
import useDraggableContext from "./use-draggable-context";
import { Point } from "framer-motion";

const useDraggableGesture = (opts?: { isDraggable?: boolean }) => {
  const {
    position,
    setPosition,
    setIsDragging,
    isDraggable: contextIsDraggable,
    bodyRef,
    neverDraggedBefore,
    setNeverDraggedBefore,
    events,
  } = useDraggableContext();
  const [initialPosition, setInitialPosition] = useState(position);

  const isDraggable =
    opts?.isDraggable !== undefined
      ? opts?.isDraggable && contextIsDraggable
      : contextIsDraggable;

  return useGesture({
    onDragStart: () => {
      if (neverDraggedBefore) {
        setNeverDraggedBefore(false);
      }
      if (!position) {
        if (bodyRef && bodyRef.current) {
          const elementPosition = bodyRef.current.getBoundingClientRect();
          const newPosition = {
            x: elementPosition.left,
            y: elementPosition.top,
          };
          setPosition(newPosition);
          setInitialPosition(newPosition);
          return;
        }
      }
      if (!isDraggable) return;
      setInitialPosition(position);
      setIsDragging(true);
      events?.onDragStart?.();
    },
    onDrag: ({ movement }) => {
      if (!isDraggable) return;
      const [x, y] = movement;
      const DRAG_THRESHOLD: Point = { x: 3, y: 3 };
      if (
        !(
          x > DRAG_THRESHOLD.x ||
          x < DRAG_THRESHOLD.x * -1 ||
          y > DRAG_THRESHOLD.y ||
          y < DRAG_THRESHOLD.y * -1
        )
      ) {
        return;
      }

      if (!initialPosition) return;
      const newPosition: Point = {
        x: initialPosition.x + x,
        y: initialPosition.y + y,
      };

      setPosition(newPosition);
      events?.onDrag?.();
    },
    onDragEnd: () => {
      setIsDragging(false);
      events?.onDragEnd?.();
    },
  });
};

export default useDraggableGesture;
