import { RichTextEditor } from "@mantine/tiptap";
import {
  AlignmentControls,
  BubbleMenu,
  ColorControls,
  DirectionControls,
  FormatControls,
  HeadingControls,
  ImageControls,
  LinkControls,
  ParagraphTypeControls,
  TableControls,
  UndoRedoControls,
} from "./components";
import { Editor } from "@tiptap/react";
import { BlurInOut } from "@/ncomponents/utilities/animations/blur-in-out";
import React from "react";

export const Toolbar = React.forwardRef<HTMLDivElement, { editor: Editor | null, visible: boolean }>(({ editor, visible }, ref) => {
  return (
    <BlurInOut ref={ref} className="sticky top-0 z-10" state={visible ? "visible" : "hidden"}>
      <RichTextEditor.Toolbar>
        <ColorControls />
        <FormatControls />
        <BubbleMenu editor={editor} />
        <HeadingControls />
        <ParagraphTypeControls />
        <LinkControls />
        <AlignmentControls />
        <DirectionControls />
        <TableControls />
        <ImageControls />
        <UndoRedoControls />
      </RichTextEditor.Toolbar>
    </BlurInOut>
  );
});
