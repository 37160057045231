import { useCreateTestPageStore } from "@/utils/stores/createTestPageStore";
import QuestionCountBadge from "./QuestionCountBadge";
import LabeledCheckbox from "./LabeledCheckbox";
import { Setter } from "@/types";

interface QuestionModeCheckboxProps {
  mode: "unused" | "incorrect" | "correct" | "marked" | "omitted";
  questionCount: number;
  loading?: boolean;
  checked?: boolean;
  onCheckedChange?: Setter<boolean>;
  label?: string;
}

const QuestionModeCheckbox = ({
  mode,
  questionCount,
  loading,
  label: externalLabel,
  checked: externalChecked,
  onCheckedChange: externalOnCheckedChange,
}: QuestionModeCheckboxProps) => {
  const store = useCreateTestPageStore();
  const checked =
    externalChecked ?? (store[mode as keyof typeof store] as boolean);
  const disabled = questionCount === 0;
  return (
    <div className="flex justify-center items-center gap-2 lg:w-auto w-full">
      <LabeledCheckbox
        id={`${mode}-checkbox`}
        checked={checked}
        onCheckedChange={
          externalOnCheckedChange ??
          ((checked) => store.toggleField(mode, checked))
        }
        disabled={disabled}
        label={externalLabel ?? mode.at(0)!.toUpperCase() + mode.slice(1)}
        className="lg:w-auto w-full"
      />
      <QuestionCountBadge loading={loading} count={questionCount} />
    </div>
  );
};

export default QuestionModeCheckbox;
