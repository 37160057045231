import { createFileRoute, Outlet, useNavigate } from "@tanstack/react-router";
import Accordion from "@/components/Accordion";
import { useCreateTestPageStore } from "@/utils/stores/createTestPageStore";
import CreateTestModeSection from "@/components/CreateTestModeSection";
import CreateTestQuestionModeSection from "@/components/CreateTestQuestionModeSection";
import { DashboardFrame } from "@/components/DashboardFrame";
import { useDashboardLayoutStore } from "@/utils/stores/dashboardLayoutStore";
import { PropsWithChildren, useEffect } from "react";
import { Input } from "@/components/Input";
import { Label } from "@/components/Label";

import Span from "@/components/common/Span";
import CreateTestNameSection from "@/components/CreateTestNameSection";
import CreateTestPageContextProvider from "./-create-test-page-components/create-test-page-provider";
import useQuestionBankSubjects from "@/hooks/subject-hooks/use-question-bank-subjects";
import useQuestionBankSystems from "@/hooks/system-hooks/use-question-bank-systems";
import useQuestionBankTopics from "@/hooks/topic-hooks/use-question-bank-topics";
import useQuestionBankQuestionHeaders from "@/hooks/question-hooks/use-question-bank-question-headers";
import CreateTestButton from "./-create-test-page-components/creat-test-button";
import { useTitle } from "@/hooks/common/use-title";
import useDashboardQuestionBank from "../-dashboard-hooks/use-dashboard-question-bank";

export const Route = createFileRoute(
  "/qbanks/$qbankEndpoint/dashboard/_dashboard/create-test/_create-test",
)({
  component: CreateTestPage,
});

function CreateTestPage() {
  const params = Route.useParams();
  const { data: questionBank } = useDashboardQuestionBank();
  useTitle(`Create Test in ${questionBank?.name}`);
  const {
    toggledSections,
    mode,
    questionCount,
    minutesPerQuestion,
    setMinutesPerQuestion,
    unused,
    incorrect,
    marked,
    omitted,
    correct,
  } = useCreateTestPageStore((state) => ({
    toggledSections: state.toggledSections,
    selectedSubjects: state.selectedSubjects,
    selectedTopics: state.selectedTopics,
    selectedSystems: state.selectedSystems,
    selectedBlueprint: state.selectedBlueprint,
    name: state.name,
    minutesPerQuestion: state.minutesPerQuestion,
    setMinutesPerQuestion: state.setMinutesPerQuestion,
    qPool: {
      unused: state.unused,
      marked: state.marked,
      incorrect: state.incorrect,
      correct: state.correct,
      omitted: state.omitted,
    },
    qmode: state.qmode,
    questionCount: state.questionCount,
    mode: {
      tutor: state.tutor,
      timed: state.timed,
    },
    unused: state.unused,
    incorrect: state.incorrect,
    marked: state.marked,
    omitted: state.omitted,
    correct: state.correct,

    questionIds: state.questionIds,
    reset: state.reset,
  }));

  const setHeaderText = useDashboardLayoutStore((state) => state.setHeaderText);

  useEffect(() => {
    setHeaderText("Create Test");
    return () => {
      setHeaderText("");
    };
  }, [setHeaderText]);

  const questionsQuery = useQuestionBankQuestionHeaders(
    {
      questionBankEndpoint: params.qbankEndpoint,
    },
    {
      filters: {
        stats: [
          ...(unused ? ["unused"] : []),
          ...(incorrect ? ["incorrect"] : []),
          ...(correct ? ["correct"] : []),
          ...(marked ? ["marked"] : []),
          ...(omitted ? ["omitted"] : []),
        ] as ("unused" | "correct" | "incorrect" | "marked" | "omitted")[],
      },
    },
  );

  const subjectsQuery = useQuestionBankSubjects({
    questionBankEndpoint: params.qbankEndpoint,
  });
  const systemsQuery = useQuestionBankSystems({
    questionBankEndpoint: params.qbankEndpoint,
  });
  const topicsQuery = useQuestionBankTopics({
    questionBankEndpoint: params.qbankEndpoint,
  });

  return (
    <CreateTestPageContextProvider
      value={{
        questionBankEndpoint: params.qbankEndpoint,
        subjects: subjectsQuery,
        systems: systemsQuery,
        topics: topicsQuery,
        questions: questionsQuery,
      }}
    >
      <div className="flex flex-col justify-start items-start w-full gap-5">
        <DashboardFrame className="w-full">
          <Accordion
            value={toggledSections}
            onValueChange={(value) => undefined}
          >
            <CreateTestModeSection />
            <CreateTestNameSection />
            <CreateTestQuestionModeSection />
            <Outlet />
          </Accordion>
        </DashboardFrame>
        <div className="flex flex-row lg:justify-start lg:items-center justify-center items-center w-full">
          <div className="flex flex-col lg:justify-start lg:items-start justify-start items-center gap-2 w-full">
            <div className="flex flex-col lg:justify-start lg:items-start justify-start items-center gap-4 w-full">
              {mode.timed && (
                <>
                  <div className="flex flex-row justify-start items-center gap-2">
                    <Input
                      className="text-sm md:text-sm lg:text-sm py-2 text-center rounded-sm bg-gray-200 border-gray-300 outline-gray-400 text-gray-500 w-12 h-10"
                      min={0.1}
                      id="minutes-per-question-input"
                      type="number"
                      value={minutesPerQuestion}
                      onChange={(e) =>
                        setMinutesPerQuestion(Number(e.target.value))
                      }
                    />
                    <Label htmlFor="minutes-per-question-input">
                      <Span> Minutes / Question</Span>
                    </Label>
                  </div>
                  <Span>
                    Total Test Time ={" "}
                    {(Number(questionCount) * minutesPerQuestion).toFixed(1)}{" "}
                    Minutes
                  </Span>
                </>
              )}
              <CreateTestButton isLoading={questionsQuery.isLoading} />
            </div>
          </div>
        </div>
      </div>
    </CreateTestPageContextProvider>
  );
}
