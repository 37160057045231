import useTestHighlights from "@/hooks/highlight-hooks/use-test-highlights";
import useTestHints from "@/hooks/hint-hooks/use-test-hints";
import NbmeLayout from "@/components/nbme-layout";
import useQuestionBank from "@/hooks/question-bank-hooks/use-question-bank";
import useTestQuestionNotes from "@/hooks/question-note-hooks/use-test-questionnotes";
import useTest from "@/hooks/test-hooks/use-test";
import useTestTestSlots from "@/hooks/test-slot-hooks/use-test-test-slots";
import useTestQuestions from "@/hooks/test-hooks/use-test-questions";
import useTestQuestionChoices from "@/hooks/question-choice-hooks/use-test-question-choices";
import useTestQuestionExplanations from "@/hooks/questionexplanation-hooks/use-test-questionexplanations";
import mergeFlags from "@/utils/common/merge-flags";
import ArrayMapUtils from "@/types/common/ArrayConvertableMap";
import { useMemo } from "react";

const useConstructedTest = ({ testId }: { testId: number }) => {
  const test = useTest({ testId });
  const questionBank = useQuestionBank({
    questionBankId: test.data?.questionBankId ?? "",
  });
  const testSlots = useTestTestSlots({ testId });
  const questions = useTestQuestions({ testId });
  const questionChoices = useTestQuestionChoices({ testId });
  const questionNotes = useTestQuestionNotes({ testId });
  const questionExplanations = useTestQuestionExplanations({ testId });
  const highlights = useTestHighlights({ testId });
  const hints = useTestHints({ testId });

  const slots = useMemo(() => {
    return ArrayMapUtils.toArray(
      ArrayMapUtils.map(testSlots.data, (ts) => {
        const question = ArrayMapUtils.find(questions.data, ts.questionId)!;
        const explanation = ArrayMapUtils.find(
          questionExplanations.data,
          (qe) => qe.questionId === question?.id,
        );

        return {
          ...ts,
          question: {
            ...question,
            note: ArrayMapUtils.find(
              questionNotes.data,
              (qn) => qn.questionId === question?.id,
            ),
            highlights: ArrayMapUtils.toArray(
              ArrayMapUtils.filter(
                highlights.data,
                (h) => h.questionId === question?.id,
              ),
            ),
            hints: ArrayMapUtils.toArray(
              ArrayMapUtils.filter(
                hints.data,
                (h) => h.questionId === question?.id,
              ),
            ),
            explanation: {
              ...explanation,
              highlights: ArrayMapUtils.toArray(
                ArrayMapUtils.filter(
                  highlights.data,
                  (h) => h.questionExplanationId === question?.explanationId,
                ),
              ),
              hints: ArrayMapUtils.toArray(
                ArrayMapUtils.filter(
                  hints.data,
                  (h) => h.explanationId === question?.explanationId,
                ),
              ),
            },
            choices: ArrayMapUtils.toArray(
              ArrayMapUtils.filter(
                questionChoices.data,
                (qn) => qn.questionId === question?.id,
              ),
            ),
          },
        };
      }),
    );
  }, [
    testSlots,
    questions,
    questionChoices,
    questionNotes,
    questionExplanations,
    highlights,
    hints,
  ]);

  return useMemo(
    () => ({
      ...mergeFlags({
        source: test,
        destinations: [
          questionBank,
          testSlots,
          questions,
          questionChoices,
          questionNotes,
          questionExplanations,
          highlights,
          hints,
        ],
      }),
      data: test.data
        ? {
            ...test.data,
            slots,
          }
        : undefined,
    }),
    [
      questionBank,
      testSlots,
      questions,
      questionChoices,
      questionNotes,
      questionExplanations,
      highlights,
      hints,
      test,
    ],
  );
};
export default useConstructedTest;
