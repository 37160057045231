import { countries } from "countries-list";
import {
  z,
  ZodError,
  ZodErrorMap,
  ZodIssueCode,
  ZodIssueOptionalMessage,
  ZodObject,
  ZodSchema,
} from "zod";

export type UpdateFormType<T = any> = (
  update: string,
  field: keyof T,
  errorField: keyof T,
) => void;

const countryCodes = Object.keys(countries).map((cc) =>
  cc.toUpperCase(),
) as unknown as readonly [string, ...string[]];

type ZodIssueCodeMapper = { [k in ZodIssueCode]: string };

const createErrorMap =
  (mapper: Partial<ZodIssueCodeMapper>): ZodErrorMap =>
  (issue: ZodIssueOptionalMessage) => {
    return { message: mapper[issue.code] || "Something is wrong" };
  };

export const signinSchema = z
  .object({
    username: z
      .string({
        errorMap: createErrorMap({
          too_small: "Please enter your username or email.",
        }),
      })
      .min(1)
      .trim()
      .toLowerCase(),
    password: z
      .string({
        errorMap: createErrorMap({ too_small: "Please enter your password." }),
      })
      .min(1)
      .trim(),
  })
  .required();

const defaultCodeIssueMapper = ({
  fieldName,
  max,
  min,
}: {
  fieldName: string;
  max: number;
  min?: number;
}): Partial<ZodIssueCodeMapper> => ({
  too_small: min
    ? `Please enter your ${fieldName} with a minimum of ${min} characters.`
    : `Please enter your ${fieldName}`,
  too_big: `Your ${fieldName} can't be longer than ${max}`,
});

export const signupSchema = z.object({
  name: z
    .string({
      errorMap: createErrorMap(
        defaultCodeIssueMapper({ fieldName: "name", max: 255, min: 2 }),
      ),
    })
    .min(2)
    .max(255)
    .regex(/^[a-zA-Z \ ]+$/, "Your name must only contain english letters.")
    .trim(),

  username: z
    .string({
      errorMap: createErrorMap(
        defaultCodeIssueMapper({ fieldName: "username", max: 255, min: 2 }),
      ),
    })
    .min(3)
    .max(255)
    .toLowerCase()
    .trim(),

  email: z
    .string({
      errorMap: createErrorMap({
        ...defaultCodeIssueMapper({ fieldName: "email", max: 255 }),
        invalid_string: "This is not a valid email.",
      }),
    })
    .min(1)
    .max(255)
    .email()
    .trim()
    .toLowerCase(),

  password: z
    .string({
      errorMap: createErrorMap(
        defaultCodeIssueMapper({ fieldName: "password", max: 255 }),
      ),
    })
    .min(1)
    .max(255)
    .trim(),

  phoneNumber: z
    .string({
      errorMap: createErrorMap({
        ...defaultCodeIssueMapper({
          fieldName: "phone number",
          max: 255,
          min: 10,
        }),
        invalid_string:
          "Your phone number must start with a + followed by the country code.",
      }),
    })
    .max(255)
    .trim()
    .startsWith("+")
    .min(10)
    .regex(/^[0-9+]*$/, "Your phone number can only contain numbers."),

  country: z.enum(countryCodes, {
    errorMap: createErrorMap({
      ...defaultCodeIssueMapper({ fieldName: "country", max: 255 }),
      invalid_enum_value: "Please select a valid country.",
    }),
  }),
});

export const newQbankSchema = z.object({
  name: z
    .string({
      errorMap: createErrorMap(
        defaultCodeIssueMapper({ fieldName: "name", max: 255 }),
      ),
    })
    .min(1)
    .max(255),
  endpoint: z
    .string()
    .regex(
      /^[a-zA-Z0-9]+$/,
      "The url for the bank cannot contain anything other than letters and numbers",
    ),
  subjects: z.array(z.string()),
  systems: z.array(z.object({ name: z.string(), topics: z.array(z.string()) })),
});

export const qbankEndpointSchema = z
  .string()
  .regex(
    /^[a-zA-Z0-9]+$/,
    "Qbank endpoint must not contain any spaces or special characters.",
  );

export const validateFormField = <FormState>(
  newValue: string,
  field: keyof FormState,
  formSchema: ZodObject<{}>,
) => {
  try {
    if (!newValue) return "";
    //@ts-ignore
    formSchema.pick({ [field]: true }).parse({ [field]: newValue });
    return "";
  } catch (exc) {
    return (exc as ZodError).issues[0]?.message;
  }
};

export const updateForm = <T>(
  update: string,
  field: keyof T,
  errorField: keyof T,
  formSchema: ZodObject<{}>,
) => {
  return {
    [field]: update,
    [errorField]: validateFormField<T>(update, field, formSchema),
  };
};

export const createTestSchema = z.object({
  name: z.string().max(1025),
  mode: z.array(z.enum(["tutor", "timed"])).max(2),
  minutesPerQuestion: z.number().optional().default(1.5),
  qPool: z
    .array(z.enum(["unused", "incorrect", "marked", "omitted", "correct"]))
    .min(1)
    .max(5),
  subjects: z.array(z.string().length(25)).min(1),
  systems: z.array(z.string().length(25)),
  topics: z.array(z.string().length(25)).min(1),
  qCount: z.number().max(40),
});

export const createCustomTestSchema = z.object({
  mode: z.array(z.enum(["tutor", "timed"])).max(2),
  questionIds: z.array(z.number()),
  name: z.string(),
  minutesPerQuestion: z.number().optional().default(1.5),
});
