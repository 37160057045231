import { useCreateTestPageStore } from "@/utils/stores/createTestPageStore";
import SystemCheckbox from "./SystemCheckbox";
import ArrayMapUtils from "@/types/common/ArrayConvertableMap";
import useCreateTestPageSystems from "@/routes/qbanks/$qbankEndpoint/dashboard/_dashboard.create-test/-hooks/use-create-test-page-systems";
import useGetSystemQuestionHeaders from "@/hooks/question-hooks/use-get-system-question-headers";
import useDashboardQuestionBank from "@/routes/qbanks/$qbankEndpoint/dashboard/-dashboard-hooks/use-dashboard-question-bank";
import useCreateTestPageQuestionHeaders from "@/routes/qbanks/$qbankEndpoint/dashboard/_dashboard.create-test/-hooks/use-create-test-page-questions";
import { useMemo } from "react";

const CreateTestSystems = () => {
  const { data: questionBank, isLoading: isQuestionBankLoading } =
    useDashboardQuestionBank();
  const { data: systemsArrayMap, isLoading: isSystemsLoading } =
    useCreateTestPageSystems();
  const { data: questionHeaders, isLoading: isQuestionHeadersLoading } =
    useCreateTestPageQuestionHeaders();
  const { toggleSelection, selectedSystems, selectedSubjects } =
    useCreateTestPageStore((state) => ({
      toggleSelection: state.toggleSelection,
      selectedSystems: state.selectedSystems,
      selectedSubjects: state.selectedSubjects,
    }));
  const systems = useMemo(
    () =>
      ArrayMapUtils.toArray(systemsArrayMap).sort((a, b) =>
        a.name.localeCompare(b.name),
      ),
    [systemsArrayMap],
  );
  const getSystemQuestionHeadersQuery = useGetSystemQuestionHeaders(
    {
      questionBankEndpoint: questionBank?.endpoint ?? "",
    },
    {
      filters: {
        questionHeaders,
        subjects: selectedSubjects.map((ss) => ss.id),
      },
    },
  );
  const { data: getSystemQuestionHeaders, isLoading: isProgressLoading } =
    getSystemQuestionHeadersQuery;

  const systemGroups = [
    systems.slice(0, systems.length - 10),
    systems.slice(systems.length - 10),
  ];
  const isLoading =
    isQuestionBankLoading ||
    isSystemsLoading ||
    isQuestionHeadersLoading ||
    isProgressLoading;

  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 lg:grid-rows-1 grid-flow-row lg:gap-x-16 w-full lg:max-w-[80%]">
      {systemGroups.map((sg, index) => {
        return (
          <div
            key={index}
            className="flex flex-col justify-start items-center lg:items-start gap-y-2 w-full"
          >
            {sg &&
              sg.map((system) => {
                const checked = !!selectedSystems.find(
                  (s) => s.id === system.id,
                );
                return (
                  <SystemCheckbox
                    className="w-full"
                    key={system.id}
                    system={{
                      ...system,
                    }}
                    questionCount={ArrayMapUtils.getLength(
                      getSystemQuestionHeaders(system.id),
                    )}
                    isLoading={isLoading}
                    checked={checked}
                    onClick={() =>
                      toggleSelection("selectedSystems", !checked, system)
                    }
                  />
                );
              })}
          </div>
        );
      })}
    </div>
  );
};
export default CreateTestSystems;
