import { ViewerBody, ViewerToolbar } from "@/components/viewer";
import useFloatingWindowContext from "./hooks/use-floating-window-context";
import { cn } from "@/lib/utils";
import { ComponentProps, PropsWithChildren, useEffect } from "react";

type FloatingWindowViewerProps = PropsWithChildren &
  ComponentProps<typeof ViewerToolbar>;
const FloatingWindowViewer = ({ children }: FloatingWindowViewerProps) => {
  const { viewer } = useFloatingWindowContext();

  useEffect(() => {
    viewer.setIsMounted(true);
  }, []);

  return (
    <>
      <ViewerBody
        className={cn("!min-h-0", viewer.hasOverflow && "cursor-grab")}
      >
        {children}
      </ViewerBody>
    </>
  );
};

export default FloatingWindowViewer;
