import { PropsWithClassName } from "@/types";
import { cn } from "@/lib/utils";
import { PropsWithChildren } from "react";
import WindowTitlebarGroup from "./window-titlebar-group";

type WindowButtonsProps = PropsWithClassName & PropsWithChildren;

const WindowButtons = ({ children, className }: WindowButtonsProps) => {
  return (
    <WindowTitlebarGroup className={cn("right-0", className)}>
      {children}
    </WindowTitlebarGroup>
  );
};

export default WindowButtons;
