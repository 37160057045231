import { useAppStore } from "@/store";
import ArrayMapUtils from "@/types/common/ArrayConvertableMap";
import useCurrentUser from "@/hooks/auth-hooks/use-current-user";
import { useMemo } from "react";

const useHighlights = () => {
  const currentUser = useCurrentUser();
  const highlights = useAppStore((state) => state.highlights);
  const userHighlights = useMemo(
    () =>
      ArrayMapUtils.filter(
        highlights,
        (highlight) => highlight.userId === currentUser?.id,
      ),
    [highlights, currentUser],
  );
  return userHighlights;
};

export default useHighlights;
