"use client";
import { cva, VariantProps } from "class-variance-authority";
import { PropsWithClassName } from "@/types";
import useCornerGesture from "./hooks/use-corner-gesture";
import { ResizableContext } from "./resizable-context";
import { ArgumentTypes } from "@/types";
import useResizableContext from "./hooks/use-resizable-context";
import mergeObjectFunctions from "@/lib/utils/merge-object-functions";

const resizableCornerVariants = cva("absolute w-4 h-4 bg-transparent", {
  variants: {
    placement: {
      "top-left":
        "-top-1 -left-1 group-data-[is-resizable=true]/resizable:cursor-nw-resize",
      "top-right":
        "-top-1 -right-1 group-data-[is-resizable=true]/resizable:cursor-ne-resize",
      "bottom-left":
        "-bottom-1 -left-1 group-data-[is-resizable=true]/resizable:cursor-sw-resize",
      "bottom-right":
        "-bottom-1 -right-1 group-data-[is-resizable=true]/resizable:cursor-se-resize",
    },
  },
});

type ResizableCornerProps = VariantProps<typeof resizableCornerVariants> &
  PropsWithClassName &
  Pick<ResizableContext, "events">;

type BinderPlacementType = ArgumentTypes<
  ReturnType<typeof useCornerGesture>
>[0]["placement"];

const ResizableCorner = ({
  events,
  className,
  placement,
}: ResizableCornerProps) => {
  const context = useResizableContext();
  const bind = useCornerGesture({
    placement: placement?.split("-") as BinderPlacementType,
    events: mergeObjectFunctions(events, context.events),
  });

  if (!placement) {
    console.error("You have to specify a placement for this corner!!");
    return <></>;
  }

  return (
    <div
      {...bind()}
      className={resizableCornerVariants({ placement, className })}
      style={{ touchAction: "none" }}
    ></div>
  );
};

export default ResizableCorner;
