import { twMerge } from "tailwind-merge";
import { Buttons, Title, ToolTip } from "./components";
import { AnimatePresence, motion } from "framer-motion";
import { fadeAnimation } from "@/assets/framer";
import { useSelectedNote, useSetSelectedNoteId } from "@/hooks/notebook";

interface BodyProps {
  id: string;
  title: string;
  isCollapsable: boolean;
  isCollapsed: boolean;
  onIsCollapsedChange: (newIsCollapsed: boolean) => void;
  showButtons: boolean;
  onTrashClicked: () => void;
  onPlusClicked: () => void;
  onXClicked: () => void;
  onCheckClicked: () => void;
  onPenClicked: () => void;
  isEditMode: boolean;
  onTitleChange: (newTitle: string) => void;
  onEditSubmit: (newTitle: string) => void;
  onSelect?: () => void;
  isSelectable?: boolean;
}

export const Body = ({
  id,
  title,
  isCollapsed,
  isCollapsable,
  onIsCollapsedChange,
  showButtons,
  onCheckClicked,
  onSelect,
  onPlusClicked,
  onPenClicked,
  onXClicked,
  onTitleChange,
  onTrashClicked,
  isEditMode,
  onEditSubmit,
  isSelectable = true,
}: BodyProps) => {
  const selectedNote = useSelectedNote();
  const setSelectedNoteId = useSetSelectedNoteId();
  const selected = id === selectedNote?.id;
  return (
    <div
      className={twMerge(
        "relative flex flex-row justify-between items-center w-full rounded-primary warm:hover:bg-brown-100 hover:bg-gray-200 dark:hover:bg-neutral-800 transition-colors px-3 py-2 gap-2 group",
        isEditMode && "bg-gray-200 dark:bg-neutral-800",
        selected && "bg-gray-200 warm:bg-brown-100 dark:bg-neutral-800",
      )}
    >
      {title && <ToolTip title={title} />}
      <Title
        isSelected={selected}
        title={title}
        isCollapsable={isCollapsable}
        isCollapsed={isCollapsed}
        onIsCollapsedChange={onIsCollapsedChange}
        onEditSubmit={onEditSubmit}
        onTitleChange={onTitleChange}
        isEditMode={isEditMode}
        onSideIconClick={() => undefined}
        onTitleClick={() => {
          onSelect?.();
          if (!isSelectable) return;
          setSelectedNoteId(id);
        }}
      />
      <AnimatePresence mode="wait">
        {(showButtons || isEditMode) && (
          <motion.div {...fadeAnimation}>
            <Buttons
              onPenClicked={onPenClicked}
              onXClicked={onXClicked}
              onCheckClicked={onCheckClicked}
              isEditMode={isEditMode}
              onTrashClicked={onTrashClicked}
              onPlusClicked={onPlusClicked}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
