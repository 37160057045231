import useCurrentUser from "@/hooks/auth-hooks/use-current-user";
import { useAppStore } from "@/store";
import ArrayMapUtils from "@/types/common/ArrayConvertableMap";
import { useMemo } from "react";

const useTests = () => {
  const user = useCurrentUser();
  const tests = useAppStore((state) => state.tests);
  const userTests = useMemo(
    () =>
      user ? ArrayMapUtils.filter(tests, (t) => t.userId === user.id) : {},
    [tests, user],
  );
  return userTests;
};

export default useTests;
