import { faXmarkCircle } from "@fortawesome/free-solid-svg-icons";
import { useNbmeLayoutStore } from "@/utils/stores/nbmeLayoutStore";
import { motion } from "framer-motion";
import { NbmeSettingsAppearanceSection } from "../NbmeSettingsAppearanceSection";
import { NbmeSettingsNightModeSection } from "../NbmeSettingsNightModeSection";
import { NbmeSettingsFeaturesSection } from "../NbmeSettingsFeaturesSection";
import { NbmeSettingsHighlightsSection } from "../NbmeSettingsHighlightsSection";
import NbmeSidePanel from "../nbme-side-panel";
import NbmeSettingsWindowsSection from "../common/nbme/NbmeSettingsWindowsSection";

export const NbmeSettingsBar = () => {
  const { settingsVisible, setSettingsVisible } = useNbmeLayoutStore(
    (state) => ({
      settingsVisible: state.settingsVisible,
      setSettingsVisible: state.setSettingsVisible,
    }),
  );

  return (
    <NbmeSidePanel.Root
      open={settingsVisible}
      onOpenChanged={setSettingsVisible}
    >
      <NbmeSidePanel.Body className="max-w-xs pe-0">
        <NbmeSidePanel.Header className="z-[999]">
          <NbmeSidePanel.Title>Settings</NbmeSidePanel.Title>
          <NbmeSidePanel.CloseButton />
        </NbmeSidePanel.Header>
        <div className="flex flex-col justify-start items-center gap-2 w-full h-full py-3 pe-4">
          <NbmeSettingsAppearanceSection />
          <NbmeSettingsHighlightsSection />
          <NbmeSettingsWindowsSection />
          <NbmeSettingsNightModeSection />
          <NbmeSettingsFeaturesSection />
        </div>
      </NbmeSidePanel.Body>
    </NbmeSidePanel.Root>
  );
};
