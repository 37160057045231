import { TableRow } from "@/types";
import { ItemPerformanceReport } from "./-types";

export const row: TableRow<ItemPerformanceReport> = {
  collapsible: true,
  columns: [
    {
      name: "Name",
      visible: true,
      content: (report) => {
        return report;
      },
    },
    {
      name: "Usage",
      visible: true,
      content: (report) => {
        const { total, used } = report;
        return `${used}/${total}`;
      },
    },
    {
      name: "Correct",
      visible: true,
      content: (report) => {
        const { correct, used } = report;
        const percentage = used ? (correct / used) * 100 : 0;
        return `${correct} (${percentage.toFixed(1)}%)`;
      },
    },
    {
      name: "Incorrect",
      visible: true,
      content: (report) => {
        const { incorrect, used } = report;
        const percentage = used ? (incorrect / used) * 100 : 0;
        return `${incorrect} (${percentage.toFixed(1)}%)`;
      },
    },
    {
      name: "Omitted",
      visible: true,
      content: (report) => {
        const { omitted, used } = report;
        const percentage = used ? (omitted / used) * 100 : 0;
        return `${omitted} (${percentage.toFixed(1)}%)`;
      },
    },
  ],
  collapsedColumns: [
    {
      name: "Name",
      visible: true,
      content: (report) => {
        return report;
      },
    },
    {
      name: "Usage",
      visible: true,
      content: (report) => {
        const { total, used } = report;
        return `${used}/${total}`;
      },
    },
    {
      name: "Correct",
      visible: true,
      content: (report) => {
        const { correct, used } = report;
        const percentage = used ? (correct / used) * 100 : 0;
        return `${correct} (${percentage.toFixed(1)}%)`;
      },
    },
    {
      name: "Incorrect",
      visible: true,
      content: (report) => {
        const { incorrect, used } = report;
        const percentage = used ? (incorrect / used) * 100 : 0;
        return `${incorrect} (${percentage.toFixed(1)}%)`;
      },
    },
    {
      name: "Omitted",
      visible: true,
      content: (report) => {
        const { omitted, used } = report;
        const percentage = used ? (omitted / used) * 100 : 0;
        return `${omitted} (${percentage.toFixed(1)}%)`;
      },
    },
  ],
};
