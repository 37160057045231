import { Hint, Question, QuestionChoice, TestSlot } from "@/api/types";
import { FontSizes } from "@/types";
import { scaleUnitInRem } from "@/utils/common/scaleUnitInRem";
import { useBreakPoint } from "@/utils/hooks";
import {
  audioFilter,
  imageFilter,
  linkFilter,
  listFilter,
  paragraphFilter,
  tableFilter,
} from "@/utils/interweave/filters";
import { trpc } from "@/utils/trpc";
import { Interweave } from "interweave";
import { useEffect } from "react";
import { toast } from "sonner";
import { twMerge } from "tailwind-merge";
import {
  NbmeChoiceContainer,
  NbmeChoices,
  NbmeQuestionText,
  NbmeResultCard,
} from ".";
import { LoadingSpinner } from "@/components/LoadingSpinner";
import { Button } from "@/components/Button";
import { hintTransformer } from "@/utils/interweave/transformers/hintTransformer";

interface NbmeQuestionProps<T = "test" | "question"> {
  variant: T;
  currentSlot?: T extends "test"
    ? TestSlot & { chosen: QuestionChoice }
    : undefined;
  question: Question & { hints: Hint[]; choices: QuestionChoice[] };
  fontSizeFactor: number;
  onCurrentSlotSubmitted?: T extends "test" ? () => void : undefined;
  isConfirmAnswerOmission?: T extends "test" ? boolean : undefined;
  onIsConfirmOmissionDialogVisibleChange?: T extends "test"
    ? (v: boolean) => void
    : undefined;
  isPercentageAnsweredVisible: boolean;
  onIsPercentageAnsweredVisibleChange: (v: boolean) => void;
  isTestTutor?: T extends "test" ? boolean : undefined;
}

export const NbmeQuestion = <T = "test" | "question",>({
  question,
  fontSizeFactor,
  variant,
  onIsConfirmOmissionDialogVisibleChange,
  isConfirmAnswerOmission,
  onCurrentSlotSubmitted,
  isPercentageAnsweredVisible,
  onIsPercentageAnsweredVisibleChange,
  isTestTutor,
  currentSlot,
}: NbmeQuestionProps<T>) => {
  const breakPoint = useBreakPoint();

  const {
    data: questionImage,
    isSuccess: isQuestionImageSuccess,
    isError: isQuestionImageError,
    error: questionImageError,
    isLoading: isQuestionImageLoading,
  } = trpc.image.getById.useQuery(
    { id: question.imageId!, size: 2 },
    {
      enabled: !!question.imageId,
    },
  );
  const {
    data: questionAudio,
    isSuccess: isQuestionAudioSuccess,
    isError: isQuestionAudioError,
    error: questionAudioError,
    isLoading: isQuestionAudioLoading,
  } = trpc.audio.getById.useQuery(
    { id: question.audioId! },
    {
      enabled: !!question.audioId,
    },
  );

  useEffect(() => {
    if (isQuestionImageError) {
      toast.error(`Could not retrieve image for this question`);
    }
  }, [isQuestionImageError]);

  return (
    <div
      id={`${question?.id}text`}
      className={twMerge(
        "flex flex-col justify-start lg:items-start items-center gap-4 w-full selection:bg-[yellow] selection:text-black selection:font-bold",
      )}
      style={{
        fontSize: scaleUnitInRem(FontSizes.Base, fontSizeFactor)
          .resultInRemAsCss,
      }}
    >
      {question && question.textHtml ? (
        <Interweave
          content={question.textHtml}
          filters={[
            imageFilter(questionImage?.dataUrl),
            tableFilter(breakPoint, fontSizeFactor),
            listFilter,
            linkFilter,
            paragraphFilter(fontSizeFactor),
          ]}
          transform={hintTransformer({
            questionSource: question.source ?? "",
          })}
        />
      ) : (
        <NbmeQuestionText>{question.text}</NbmeQuestionText>
      )}
      {question &&
        question.mediaHtml &&
        (isQuestionAudioLoading ? (
          <div className="flex flex-row justify-center items-center w-full md:w-auto">
            <div className="lg:w-14 md:w-12 w-10">
              <LoadingSpinner />
            </div>
          </div>
        ) : (
          <Interweave
            content={question.mediaHtml}
            filters={[audioFilter(questionAudio?.dataUrl)]}
          />
        ))}
      <NbmeChoiceContainer>
        <NbmeChoices
          question={question}
          fontSizeFactor={fontSizeFactor}
          isTestTutor={isTestTutor || false}
          variant={variant}
          isPercentageAnsweredVisible={isPercentageAnsweredVisible}
        />
      </NbmeChoiceContainer>
      {variant === "question" || currentSlot?.submitted ? (
        <NbmeResultCard
          variant={variant}
          isPercentageAnsweredVisible={isPercentageAnsweredVisible}
          fontSizeFactor={fontSizeFactor}
          onIsPercentageAnsweredVisibleChange={
            onIsPercentageAnsweredVisibleChange
          }
          question={question}
        />
      ) : (
        isTestTutor && (
          <Button
            NBME
            type="button"
            className="w-min"
            onClick={() => {
              if (isConfirmAnswerOmission && !currentSlot?.chosen) {
                onIsConfirmOmissionDialogVisibleChange?.(true);
                return;
              }
              onCurrentSlotSubmitted?.();
            }}
          >
            Submit
          </Button>
        )
      )}
    </div>
  );
};
