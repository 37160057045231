import { DashboardTable } from "@/components/DashboardTable";
import { useReportOptions } from "../../-hooks";
import { ProgressBar } from "@/components/ProgressBar";
import { percent } from "@/utils/common/percent";
import useQuestionBankTestSystems from "@/hooks/system-hooks/use-question-bank-test-systems";
import { Test } from "@/models/test-model";
import useQuestionBankSystemPerformanceReports from "@/hooks/question-hooks/use-question-bank-system-performance-reports";
import useTestQuestionHeaders from "@/hooks/question-hooks/use-test-question-headers";
import useDashboardQuestionBank from "@/routes/qbanks/$qbankEndpoint/dashboard/-dashboard-hooks/use-dashboard-question-bank";

export const PerformanceTestAnalysisPageSystemsTable = ({
  testId,
}: {
  testId: Test["id"];
}) => {
  const { data: questionBank } = useDashboardQuestionBank();
  const questionBankEndpoint = questionBank?.endpoint ?? "";
  const { options } = useReportOptions();
  const { data: systems } = useQuestionBankTestSystems({
    questionBankEndpoint,
    testId,
  });
  const { data: questionHeaders } = useTestQuestionHeaders({ testId });
  const { data: testPerformance } = useQuestionBankSystemPerformanceReports(
    {
      questionBankEndpoint,
    },
    { filters: { systems, questionHeaders } },
  );
  if (!testPerformance) return <></>;
  return (
    <DashboardTable
      currentPage={1}
      itemsPerPage={1000000}
      items={testPerformance.sort((a, b) => a.name.localeCompare(b.name))}
      columnContentMapper={(column, content) => {
        if (column.name === "Name") {
          const { name, correct, incorrect, omitted, total } = content;
          return (
            <div className="flex flex-col justify-start items-start gap-3 w-[10rem] md:w-[20rem]">
              <p className="text-start">{name}</p>
              <ProgressBar
                className="bg-white md:bg-gray-200"
                percentages={[
                  options.includes("Correct") ? percent(correct, total) : 0,
                  options.includes("Incorrect") ? percent(incorrect, total) : 0,
                  options.includes("Omitted") ? percent(omitted, total) : 0,
                ]}
                percentageClassNames={[
                  "bg-lime-500",
                  "bg-red-500",
                  "bg-sky-500",
                ]}
              />
            </div>
          );
        }
        return content;
      }}
      columns={{
        collapsible: true,
        columns: [
          {
            name: "Name",
            visible: true,
            content: (item) => ({
              name: item.name,
              total: item.total,
              correct: item.correct,
              incorrect: item.incorrect,
              omitted: item.omitted,
            }),
          },
          {
            name: "Total Q",
            visible: true,
            content: (item) => item.total,
          },
          {
            name: "Correct Q",
            visible: true,
            content: (item) => item.correct,
          },
          {
            name: "Incorrect Q",
            visible: true,
            content: (item) => item.incorrect,
          },
          {
            name: "Omitted Q",
            visible: true,
            content: (item) => item.omitted,
          },
        ],
        collapsedColumns: [
          {
            name: "Name",
            visible: true,
            content: (item) => item.name,
          },
          {
            name: "Total Q",
            visible: true,
            content: (item) => item.total,
          },
          {
            name: "Correct Q",
            visible: true,
            content: (item) => item.correct,
          },
          {
            name: "Incorrect Q",
            visible: true,
            content: (item) => item.incorrect,
          },
          {
            name: "Omitted Q",
            visible: true,
            content: (item) => item.omitted,
          },
        ],
      }}
      collapsedItems={(item) => item.children}
    />
  );
};
