import ArrayMapUtils from "@/types/common/ArrayConvertableMap";
import { HintSliceCreator, hintSliceState } from ".";

const createHintSlice: HintSliceCreator = (set) => {
  return {
    ...hintSliceState,
    addHint: (hint) =>
      set((state) => {
        ArrayMapUtils.push(state.hints, [hint]);
      }),
    addHints: (hints) =>
      set((state) => {
        ArrayMapUtils.push(state.hints, hints);
      }),
  };
};

export default createHintSlice;
