import { DashboardFrame } from "./DashboardFrame";
import { CoursologyLogo } from "./CoursologyLogo";
import { useTitle } from "@/hooks/common/use-title";

export const DashboardComingSoonPage = (props: {}) => {
  useTitle("Coming soon...");
  return (
    <DashboardFrame className="relative min-h-[85vh]">
      <div className="text-gray-300 flex flex-col justify-center items-center gap-3 text-base md:text-xl lg:text-2xl absolute left-[50%] top-[50%] translate-y-[-50%] translate-x-[-50%]">
        <CoursologyLogo className="w-[18.75rem] dark:hidden dark:invisible" />
        <CoursologyLogo
          variant="mono"
          className="w-[18.75rem] hidden dark:block"
        />
        <p className="text-primary-500 warm:text-brown-700 dark:text-neutral-100">
          Coming soon...
        </p>
      </div>
    </DashboardFrame>
  );
};
