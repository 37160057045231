import useQuestionBankQuestionHeaders from "@/hooks/question-hooks/use-question-bank-question-headers";
import useQuestionBankSubjects from "@/hooks/subject-hooks/use-question-bank-subjects";
import useQuestionBankSystems from "@/hooks/system-hooks/use-question-bank-systems";
import useQuestionBankTopics from "@/hooks/topic-hooks/use-question-bank-topics";
import { createContext } from "react";

const createTestPageContext = createContext<
  | {
      questionBankEndpoint: string;
      questions: ReturnType<typeof useQuestionBankQuestionHeaders>;
      subjects: ReturnType<typeof useQuestionBankSubjects>;
      systems: ReturnType<typeof useQuestionBankSystems>;
      topics: ReturnType<typeof useQuestionBankTopics>;
    }
  | undefined
>(undefined);

export default createTestPageContext;
