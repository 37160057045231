import { useCreateTestPageStore } from "@/utils/stores/createTestPageStore";
import DashboardAccordionSection from "./DashboardAccordionSection";
import CreateTestSubjects from "./CreateTestSubjects";
import LabeledCheckbox from "./LabeledCheckbox";
import useCreateTestPageContext from "@/routes/qbanks/$qbankEndpoint/dashboard/_dashboard.create-test/-hooks/use-create-test-page-context";
import ArrayMapUtils from "@/types/common/ArrayConvertableMap";
import useGetSubjectQuestionHeaders from "@/hooks/question-hooks/use-get-subject-question-headers";
import useQuestionBankSubjects from "@/hooks/subject-hooks/use-question-bank-subjects";
import useCreateTestPageSubjects from "@/routes/qbanks/$qbankEndpoint/dashboard/_dashboard.create-test/-hooks/use-create-test-page-subjects";
import useCreateTestPageQuestionHeaders from "@/routes/qbanks/$qbankEndpoint/dashboard/_dashboard.create-test/-hooks/use-create-test-page-questions";
import { useMemo } from "react";

const CreateTestSubjectsSection = () => {
  const { questionBankEndpoint } = useCreateTestPageContext();
  const {
    selectedSubjects,
    setSelectedSubjects,
    toggleSection,
    toggledSections,
  } = useCreateTestPageStore();
  const { data: questionHeaders } = useCreateTestPageQuestionHeaders();
  const { data: subjects } = useCreateTestPageSubjects();
  const { data: getSubjectQuestionHeaders } = useGetSubjectQuestionHeaders(
    { questionBankEndpoint },
    {
      filters: {
        questionHeaders,
      },
    },
  );
  const isTitleChecked = useMemo(
    () =>
      selectedSubjects.length !== 0 &&
      selectedSubjects.length ===
        ArrayMapUtils.getLength(
          ArrayMapUtils.filter(
            subjects,
            (s) =>
              ArrayMapUtils.getLength(getSubjectQuestionHeaders(s.id)) !== 0,
          ),
        ),
    [selectedSubjects, subjects],
  );

  const allSubjects = useMemo(
    () =>
      ArrayMapUtils.toArray(
        ArrayMapUtils.filter(
          subjects,
          (s) =>
            !!ArrayMapUtils.find(
              getSubjectQuestionHeaders(s.id),
              (qh) => qh.subjectId === s.id,
            ),
        ),
      ),
    [subjects],
  );

  return (
    <DashboardAccordionSection
      title={
        <div className="flex flex-row justify-center items-center gap-2">
          <LabeledCheckbox
            labelClassName="font-medium text-black"
            id="all-subjects-checkbox"
            checked={isTitleChecked}
            onCheckedChange={(checked) =>
              checked
                ? setSelectedSubjects(allSubjects)
                : setSelectedSubjects([])
            }
            label="Subjects"
          />
        </div>
      }
      value="subjects"
      className="border-b"
      onToggle={() => toggleSection("subjects")}
      toggled={toggledSections.includes("subjects")}
    >
      <div className="lg:ml-8 flex flex-row justify-center lg:justify-start items-center gap-10 mt-3 w-full pb-5 pt-2">
        <CreateTestSubjects />
      </div>
    </DashboardAccordionSection>
  );
};

export default CreateTestSubjectsSection;
