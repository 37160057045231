import useQuestionBank from "@/hooks/question-bank-hooks/use-question-bank";
import { trpc } from "@/utils/trpc";
import { useEffect, useMemo } from "react";
import useAddSubjects from "./use-add-subjects";
import useSubjects from "./use-subjects";
import { QuestionBankIdentifier } from "@/types/question-bank-types";
import ArrayMapUtils from "@/types/common/ArrayConvertableMap";

type Opts = {
  enabled?: boolean;
};

const useQuestionBankSubjects = (args: QuestionBankIdentifier, opts?: Opts) => {
  const { data: questionBank } = useQuestionBank(args);
  const query = trpc.subject.getQuestionBankSubjects.useQuery(
    {
      qBankId: questionBank?.id ?? "",
    },
    { enabled: !!questionBank && (opts?.enabled ?? true) },
  );
  const subjects = useSubjects();
  const addSubjects = useAddSubjects();
  const { data, isLoading } = query;
  useEffect(() => {
    if (!!data && !isLoading) {
      addSubjects(data);
    }
  }, [data, isLoading]);
  const questionBankSubjects = useMemo(
    () => ArrayMapUtils.filter(subjects, (s) => s.bankId === questionBank?.id),
    [subjects],
  );

  return { ...query, data: questionBankSubjects };
};

export default useQuestionBankSubjects;
