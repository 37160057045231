import { Paginator } from "@/components/Paginator";
import { ComponentProps } from "react";

type PaginatorProps = Omit<
  ComponentProps<typeof Paginator>,
  "itemSingularName"
>;
export const PreviousTestsPagePaginator = ({ ...props }: PaginatorProps) => {
  return <Paginator {...props} itemSingularName="test" />;
};
