import { NbmeNavbarButtonWithIcon } from ".";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear } from "@fortawesome/free-solid-svg-icons";

interface NbmeSettingsNavbarButtonProps {
  isSettingsVisible: boolean;
  onIsSettingsVisibleChange: (v: boolean) => void;
}

export const NbmeSettingsNavbarButton = ({
  isSettingsVisible,
  onIsSettingsVisibleChange,
}: NbmeSettingsNavbarButtonProps) => {
  return (
    <NbmeNavbarButtonWithIcon
      className="w-full md:w-auto"
      icon={<FontAwesomeIcon icon={faGear} className="w-8 h-8" />}
      label="Settings"
      onClick={() => onIsSettingsVisibleChange(!isSettingsVisible)}
    />
  );
};
