import { useState } from "react";
import { NbmeFloatingWindow } from "./NbmeFloatingWindow";
import { Button } from "./Button";
import { useNbmeLayoutStore } from "@/utils/stores/nbmeLayoutStore";
import LabeledCheckbox from "./LabeledCheckbox";
import toast from "@/lib/client/toast";

export const NbmeFeedbackWindow = () => {
  const [draft, setDraft] = useState<string>("");
  const [technical, setTechnical] = useState<boolean>(false);

  const { feedbackVisible, setFeedbackVisible, setShortcutsEnabled } =
    useNbmeLayoutStore((state) => ({
      feedbackVisible: state.feedbackVisible,
      setFeedbackVisible: state.setFeedbackVisible,
      setShortcutsEnabled: state.setShortcutsEnabled,
    }));

  const giveFeedback = () => undefined;

  return (
    <NbmeFloatingWindow
      visible={feedbackVisible}
      title={"Submit Feedback"}
      maximizable={false}
      className="lg:min-w-[30rem] md:min-w-[25rem] min-w-[20rem]"
      resizable={false}
      draggableFromBody={false}
      viewportClassName="dark:bg-nbme-primary-dark-800"
      onCloseClicked={() => {
        setFeedbackVisible(false);
        setDraft("");
      }}
    >
      <form
        onSubmit={() => {
          if (!draft) {
            setFeedbackVisible(false);
            return;
          }
          giveFeedback();
          toast.success("Thank you for your feedback!");
          setFeedbackVisible(false);
        }}
        className="flex flex-col justify-start items-center w-full p-3 gap-3"
      >
        <textarea
          value={draft}
          onChange={(e) => setDraft(e.target.value)}
          onFocus={() => setShortcutsEnabled(false)}
          onBlur={() => setShortcutsEnabled(true)}
          placeholder="Your feedback matters to us! Make sure to avoid simple feedback as 'good question', or 'bad question'."
          className="w-full border border-gray-300 warm:placeholder:text-brown-300 rounded-primary px-2 py-1 min-h-[10rem] placeholder:text-sm lg:placeholder:text-base bg-white warm:bg-white dark:bg-nbme-primary-dark-600 dark:text-white dark:placeholder:text-nbme-primary-dark-300 dark:border-nbme-primary-dark-600 dark:outline-nbme-primary-dark-600"
        ></textarea>
        <div className="flex flex-row justify-between items-center w-full gap-5">
          <LabeledCheckbox
            className="w-full"
            checkBoxClassName="dark:bg-nbme-primary-dark-600 dark:border-nbme-primary-dark-600"
            labelClassName="text-xs w-full warm:text-brown-700"
            id="technical-checkbox"
            label="Check here if you are submitting feedback regarding a technical issue."
            checked={technical}
            onClick={() => setTechnical(!technical)}
          />
          <Button
            type="submit"
            className="p-1 text-sm md:text-sm lg:text-sm bg-nbme-primary-600 hover:bg-nbme-primary-700 focus:bg-nbme-primary-700 w-[20%] dark:bg-nbme-primary-dark-700 dark:hover:bg-nbme-primary-dark-600 warm:bg-brown-800 hover:warm:bg-brown-900"
          >
            Submit
          </Button>
        </div>
      </form>
    </NbmeFloatingWindow>
  );
};
