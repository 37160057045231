import { generate } from "generate-password";

export const string = (options?: { length?: number }) => {
  return generate({
    length: length | 10,
    strict: true,
    numbers: true,
    lowercase: true,
    uppercase: true,
  });
};
