import { twMerge } from "tailwind-merge";
import { LoadingSpinner } from "./LoadingSpinner";

interface QuestionCountBadgeProps {
  count: number;
  className?: string;
  loading?: boolean;
  spinnerClassName?: string;
}

const QuestionCountBadge = ({
  count,
  className,
  loading,
  spinnerClassName,
}: QuestionCountBadgeProps) => {
  const none = count === 0 || loading;
  return (
    <div
      className={twMerge(
        "border border-gray-300 px-2 py-1 rounded-full text-primary-500 font-medium text-xs warm:text-brown-900 warm:bg-white dark:bg-neutral-700 dark:text-white warm:border-brown-300 dark:border-neutral-600 transition-colors",
        none && "text-gray-300",
        loading && "px-0.5 py-0.5",
        className,
      )}
    >
      {loading ? (
        <div className="w-5 h-5">
          <LoadingSpinner className={spinnerClassName} />
        </div>
      ) : (
        count
      )}
    </div>
  );
};

export default QuestionCountBadge;
