"use client";
import { Button } from "@/components/Button";
import useQuestionBankQuestionHeaders from "@/hooks/question-hooks/use-question-bank-question-headers";
import { QuestionBank } from "@/api/types";
import ArrayMapUtils from "@/types/common/ArrayConvertableMap";
import { useMemo } from "react";
import { useNavigate } from "@tanstack/react-router";

const QuestionBankButton = ({ id, endpoint, name }: QuestionBank) => {
  const navigate = useNavigate();
  const { data: questionHeaders, isLoading } = useQuestionBankQuestionHeaders({
    questionBankId: id,
  });
  const questionCount = useMemo(
    () =>
      ArrayMapUtils.getLength(
        ArrayMapUtils.filter(questionHeaders, (qh) => qh.questionBankId === id),
      ),
    [questionHeaders, id],
  );

  return (
    <Button
      onClick={() =>
        navigate({
          to: "/qbanks/$qbankEndpoint/dashboard",
          from: "/home",
          params: { qbankEndpoint: endpoint },
        })
      }
      key={id}
      className="flex flex-col justify-start items-start text-white bg-primary-500 rounded-2xl p-3 px-6 hover:scale-105 transition-transform"
    >
      <div className="flex flex-row justify-between items-center gap-1 w-full">
        <h3 className="font-bold md:text-sm text-start">{name}</h3>
        {isLoading && <div className="h-5 w-10 rounded-full bg-white"></div>}
        {questionHeaders && !isLoading && (
          <p className="font-semibold whitespace-nowrap text-base w-fit">
            {questionCount} <span className="text-xs text-primary-100">Qs</span>
          </p>
        )}
      </div>
    </Button>
  );
};

export default QuestionBankButton;
