
import Triggerable from "@/components/triggerable";
import { WithTooltip } from "@/components/pages/dashboard";
import useHighlightMarkers from "@/hooks/highlightmarker-hooks/use-highlightmarkers";
import { useMemo } from "react";
import ArrayMapUtils from "@/types/common/ArrayConvertableMap";
import useChangeHighlightHighlightMarker from "@/hooks/highlight-hooks/use-change-highlight-highlightmarker";
import { useNbmeContextMenuState } from ".";

const NbmeContextMenuChangeHighlightMarker = (
  { currentMarkerId, onNewMarkerSelected, highlightId }: {
    currentMarkerId: string | null;
    highlightId: string;
    onNewMarkerSelected?: (newMarkerId: string) => void;
  },
) => {
  const setIsVisible = useNbmeContextMenuState(state => state.setVisible);
  const changeHighlightMarker = useChangeHighlightHighlightMarker();
  const { data: highlightMarkerArrayMap } = useHighlightMarkers();
  const highlightMarkers = useMemo(
    () => ArrayMapUtils.toArray(highlightMarkerArrayMap),
    [highlightMarkerArrayMap],
  );

  return (
    <Triggerable.Root className="relative inline-flex flex-row justify-center items-center w-full left-0">
      <WithTooltip tooltip="Change Color">
        <Triggerable.Toggle className=" rounded-primary hover:scale-110 transition-transform p-1 dark:bg-neutral-600 bg-nbme-primary-600  m-auto inline-flex flex-col justify-center items-center active:scale-95">
          <div
            className="w-5 h-5 rounded-full"
            style={{
              background:
                highlightMarkers.find((hm) => hm.id === currentMarkerId)
                  ?.color ?? "yellow",
            }}
          >
          </div>
        </Triggerable.Toggle>
      </WithTooltip>
      <Triggerable.Body className="flex flex-col justify-start items-center absolute md:-bottom-1.5 md:top-auto -top-1.5 md:translate-y-full -translate-y-full gap-2 py-2 left-0 px-1 rounded-primary dark:bg-neutral-700 bg-nbme-primary-600 max-h-30 overflow-y-auto">
        {highlightMarkers &&
          highlightMarkers.filter((hm) => hm.id !== currentMarkerId).map((
            hm,
          ) => (
            <button
              key={hm.id}
              className="w-5 h-5 rounded-full hover:scale-110 hover:shadow-md transition-[transform,_box-shadow]"
              style={{ background: hm.color }}
              onClick={() => {
                setIsVisible(false);
                changeHighlightMarker({
                  highlightId,
                  highlightMarkerId: hm.id,
                });
                onNewMarkerSelected?.(hm.id);
              }}
            >
            </button>
          ))}
      </Triggerable.Body>
    </Triggerable.Root>
  );
};

export default NbmeContextMenuChangeHighlightMarker;
