import React from 'react'
import { motion } from 'framer-motion'
import { PropsWithClassName } from '@coursology/types'
import { cn } from '@/lib/utils'

const RippleWaveLoader = ({ rippleClassName, className }: { rippleClassName?: string } & PropsWithClassName) => {
  return (
    <div className={cn("flex items-center justify-center space-x-1", className)}>
      {[...Array(7)].map((_, index) => (
        <motion.div
          key={index}
          className={cn("h-8 w-2 rounded-full bg-white", rippleClassName)}
          animate={{
            scaleY: [0.5, 1.5, 0.5],
            scaleX: [1, 0.8, 1],
            translateY: ['0%', '-15%', '0%'],
          }}
          transition={{
            duration: 1,
            repeat: Infinity,
            ease: 'easeInOut',
            delay: index * 0.1,
          }}
        />
      ))}
    </div>
  )
}

export default RippleWaveLoader
