import { createFileRoute } from "@tanstack/react-router";
import DashboardStatsSection from "@/components/DashboardStatsSection";
import {
  PerformanceTestAnalysisPageSelect,
  PerformanceTestAnalysisPageSubjectsTable,
  PerformanceTestAnalysisPageSystemsTable,
} from "./-components";
import { useMemo } from "react";
import { DashboardScoreStatsCircle } from "@/components/DashboardScoreStatsCircle";
import useTestQuestionHeaders from "@/hooks/question-hooks/use-test-question-headers";
import ArrayMapUtils from "@/types/common/ArrayConvertableMap";
import { LoadingPage } from "@/components/LoadingPage";

export const Route = createFileRoute(
  "/qbanks/$qbankEndpoint/dashboard/_dashboard/performance/test/$testId/analysis",
)({
  component: PerfromanceTestAnalysisPage,
});

function PerfromanceTestAnalysisPage() {
  const params = Route.useParams();
  const { data: questionHeadersArrayMap, isLoading: isTestPerformanceLoading } =
    useTestQuestionHeaders({
      testId: Number(params.testId),
    });
  const questionHeaders = useMemo(
    () => ArrayMapUtils.toArray(questionHeadersArrayMap),
    [questionHeadersArrayMap],
  );

  const { total, omitted, incorrect, correct } = {
    total: questionHeaders.length,
    omitted: questionHeaders.filter((q) => q.used === "omitted").length,
    correct: questionHeaders.filter((q) => q.used === "correct").length,
    incorrect: questionHeaders.filter((q) => q.used === "incorrect").length,
  };
  if (isTestPerformanceLoading) return <LoadingPage />;
  return (
    <div className="flex flex-col justify-start items-center w-full">
      <div className="grid grid-rows-3 md:grid-cols-3 md:grid-rows-none gap-16 w-full px-10">
        <div className="flex flex-col justify-center items-center">
          <DashboardScoreStatsCircle
            total={total}
            incorrect={incorrect}
            omitted={omitted}
            correct={correct}
          />
        </div>
        <DashboardStatsSection
          isLoading={isTestPerformanceLoading}
          title="Your Score"
          items={[
            { text: "Total Correct", count: correct },
            {
              text: "Total Incorrect",
              count: incorrect,
            },
            { text: "Total Omitted", count: omitted },
          ]}
        />

        <div className="relative">
          <DashboardStatsSection
            isLoading={isTestPerformanceLoading}
            className="opacity-30"
            title="Answer Changes"
            items={[
              { text: "Correct to Incorrect", count: 0 },
              {
                text: "Incorrect to Correct",
                count: 0,
              },
              { text: "Incorrect to Incorrect", count: 0 },
            ]}
          />
          <p className="opacity-100 absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] text-gray-500 dark:text-neutral-400 w-full text-center">
            Coming soon...
          </p>
        </div>
      </div>
      <div className="flex flex-col justify-center items-center w-full">
        <div className="self-end px-5">
          <PerformanceTestAnalysisPageSelect />
        </div>
        <div className="w-full flex flex-col justify-center items-center gap-12">
          <div className="w-full flex flex-col justify-center items-center gap-3 px-6">
            <div className="w-full border-b border-b-gray-300 px-5 py-3">
              <p className="font-semibold text-gray-600 dark:text-neutral-100">
                Subjects
              </p>
            </div>
            <div className="w-full md:px-5">
              <PerformanceTestAnalysisPageSubjectsTable
                testId={Number(params.testId)}
              />
            </div>
          </div>
          <div className="w-full flex flex-col justify-center items-center gap-3 px-6">
            <div className="w-full border-b border-b-gray-300 px-5 py-3">
              <p className="font-semibold text-gray-600 dark:text-neutral-100">
                Systems
              </p>
            </div>
            <div className="w-full md:px-5">
              <PerformanceTestAnalysisPageSystemsTable
                testId={Number(params.testId)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
