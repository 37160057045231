import useQuestionBankSubjectPerformanceReports from "@/hooks/question-hooks/use-question-bank-subject-performance-reports";
import { createContext } from "react";

const performanceReportsPageContext = createContext<
  | {
      reports: ReturnType<typeof useQuestionBankSubjectPerformanceReports>;
      questionBankEndpoint: string;
    }
  | undefined
>(undefined);

export default performanceReportsPageContext;
