import { PropsWithChildren } from "react";
import useResizableContext from "./hooks/use-resizable-context";
import { PropsWithClassName } from "@/types";

type ResizableMaximizeButtonProps = PropsWithChildren & PropsWithClassName;
const ResizableMaximizeButton = ({
  children,
  className,
}: ResizableMaximizeButtonProps) => {
  const { maximize } = useResizableContext();
  return (
    <button className={className} onClick={maximize}>
      {children}
    </button>
  );
};

export default ResizableMaximizeButton;
