import { cn } from "@/lib/utils";
import { IconProps } from "@coursology/types";

const ChatGptIcon = ({ className }: IconProps) => (
  <svg className={cn('w-8 h-8', className)} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 320">
    <path d="M297.06 130.97a79.71 79.71 0 0 0-6.85-65.48c-17.46-30.4-52.56-46.04-86.84-38.68A79.75 79.75 0 0 0 143.24 0C108.2-.08 77.11 22.48 66.33 55.82a79.75 79.75 0 0 0-53.31 38.67c-17.59 30.32-13.58 68.54 9.92 94.54a79.71 79.71 0 0 0 6.85 65.48c17.46 30.4 52.56 46.04 86.84 38.68a79.69 79.69 0 0 0 60.13 26.8c35.06.09 66.16-22.49 76.94-55.86a79.75 79.75 0 0 0 53.31-38.67c17.57-30.32 13.55-68.51-9.94-94.51zM176.78 299.08a59.77 59.77 0 0 1-38.39-13.88c.49-.26 1.34-.73 1.89-1.07l63.72-36.8a10.36 10.36 0 0 0 5.24-9.07v-89.83l26.93 15.55c.29.14.48.42.52.74v74.39c-.04 33.08-26.83 59.9-59.91 59.97M47.94 244.05a59.7 59.7 0 0 1-7.15-40.18c.47.28 1.3.79 1.89 1.13l63.72 36.8c3.23 1.89 7.23 1.89 10.47 0l77.79-44.92v31.1c.02.32-.13.63-.38.83L129.87 266c-28.69 16.52-65.33 6.7-81.92-21.95zM31.17 104.96c7-12.16 18.05-21.46 31.21-26.29 0 .55-.03 1.52-.03 2.2v73.61c-.02 3.74 1.98 7.21 5.23 9.06l77.79 44.91L118.44 224c-.27.18-.61.21-.91.08l-64.42-37.22c-28.63-16.58-38.45-53.21-21.95-81.89zm221.26 51.49-77.79-44.92 26.93-15.54c.27-.18.61-.21.91-.08l64.42 37.19c28.68 16.57 38.51 53.26 21.94 81.94a59.94 59.94 0 0 1-31.2 26.28v-75.81c.03-3.74-1.96-7.2-5.2-9.06zm26.8-40.34c-.47-.29-1.3-.79-1.89-1.13l-63.72-36.8a10.38 10.38 0 0 0-10.47 0l-77.79 44.92V92c-.02-.32.13-.63.38-.83l64.41-37.16c28.69-16.55 65.37-6.7 81.91 22a59.95 59.95 0 0 1 7.15 40.1zm-168.51 55.43-26.94-15.55a.94.94 0 0 1-.52-.74V80.86c.02-33.12 26.89-59.96 60.01-59.94 14.01 0 27.57 4.92 38.34 13.88-.49.26-1.33.73-1.89 1.07L116 72.67a10.34 10.34 0 0 0-5.24 9.06l-.04 89.79zM125.35 140 160 119.99l34.65 20V180L160 200l-34.65-20z"></path>
  </svg>
);

export default ChatGptIcon;
