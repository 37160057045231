export const isChildOf = ({
  node,
  parent,
  onChild,
  onNotChild,
}: {
  node: Node;
  parent: Node;
  onChild?: (node: Node, parent: Node) => void;
  onNotChild?: (node: Node, parent: Node) => void;
}): boolean => {
  let result = false;
  const children = Array.from(parent.childNodes);
  for (const child of children) {
    if (result) break;
    if (node === child) {
      result = true;
      onChild?.(child, parent);
      break;
    } else {
      onNotChild?.(child, parent);
      result = isChildOf({ node, parent: child, onChild, onNotChild });
    }
  }

  return result;
};
