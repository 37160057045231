import React, {
  ButtonHTMLAttributes,
  DetailedHTMLProps,
  PropsWithChildren,
} from "react";
import { twMerge } from "tailwind-merge";
import WithLoadingState from "./common/with-loading-state";
import { cn } from "@/lib/utils";

interface NbmeNavbarButtonBaseProps
  extends DetailedHTMLProps<
      ButtonHTMLAttributes<HTMLButtonElement>,
      HTMLButtonElement
    >,
    PropsWithChildren {
  isLoading?: boolean;
}

export const NbmeNavbarButtonBase = ({
  children,
  className,
  isLoading,
  ...props
}: NbmeNavbarButtonBaseProps) => {
  return (
    <WithLoadingState
      isLoading={isLoading}
      className={cn(
        "hover:bg-nbme-primary-800 warm:hover:bg-brown-900 py-1 px-1 rounded-sm dark:hover:bg-nbme-primary-dark-700 transition-colors",
        className,
      )}
    >
      <button
        disabled={isLoading}
        {...props}
        className={cn("disabled:opacity-50")}
      >
        {children}
      </button>
    </WithLoadingState>
  );
};
