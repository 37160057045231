import { PropsWithChildren } from "react";
import { NbmeBottomNavbar, NbmeTopNavbar } from ".";
import { NbmeTopNavbarProps } from "./NbmeTopNavbar";
import { NbmeBottomNavbarProps } from "./NbmeBottomNavbar";

export interface NbmeNavbarsProps<T = "test" | "question">
  extends PropsWithChildren,
    NbmeTopNavbarProps<T>,
    NbmeBottomNavbarProps<T> {}

export const NbmeNavbars = <T = "test" | "question",>({
  children,
  ...props
}: NbmeNavbarsProps<T>) => {
  return (
    <div className="flex-grow flex flex-col justify-between items-between min-h-screen">
      <NbmeTopNavbar {...props} />
      {children}
      <NbmeBottomNavbar {...props} />
    </div>
  );
};
