import {
  faCircleXmark,
  faUpDownLeftRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BreakPoints, Point } from "@/types";
import { PropsWithClassName } from "@/types";
import { clamp } from "@/utils/common/clamp";
import { useBreakPoint } from "@/utils/hooks/useBreakpoints";
import { useDimensions } from "@/utils/hooks/useDimensions";
import { useMousePosition } from "@/utils/hooks/useMousePosition";
import { useTouchPosition } from "@/utils/hooks/useTouchPosition";
import { useViewportDimensions } from "@/utils/hooks/useViewportDimensions";
import { PropsWithChildren, useEffect, useRef, useState } from "react";
import { twMerge } from "tailwind-merge";
import { AnimatePresence, motion } from "framer-motion";
import { smoothTransition } from "@/assets/framer";

interface NbmeFloatingWindowProps
  extends PropsWithClassName,
    PropsWithChildren {
  title: string | JSX.Element;
  onCloseClicked?: () => void;
  position?: Point;
  onPositionChanged?: (newPosition: Point) => void;
  viewportClassName?: string;
  isVisible?: boolean;
  onIsSelectionEnabledChange: (v: boolean) => void;
}

export const NbmeFloatingWindow = ({
  className,
  children,
  onCloseClicked,
  position,
  onPositionChanged,
  title,
  viewportClassName,
  isVisible,
  onIsSelectionEnabledChange,
}: NbmeFloatingWindowProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const { width: windowW, height: windowH } = useDimensions(ref);
  const { x: mouseX, y: mouseY } = useMousePosition();
  const touchPos = useTouchPosition();
  const breakPoint = useBreakPoint();
  const { width: viewportW, height: viewportH } = useViewportDimensions();
  const [mouseOffset, setMouseOffset] = useState<Point>({ x: 0, y: 0 });
  const [holding, setHolding] = useState<boolean>(false);
  const [windowPos, setWindowPos] = useState<Point>({
    x: breakPoint < BreakPoints.Medium ? 0 : viewportW / 2,
    y: breakPoint < BreakPoints.Medium ? 0 : viewportH / 2,
  });

  const clampWindowPosition = ({ x, y }: Point) => {
    return {
      x: clamp({ num: x, min: 0, max: viewportW - windowW }),
      y: clamp({ num: y, min: 0, max: viewportH - windowH }),
    };
  };

  useEffect(() => {
    if (holding && ref && ref.current) {
      onIsSelectionEnabledChange(false);
      const { left, top } = ref.current.getBoundingClientRect();
      setMouseOffset({
        x: touchPos ? touchPos.x - left : mouseX - left,
        y: touchPos ? touchPos.y - top : mouseY - top,
      });
    } else if (!holding) {
      onIsSelectionEnabledChange(true);
    }
  }, [holding, ref.current]);

  useEffect(() => {
    if (holding) {
      const newPosition = clampWindowPosition({
        x: mouseX - mouseOffset.x,
        y: mouseY - mouseOffset.y,
      });
      setWindowPos(newPosition);
      onPositionChanged?.(newPosition);
    }
  }, [mouseY, mouseX]);

  useEffect(() => {
    if (touchPos) {
      if (holding) {
        const newPosition = clampWindowPosition({
          x: touchPos.x - mouseOffset.x,
          y: touchPos.y - mouseOffset.y,
        });

        setWindowPos(newPosition);
        onPositionChanged?.(newPosition);
      }
    }
  }, [touchPos]);

  return (
    <AnimatePresence mode="wait">
      {isVisible ? (
        <motion.div
          key={title + "window"}
          className={twMerge(
            "fixed rounded-primary flex flex-col justify-start items-center border border-gray-300 dark:border-nbme-primary-dark-600 overflow-hidden bg-white dark:bg-nbme-primary-dark-700",
            "z-[998] shadow-lg translate-x-[-50%] translate-y-[-50%] lg:translate-x-0 lg:translate-y-0",
            className,
          )}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={smoothTransition}
          style={{
            left:
              breakPoint < BreakPoints.Large
                ? "50%"
                : (position?.x ?? windowPos.x),
            top:
              breakPoint < BreakPoints.Large
                ? "50%"
                : (position?.y ?? windowPos.y),
          }}
          ref={ref}
          tabIndex={0}
        >
          <div
            className={twMerge(
              "relative py-3 text-lg flex flex-row justify-end items-center w-full px-3 bg-nbme-primary-600 dark:bg-nbme-primary-dark-900 cursor-default lg:cursor-move text-white ",
            )}
            onMouseDown={() => setHolding(true)}
            onMouseUp={() => setHolding(false)}
            onTouchStart={() => {
              setHolding(true);
            }}
            onTouchEnd={() => setHolding(false)}
          >
            <p className="absolute left-[50%] top-[50%] translate-y-[-50%] translate-x-[-50%] font-bold text-sm md:text-base">
              {title}
            </p>
            <button type="button" onClick={onCloseClicked}>
              <FontAwesomeIcon icon={faCircleXmark} />
            </button>
            <FontAwesomeIcon
              icon={faUpDownLeftRight}
              className="hidden lg:block absolute left-3 top-3 text-white w-4 h-4"
            />
          </div>
          <div className={twMerge("w-full", viewportClassName)}>{children}</div>
        </motion.div>
      ) : (
        <></>
      )}
    </AnimatePresence>
  );
};
