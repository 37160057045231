import { createFileRoute } from "@tanstack/react-router";
import { PropsWithChildren } from "react";
import { DashboardFrame } from "@/components/DashboardFrame";
import {
  PerformanceReportsPageQueryInput,
  PerformanceReportsPageSelect,
  PerformanceReportsPageTabs,
} from "./-components";
import { useBreakPoint } from "@/utils/hooks";
import { BreakPoints } from "@/types";
import { useDashboardLayoutStore } from "@/utils/stores";
import { trpc } from "@/utils/trpc";
import { useEffect } from "react";
import usePerformanceReportsPageStore from "./-store";
import { PerformanceReportsPageTable } from "./-components";
import { LoadingSpinner } from "@/components/LoadingSpinner";
import { toast } from "sonner";
import PerformancePageReportsContextProvider from "./-components/PerformanceReportsPageContextProvider";
import useQuestionBankSubjectPerformanceReports from "@/hooks/question-hooks/use-question-bank-subject-performance-reports";
import useQuestionBankSystemPerformanceReports from "@/hooks/question-hooks/use-question-bank-system-performance-reports";
import useQuestionBankTopicPerformanceReports from "@/hooks/question-hooks/use-question-bank-topic-performance-reports";
import { useTitle } from "@/hooks/common/use-title";
import useDashboardQuestionBank from "../../-dashboard-hooks/use-dashboard-question-bank";

export const Route = createFileRoute(
  "/qbanks/$qbankEndpoint/dashboard/_dashboard/performance/reports",
)({
  component: PerformanceReportsPage,
});

function PerformanceReportsPage() {
  const { data: questionBank } = useDashboardQuestionBank();
  useTitle(`Performance Reports ${questionBank?.name}`)
  const breakPoint = useBreakPoint();
  const isScreenSmall = breakPoint < BreakPoints.Medium;
  return (
    <DashboardFrame className="min-h-[85vh] w-full">
      <div
        className={`w-full p-9 flex flex-col justify-start items-start gap-7`}
      >
        <PerformanceReportsPageTabs />
        {isScreenSmall ? (
          <div className="relative flex flex-col justify-start items-center gap-6 w-full">
            <PerformanceReportsPageSelect />
            <div className="flex flex-row justify-center items-center w-full">
              <PerformanceReportsPageQueryInput />
            </div>
          </div>
        ) : (
          <div className="relative flex w-full flex-row items-center justify-between">
            <PerformanceReportsPageSelect />
            <PerformanceReportsPageQueryInput />
          </div>
        )}
      </div>
      <PerformanceReports />
    </DashboardFrame>
  );
}

function PerformanceReports() {
  const params = Route.useParams();

  const setDashboardHeader = useDashboardLayoutStore(
    (state) => state.setHeaderText,
  );
  useEffect(() => {
    setDashboardHeader("Reports");
    return () => setDashboardHeader("");
  }, []);
  const { currentTab } = usePerformanceReportsPageStore((state) => ({
    currentTab: state.currentTab,
  }));
  const subjectReports = useQuestionBankSubjectPerformanceReports({
    questionBankEndpoint: params.qbankEndpoint,
  });

  const systemReports = useQuestionBankSystemPerformanceReports({
    questionBankEndpoint: params.qbankEndpoint,
  });
  const topicReports = useQuestionBankTopicPerformanceReports({
    questionBankEndpoint: params.qbankEndpoint,
  });

  if (subjectReports.isLoading) {
    return (
      <div className="relative min-h-[50vh] min-w-full flex flex-col justify-center items-center">
        <div className="w-16 h-16 absolute left-[0.5] top-[0.5]">
          <LoadingSpinner />
        </div>
      </div>
    );
  }
  let reports;
  switch (currentTab) {
    case "systems":
      reports = systemReports;
      break;
    case "subjects":
      reports = subjectReports;
      break;
    case "topics":
      reports = topicReports;
      break;
    default:
      reports = subjectReports;
      break;
  }

  return (
    <PerformancePageReportsContextProvider
      value={{ reports: reports, questionBankEndpoint: params.qbankEndpoint }}
    >
      <PerformanceReportsPageTable />
    </PerformancePageReportsContextProvider>
  );
}
