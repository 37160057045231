import { motion, useAnimate, useMotionValue } from 'framer-motion';
import { PropsWithChildren, useEffect, useState } from 'react';
export const Spin = ({ children, spinning, speed = 1 }: PropsWithChildren & { spinning: boolean, speed?: number }) => {
  const rotation = useMotionValue(0);
  const [scope, animate] = useAnimate();
  useEffect(() => {
    const animation =
      animate(rotation, rotation.get() + 360, { type: "tween", ease: 'linear', duration: 1 / speed, repeat: Infinity, repeatDelay: 0, repeatType: "loop" })
    if (!spinning) {
      animation.pause();
    }
  })

  return <motion.div ref={scope} style={{ rotate: rotation }}>
    {children}
  </motion.div>
}
