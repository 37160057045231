import { FilterInterface } from "interweave";

export const linkFilter: FilterInterface = {
  node(name, node) {
    if (name === "a") {
      node.setAttribute("href", "javascript:void(0)");
      node.className = "cursor-default";
    }
    return node;
  },
};
