import { Button } from ".";

interface CancelButtonProps {
  isEditMode: boolean;
  onIsEditModeChange: (newValue: boolean) => void;
  onEditCancel: () => void;
}

export const CancelButton = ({
  onIsEditModeChange,
  onEditCancel,
}: CancelButtonProps) => {
  return (
    <Button
      variant="dashboard"
      className="lg:text-base"
      onClick={() => {
        onIsEditModeChange(false);
        onEditCancel();
      }}
    >
      {"Back to Reading Mode"}
    </Button>
  );
};
