import "@fortawesome/fontawesome-svg-core/styles.css";
import "@fontsource-variable/inter"; // Defaults to wght axis
import "@fontsource-variable/inter/wght.css"; // Specify axis
import "@fontsource-variable/inter/wght-italic.css"; // Specify axis and style
import "@fortawesome/fontawesome-svg-core/styles.css";
import "@mantine/core/styles.css";
import "@mantine/tiptap/styles.css";

import { Profiler, StrictMode } from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import { queryClient, trpc, trpcClient } from "./utils/trpc";
import { QueryClientProvider } from "@tanstack/react-query";
import { createRouter, RouterProvider } from "@tanstack/react-router";
import { routeTree } from "./routeTree.gen.ts";
import * as Sentry from "@sentry/react";
Sentry.init({
  dsn: "https://0c82907976add3aaec6517f679b6b3fc@o4505258043703296.ingest.us.sentry.io/4508227444670464",
  integrations: [],
});

const router = createRouter({ routeTree });

//Using declaration merging to type-safe the router.
declare module "@tanstack/react-router" {
  interface Register {
    router: typeof router;
  }
}

createRoot(document.getElementById("root")!).render(<Main />);

function Main() {
  return (
    <StrictMode>
      <trpc.Provider client={trpcClient} queryClient={queryClient}>
        <QueryClientProvider client={queryClient}>
          <RouterProvider router={router} />
        </QueryClientProvider>
      </trpc.Provider>
    </StrictMode>
  );
}
