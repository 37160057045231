import { twMerge } from "tailwind-merge";
import {
  CheckButton,
  PenButton,
  PlusButton,
  TrashButton,
  XButton,
} from "../../../../..";

type ButtonsProps = {
  onTrashClicked: () => void;
  onPlusClicked: () => void;
  onCheckClicked: () => void;
  onXClicked: () => void;
  onPenClicked: () => void;
  isEditMode: boolean;
};

export const Buttons = ({
  onPlusClicked,
  onTrashClicked,
  onXClicked,
  onCheckClicked,
  onPenClicked,
  isEditMode,
}: ButtonsProps) => {
  return (
    <div
      className={twMerge(
        "absolute right-3 top-[0.5] translate-y-[-50%] flex flex-row justify-end items-center gap-2 opacity-0",
        "group-hover:opacity-100",
      )}
    >
      {isEditMode ? (
        <>
          <XButton
            className="text-gray-400 warm:text-brown-950/40 warm:enabeld:hover:text-brown-500 dark:text-neutral-400 enabled:hover:text-gray-500 dark:enabled:hover:text-neutral-300 text-base"
            onClick={onXClicked}
          />
          <CheckButton
            className="text-gray-400 warm:text-brown-950/40 warm:enabeld:hover:text-brown-500 dark:text-neutral-400 enabled:hover:text-gray-500 dark:enabled:hover:text-neutral-300 text-base"
            onClick={onCheckClicked}
          />
        </>
      ) : (
        <>
          <PenButton
            onClick={onPenClicked}
            className="text-gray-400 warm:text-brown-950/40 warm:enabeld:hover:text-brown-500 dark:text-neutral-400 enabled:hover:text-gray-500 dark:enabled:hover:text-neutral-300 text-base"
          />
          <TrashButton
            onClick={onTrashClicked}
            className="text-gray-400 warm:text-brown-950/40 warm:enabeld:hover:text-brown-500 dark:text-neutral-400 enabled:hover:text-gray-500 dark:enabled:hover:text-neutral-300 text-base"
          />
          <PlusButton
            onClick={onPlusClicked}
            className="text-gray-400 dark:text-neutral-400 warm:text-brown-950/40 warm:enabeld:hover:text-brown-500 enabled:hover:text-gray-500 dark:enabled:hover:text-neutral-300 text-base"
          />
        </>
      )}
    </div>
  );
};
