import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Input, InputProps } from "./Input";
import { twMerge } from "tailwind-merge";
import { LoadingSpinner } from "./LoadingSpinner";
import { motion } from "framer-motion";
import { fadeAnimation } from "@/assets/framer";
import { cn } from "@/lib/utils";

export interface SearchInputProps extends InputProps {
  isLoading?: boolean;
  iconClassName?: string;
}

export const SearchInput = ({
  className,
  iconClassName,
  isLoading,
  ...props
}: SearchInputProps) => {
  return (
    <div className="flex flex-row justify-start items-center gap-1">
      <Input
        placeholder="Search"
        {...props}
        className={twMerge("peer", className)}
      />
      <motion.div
        key={isLoading ? "is-loading" : "is-not-loading"}
        {...fadeAnimation}
        className="inline-flex justify-center items-center md:w-14 md:h-14 w-12 h-12"
      >
        {isLoading ? (
          <LoadingSpinner className="p-0 inline" />
        ) : (
          <FontAwesomeIcon
            icon={faSearch}
            className={cn(
              "text-gray-500 warm:text-brown-500 dark:text-neutral-500 transition-colors peer-focus:text-primary-500 warm:peer-focus:text-brown-700 md:w-5 md:h-5 w-4 h-4",
              iconClassName,
            )}
          />
        )}
      </motion.div>
    </div>
  );
};
