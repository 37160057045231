import { createContext } from "react";
import { NotesPageState } from "./-index";
import useQuestionBankQuestionNotes from "@/hooks/question-note-hooks/use-question-bank-question-notes";

const notesPageContext = createContext<
  | (NotesPageState & {
      questionBankEndpoint: string;
      questionNotes: ReturnType<typeof useQuestionBankQuestionNotes>;
    })
  | undefined
>(undefined);
export default notesPageContext;
