export const convertStringDatesToActualDatesForOne = <
  T extends { createdAt: string; lastUpdatedAt: string },
>(
  tag: T,
) => {
  return {
    ...tag,
    createdAt: new Date(tag.createdAt),
    lastUpdatedAt: new Date(tag.lastUpdatedAt),
  };
};

export const convertStringDatesToActualDates = <
  T extends { createdAt: string; lastUpdatedAt: string },
>(
  tags: T[],
) => {
  const result = [];
  for (let i = 0; i < tags.length; i++) {
    const tag = tags[i]!;
    //@ts-ignore
    result.push(convertStringDatesToActualDatesForOne(tag));
  }
  return result;
};
