import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as RadixDialog from "@radix-ui/react-dialog";
import { PropsWithChildren, ReactNode } from "react";
import { twMerge } from "tailwind-merge";
import { motion } from "framer-motion";

interface DialogProps extends PropsWithChildren {
  title: string;
  description?: ReactNode;
  className?: string;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
  onCloseClicked?: React.MouseEventHandler<HTMLButtonElement>;

  /**Dark mode has to be passed around as a prop because
  the dialogue usually exists outside of any layout
  (We can't just set "dark" on the parent because there is no parent)*/
  dark?: boolean;
}

const Dialog = ({
  dark,
  title,
  description,
  children,
  className,
  open,
  onOpenChange,
  onCloseClicked,
}: DialogProps) => (
  <RadixDialog.Root open={open} onOpenChange={onOpenChange}>
    <RadixDialog.Portal>
      <RadixDialog.Overlay>
        <motion.div
          className={twMerge(
            "bg-gray-900 fixed inset-0 backdrop-blur bg-opacity-10 z-[1000]",
            dark && "dark",
          )}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
        ></motion.div>
      </RadixDialog.Overlay>
      <RadixDialog.Content
        className={twMerge(
          "fixed top-[50%] left-[50%] max-h-[85vh] w-[90vw] max-w-lg lg:max-w-xl translate-x-[-50%] translate-y-[-50%] rounded-primary z-[9999]",
          dark && "dark",
        )}
      >
        <motion.div
          className={twMerge(
            "w-full h-full text-gray-800 warm:text-brown-800 dark:text-white bg-white warm:bg-brown-50 dark:bg-neutral-900 border border-gray-300 dark:border-neutral-700 p-6 focus:outline-none rounded-primary",
            dark && "dark text-white",
            className,
          )}
          initial={{ scale: 0.95, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
        >
          <div className="flex flex-col justify-center items-start gap-1">
            <RadixDialog.Title className="text-gray-900 warm:text-brown-950 dark:text-white font-bold text-xl">
              {title}
            </RadixDialog.Title>
            {description && (
              <RadixDialog.Description className="dark:text-white font-medium text-lg">
                {description}
              </RadixDialog.Description>
            )}
          </div>
          {children}
          <RadixDialog.Close asChild>
            <button
              type="button"
              className="text-red-500 dark:text-white absolute top-[10px] right-[10px] inline-flex h-6 w-6 items-center justify-center rounded-full outline-none"
              onClick={onCloseClicked}
            >
              <FontAwesomeIcon icon={faCircleXmark} />
            </button>
          </RadixDialog.Close>
        </motion.div>
      </RadixDialog.Content>
    </RadixDialog.Portal>
  </RadixDialog.Root>
);

export default Dialog;
