import { ArgumentTypes } from "@/types";
import useResizableGesture from "./use-resizable-gesture";

type HandleGestureArguments = ArgumentTypes<
  ReturnType<typeof useResizableGesture>
>[0];

const useCornerGesture = ({
  placement,
  invertResizeDirection,
  events,
}: Omit<HandleGestureArguments, "placement" | "dragAxes"> & {
  placement: ["top" | "bottom", "left" | "right"];
}) => {
  return useResizableGesture({
    placement: placement,
    dragAxes: { x: true, y: true },
    invertResizeDirection,
    events,
  });
};

export default useCornerGesture;
