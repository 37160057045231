"use client";

import {
  DetailedHTMLProps,
  HTMLInputTypeAttribute,
  InputHTMLAttributes,
} from "react";
import { twMerge } from "tailwind-merge";

export interface InputProps
  extends DetailedHTMLProps<
    InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  value: string | number;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  error?: boolean;
  variant?: "regular" | "dashboard";
}

export const Input = ({
  error,
  placeholder,
  variant = "regular",
  className,
  value = "",
  id,
  onChange,
  type,
  ...props
}: InputProps) => {
  return (
    <input
      {...props}
      type={type}
      value={value}
      id={id}
      onChange={onChange}
      className={twMerge(
        " px-2 py-3 w-full  text-xs md:text-sm lg:text-base transition-colors duration-200 disabled:bg-primary-100 disabled:text-gray-500 disabled:border-primary-400 bg-white dark:bg-neutral-800 warm:bg-brown-50 warm:text-brown-950/80 warm:selection:bg-brown-700 warm:placeholder:text-brown-300 warm:selection:text-white dark:border-neutral-700 dark:outline-transparent dark:text-neutral-100 dark:placeholder:text-neutral-500",
        variant === "regular" &&
          "rounded-primary border border-primary-500 warm:border-brown-300 outline-primary-400",
        className,
        variant === "dashboard" &&
          "border-b focus:border-primary-500 warm:focus:border-brown-600 transition-[border-color] outline-transparent rounded-none md:rounded-none lg:rounded-none outline-none md:outline-none focus:outline-none lg:outline-none rounded-t-primary",
        error && "border-red-500 outline-red-400 text-red-500",
      )}
      placeholder={placeholder}
    ></input>
  );
};
