import { ResizableHandle, ResizablePanel } from "@/components/ui/resizable";
import { ScrollArea } from "../../../../components";
import React, { PropsWithChildren } from "react";
import { useNotebookUiVariant } from "@/hooks/notebook";
import { cn } from "@/lib/utils";

export const Layout = React.forwardRef<HTMLDivElement, PropsWithChildren>(({ children }, ref) => {
  const [variant] = useNotebookUiVariant();
  const onDesktop = variant === "desktop";
  return (
    <>
      <ResizableHandle withHandle />
      <ResizablePanel
        order={2}
        defaultSize={70}
        className="h-full max-h-screen"
      >
        <ScrollArea ref={ref}>{children}</ScrollArea>
      </ResizablePanel>
    </>
  );
});
