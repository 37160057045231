import { ChangeEventHandler } from "react";
import { handleNewTag } from ".";

const lowerCase = (value: string) => value.toLowerCase();

const removeSpaces = (value: string) => {
  return value.replaceAll(/\ /g, "");
};

const hasNoEndingSpaces = (value: string) => {
  return !value.endsWith(" ");
};

const getValueBeforeSpace = (value: string) => {
  return value.trimEnd();
};

const isEmpty = (value: string) => {
  return !value;
};

export const handleChange =
  ({
    setValue,
    onTagAdd,
  }: {
    setValue: (newValue: string) => void;
    onTagAdd: (newTag: string) => void;
  }): ChangeEventHandler<HTMLInputElement> =>
  (e) => {
    const value = e.target.value;
    const valueWithoutSpaces = removeSpaces(value);
    setValue(lowerCase(valueWithoutSpaces));
    if (isEmpty(valueWithoutSpaces)) return;
    if (hasNoEndingSpaces(value)) return;
    handleNewTag(valueWithoutSpaces)({ next: onTagAdd, setValue });
  };
