import React, { PropsWithChildren } from "react";
import { twMerge } from "tailwind-merge";

interface DashboardFrameProps extends PropsWithChildren {
  className?: string;
}

export const DashboardFrame = ({
  children,
  className,
}: DashboardFrameProps) => {
  return (
    <div
      className={twMerge(
        "bg-white warm:bg-white dark:bg-neutral-900 rounded-primary shadow-md shadow-gray-200 dark:shadow-none transition-colors dark:text-neutral-50 border dark:border-neutral-800",
        className,
      )}
    >
      {children}
    </div>
  );
};
