import { cn } from "@/lib/utils";
import { FilterInterface } from "interweave";

export const paragraphFilter = (
  fontSizeFactor: number,
  align: "left" | "right" | "center" = "left",
): FilterInterface => ({
  node(name, node) {
    if (name === "p" || name === "h3") {
      const BREAKLINE_CHARACTER = "\u00A0";
      if (node.textContent === BREAKLINE_CHARACTER) {
        node.innerHTML = "";
        return node;
      }
      node.setAttribute(
        "class",
        cn(
          "mb-4 text-start",
          align === "right" && "text-end",
          align === "center" && "text-center",
        ),
      );
      node.style.marginBottom = `${1 * fontSizeFactor}rem`;
      if (!node.querySelector("img") && node.textContent === "") return null;
      if (!!node.querySelector("img")) {
        node.setAttribute(
          "class",
          cn(
            node.className,
            " flex justify-center items-center lg:block",
            align === "center" && "lg:flex",
            align === "right" && "lg:flex lg:justfiy-end lg:items-end",
          ),
        );
      }
      if (node.parentElement?.tagName === "LI") {
        node.classList.add("inline");
      }
    }
    if (name === "span") {
      node.style.color = "";
      node.style.background = "";
    }
    return node;
  },
});
