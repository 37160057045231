import { QuestionBankIdentifier } from "@/types/question-bank-types";
import useQuestionBankSubjects from "./use-question-bank-subjects";
import { Question } from "@/models/question-model";
import ArrayMapUtils from "@/types/common/ArrayConvertableMap";
import useQuestionBankQuestionHeaders from "@/hooks/question-hooks/use-question-bank-question-headers";
import mergeFlags from "@/utils/common/merge-flags";
import { useMemo } from "react";

const useQuestionBankQuestionSubject = (
  args: QuestionBankIdentifier & { questionId: Question["id"] },
) => {
  const questionBankQuestionHeadersQuery = useQuestionBankQuestionHeaders(args);
  const { data: questionBankQuestionHeaders } =
    questionBankQuestionHeadersQuery;
  const questionBankSubjectsQuery = useQuestionBankSubjects(args);
  const { data: questionBankSubjects } = questionBankSubjectsQuery;
  const question = useMemo(
    () => ArrayMapUtils.find(questionBankQuestionHeaders, args.questionId),
    [questionBankQuestionHeaders, args.questionId],
  );

  const questionSubject = useMemo(
    () =>
      question
        ? ArrayMapUtils.find(questionBankSubjects, question.subjectId)
        : undefined,
    [questionBankSubjects, question?.subjectId],
  );

  return {
    ...mergeFlags({
      source: questionBankQuestionHeadersQuery,
      destinations: [questionBankSubjectsQuery],
    }),
    data: questionSubject,
  };
};
export default useQuestionBankQuestionSubject;
