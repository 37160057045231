import mergeFunctions from "./merge-functions";

const infuseFunctionIntoMutation = <
  Mutation extends {
    mutate: (args: Args) => Result;
    mutateAsync: (args: Args) => PromiseResult;
  },
  Args,
  Result,
  PromiseResult,
>({
  func,
  mutation,
}: {
  func: (args: Args) => any | void;
  mutation: Mutation;
}): Mutation => {
  const result = {
    ...mutation,
    mutate: mergeFunctions(mutation.mutate, [func]),
    mutateAsync: mergeFunctions(mutation.mutateAsync, [func]),
  };

  return result;
};

export default infuseFunctionIntoMutation;
