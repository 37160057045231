import usePerformanceReportsPageStore from "../-store";

export const usePerformanceReportsPageReportOptions = () =>
  usePerformanceReportsPageStore((state) => ({
    type: state.reportOptions.type,
    setType: (newType: typeof state.reportOptions.type) => {
      state.setReportOptions({ ...state.reportOptions, type: newType });
    },
    options: state.reportOptions.options,
    setOptions: (newOptions: typeof state.reportOptions.options) => {
      state.setReportOptions({ ...state.reportOptions, options: newOptions });
    },
  }));
