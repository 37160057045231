import { twMerge } from "tailwind-merge";
import {
  CancelButton,
  Layout,
  OpenNotesButton,
  SaveButton,
  TagInput,
} from "./components";
import { BlurInOut } from "@/ncomponents/utilities/animations/blur-in-out";
interface FooterProps {
  selectedNote?: { id: string };
  visible: boolean;
  isEditMode: boolean;
  onIsEditModeChange: (v: boolean) => void;
  onEditSave: () => void;
  onEditCancel: () => void;
}

export const Footer = ({
  visible,
  isEditMode,
  onIsEditModeChange,
  onEditSave,
  selectedNote,
  onEditCancel,
}: FooterProps) => {
  return (
    <Layout>
      <div
        className={twMerge(
          "flex flex-row justify-star items-center w-full opacity-0",
          visible && "opacity-100",
        )}
      >
        {selectedNote && (
          <>
            <TagInput selectedNote={selectedNote} />
            <div
              className={twMerge(
                "flex-row justify-start items-center opacity-0 transition-opacity hidden",
                visible && "opacity-100 flex",
              )}
            >
              <BlurInOut state={isEditMode ? 'visible' : "hidden"} delay={0}>
                <CancelButton
                  isEditMode={isEditMode}
                  onIsEditModeChange={onIsEditModeChange}
                  onEditCancel={onEditCancel}
                />
              </BlurInOut>
            </div>
          </>
        )}
      </div>

      <OpenNotesButton />
    </Layout>
  );
};
