import { useCreateTestPageStore } from "@/utils/stores/createTestPageStore";
import DashboardAccordionSection from "./DashboardAccordionSection";
import LabeledCheckbox from "./LabeledCheckbox";
import CreateTestSystems from "./CreateTestSystems";
import ArrayMapUtils from "@/types/common/ArrayConvertableMap";
import useCreateTestPageSystems from "@/routes/qbanks/$qbankEndpoint/dashboard/_dashboard.create-test/-hooks/use-create-test-page-systems";
import useGetSystemQuestionHeaders from "@/hooks/question-hooks/use-get-system-question-headers";
import useDashboardQuestionBank from "@/routes/qbanks/$qbankEndpoint/dashboard/-dashboard-hooks/use-dashboard-question-bank";
import useCreateTestPageQuestionHeaders from "@/routes/qbanks/$qbankEndpoint/dashboard/_dashboard.create-test/-hooks/use-create-test-page-questions";
import { useMemo } from "react";

const CreateTestSystemsSection = () => {
  const { data: questionBank } = useDashboardQuestionBank();
  const { data: systems } = useCreateTestPageSystems();
  const { data: questionHeaders } = useCreateTestPageQuestionHeaders();
  const {
    selectedSystems,
    setSelectedSystems,
    toggleSection,
    selectedSubjects,
    toggledSections,
  } = useCreateTestPageStore((state) => ({
    selectedSystems: state.selectedSystems,
    setSelectedSystems: state.setSelectedSystems,
    toggleSection: state.toggleSection,
    toggledSections: state.toggledSections,
    selectedSubjects: state.selectedSubjects,
  }));
  const { data: getSystemQuestionHeaders } = useGetSystemQuestionHeaders(
    { questionBankEndpoint: questionBank?.endpoint ?? "" },
    {
      enabled: !!questionBank,
      filters: {
        questionHeaders,
        subjects: selectedSubjects.map((s) => s.id),
      },
    },
  );
  const isTitleChecked = useMemo(
    () =>
      selectedSystems.length !== 0 &&
      selectedSystems.length ===
        ArrayMapUtils.getLength(
          ArrayMapUtils.filter(
            systems,
            (s) =>
              ArrayMapUtils.getLength(getSystemQuestionHeaders(s.id)) !== 0,
          ),
        ),
    [selectedSystems, systems],
  );
  const allSystems = useMemo(
    () =>
      ArrayMapUtils.toArray(
        ArrayMapUtils.filter(
          systems,
          (s) =>
            !!ArrayMapUtils.find(
              getSystemQuestionHeaders(s.id),
              (qh) => qh.systemId === s.id,
            ),
        ),
      ),
    [systems, getSystemQuestionHeaders],
  );

  return (
    <DashboardAccordionSection
      title={
        <div className="flex flex-row justify-center items-center gap-2">
          <LabeledCheckbox
            labelClassName="font-medium text-black"
            id="all-systems-checkbox"
            checked={isTitleChecked}
            onCheckedChange={(checked) =>
              checked ? setSelectedSystems(allSystems) : setSelectedSystems([])
            }
            label="Systems"
          />
        </div>
      }
      value="systems"
      className="border-b"
      onToggle={() => toggleSection("systems")}
      toggled={toggledSections.includes("systems")}
    >
      <div className="lg:ml-8 flex flex-row justify-center lg:justify-start items-center gap-10 mt-3 w-full pb-5 pt-2">
        <CreateTestSystems />
      </div>
    </DashboardAccordionSection>
  );
};
export default CreateTestSystemsSection;
