import useGetTopicQuestionHeaders from "./use-get-topic-question-headers";
import useQuestionBankTopics from "@/hooks/topic-hooks/use-question-bank-topics";
import { PerformanceReport } from "@/types/statistics/performance-report";
import ArrayMapUtils, { ArrayMap } from "@/types/common/ArrayConvertableMap";
import mergeFlags from "@/utils/common/merge-flags";
import { QuestionBankIdentifier } from "@/types/question-bank-types";
import { useCallback } from "react";
import { QuestionHeader } from "@/models/question-model";

const useGetQuestionBankTopicPerofrmanceReports = (
  args: QuestionBankIdentifier,
) => {
  const topicsQuery = useQuestionBankTopics(args);
  const { data: topics } = topicsQuery;
  const getTopicsQuestionHeadersQuery = useGetTopicQuestionHeaders(args);
  const {
    data: getTopicQuestionHeaders,
    isLoading: isGetTopicsQuestionHeadersLoading,
  } = getTopicsQuestionHeadersQuery;

  const getQuestionBankTopicPerformanceReports = useCallback(
    (externalQuestionHeaders?: ArrayMap<QuestionHeader>) => {
      const performanceReports: PerformanceReport[] = [];
      if (!!getTopicQuestionHeaders && !isGetTopicsQuestionHeadersLoading) {
        ArrayMapUtils.forEach(topics, (topic) => {
          let total = 0;
          let correct = 0;
          let incorrect = 0;
          let omitted = 0;
          let used = 0;
          const topicQuestionHeaders = externalQuestionHeaders
            ? ArrayMapUtils.filter(
                externalQuestionHeaders,
                (qh) => qh.topicId === topic.id,
              )
            : getTopicQuestionHeaders(topic.id);
          ArrayMapUtils.forEach(topicQuestionHeaders, (questionHeader) => {
            total++;
            if (questionHeader.used) {
              used++;
              switch (questionHeader.used) {
                case "omitted":
                  omitted++;
                  break;
                case "correct":
                  correct++;
                  break;
                case "incorrect":
                  incorrect++;
                  break;
              }
            }
          });
          const performanceReport = {
            id: topic.id,
            name: topic.name,
            total,
            incorrect,
            correct,
            omitted,
            used,
            children: [],
          };
          if (performanceReport.total === 0) return;
          performanceReports.push(performanceReport);
        });
      }
      return performanceReports;
    },
    [getTopicQuestionHeaders, isGetTopicsQuestionHeadersLoading, topics],
  );

  return {
    ...mergeFlags({
      source: topicsQuery,
      destinations: [getTopicsQuestionHeadersQuery],
    }),
    data: getQuestionBankTopicPerformanceReports,
  };
};

export default useGetQuestionBankTopicPerofrmanceReports;
