/** Clamps the given num in between min and max so that
 * the return value will never be less than min or more than max (inclusive) */

export const clamp = ({
  num,
  min,
  max,
}: {
  num: number;
  min: number;
  max: number;
}) => Math.min(Math.max(num, min), max);
