import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PropsWithClassName } from "@/types";
import { twMerge } from "tailwind-merge";

interface DashboardCenterPieceProps extends PropsWithClassName {
  icons: [IconDefinition?, IconDefinition?];
  label: string;
}

export const DashboardCenterPiece = ({
  icons,
  label,
  className,
}: DashboardCenterPieceProps) => {
  return (
    <div
      className={twMerge(
        "flex flex-col justify-center items-center w-full",
        className,
      )}
    >
      <div className="relative">
        {icons[1] && (
          <FontAwesomeIcon
            icon={icons[1]}
            className="absolute w-10 h-10 text-gray-400 warm:text-brown-500 dark:text-neutral-500 -translate-y-[150%] translate-x-[60%]"
          />
        )}
        {icons[0] && (
          <FontAwesomeIcon
            icon={icons[0]}
            className="absolute w-20 h-20 text-gray-500 warm:text-brown-700 dark:text-neutral-400 -translate-y-[50%] -translate-x-[50%]"
          />
        )}
      </div>
      <p className="mt-12 text-gray-700 warm:text-brown-800 dark:text-neutral-400 w-1/3 text-center">
        {label}
      </p>
    </div>
  );
};
