import ArrayMapUtils from "@/types/common/ArrayConvertableMap";
import useAddHints from "./use-add-hints";
import useHints from "./use-hints";
import { useEffect, useMemo } from "react";
import { GetTestInput } from "@/api/routers";
import useTestQuestions from "@/hooks/test-hooks/use-test-questions";
import { trpc } from "@/utils/trpc";
import useTestQuestionExplanations from "@/hooks/questionexplanation-hooks/use-test-questionexplanations";
import mergeFlags from "@/utils/common/merge-flags";

const useTestHints = ({ testId }: GetTestInput) => {
  const addHints = useAddHints();
  const hints = useHints();
  const testQuestionsQuery = useTestQuestions({ testId });
  const { data: testQuestions } = testQuestionsQuery;
  const testExplanationsQuery = useTestQuestionExplanations({ testId });
  const { data: testExplanations } = testExplanationsQuery;
  const testHintsQuery = trpc.hint.getTestHints.useQuery({ testId });
  const { data, isLoading } = testHintsQuery;
  useEffect(() => {
    if (data && !isLoading) {
      addHints(data);
    }
  }, [data, isLoading]);

  const testHints = useMemo(
    () =>
      ArrayMapUtils.filter(hints, (hint) => {
        return (
          !!ArrayMapUtils.find(testQuestions, hint.questionId ?? -1) ||
          !!ArrayMapUtils.find(testExplanations, hint.explanationId ?? "")
        );
      }),
    [hints, testQuestions, testExplanations],
  );

  return useMemo(
    () => ({
      ...mergeFlags({
        source: testHintsQuery,
        destinations: [testQuestionsQuery, testExplanationsQuery],
      }),
      data: testHints,
    }),
    [testHints, testHintsQuery, testQuestionsQuery, testExplanationsQuery],
  );
};
export default useTestHints;
