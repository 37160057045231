import { ComponentProps } from "react";
import ResizableBorder from "../resizable-border";
import { PropsWithClassName } from "@/types";
import { ResizableContext } from "../resizable-context";

type ResizableBorderInternalProps = ComponentProps<typeof ResizableBorder>;

type Events = Partial<Pick<ResizableContext, "events">>;

type ResizableBorderUserProps = PropsWithClassName;

const createResizableBorder =
  (
    internalProps: Omit<
      ResizableBorderInternalProps,
      "className" | "invertResizeDirection"
    >,
  ) =>
  (
    userProps: ResizableBorderUserProps &
      Pick<ResizableBorderInternalProps, "invertResizeDirection"> &
      Events,
  ) => {
    return <ResizableBorder {...internalProps} {...userProps} />;
  };

export default createResizableBorder;
