import { Button } from "@/components/ui/button";
import { SettingsIcon } from "@/components/icons";
import { WithTooltip } from "@/components/pages/dashboard";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  DropdownMenuUnclickableItem,
} from "@/components/ui/dropdown-menu";
import { Switch } from "@/components/ui/switch";
import useTestPageTest from "@/routes/tests/$testId/-test-page-hooks/use-test-page-test";
import useTestPageSetTestMode from "@/routes/tests/$testId/-test-page-hooks/use-test-page-set-test-mode";

export const EditTestModeButton = () => {
  const { data: test } = useTestPageTest();
  const { isTutor, isTimed } = {
    isTutor: test?.mode.includes("tutor"),
    isTimed: test?.mode.includes("timed"),
  };
  const setTestMode = useTestPageSetTestMode();
  return (
    <DropdownMenu>
      <DropdownMenuTrigger className="z-[10000]">
        <WithTooltip className="z-[100000]" tooltip={"Edit test mode"}>
          <Button
            variant={"ghost"}
            size={"icon"}
            className="hover:bg-nbme-primary-800 warm:hover:bg-brown-900 warm:hover:text-white hover:text-white text-white"
          >
            <SettingsIcon />
          </Button>
        </WithTooltip>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="z-[10000]">
        <DropdownMenuLabel className="text-gray-700">
          Test Mode
        </DropdownMenuLabel>
        <DropdownMenuSeparator></DropdownMenuSeparator>
        <DropdownMenuUnclickableItem className="flex flex-row justify-between">
          <p>Tutor</p>
          <Switch
            checked={isTutor}
            onCheckedChange={(newIsTutor) =>
              setTestMode({ mode: { tutor: newIsTutor } })
            }
          ></Switch>
        </DropdownMenuUnclickableItem>
        <DropdownMenuSeparator></DropdownMenuSeparator>
        <DropdownMenuUnclickableItem className="flex flex-row justify-between">
          <p>Timed</p>
          <Switch
            checked={isTimed}
            onCheckedChange={(newIsTimed) =>
              setTestMode({ mode: { timed: newIsTimed } })
            }
          ></Switch>
        </DropdownMenuUnclickableItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
