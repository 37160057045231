import useTestPageTest from "@/routes/tests/$testId/-test-page-hooks/use-test-page-test";
import useTestPageTestSelectedTestSlot from "@/routes/tests/$testId/-test-page-hooks/use-test-page-test-selected-test-slot";
import { fadeAnimation } from "@/assets/framer";
import { NbmeClickToRevealButton } from "@/components/NbmeClickToRevealButton";
import useIncrementTestTimeElapsed from "@/hooks/test-hooks/use-increment-test-time-elapsed";
import { Test } from "@/models/test-model";
import { calculateMinutesRemaining } from "@/services/test";
import { useDateEverySecond } from "@/utils/hooks";
import { useNbmeTimerVisible } from "@/utils/stores/nbmeLayoutStore";
import { AnimatePresence, motion } from "framer-motion";
import { DateTime, Duration } from "luxon";
import { useEffect, useState } from "react";
import { toast } from "sonner";
import { twMerge } from "tailwind-merge";
import { useStopwatch } from "react-timer-hook";
import useSubmitTest from "@/hooks/test-hooks/use-submit-test";

const TestTimer = ({ testId }: { testId: Test["id"] }) => {
  const { data: test } = useTestPageTest();
  const { data: selectedSlot } = useTestPageTestSelectedTestSlot();
  const { timerVisible, setTimerVisible } = useNbmeTimerVisible();
  useIncrementTestTimeElapsed();
  const [warned, setWarned] = useState<boolean>(false);
  const { seconds } = useStopwatch({ autoStart: true });
  const submitTest = useSubmitTest();
  const [secondsElapsed, setSecondsElapsed] = useState<number>(test?.timeElapsed ?? 0);
  const [testEndsAt, setTestEndsAt] = useState<Date | null>(test?.endsAt ?? null);

  useEffect(() => {
    setTestEndsAt(test?.endsAt ?? null);
  }, [test?.endsAt])

  useEffect(() => {
    setSecondsElapsed(test?.timeElapsed ?? 0);
  }, [test?.timeElapsed])


  useEffect(() => {
    if (!selectedSlot?.submitted && (!test?.submitted || !test?.suspended)) {
      setSecondsElapsed(secondsElapsed + 1);
    }

    if (selectedSlot?.submitted && testEndsAt) {
      setTestEndsAt(DateTime.fromJSDate(new Date(testEndsAt))
        .plus(Duration.fromObject({ seconds: 1 }))
        .toJSDate());
    };

    const minutesRemaining = test
      ? calculateMinutesRemaining({ test: { ...test, endsAt: testEndsAt } })
      : undefined;
    if (minutesRemaining === undefined) return;
    if (timed && testEndsAt) {
      minutesRemaining &&
        setTimeRemaining(
          Duration.fromObject({ minutes: minutesRemaining }).toFormat(
            "hh:mm:ss",
          ),
        );
    }
    if (minutesRemaining <= 0) {
      timeOut();
      return;
    }
    if (minutesRemaining <= 1 && !warned && !test?.submitted) {
      toast.warning("You have only one minute remaining.");
      setWarned(true);
    }
  }, [seconds]);

  const formatTimeElapsed = (): string => {
    if (!test?.startedAt) return "";
    return Duration.fromMillis(secondsElapsed * 1000).toFormat("hh:mm:ss");
  };

  const timed = test?.mode.includes("timed");
  const timeOut = () => timed && submitTest({ testId: test?.id ?? 0 });

  const [timeRemaining, setTimeRemaining] = useState<string>("");

  return (
    <AnimatePresence mode="wait">
      <motion.p
        className={twMerge(
          "transition-colors",
          !timerVisible && "text-nbme-primary-200",
        )}
        key={timerVisible ? "timer" : "timer-hidden"}
        {...fadeAnimation}
      >
        {timerVisible ? (
          `${timed ? `Block Time Remaining:` : `Block Time Elapsed:`} ${timed ? timeRemaining : formatTimeElapsed()
          }`
        ) : (
          <NbmeClickToRevealButton
            onClick={() => setTimerVisible(true)}
            className="text-nbme-primary-300 warm:text-brown-500 dark:text-nbme-primary-dark-400"
          />
        )}
      </motion.p>
    </AnimatePresence>
  );
};

export default TestTimer;
