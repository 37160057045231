import { TestSlot } from "@/models/testslot-model";
import { Test } from "../test-model-types";
import {
  calculateTestEndsAtIncrementInSecondsAfterSlotSelection,
  calculateTestEndsAtIncrementInSecondsAfterSuspension,
  calculateTestTimeElapsedIncrementInSeconds,
} from "@/services/test";
import { DateTime, Duration } from "luxon";

const resumeTestManipulator = ({
  test,
  lastSelectedSlot,
}: {
  test: Pick<
    Test,
    | "id"
    | "suspended"
    | "submitted"
    | "lastUpdatedTimeAt"
    | "suspendedAt"
    | "timeElapsed"
    | "endsAt"
    | "mode"
  >;
  lastSelectedSlot?: Pick<
    TestSlot,
    "submitted" | "submittedAt" | "lastSelected"
  >;
}): Pick<
  Test,
  "lastUpdatedTimeAt" | "endsAt" | "timeElapsed" | "suspended" | "suspendedAt"
> => {
  let result: Partial<ReturnType<typeof resumeTestManipulator>> = {
    endsAt: test.endsAt,
  };
  const testIsStillRunning = !test.suspended && !test.submitted;
  const selectedSlotWasSubmitted =
    lastSelectedSlot && lastSelectedSlot.submitted;
  const testIsTimed = !!test.endsAt;

  if (testIsStillRunning && selectedSlotWasSubmitted && testIsTimed) {
    result.endsAt = calculateTestEndsAtIncrementInSecondsAfterSlotSelection({
      ...test,
      previouslySelectedSlot: lastSelectedSlot!,
    });
  }

  result = {
    suspended: false,
    suspendedAt: null,
    lastUpdatedTimeAt: new Date(),
    endsAt: testIsTimed
      ? test.suspended
        ? calculateTestEndsAtIncrementInSecondsAfterSuspension({
            ...test,
            ...result,
          })
        : result.endsAt
      : null,
    timeElapsed: !lastSelectedSlot
      ? test.timeElapsed
      : DateTime.fromSeconds(test.timeElapsed)
          .plus(
            Duration.fromObject({
              seconds: calculateTestTimeElapsedIncrementInSeconds({
                testSubmitted: test.submitted,
                testSuspended: test.suspended,
                lastSlotSubmitted: lastSelectedSlot.submitted,
                lastUpdatedTimeAt: test.lastUpdatedTimeAt,
              }),
            }),
          )
          .toSeconds(),
  };

  return result as ReturnType<typeof resumeTestManipulator>;
};

export default resumeTestManipulator;
