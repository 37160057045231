import OverallStatsPage from "@/components/OverallStatsPage";
import { useTitle } from "@/hooks/common/use-title";
import { useDashboardLayoutStore } from "@/utils/stores";
import { createFileRoute } from "@tanstack/react-router";
import { useEffect } from "react";
import useDashboardQuestionBank from "../../-dashboard-hooks/use-dashboard-question-bank";

export const Route = createFileRoute(
  "/qbanks/$qbankEndpoint/dashboard/_dashboard/performance/overall",
)({
  component: PerformancePage,
});

function PerformancePage() {
  const { setHeaderText } = useDashboardLayoutStore();
  const { data: questionBank } = useDashboardQuestionBank();
  useTitle(`Overall Performance ${questionBank?.name}`);
  useEffect(() => {
    setHeaderText("Performance");
    return () => setHeaderText("");
  }, [setHeaderText]);
  return <OverallStatsPage />;
}
