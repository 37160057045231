export const handleNewTag =
  (newTag: string) =>
  ({
    next,
    setValue,
  }: {
    next: (newTag: string) => void;
    setValue: (string: string) => void;
  }) => {
    next(newTag);
    setValue("");
  };
