/** Climbs up the dom tree from the given node until
 * it reaches the question text or the question explanation node */
export const climbToSurface = (sunkenNode: Node, UPPER_LIMIT = 1000): Node => {
  let parent = sunkenNode.parentNode;
  if (!parent) return sunkenNode;
  //Avoiding using a while loop to prevent
  //freezing if the algorithm fails for any reason
  for (let i = 0; i < UPPER_LIMIT; i++) {
    if (!parent) break;
    if (
      ["question-text", "question-explanation"].includes(
        (parent as HTMLElement).getAttribute("id") ?? "",
      )
    ) {
      break;
    }
    parent = parent.parentNode ?? null;
  }

  return parent as Node;
};
