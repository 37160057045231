import { findTarget } from "@/store/utils";
import { Action } from "../../types";
import { deleteTag } from "./mutations";
import { toast } from "sonner";

export const removeTag: Action =
  ([set, get]) =>
  (tagId: string) => {
    const oldTags = get().tags;
    const targetTag = findTarget(tagId, oldTags);
    if (!targetTag) return;
    set((state) => {
      state.tags = oldTags.filter((t) => t.id !== tagId);
    });
    deleteTag(tagId).catch((error) => {
      toast.error(`Could not delete tag, an error occurred: ${error.message}`);
      set((state) => {
        state.tags.push(targetTag);
      });
    });
  };
