import GreenCheckMark from "@/components/common/green-check-mark";
import { PropsWithClassName } from "@/types";
import { cn } from "@/lib/utils";
import { cva, VariantProps } from "class-variance-authority";
import { ComponentProps } from "react";

const orderedTestButtonVariants = cva(
  "flex flex-row justify-center items-center gap-2.5 py-2 px-2.5 rounded-3xl font-semibold transition-[background-color,_transform] hover:scale-105 active:scale-95",
  {
    variants: {
      active: {
        true: "bg-primary-500 dark:bg-neutral-50 border-white text-white dark:text-neutral-900",
        false:
          "bg-white dark:bg-neutral-700 border border-gray-300 dark:border-neutral-600 text-primary-500 dark:text-white",
      },
    },
  },
);

type OrderedTestButtonProps = VariantProps<typeof orderedTestButtonVariants> &
  PropsWithClassName &
  ComponentProps<"button"> & {
    name: string;
    used?: boolean;
    questionCount: number;
  };
const OrderedTestButton = ({
  active = false,
  name,
  used = false,
  questionCount,
  className,
  ...props
}: OrderedTestButtonProps) => {
  return (
    <button
      className={orderedTestButtonVariants({ active, className })}
      {...props}
    >
      {used && <GreenCheckMark />}
      {name}
      <div
        className={cn(
          orderedTestButtonVariants({
            active: !active,
          }),
          "border-none px-1.5 w-auto h-auto py-1 text-xs",
        )}
      >
        {questionCount} Qs
      </div>
    </button>
  );
};

export default OrderedTestButton;
