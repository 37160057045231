import { useAppStore } from "@/store";
import { PageAction } from "../../types";
import { fixDates } from "../../../../utils";
import { postPage } from "./mutations";
import { toast } from "sonner";

export const addPage: PageAction =
  ([set]) =>
  (title: string) => {
    const appState = useAppStore.getState();

    const { pages: oldPages, notebook } = appState;
    if (!title) return;
    if (!notebook) return;
    const notebookId = notebook.id;
    const user = appState.currentUser;
    if (!user) return;
    const now = new Date();

    let newPages: typeof oldPages = [
      ...oldPages,
      {
        title,
        notebookId,
        id: "",
        lastUpdatedAt: now,
        createdAt: now,
        creatorId: user.id,
      },
    ];

    set((state) => {
      state.pages = newPages;
    });

    postPage({ title, notebookId })
      .catch((error) => {
        toast.error(
          `Could not add a new page, an error occurred: ${error.message}`,
        );
        set((state) => {
          state.pages = oldPages;
        });
      })
      .then((newPage) => {
        //Honestly don't know why typescript complains about this..
        //can't be bothered to find out
        //@ts-ignore
        newPages = [...oldPages, fixDates(newPage!)];
        set((state) => {
          state.pages = newPages;
        });
        toast.success(`Successfully added page: ${newPage!.title}`);
      });
  };
