import { Setter } from "@/types";
import { Slider } from "./ui/slider";
import useNbmeSidePanelContext from "./nbme-side-panel/hooks/use-nbme-side-panel-context";

type NbmeSettingsSliderProps = {
  value: number;
  defaultValue?: number;
  onValueChange: Setter<number>;
  min?: number;
  max?: number;
};
export const NbmeSettingsSlider = ({
  value,
  onValueChange,
  min = 0,
  max = 100,
}: NbmeSettingsSliderProps) => {
  const { setDraggable } = useNbmeSidePanelContext();
  return (
    <Slider
      onTouchStart={() => {
        setDraggable(false);
      }}
      onMouseEnter={() => {
        setDraggable(false);
      }}
      onTouchEnd={() => {
        setDraggable(true);
      }}
      onMouseLeave={() => {
        setDraggable(true);
      }}
      defaultValue={[value]}
      value={[value]}
      onValueChange={(arr) => onValueChange(arr[0]!)}
      max={max}
      min={min}
    />
  );
};
