import HollowSolidTransition from "@/components/hollow-solid-transition";
import { CopyToClipboardIcon as SolidCopyToClipboardIcon } from "@/components/icons";
import { CopyToClipboardIcon as HollowCopyToClipboardIcon } from "@/components/icons/hollow";

import { WithTooltip } from "@/components/pages/dashboard";
import useCopyToClipboard from "@/hooks/use-copy-to-clipboard";

const NbmeContextMenuCopyToClipboard = (
  { textToCopy, onTextCopy, ...props }:
    & { textToCopy?: string, onTextCopy?: () => void, },
) => {
  const copyToClipboard = useCopyToClipboard();
  return (
    <WithTooltip tooltip="Copy To Clipboard">
      <div
        onClick={(e) => {
          textToCopy && copyToClipboard(textToCopy);
          onTextCopy?.();
        }}
        className="hover:scale-110 transition-transform active:scale-95"
        {...props}
      >
        <HollowSolidTransition.Root className="p-1 dark:bg-neutral-600 bg-nbme-primary-600 rounded-primary ">
          <HollowSolidTransition.Solid>
            <SolidCopyToClipboardIcon className="w-5 h-5" />
          </HollowSolidTransition.Solid>

          <HollowSolidTransition.Hollow>
            <HollowCopyToClipboardIcon className="w-5 h-5" />
          </HollowSolidTransition.Hollow>
        </HollowSolidTransition.Root>
      </div>
    </WithTooltip>
  );
};

export default NbmeContextMenuCopyToClipboard;
