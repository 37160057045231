import { Setter, TableRow } from "@/types";
import { titlize } from "@/utils/common/titlize";
import { twMerge } from "tailwind-merge";
import { DashboardTableRowCollapseButton } from "./DashboardTableRowCollapseButton";

interface DashboardTableHeaderRowProps<T extends { id: string | number }> {
  row: TableRow<T>;
  isAllCollapsed: boolean;
  onIsAllCollapsedChange: Setter<boolean>;
}

export const DashboardTableHeaderRow = <T extends { id: string | number }>({
  row,
  onIsAllCollapsedChange,
  isAllCollapsed,
}: DashboardTableHeaderRowProps<T>) => {
  const cellClassName =
    "table-cell text-left border-b border-b-gray-300 dark:border-b-neutral-600 py-3 text-gray-700 font-semibold text-xs md:text-sm lg:text-base dark:text-gray-50";

  return (
    <tr>
      {row.collapsible && (
        <DashboardTableRowCollapseButton
          isCollapsed={isAllCollapsed}
          onIsCollapsedChange={onIsAllCollapsedChange}
          className={cellClassName}
        />
      )}
      {row.columns.map((column, index, array) => {
        const isFirstColumn = index === 0;
        const isLastColumn = index === array.length - 1;
        if (!column.visible) return undefined;
        return (
          <th
            key={index + column.name}
            className={twMerge(
              cellClassName,
              isFirstColumn && "pl-6",
              isLastColumn && "pr-6",
            )}
          >
            {titlize(column.name)}
          </th>
        );
      })}
    </tr>
  );
};
