import { NbmeNavbarButtonWithIcon } from ".";
import { faBoltLightning } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface NbmeFlashcardsNavbarButtonProps {
  isFlashcardsVisible: boolean;
  onIsFlashcardsVisibleChange: (v: boolean) => void;
}

export const NbmeFlashcardsNavbarButton = ({
  isFlashcardsVisible,
  onIsFlashcardsVisibleChange,
}: NbmeFlashcardsNavbarButtonProps) => {
  return (
    <NbmeNavbarButtonWithIcon
      className="w-full md:w-auto"
      icon={<FontAwesomeIcon icon={faBoltLightning} className="h-6 w-6" />}
      label="Flashcards"
      onClick={() => onIsFlashcardsVisibleChange(!isFlashcardsVisible)}
    />
  );
};
