import { PropsWithClassName } from "@/types";
import {
  ButtonHTMLAttributes,
  DetailedHTMLProps,
  PropsWithChildren,
} from "react";
import { twMerge } from "tailwind-merge";

export interface NbmeCalculatorButtonProps
  extends PropsWithChildren,
    PropsWithClassName,
    DetailedHTMLProps<
      ButtonHTMLAttributes<HTMLButtonElement>,
      HTMLButtonElement
    > {}

export const NbmeCalculatorButton = ({
  className,
  children,
  ...props
}: NbmeCalculatorButtonProps) => {
  return (
    <button
      className={twMerge(
        "relative px-3 py-2 text-gray-700 text-sm rounded-full font-semibold bg-gray-100 border border-gray-300 overflow-hidden text-center",
        "after:bg-gray-500 after:absolute after:w-full after:h-full after:left-0 after:top-0 after:opacity-0 after:hover:opacity-10 after:transition-opacity after:z-[1] after:focus:opacity-10",
        className,
      )}
      {...props}
    >
      <div className="z-[2]">{children}</div>
    </button>
  );
};
