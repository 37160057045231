import { random } from "@/lib";
import { Tag } from "../../../types";

export const constructNewTagLocally = ({
  title,
  creatorId,
  noteId,
}: {
  title: string;
  creatorId: string;
  noteId?: string;
}): Tag => {
  return {
    title,
    id: `local-${random.string()}`,
    lastUpdatedAt: new Date(),
    createdAt: new Date(),
    creatorId,
    notes: noteId ? [{ id: noteId }] : [],
  };
};
