import createQuestionNoteSlice from "./create-question-note-slice";
import { QuestionNote } from "@/models";
import { StateCreator } from "zustand";
import { SliceModifiers } from "@/store/types";
import { ArrayMap } from "@/types/common/ArrayConvertableMap";
import { inferProcedureInput } from "@trpc/server";
import { QuestionNoteRouter } from "@/api/routers/question-note-router";
import { AuthSlice } from "../auth-slice";

export type QuestionNoteSliceActions = {
  addQuestionNote: (questionNote: QuestionNote) => void;
  addQuestionNotes: (questionNotes: QuestionNote[]) => void;
  editQuestionNote: (
    args: inferProcedureInput<QuestionNoteRouter["editQuestionNote"]>,
  ) => void;
  deleteQuestionNote: (
    args: inferProcedureInput<QuestionNoteRouter["deleteQuestionNote"]>,
  ) => void;
  createQuestionNote: (args: Omit<QuestionNote, "userId">) => void;
  resetQuestionNotes: (args: { questionNoteIds: QuestionNote["id"][] }) => void;
};
export type QuestionNoteSliceState = typeof questionNoteSliceState;

export type QuestionNoteSlice = QuestionNoteSliceState &
  QuestionNoteSliceActions;

export type QuestionNoteSliceCreator = StateCreator<
  QuestionNoteSlice & AuthSlice,
  [["zustand/immer", never]],
  [],
  QuestionNoteSlice
>;

export type QuestionNoteSliceModifiers =
  SliceModifiers<QuestionNoteSliceCreator>;

export type QuestionNoteSliceAction = (
  ...modifiers: QuestionNoteSliceModifiers
) => void;

export const questionNoteSliceState = {
  questionNotes: {} as ArrayMap<QuestionNote>,
  deletedQuestionNotes: {} as ArrayMap<QuestionNote>,
};

export default createQuestionNoteSlice;
