import { NbmeNavbarButtonWithIcon } from ".";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFlaskVial } from "@fortawesome/free-solid-svg-icons";
import { MouseEventHandler } from "react";

interface NbmeLabValuesButtonProps {
  isLabValuesVisible: boolean;
  onIsLabValuesVisibleChange: (newIsLabValuesVisible: boolean) => void;
}

export const NbmeLabValuesButton = ({
  onIsLabValuesVisibleChange,
  isLabValuesVisible,
}: NbmeLabValuesButtonProps) => {
  const handleClick: MouseEventHandler = (e) => {
    onIsLabValuesVisibleChange(!isLabValuesVisible);
  };

  return (
    <NbmeNavbarButtonWithIcon
      className="w-full md:w-auto"
      icon={<FontAwesomeIcon icon={faFlaskVial} className="w-8 h-8" />}
      label="Lab Values"
      onClick={handleClick}
    />
  );
};
