import { useOnClient } from "@/hooks";
import { Counter } from "./components";
import { useUsersDashboard } from "./hooks";
export const Header = () => {
  const onClient = useOnClient();
  const { users, filteredUsers, isFiltersApplied, isLoading } =
    useUsersDashboard((state) => ({
      users: state.allUsers.length,
      filteredUsers: state.filteredUsers.length,
      isFiltersApplied: state.isFilterApplied,
      isLoading: state.areUsersLoading,
    }));
  const isReady = !isLoading;
  return (
    <div className="w-full flex-row justify-between items-center font-bold">
      {isReady && onClient && (
        <div className="flex flex-row justify-start items-center gap-5">
          <Counter name="Total Users Count:" count={users} />
          {isFiltersApplied && (
            <Counter name="Filtered Users Count:" count={filteredUsers} />
          )}
        </div>
      )}
    </div>
  );
};
