import { RichTextEditor } from "@mantine/tiptap";
import { useEditor } from "@tiptap/react";
import "@mantine/core/styles.css";
import "@mantine/tiptap/styles.css";
import { Toolbar } from "./components";
import { extensions } from "./extensions";
import { RefObject, useEffect } from "react";
import { Setter } from "@/types";
import { useAppTheme } from "@/hooks";
import { cn } from "@/lib/utils";
import useOnClickOutside from "@/hooks/use-on-click-outside";
import { toast } from "sonner";

const keyboardHandler = ({ onEscape }: { onEscape?: () => void }) => (e: KeyboardEvent) => {
  if (e.key === 'Escape') {
    e.preventDefault();
    onEscape?.();
  }
  if (e.ctrlKey) {
    if (e.key === 's') {
      e.preventDefault();
      toast.info(`No need to save, your notebook autosaves all your changes!`);

    }
  }
}

export function TipTap({
  content,
  onChange,
  editable,
  onEditableChange,
  toolbarVisible,
}: {
  content: string;
  toolbarVisible: boolean;
  onChange: Setter<string>;
  editable: boolean;
  onEditableChange: (v: boolean) => void
}) {
  const [darkMode] = useAppTheme();
  const editor = useEditor({
    immediatelyRender: false,
    editorProps: {
      attributes: {
        spellcheck: "false",
      },
    },
    onUpdate: ({ editor }) => onChange(editor?.getHTML()),
    extensions,
    content,
    editable,
  });

  const ref = useOnClickOutside(() => {
    if (!editable) return;
    onEditableChange(false);
  })

  useEffect(() => {
    editor?.setOptions({ editable });
  }, [editable]);

  useEffect(() => {
    editor?.commands.setContent(content);
  }, [content]);

  useEffect(() => {
    const handler = keyboardHandler({
      onEscape: () => {
        onEditableChange(false);
      }
    });
    document.addEventListener('keydown', handler)
    return () => document.removeEventListener('keydown', handler)
  }, [onEditableChange])

  return (
    <RichTextEditor ref={ref as RefObject<HTMLDivElement>} editor={editor}>
      <Toolbar editor={editor} visible={toolbarVisible} />
      <RichTextEditor.Content content={content} className={cn("w-full", !editable && 'cursor-pointer')} onClick={() => onEditableChange(true)} />
    </RichTextEditor>
  );
}
