import { useAppStore } from "@/store";
import { NoteAction } from "../../types";

import { findTarget } from "@/store/utils";
import { toast } from "sonner";
import { postNote } from "./mutations";
import { fixDates } from "../../utils";

export const addNote: NoteAction =
  ([set]) =>
  ({
    title,
    pageId,
    parentId,
  }: {
    title: string;
    pageId: string;
    parentId?: string;
  }) => {
    const appState = useAppStore.getState();
    const currentUser = appState.currentUser;
    if (!currentUser) return;
    if (!title) return;
    const { notes: oldNotes, pages, notebook } = appState;
    if (!notebook) {
      toast.error(`Your notebook is not loaded yet...`);
      return;
    }
    const { id: notebookId } = notebook;
    const parentPage = findTarget(pageId, pages);
    if (!parentPage) {
      toast.error(`Page does not exist or has been deleted.`);
      return;
    }
    const now = new Date();
    let newNote = {
      id: "",
      notebookId,
      creatorId: currentUser.id,
      createdAt: now,
      lastUpdatedAt: now,
      html: "",
      markdown: "",
      parentId: parentId ?? null,
      pageId,
      title,
    };

    let newNotes: typeof oldNotes = [...oldNotes, newNote];
    set((state) => {
      state.notes = newNotes;
    });
    postNote({ title, pageId, parentId })
      .catch((error) => {
        toast.error(`Could not add note, an error occured: ${error}`);
        set((state) => {
          state.notes = oldNotes;
        });
      })
      .then((noteFromServer) => {
        toast.success(`Successfully added note: ${title}.`);
        newNote = fixDates(noteFromServer!) as typeof newNote;
        newNotes = [...oldNotes, newNote];

        set((state) => {
          state.notes = newNotes;
        });
      });
  };
