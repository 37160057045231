import { useAppStore } from "@/store";
import useAuthSlice from "@/hooks/auth-hooks/use-auth-slice";

export const useNoteTags = (noteId: string) => {
  const { currentUser } = useAuthSlice();
  const { allTags, internalAddTag, connectTagToNote, disconnectTagFromNote } =
    useAppStore((state) => ({
      allTags: state.tags.filter((t) => t.creatorId === currentUser?.id),
      internalAddTag: state.addTag,
      connectTagToNote: state.connectTagToNote,
      disconnectTagFromNote: state.disconnectTagFromNote,
    }));

  const getTagByTitle = (tag: string) => allTags.find((t) => t.title === tag);

  const addTag = (tag: string) => {
    const alreadyExistingTag = getTagByTitle(tag);
    if (alreadyExistingTag) {
      if (alreadyExistingTag.id.startsWith("local")) return;
      return connectTagToNote({ tagId: alreadyExistingTag.id, noteId });
    }

    return internalAddTag({ title: tag, noteId });
  };

  const removeTag = (tag: string) => {
    const targetTag = getTagByTitle(tag);
    if (!targetTag || targetTag.id.startsWith("local")) return;
    disconnectTagFromNote({ tagId: targetTag.id, noteId });
  };

  const noteTags = allTags.filter((t) =>
    t.notes.map((n) => n.id).includes(noteId),
  );

  return { tags: noteTags, addTag, removeTag };
};
