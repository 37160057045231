import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PropsWithClassName } from "@/types";
import { IconButton } from ".";

interface TrashButtonProps extends PropsWithClassName {
  onClick?: () => void;
}

export const TrashButton = ({ className, onClick }: TrashButtonProps) => {
  return (
    <IconButton onClick={onClick} className={className}>
      <FontAwesomeIcon icon={faTrash} />
    </IconButton>
  );
};
