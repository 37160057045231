import {
  NbmeCalculatorButton,
  NbmeCalculatorButtonProps,
} from "./NbmeCalculatorButton";
import { twMerge } from "tailwind-merge";

interface NbmeCalculatorSecondaryButtonProps
  extends NbmeCalculatorButtonProps {}

export const NbmeCalculatorSecondaryButton = ({
  children,
  className,
  ...props
}: NbmeCalculatorSecondaryButtonProps) => {
  return (
    <NbmeCalculatorButton
      className={twMerge(
        "bg-indigo-500 dark:bg-indigo-600 border-0 text-white transition-colors",
        className,
      )}
      {...props}
    >
      {children}
    </NbmeCalculatorButton>
  );
};
