import { PropsWithChildren } from "react";
import useResizableContext from "./hooks/use-resizable-context";
import { PropsWithClassName } from "@/types";
import { cn } from "@/lib/utils";

type ResizableBodyProps = PropsWithChildren & PropsWithClassName;

const ResizableBody = ({ children, className }: ResizableBodyProps) => {
  const { dimensions, isResizing, bodyRef } = useResizableContext();
  return (
    <div
      ref={bodyRef}
      className={cn("relative", isResizing && "select-none", className)}
      style={{ width: dimensions?.x, height: dimensions?.y }}
    >
      {children}
    </div>
  );
};

export default ResizableBody;
