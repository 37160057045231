import { QuestionBank, StatRecord, Subject, System, Topic } from "@/models";
import { StateCreator } from "zustand";
import { SliceModifiers } from "@/store/types";
import createQuestionBankSlice from "./create-statrecord-slice";
import { ArrayMap } from "@/types/common/ArrayConvertableMap";
import { AuthSlice } from "../auth-slice";

type StatRecordIdentifiers =
  | {
      questionBankId: QuestionBank["id"];
      subjectId?: undefined;
      topicId?: undefined;
      systemId?: undefined;
    }
  | {
      questionBankId?: undefined;
      subjectId: Subject["id"];
      topicId?: undefined;
      systemId?: undefined;
    }
  | {
      questionBankId?: undefined;
      subjectId?: undefined;
      topicId: Topic["id"];
      systemId?: undefined;
    }
  | {
      questionBankId?: undefined;
      subjectId?: undefined;
      topicId?: undefined;
      systemId: System["id"];
    };

export type StatRecordSliceActions = {
  addStatRecord: (StatRecord: StatRecord) => void;
  addStatRecords: (StatRecords: StatRecord[]) => void;
  getTodaysStatRecord: ({
    questionBankId,
    topicId,
    systemId,
    subjectId,
  }: StatRecordIdentifiers) => StatRecord | undefined;
  createNewStatRecord: ({
    questionBankId,
    topicId,
    systemId,
    subjectId,
  }: StatRecordIdentifiers) => StatRecord | undefined;
  recordAnswerChange: ({
    change,
    questionBankId,
    subjectId,
    systemId,
    topicId,
  }: {
    change:
      | "correctToIncorrect"
      | "incorrectToIncorrect"
      | "incorrectToCorrect";
    questionBankId: string;
    subjectId: string;
    systemId: string;
    topicId: string;
  }) => void;
  resetStatRecords: () => void;
};
export type StatRecordSliceState = typeof statRecordSliceState;

export type StatRecordSlice = StatRecordSliceState & StatRecordSliceActions;

export type StatRecordSliceCreator = StateCreator<
  StatRecordSlice & AuthSlice,
  [["zustand/immer", never]],
  [],
  StatRecordSlice
>;

export type StatRecordSliceModifiers = SliceModifiers<StatRecordSliceCreator>;

export type StatRecordSliceAction = (
  ...modifiers: StatRecordSliceModifiers
) => void;

export const statRecordSliceState = {
  statRecords: {} as ArrayMap<StatRecord>,
};

export default createQuestionBankSlice;
