import { TableRow } from "@/types";
import { TableRowContentMapper } from "@/types";
import { twMerge } from "tailwind-merge";
import { DashboardTableRowCollapseButton } from "./DashboardTableRowCollapseButton";
import { Fragment, useState } from "react";

export interface DashboardTableRowProps<T extends { id: string | number }> {
  item: T;
  isAllCollapsed?: boolean;
  collapsedItems?: (item: T) => ({ id: string | number } & any)[];
  isCollapsedItem?: boolean;
  row: TableRow<T>;
  columnContentMapper?: TableRowContentMapper<T>;
  collapsedColumnContentMapper?: TableRowContentMapper<any>;
}

export const DashboardTableRow = <T extends { id: string | number }>({
  item,
  collapsedItems,
  row,
  columnContentMapper,
  collapsedColumnContentMapper,
  isCollapsedItem,
  isAllCollapsed,
}: DashboardTableRowProps<T>) => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);

  const cellClassName =
    "text-left border-b border-b-gray-300 dark:border-b-neutral-700 py-5 text-xs md:text-sm lg:text-base";

  return (
    <Fragment>
      <tr className="hover:bg-gray-100 warm:hover:bg-brown-50 text-gray-700 warm:text-brown-800 dark:text-gray-50 dark:hover:bg-neutral-800 transition-colors">
        {row.collapsible && (
          <DashboardTableRowCollapseButton
            isCollapsed={isAllCollapsed || isCollapsed}
            onIsCollapsedChange={setIsCollapsed}
            className={cellClassName}
          />
        )}
        {/* Empty row so that when rendering an item that is a collapsed
            item everything is aligned*/}
        {isCollapsedItem && <td className={cellClassName}></td>}
        {row.columns.map((c, index, array) => {
          const isFirstColumn = index === 0;
          const isLastColumn = index === array.length - 1;
          if (!c.visible) return undefined;

          return (
            <Fragment key={item.id + "-" + c.name}>
              <td
                className={twMerge(
                  cellClassName,
                  isFirstColumn && "pl-6",
                  isLastColumn && "pr-6",
                  isCollapsedItem && "lg:text-sm md:text-sm sm:text-xs",
                )}
              >
                {columnContentMapper
                  ? columnContentMapper(c, c.content?.(item))
                  : c.content?.(item)}
              </td>
            </Fragment>
          );
        })}
      </tr>
      {(isAllCollapsed || isCollapsed) &&
        collapsedItems?.(item).map((collapsedItem, index) => {
          return (
            <DashboardTableRow
              isCollapsedItem
              key={String(collapsedItem.id) + index}
              item={collapsedItem}
              row={{ columns: row.collapsedColumns!, collapsible: false }}
              columnContentMapper={collapsedColumnContentMapper}
            />
          );
        })}
    </Fragment>
  );
};
