import { Popover } from "@/components/common/Popover";
import { Item } from "./components";
import { motion } from "framer-motion";
import { smoothTransition } from "@/assets/framer";
import { calculateXOffsetOfCaret } from "./utils";
import { useScreenHalf } from "@/hooks";
import { useEffect, useRef } from "react";
import { twMerge } from "tailwind-merge";
import { ArrayElement } from "@/types";

interface AutocompletorProps {
  matches: { id: string; title: string }[];
  value: string;
  selectedMatch?: { title: string; id: string };
  onMatchClick: (match: ArrayElement<AutocompletorProps["matches"]>) => void;
}

export const Autocompletor = ({
  value,
  selectedMatch,
  matches,
  onMatchClick,
}: AutocompletorProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const isVisible = matches.length !== 0 && !!value;

  return (
    <motion.div
      transition={smoothTransition}
      initial={{ x: 0 }}
      animate={{ x: calculateXOffsetOfCaret(value) }}
      className="z-[1000]"
      ref={ref}
    >
      <Popover
        isVisible={isVisible}
        className={twMerge(
          "absolute min-w-0 p-2  -translate-x-[50%] transition-transform",
          "bottom-0 -translate-y-[1.25rem]",
        )}
      >
        <div className="flex flex-col-reverse justify-start items-center">
          {matches.map((t, index) => {
            const MAXIMUM_NUMBER_OF_ITEMS = 3;
            const maximumReached = index > MAXIMUM_NUMBER_OF_ITEMS - 1;
            if (maximumReached) return;
            const isSelected = selectedMatch?.title === t.title;
            return (
              <Item
                onClick={() => onMatchClick(t)}
                matcher={value}
                isSelected={isSelected}
                key={t.id}
                title={t.title}
              />
            );
          })}
        </div>
      </Popover>
    </motion.div>
  );
};
