import { NbmeSettingsSwitch } from "./NbmeSettingsSwitch";
import { useNbmePercentageAnsweredVisible } from "@/utils/stores/nbmeLayoutStore";

export const NbmeSettingsPercentageAnsweredControl = () => {
  const { percentageAnsweredVisible, setPercentageAnsweredVisible } =
    useNbmePercentageAnsweredVisible();

  return (
    <NbmeSettingsSwitch
      checked={!percentageAnsweredVisible}
      onCheckedChange={(newValue) => setPercentageAnsweredVisible(!newValue)}
    />
  );
};
