import { SliceModifiers } from "@/store/types";
import { StateCreator } from "zustand";

export const mountSlices = (
  sliceCreators: { [k: string]: StateCreator<any, any, any, any> },
  modifiers: SliceModifiers<(typeof sliceCreators)[keyof typeof sliceCreators]>,
) => {
  let result: ReturnType<(typeof sliceCreators)[keyof typeof sliceCreators]> =
    {};
  for (const creatorKey in sliceCreators) {
    const creator = sliceCreators[creatorKey]!;
    result = { ...result, ...creator(...modifiers) };
  }
  return result;
};
