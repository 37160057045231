import { Point } from "@/types";
import { NbmeFloatingWindow } from ".";
import { useEffect, useState } from "react";
import { Button } from "@/components/Button";

export interface NbmeQuestionNoteWindowProps {
  note: { text: string } | undefined;
  isVisible: boolean;
  onIsVisibleChange: (v: boolean) => void;
  position?: Point;
  onPositionChange?: (newPosition: Point) => void;
  onIsSelectionEnabledChange: (v: boolean) => void;
  onNoteChange: (note: { text: string }) => void;
  onNoteDeleted: (note: { text: string }) => void;
  onIsShortcutsEnabledChange: (v: boolean) => void;
}

export const NbmeQuestionNoteWindow = ({
  note,
  isVisible,
  onIsVisibleChange,
  position,
  onPositionChange,
  onIsSelectionEnabledChange,
  onNoteChange,
  onIsShortcutsEnabledChange,
  onNoteDeleted,
}: NbmeQuestionNoteWindowProps) => {
  const [draft, setDraft] = useState<string>("");
  useEffect(() => {
    if (!note?.text) return;
    setDraft(note.text);
  }, [note]);

  return (
    <NbmeFloatingWindow
      isVisible={isVisible}
      position={position}
      onIsSelectionEnabledChange={onIsSelectionEnabledChange}
      onPositionChanged={onPositionChange}
      title={"Edit Question Notes"}
      className="lg:min-w-[30rem] md:min-w-[25rem] min-w-[20rem]"
      viewportClassName="dark:bg-nbme-primary-dark-800"
      onCloseClicked={() => {
        onIsVisibleChange(false);
        setDraft("");
      }}
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          if (!draft) {
            onIsVisibleChange(false);
            return;
          }
          onNoteChange({ text: draft });
          onIsVisibleChange(false);
        }}
        className="flex flex-col justify-start items-center w-full p-3 gap-3"
      >
        <textarea
          value={draft}
          onChange={(e) => setDraft(e.target.value)}
          onFocus={() => onIsShortcutsEnabledChange(false)}
          onBlur={() => onIsShortcutsEnabledChange(true)}
          placeholder="Click to edit notes."
          className={`w-full border border-gray-300 rounded-primary px-2 py-1 min-h-[10rem] dark:bg-nbme-primary-dark-600
                      dark:text-white dark:placeholder:text-nbme-primary-dark-300 dark:border-nbme-primary-dark-600 dark:outline-nbme-primary-dark-600`}
        ></textarea>
        <div className="flex flex-row justify-between items-center w-full gap-5">
          <Button
            type="submit"
            className="p-1 text-sm md:text-sm lg:text-sm bg-nbme-primary-600 dark:bg-nbme-primary-dark-700 dark:hover:bg-nbme-primary-dark-600 hover:bg-nbme-primary-700 focus:bg-nbme-primary-700"
          >
            Save and Close
          </Button>
          <Button
            onClick={() => {
              if (!note?.text) return;
              onNoteDeleted(note);
              setDraft("");
              onIsVisibleChange(false);
            }}
            className="p-1 text-sm md:text-sm lg:text-sm bg-nbme-primary-600 dark:bg-nbme-primary-dark-700 dark:hover:bg-nbme-primary-dark-600 hover:bg-nbme-primary-700 focus:bg-nbme-primary-700"
          >
            Delete Notes
          </Button>
        </div>
      </form>
    </NbmeFloatingWindow>
  );
};
