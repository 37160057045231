import QuestionCountBadge from "./QuestionCountBadge";
import LabeledCheckbox from "./LabeledCheckbox";
import Accordion from "./Accordion";
import { useEffect, useMemo, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { twMerge } from "tailwind-merge";
import AccordionItem from "./AccordionItem";
import TopicCheckbox from "./TopicCheckbox";
import { useCreateTestPageStore } from "@/utils/stores/createTestPageStore";
import useCreateTestPageContext from "@/routes/qbanks/$qbankEndpoint/dashboard/_dashboard.create-test/-hooks/use-create-test-page-context";
import ArrayMapUtils from "@/types/common/ArrayConvertableMap";
import useQuestionBankSystemTopics from "@/hooks/topic-hooks/use-question-bank-system-topics";
import type { System } from "@/models";
import useGetTopicQuestionHeaders from "@/hooks/question-hooks/use-get-topic-question-headers";
import useCreateTestPageQuestionHeaders from "@/routes/qbanks/$qbankEndpoint/dashboard/_dashboard.create-test/-hooks/use-create-test-page-questions";

interface SystemCheckBoxProps {
  questionCount: number;
  system: System;
  checked: boolean;
  className?: string;
  isLoading?: boolean;
  onClick?: () => void;
}

const SystemCheckbox = ({
  checked,
  system,
  questionCount,
  onClick,
  className,
  isLoading,
}: SystemCheckBoxProps) => {
  const { questionBankEndpoint } = useCreateTestPageContext();
  const { selectedSystems, selectedTopics, toggleSelection, selectedSubjects } =
    useCreateTestPageStore((state) => ({
      selectedSystems: state.selectedSystems,
      toggleSelection: state.toggleSelection,
      selectedSubjects: state.selectedSubjects,
      selectedTopics: state.selectedTopics,
    }));

  const { data: systemTopicsArrayMap } = useQuestionBankSystemTopics({
    questionBankEndpoint,
    systemId: system.id,
  });
  const { data: questionHeaders } = useCreateTestPageQuestionHeaders();
  const { data: getTopicQuestionHeaders, isLoading: isTopicsLoading } =
    useGetTopicQuestionHeaders(
      {
        questionBankEndpoint,
      },
      {
        filters: {
          questionHeaders,
          subjects: selectedSubjects.map((s) => s.id),
        },
      },
    );

  const topics = useMemo(
    () => ArrayMapUtils.toArray(systemTopicsArrayMap),
    [systemTopicsArrayMap],
  );

  const disabled = questionCount === 0;
  const [toggledSection, setToggledSection] = useState<string[]>([]);
  const toggled = toggledSection.includes(system.id);

  // useEffect(() => {
  //   if (topics.filter((t) => !selectedTopics.includes(t)).length > 0) {
  //     toggleSelection("selectedSystems", false, system);
  //   } else toggleSelection("selectedSystems", true, system);
  // }, [selectedTopics]);

  useEffect(() => {
    for (const topic of topics) {
      toggleSelection("selectedTopics", checked, topic);
    }
  }, [checked]);

  const collapsible = topics.length > 1;

  return (
    <Accordion
      value={toggledSection}
      onValueChange={(value) => setToggledSection(value)}
      className="w-full max-w-md"
    >
      <div
        className={twMerge(
          "flex flex-col justify-start items-start",
          toggled && "gap-2 mb-5",
        )}
      >
        <div className="flex justify-between items-center w-full gap-3">
          <LabeledCheckbox
            className={className}
            id={`${system.id}-subject-checkbox`}
            checked={checked && questionCount !== 0}
            labelClassName="md:text-sm lg:text-sm"
            checkBoxClassName="min-w-max"
            onClick={onClick}
            disabled={disabled}
            label={system.name.at(0)!.toUpperCase() + system.name.slice(1)}
          />
          <div className="flex flex-row justify-start items-center gap-2">
            <QuestionCountBadge loading={isLoading} count={questionCount} />
            <button
              type="button"
              className={twMerge(
                "text-gray-500 warm:text-brown-500 invisible pointer-events-none",
                disabled && "text-gray-300 warm:text-brown-300",
                collapsible && "visible pointer-events-auto",
              )}
              onClick={() =>
                toggled ? setToggledSection([]) : setToggledSection([system.id])
              }
            >
              <FontAwesomeIcon icon={toggled ? faMinus : faPlus} />
            </button>
          </div>
        </div>
        <AccordionItem value={system.id} className="">
          <div className="lg:ml-6 ml-3 flex flex-col justify-start items-center lg:items-start gap-1 w-full">
            {topics.length > 1 &&
              topics
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((topic) => (
                  <TopicCheckbox
                    key={topic.id}
                    topic={topic}
                    questionCount={ArrayMapUtils.getLength(
                      getTopicQuestionHeaders(topic.id),
                    )}
                    isLoading={isTopicsLoading}
                    className="w-full"
                    labelClassName="lg:text-sm md:text-sm"
                    onCheckedChange={(checked) => {
                      toggleSelection("selectedTopics", checked, topic);
                    }}
                  />
                ))}
          </div>
        </AccordionItem>
      </div>
    </Accordion>
  );
};

export default SystemCheckbox;
