import { RichTextEditor, useRichTextEditorContext } from "@mantine/tiptap";
import {
  DeleteColumn,
  DeleteRow,
  DeleteTable,
  InsertColumnAfter,
  InsertColumnBefore,
  InsertRowAfter,
  InsertRowBefore,
  InsertTable,
  MergeOrSplit,
} from "./components";

export const TableControls = () => {
  const { editor } = useRichTextEditorContext();
  const currentlyOnTable = editor?.isActive("table");
  return (
    <RichTextEditor.ControlsGroup>
      <InsertTable />
      {currentlyOnTable && (
        <>
          <DeleteTable />
          <InsertRowAfter />
          <InsertRowBefore />
          <DeleteRow />
          <InsertColumnAfter />
          <InsertColumnBefore />
          <DeleteColumn />
          <MergeOrSplit />
        </>
      )}
    </RichTextEditor.ControlsGroup>
  );
};
