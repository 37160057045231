import { trpcProxyClient } from "@/utils/trpc";

export const renamePage = ({
  id,
  newTitle,
}: {
  id: string;
  newTitle: string;
}) => {
  return trpcProxyClient.notebook.page.renameOne.mutate({ id, newTitle });
};
