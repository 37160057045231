import { QuestionBankIdentifier } from "@/types/question-bank-types";
import { trpc } from "@/utils/trpc";
import { useEffect, useMemo } from "react";
import useAddQuestionNotes from "./use-add-question-notes";
import useQuestionBank from "@/hooks/question-bank-hooks/use-question-bank";
import mergeFlags from "@/utils/common/merge-flags";
import useQuestionNotes from "./use-question-notes";
import ArrayMapUtils from "@/types/common/ArrayConvertableMap";
import useQuestionBankQuestionHeaders from "@/hooks/question-hooks/use-question-bank-question-headers";
import useQuestionBankSubjects from "@/hooks/subject-hooks/use-question-bank-subjects";
import useQuestionBankTopics from "@/hooks/topic-hooks/use-question-bank-topics";
import useQuestionBankSystems from "@/hooks/system-hooks/use-question-bank-systems";
import useGetQuestionHeader from "@/hooks/question-hooks/use-get-question-header";
import useGetQuestionBankQuestionHeaderWithArticles from "@/hooks/question-hooks/use-get-question-bank-question-header-with-articles";

/**TODO: REFACTOR THIS*/
const useQuestionBankQuestionNotes = (
  args: QuestionBankIdentifier,
  {
    sortBy,
    searchQuery,
  }: {
    sortBy: "questionId" | "subjectId" | "systemId" | "topicId";
    searchQuery?: string;
  },
) => {
  const questionBankQuery = useQuestionBank(args);
  const questionHeadersQuery = useQuestionBankQuestionHeaders(args);
  const { data: questionBank } = questionBankQuery;
  const { data: questionHeaders } = questionHeadersQuery;
  const subjectsQuery = useQuestionBankSubjects(args);
  const { data: subjects } = subjectsQuery;
  const systemsQuery = useQuestionBankSystems(args);
  const { data: systems } = systemsQuery;
  const topicsQuery = useQuestionBankTopics(args);
  const { data: topics } = topicsQuery;
  const query = trpc.questionNote.getQuestionBankQuestionNotes.useQuery(
    {
      qBankId: questionBank?.id ?? "",
    },
    {
      enabled: !!questionBank,
      refetchOnMount: true,
    },
  );
  const questionNotes = useQuestionNotes();
  const addQuestionNotes = useAddQuestionNotes();
  const { data: getQuestionHeaderWithArticles } =
    useGetQuestionBankQuestionHeaderWithArticles(args);
  const { data, isLoading } = query;

  useEffect(() => {
    if (!!data && !isLoading) {
      addQuestionNotes(data);
    }
  }, [data, isLoading]);

  const filteredQuestionNotes = useMemo(
    () =>
      ArrayMapUtils.filter(questionNotes, (questionNote) =>
        ArrayMapUtils.includes(questionHeaders, (qh) => {
          const questionNoteIsInQuestionBank =
            questionNote.questionId === qh.id;
          const questionNoteTextIncludesSearchQuery = searchQuery
            ? questionNote.text
                .toLowerCase()
                .includes(searchQuery.toLowerCase())
            : true;
          const searchQueryIsNumber = !isNaN(Number(searchQuery));
          let searchQueryEqualsQuestionId = false;
          if (searchQueryIsNumber) {
            searchQueryEqualsQuestionId =
              questionNote.questionId === Number(searchQuery);
          }
          const subjectIncludesSearchQuery = !!getQuestionHeaderWithArticles(
            questionNote.questionId,
          )
            ?.subject.name.toLowerCase()
            .includes(searchQuery?.toLowerCase() ?? "");
          const systemIncludesSearchQuery = !!getQuestionHeaderWithArticles(
            questionNote.questionId,
          )
            ?.system.name.toLowerCase()
            .includes(searchQuery?.toLowerCase() ?? "");
          const topicIncludesSearchQuery = !!getQuestionHeaderWithArticles(
            questionNote.questionId,
          )
            ?.topic.name.toLowerCase()
            .includes(searchQuery?.toLowerCase() ?? "");

          return (
            (questionNoteIsInQuestionBank &&
              (questionNoteTextIncludesSearchQuery ||
                searchQueryEqualsQuestionId)) ||
            subjectIncludesSearchQuery ||
            systemIncludesSearchQuery ||
            topicIncludesSearchQuery
          );
        }),
      ),
    [questionNotes, questionHeaders, searchQuery],
  );
  const sortedQuestionNotes = useMemo(
    () =>
      ArrayMapUtils.sort(filteredQuestionNotes, (questionNote) => {
        const questionHeader = ArrayMapUtils.find(
          questionHeaders,
          questionNote.questionId,
        )!;
        const question = {
          ...questionHeader,
          subject: ArrayMapUtils.find(subjects, questionHeader.subjectId),
          system: ArrayMapUtils.find(systems, questionHeader.systemId),
          topic: ArrayMapUtils.find(topics, questionHeader.topicId),
        };
        switch (sortBy) {
          case "questionId":
            return question?.uWolrdId ?? 0;
          case "subjectId":
            return question?.subject?.name ?? "";
          case "systemId":
            return question?.system?.name ?? "";
          case "topicId":
            return question?.topic?.name ?? "";
        }
      }),
    [sortBy, filteredQuestionNotes, subjects, systems, topics, questionHeaders],
  );

  return {
    ...mergeFlags({
      source: query,
      destinations: [questionBankQuery, questionHeadersQuery],
    }),
    data: sortedQuestionNotes,
  };
};

export default useQuestionBankQuestionNotes;
