import { useNbmeExhibitStyle } from "@/utils/stores/nbmeLayoutStore";
import { nbmeHintTriggerVariants } from "./NbmeHintTrigger";
import { CheckMarkIcon } from "./icons";
import { AnimatePresence, motion } from "framer-motion";
import { fadeAnimation } from "@/assets/framer";

export const NbmeSettingsExhibitStyleControl = () => {
  const [exhibitStyle, setExhibitStyle] = useNbmeExhibitStyle();

  return (
    <div className="flex flex-row justify-start items-center divide-x divide-solid divide-gray-300 dark:divide-neutral-600 [&>*]:px-1.5">
      <div className="relative">
        <button
          className={nbmeHintTriggerVariants({
            style: "button",
            className: "text-xs",
          })}
          onClick={() => setExhibitStyle("button")}
        >
          Button
        </button>
        <CheckedCheckMark visible={exhibitStyle === "button"} />
      </div>
      <div className="relative">
        <button
          className={nbmeHintTriggerVariants({
            style: "link",
            className: "text-xs",
          })}
          onClick={() => setExhibitStyle("link")}
        >
          Link
        </button>
        <CheckedCheckMark visible={exhibitStyle === "link"} />
      </div>
      <div className="relative">
        <button
          className={nbmeHintTriggerVariants({
            style: "plain",
            className: "text-xs",
          })}
          onClick={() => setExhibitStyle("plain")}
        >
          Plain
        </button>
        <CheckedCheckMark visible={exhibitStyle === "plain"} />
      </div>
    </div>
  );
};

const CheckedCheckMark = ({ visible }: { visible: boolean }) => {
  return (
    <AnimatePresence mode="wait">
      {visible && (
        <motion.div
          {...fadeAnimation}
          className="absolute rounded-full w-3 h-3 bg-nbme-primary-600 warm:bg-brown-800 dark:bg-neutral-600 text-white inline-flex justify-center items-center -top-2 -left-1 transition-colors"
        >
          <CheckMarkIcon className="w-2 h-2" />
        </motion.div>
      )}
    </AnimatePresence>
  );
};
