import { Button } from "@/components/Button";
import Dialog from "@/components/Dialog";

interface NbmeSuspendTestDialogProps {
  isDarkMode: boolean;
  isVisible: boolean;
  onIsVisibleChange: (v: boolean) => void;
  onTestSuspended: () => void;
}

export const NbmeSuspendTestDialog = ({
  isDarkMode,
  isVisible,
  onIsVisibleChange,
  onTestSuspended,
}: NbmeSuspendTestDialogProps) => {
  return (
    <Dialog
      dark={isDarkMode}
      title={"Suspend Test?"}
      description={
        'Time will freeze until you come back again to the test, you can find it at any time in the "Previous Tests" page in your dashboard.'
      }
      open={isVisible}
      onCloseClicked={() => onIsVisibleChange(false)}
      className="dark:bg-nbme-primary-dark-900 dark:border-nbme-primary-dark-600"
      onOpenChange={onIsVisibleChange}
    >
      <div className="flex flex-row justify-end items-center mt-4">
        <div className="flex flex-row justify-start items-center gap-3">
          <Button className="p-1 px-2" onClick={() => onIsVisibleChange(false)}>
            Cancel
          </Button>
          <Button
            className="p-1 px-2"
            error
            onClick={() => {
              onIsVisibleChange(false);
              onTestSuspended();
            }}
          >
            Suspend
          </Button>
        </div>
      </div>
    </Dialog>
  );
};
