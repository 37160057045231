import { trpc } from "@/utils/trpc";
import useAddQuestionNote from "./use-add-question-note";
import { useEffect, useMemo } from "react";
import ArrayMapUtils from "@/types/common/ArrayConvertableMap";
import useQuestionNotes from "./use-question-notes";

const useQuestionQuestionNote = (questionId: number) => {
  const questionNotes = useQuestionNotes();
  const addQuestionNote = useAddQuestionNote();
  const query = trpc.questionNote.getQuestionQuestionNote.useQuery(
    {
      questionId,
    },
    { enabled: questionId > 0 },
  );

  const { data: questionNote, isLoading } = query;
  useEffect(() => {
    if (questionNote && !isLoading) {
      addQuestionNote(questionNote);
    }
  }, [questionNote, isLoading]);
  const questionQuestionNote = useMemo(
    () =>
      ArrayMapUtils.find(questionNotes, (qn) => qn.questionId === questionId),
    [questionNotes, questionId],
  );

  return {
    ...query,
    data: questionQuestionNote,
  };
};

export default useQuestionQuestionNote;
