import { useState } from "react";
import { PageTree, PlusButton } from "./components";
import { Page } from "./types";
import { usePageActions } from "@/hooks/notebook";

interface NotebookTreeProps {
  pages: Page[];
}

export const NotebookTree = ({ pages }: NotebookTreeProps) => {
  const [isAddingNewPage, setIsAddingNewPage] = useState<boolean>(false);
  const { add: addPage, remove, rename } = usePageActions();

  const handleNewPage = (title: string) => {
    setIsAddingNewPage(false);
    addPage(title);
  };

  const handleRemovePage = (id: string) => {
    remove(id);
  };

  const handleRenamePage = ({
    id,
    newTitle,
  }: {
    id: string;
    newTitle: string;
  }) => {
    rename({ id, newTitle });
  };

  return (
    <div className="flex flex-col justify-start items-center gap-3 w-full">
      <div className="flex flex-row justify-between items-center w-full">
        <p className="text-lg overflow-hidden text-ellipsis whitespace-nowrap font-semibold text-gray-700 warm:text-brown-900 dark:text-neutral-300">
          Create New Page
        </p>
        <PlusButton
          onClick={() => setIsAddingNewPage(true)}
          className="mr-3 warm:text-brown-800"
        />
      </div>
      <div className="flex flex-col justify-start items-start w-full">
        {pages.map((p) => (
          <PageTree
            onEditCancel={() => undefined}
            onEditConfirm={(page) =>
              handleRenamePage({ id: page.id, newTitle: page.title })
            }
            onRemove={() => handleRemovePage(p.id)}
            key={p.id}
            {...p}
          />
        ))}
        {isAddingNewPage && (
          <PageTree
            onRemove={() => undefined}
            key={"temporary"}
            id=""
            title=""
            notes={[]}
            isEditMode={true}
            onEditConfirm={(page) => handleNewPage(page.title)}
            onEditCancel={() => setIsAddingNewPage(false)}
          />
        )}
      </div>
    </div>
  );
};
