import { useDraggableState } from "@/components/draggable";
import { useResizableState } from "@/components/resizable";
import { useTriggerableState } from "@/components/triggerable";
import { ViewerRef } from "@/components/viewer";
import mergeObjectFunctions from "@/lib/utils/merge-object-functions";
import { ArgumentTypes, Setter } from "@/types";
import { KeyboardEventHandler, useEffect, useRef, useState } from "react";

const useFloatingWindowState = (
  args?: ArgumentTypes<typeof useTriggerableState>[0] &
    ArgumentTypes<typeof useDraggableState>[0] &
    ArgumentTypes<typeof useResizableState>[0] & {
      isDraggableFromBody?: boolean;
      onCopy?: Function;
      onDownload?: Function;
    },
) => {
  const triggerable = useTriggerableState(args);
  const resizable = useResizableState({
    minDimensions: args?.minDimensions,
    maxDimensions: args?.maxDimensions,
    initialDimensions: args?.initialDimensions,
    isResizable: args?.isResizable,
    isMaximizable: args?.isMaximizable,
    onIsResizableChanged: args?.onIsResizableChanged,
    events: {
      onResizeStart: () => {
        let newPosition = draggable.position;

        if (!newPosition) {
          const body = draggable.bodyRef.current;
          if (body) {
            const bodyRect = body.getBoundingClientRect();
            newPosition = {
              x: bodyRect.left,
              y: bodyRect.top,
            };
            draggable.setPosition(newPosition);
          }
        }
        setInitialPositionOnGesture(newPosition);
        args?.events?.onResizeStart?.();
      },
      onResizeEnd: () => {
        viewerRef.current?.centerView();
        args?.events?.onResizeEnd?.();
      },
      onMaximize: () => {
        setMinimizedPosition(draggable.position);
        draggable.setPosition({ x: 0, y: 0 });
        viewerRef.current?.centerView();
        viewerContentRef.current &&
          viewerRef.current?.zoomToElement(viewerContentRef.current);
        draggable.setIsDraggable(false);
        args?.events?.onMaximize?.();
      },
      onMinimize: () => {
        draggable.setPosition(minimizedPosition);
        viewerRef.current?.centerView();
        viewerContentRef.current &&
          viewerRef.current?.zoomToElement(viewerContentRef.current);
        draggable.setIsDraggable(true);
        args?.events?.onMinimize?.();
      },
    },
  });
  const draggable = useDraggableState({
    ...args,
    events: {
      onDrag: () => {
        if (!resizable.dimensions) {
          if (!resizable.bodyRef || !resizable.bodyRef.current) return;
          const resizableBody = resizable.bodyRef.current;
          const newDimensions = resizableBody.getBoundingClientRect();
          resizable.setDimensions({
            x: newDimensions.width,
            y: newDimensions.height,
          });
        }
        args?.events?.onDrag?.();
      },
      onDragEnd: args?.events?.onDragEnd,
      onDragStart: args?.events?.onDragStart,
    },
  });
  const [initialPositionOnGesture, setInitialPositionOnGesture] = useState(
    draggable.position,
  );
  const [viewerHasOverflow, setViewerHasOverflow] = useState(false);

  const [minimizedPosition, setMinimizedPosition] = useState(
    draggable.position,
  );

  const viewerRef = useRef<ViewerRef>(null);
  const viewerContentRef = useRef<HTMLDivElement>(null);
  const [isViewerMounted, setIsViewerMounted] = useState(false);

  useEffect(() => {
    const ref = draggable.bodyRef;
    if (!ref || !ref.current) return;
    const handleKey = (e: KeyboardEvent) => {
      if (e.key === "Escape") triggerable.setIsOpen(false);
      if (e.key === "f") {
        resizable.isMaximized ? resizable.minimize() : resizable.maximize();
      }
    };
    ref.current.addEventListener("keyup", handleKey);
    return () => {
      ref && ref.current && ref.current.removeEventListener("keyup", handleKey);
    };
  }, [draggable, draggable.bodyRef]);

  useEffect(() => {
    const ref = draggable.bodyRef;
    if (!ref || !ref.current) return;
    if (triggerable.isOpen) ref.current.focus();
  }, [triggerable.isOpen]);

  return {
    triggerable,
    draggable,
    resizable,
    initialPositionOnGesture,
    setInitialPositionOnGesture,
    minimizedPosition,
    setMinimizedPosition,
    isDraggableFromBody: args?.isDraggableFromBody,
    viewer: {
      ref: viewerRef,
      contentRef: viewerContentRef,
      hasOverflow: viewerHasOverflow,
      setHasOverflow: setViewerHasOverflow,
      isMounted: isViewerMounted,
      setIsMounted: setIsViewerMounted,
      onCopy: args?.onCopy,
      onDownload: args?.onDownload,
    },
  };
};

export type FloatingWindowState = ReturnType<typeof useFloatingWindowState>;

export default useFloatingWindowState;
