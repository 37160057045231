import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NbmeNavbarButtonWithIcon } from ".";
import { faCircleStop } from "@fortawesome/free-solid-svg-icons";

interface NbmeEndTestNavbarButtonProps {
  isEndTestDialogVisible: boolean;
  onIsEndTestDialogVisibleChange: (v: boolean) => void;
  isTestSubmitted: boolean;
}

export const NbmeEndTestNavbarButton = ({
  isEndTestDialogVisible,
  onIsEndTestDialogVisibleChange,
  isTestSubmitted,
}: NbmeEndTestNavbarButtonProps) => {
  return (
    <NbmeNavbarButtonWithIcon
      className="w-full md:w-auto"
      icon={
        <div className="flex flex-row justify-center items-center bg-white rounded-full">
          <FontAwesomeIcon
            icon={faCircleStop}
            className="w-6 h-6 text-red-500"
          />
        </div>
      }
      label={isTestSubmitted ? "End Review" : "End Block"}
      onClick={() => onIsEndTestDialogVisibleChange(!isEndTestDialogVisible)}
    />
  );
};
