import { useNbmeLayoutStore } from "@/utils/stores";

const useNbmeSidebarVisible = () => {
  const { sidebarVisible, setSidebarVisible } = useNbmeLayoutStore((state) => {
    return {
      sidebarVisible: state.sidebarVisible,
      setSidebarVisible: state.setSidebarVisible,
    };
  });

  return [sidebarVisible, setSidebarVisible] as const;
};

export default useNbmeSidebarVisible;
