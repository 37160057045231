import { createFileRoute } from "@tanstack/react-router";
import { useDashboardLayoutStore } from "@/utils/stores/dashboardLayoutStore";
import { useEffect } from "react";
import { Notebook } from "@/components/common/Notebook";
import { useTitle } from "@/hooks/common/use-title";

export const Route = createFileRoute(
  "/qbanks/$qbankEndpoint/dashboard/_dashboard/notebook",
)({
  component: NotebookPage,
});

function NotebookPage() {
  const { height } = useDashboardLayoutStore(
    (state) => state.viewportDimensions,
  );
  useTitle("My Notebook");
  const { setHeaderText } = useDashboardLayoutStore();
  useEffect(() => {
    setHeaderText("My Notebook");
    return () => setHeaderText("");
  }, [setHeaderText]);
  return (
    <div style={{ height }}>
      <Notebook />
    </div>
  );
}
