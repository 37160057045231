interface NbmeSettingsSidebarThemeControl {
  onIsDarkModeChange: (v: boolean) => void;
  isNightMode: boolean;
}
export const NbmeSettingsSidebarThemeControl = ({
  onIsDarkModeChange,
  isNightMode,
}: NbmeSettingsSidebarThemeControl) => {
  return (
    <div className="flex flex-row justify-center items-center gap-2">
      <button
        disabled={isNightMode}
        className="w-8 h-8 rounded-full bg-white border-2 border-gray-300 focus:border-primary-400 enabled:hover:border-primary-400 transition-colors"
        onClick={() => onIsDarkModeChange(false)}
      ></button>
      <button
        disabled={isNightMode}
        className={
          "w-8 h-8 rounded-full bg-black border-2 border-gray-300 focus:border-primary-400 enabled:hover:bordery-primary-400 transition-colors"
        }
        onClick={() => onIsDarkModeChange(true)}
      ></button>
    </div>
  );
};
