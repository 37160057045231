import { useEffect, useRef, useState } from "react";
import {
  BreadCrumb,
  Footer,
  Header,
  HtmlEditor,
  Layout,
  placeholder,
} from "./components";
import { useSelectedNoteContent } from "./hooks";
import { useMotionValueEvent, useScroll } from "framer-motion";
import { useTimer } from "use-timer";


export const Body = () => {
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const { content, setContent, draft, setDraft, resetDraft, selectedNote } =
    useSelectedNoteContent();

  const autoSaveTimer = useTimer({
    endTime: 1, autostart: false, onTimeOver: () => {
      if (content !== draft) setContent(draft);
    }
  })
  useEffect(() => {
    if (isEditMode && autoSaveTimer.status === 'STOPPED' || autoSaveTimer.status === 'PAUSED') {
      autoSaveTimer.start();
    }
  }, [isEditMode, autoSaveTimer])
  const containerRef = useRef<HTMLDivElement>(null);
  const [atTheTop, setAtTheTop] = useState<boolean>(true);
  const { scrollY } = useScroll({ container: containerRef });
  useMotionValueEvent(scrollY, 'change', (latest) => {
    if (latest < 80) {
      setAtTheTop(true);
    } else {
      setAtTheTop(false);
    }
  });


  return (
    <Layout ref={containerRef}>
      <BreadCrumb />
      <Header />
      <HtmlEditor
        editable={isEditMode}
        onEditableChange={(v) => {
          if (v) {
            setIsEditMode(v);
          } else {
            setIsEditMode(v);
            if (content !== draft) setContent(draft);
          }
        }}
        onChange={(value) => {
          setDraft(value);
          autoSaveTimer.reset();
        }}
        content={selectedNote ? (content ?? "") : placeholder}
        toolbarVisible={isEditMode || (!isEditMode && atTheTop)}
      />

      <Footer
        selectedNote={
          selectedNote
            ? {
              id: selectedNote.id,
            }
            : undefined
        }
        visible={!!selectedNote}
        isEditMode={isEditMode}
        onIsEditModeChange={setIsEditMode}
        onEditSave={() => {
          if (selectedNote) setContent(draft);
          setIsEditMode(false);
        }}
        onEditCancel={() => {
          if (content !== draft) setContent(draft);
        }}
      />
    </Layout>
  );
};
