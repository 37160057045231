import { Dimension, Setter } from "@/types";
import { create } from "zustand";
import { immer } from "zustand/middleware/immer";

interface DashboardLayoutStore {
  headerText: string;
  viewportDimensions: Dimension;
  setViewPortDimensions: Setter<this["viewportDimensions"]>;
  sidebarVisible: boolean;
  isResetAccountDialogVisible: boolean;
  toggleSidebar: () => void;
  showSidebar: () => void;
  hideSidebar: () => void;
  setHeaderText: (text: string) => void;
  setIsResetAccountDialogVisible: Setter<this["isResetAccountDialogVisible"]>;
}

export const useDashboardLayoutStore = create(
  immer<DashboardLayoutStore>((set) => ({
    viewportDimensions: { height: 0, width: 0 },
    setViewPortDimensions: (newValue) =>
      set(() => ({ viewportDimensions: newValue })),
    headerText: "",
    setHeaderText: (text) => set(() => ({ headerText: text })),
    hideSidebar: () =>
      set((state) => {
        state.sidebarVisible = false;
      }),
    sidebarVisible: false,
    isResetAccountDialogVisible: false,
    setIsResetAccountDialogVisible: (newValue) =>
      set(() => ({ isResetAccountDialogVisible: newValue })),
    showSidebar: () => set((state) => ({ sidebarVisible: true })),
    toggleSidebar: () =>
      set((state) => ({ sidebarVisible: !state.sidebarVisible })),
  })),
);
