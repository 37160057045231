import { createFileRoute } from "@tanstack/react-router";
import { Separator } from "@/components/Separator";
import { useBreakPoint } from "@/utils/hooks";
import { BreakPoints } from "@coursology/types";
import { cn } from "@/lib/utils";
import { Outlet } from "@tanstack/react-router";

export const Route = createFileRoute("/auth")({
  component: SigninPageLayout,
});

function SigninPageLayout() {
  const breakPoint = useBreakPoint();

  return (
    <div className="min-h-full min-w-full">
      <div className="fixed bg-primary-500 min-h-full min-w-full [clip-path:polygon(0%_65%,100%_25%,100%_100%,0%_100%)] lg:[clip-path:polygon(0%_0%,65%_0%,50%_100%,0%_100%)]"></div>
      <div className="flex flex-col justify-center items-center min-h-screen min-w-full px-3 py-3 lg:px-0 lg:py-0">
        <div className="relative flex flex-col-reverse lg:flex-row min-h-full justify-between items-center min-w-full gap-y-10">
          <div className="flex-grow flex flex-col justify-center lg:justify-center items-center min-h-full min-w-full lg:min-w-0">
            <Outlet />
          </div>
          <div className="flex flex-col justify-center items-center min-h-[35%] min-w-full lg:min-w-[50%] lg:min-h-full">
            <div className="flex flex-row lg:flex-col justify-between items-center w-full lg:min-h-screen">
              <Separator className="w-full h-[2px] lg:h-full lg:w-[2px]" />
              <img
                className={cn(
                  "h-auto",
                  (breakPoint ?? 0) < BreakPoints.Large ? "w-52" : "w-72",
                )}
                src={"/coursology_logo.svg"}
                alt="Coursology Logo"
              />
              <Separator className="w-full h-[2px] lg:h-full lg:w-[2px]" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
