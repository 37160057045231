import { NbmeSettingsSidebarSwitch } from ".";

export interface NbmeSettingsSidebarNightModeControlProps {
  isNightMode: boolean;
  onIsNightModeChange: (v: boolean) => void;
}

export const NbmeSettingsSidebarNightModeControl = ({
  isNightMode,
  onIsNightModeChange,
}: NbmeSettingsSidebarNightModeControlProps) => {
  return (
    <NbmeSettingsSidebarSwitch
      checked={isNightMode}
      onCheckedChange={onIsNightModeChange}
    />
  );
};
