import { createFileRoute } from "@tanstack/react-router";
import { DashboardFrame } from "@/components/DashboardFrame";
import NotesPageQueryInput from "./-notes-page-components/notes-page-query-input";
import NotesPageSortBySelect from "./-notes-page-components//notes-page-sort-by-select";
import { useDashboardLayoutStore } from "@/utils/stores/dashboardLayoutStore";
import { useEffect } from "react";
import NotesPageNotesContainer from "./-notes-page-components/notes-page-notes-container";
import NotesPageProvider from "./-notes-page-components/notes-page-provider";
import useNotesPageState from "./-notes-page-hooks/use-notes-page-state";
import NotesPagePaginator from "./-notes-page-components/notes-page-paginator";
import { useTitle } from "@/hooks/common/use-title";
import useDashboardQuestionBank from "../-dashboard-hooks/use-dashboard-question-bank";

export const Route = createFileRoute(
  "/qbanks/$qbankEndpoint/dashboard/_dashboard/notes",
)({
  component: NotesPage,
});
function NotesPage() {
  const params = Route.useParams();
  const { data: questionBank } = useDashboardQuestionBank();
  useTitle(`Question Notes ${questionBank?.name}`);
  const state = useNotesPageState(params.qbankEndpoint);
  const { setHeaderText } = useDashboardLayoutStore();

  useEffect(() => {
    setHeaderText("Notes");

    return () => setHeaderText("");
  }, []);

  return (
    <NotesPageProvider
      value={{
        ...state,
      }}
    >
      <DashboardFrame className="min-h-[85vh] pb-5 h-full">
        <div className="hidden md:flex flex-row justify-between items-center w-full p-3">
          <NotesPageQueryInput />
          <NotesPageSortBySelect />
        </div>
        <div className="md:hidden flex flex-col justify-start items-center w-full p-3 gap-3">
          <div className="self-start">
            <NotesPageQueryInput />
          </div>
          <div className="self-end">
            <NotesPageSortBySelect />
          </div>
        </div>
        <div className="w-full p-3">
          <NotesPageNotesContainer qbankEndpoint={params.qbankEndpoint} />
        </div>
        <div className="w-full flex flex-row justify-center md:justify-end items-center px-6 mt-5 text-xs md:text-sm lg:text-base">
          <NotesPagePaginator />
        </div>
      </DashboardFrame>
    </NotesPageProvider>
  );
}
