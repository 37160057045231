import { Point } from "@/types";
import { useEffect, useState } from "react";

export const useTouchPosition = () => {
  const [touchPos, setTouchPos] = useState<Point | undefined>(undefined);

  useEffect(() => {
    const handleTouchMove = (event: TouchEvent) => {
      setTouchPos({
        x: event.touches[0]?.clientX || 0,
        y: event.touches[0]?.clientY || 0,
      });
    };

    window.addEventListener("touchmove", handleTouchMove);
    window.addEventListener("touchstart", handleTouchMove);

    return () => {
      window.removeEventListener("touchmove", handleTouchMove);

      window.removeEventListener("touchstart", handleTouchMove);
    };
  }, [touchPos]);

  return touchPos;
};
