import { Question, TestSlot } from "@/api/types";
import { DateTime } from "luxon";
import { millisecondsToMinutes } from "@/utils/common/millisecondsToMinutes";

export const calculateSlotTimeElapsedIncrement = (slot: {
  lastSelected: Date | null;
}) => {
  return slot.lastSelected
    ? new Date().getTime() / 1000 - new Date(slot.lastSelected).getTime() / 1000
    : 0;
};

/** Calculates the duration by which to increment the elapsed time
 * in a test when selecting a new slot, takes the previous slot
 * as an argument
 * returns 0 if the previous slot was already submitted */

export const calculateTestTimeElapsedIncrementInSeconds = (args: {
  lastUpdatedTimeAt: Date | null;
  testSubmitted: boolean | null;
  testSuspended: boolean | null;
  lastSlotSubmitted: boolean | null;
}): number => {
  const { testSuspended, testSubmitted, lastUpdatedTimeAt, lastSlotSubmitted } =
    args;
  const NO_TIME_ELAPSED = 0;
  const nowInSeconds = new Date().getTime() / 1000;
  const lastUpdatedTimeAtInSeconds = lastUpdatedTimeAt
    ? new Date(lastUpdatedTimeAt).getTime() / 1000
    : 0;
  return lastSlotSubmitted ||
    !lastUpdatedTimeAt ||
    testSubmitted ||
    testSuspended
    ? NO_TIME_ELAPSED
    : nowInSeconds - lastUpdatedTimeAtInSeconds;
};

export const getSelectedSlot = (test: {
  slots: TestSlot[];
  selectedSlot: number;
}) => {
  return test.slots.find((s) => s.order === test.selectedSlot);
};

export const calculateTestEndsAtIncrementInSecondsAfterSuspension = (test: {
  mode: string;
  endsAt: Date | null;
  suspendedAt: Date | null;
}): Date | null => {
  return test.mode.includes("timed")
    ? DateTime.fromJSDate(new Date(test.endsAt!))
        .plus(
          DateTime.fromJSDate(new Date()).diff(
            DateTime.fromJSDate(new Date(test.suspendedAt!)),
          ),
        )
        .toJSDate()
    : null;
};

export const calculateTestEndsAtIncrementInSecondsAfterSlotSelection = (test: {
  submitted: boolean;
  mode: string;
  endsAt: Date | null;
  previouslySelectedSlot: {
    submitted: boolean;
    submittedAt: Date | null;
    lastSelected: Date | null;
  };
}): Date | null => {
  const testIsSubmitted = test.submitted;
  const testEndDateAsIs = test.endsAt;
  const testIsMissingEndsAtDate = test.endsAt === null;
  const testIsNotTimed = !test.mode.includes("timed");
  const slotWasNotSubmitted = !test.previouslySelectedSlot.submitted;
  const submissionDateWasNotRecorded = !test.previouslySelectedSlot.submittedAt;

  const slotLastSelectedAtDateWasNotRecorded =
    !test.previouslySelectedSlot.lastSelected;

  const now = DateTime.now();

  if (testIsSubmitted) return testEndDateAsIs;
  if (testIsNotTimed) return null;

  if (testIsMissingEndsAtDate) return null;
  const testEndsAt = DateTime.fromJSDate(new Date(test.endsAt!));

  //No need to increment time if the previous slot was not submitted
  if (slotWasNotSubmitted) return testEndDateAsIs;

  //We can't proceed with the calculation if the lastSelectedAt date
  //was missing
  if (slotLastSelectedAtDateWasNotRecorded) return testEndDateAsIs;
  const slotLastSelectedAt = DateTime.fromJSDate(
    new Date(test.previouslySelectedSlot.lastSelected!),
  );

  const timePassedSinceSlotWasSelected = now.diff(slotLastSelectedAt);

  if (submissionDateWasNotRecorded) {
    return testEndsAt.plus(timePassedSinceSlotWasSelected).toJSDate();
  }
  const slotLastSubmittedAt = DateTime.fromJSDate(
    new Date(test.previouslySelectedSlot.submittedAt!),
  );
  const timePassedSinceSlotWasSubmitted = now.diff(slotLastSubmittedAt);

  //If the slot was submitted BEFORE it has been recently selected
  //then we need to add the time passed since LAST SELECTION
  //if it was submitted AFTER the last selection, then we need
  //to add the time passed since the submission itself.
  //Basically, we add the lesser time

  const millisSinceSubmission = timePassedSinceSlotWasSubmitted.toMillis();
  const millisSinceSelection = timePassedSinceSlotWasSelected.toMillis();

  const timeToAdd =
    millisSinceSubmission <= millisSinceSelection
      ? timePassedSinceSlotWasSubmitted
      : timePassedSinceSlotWasSelected;

  const newEndsAt = testEndsAt.plus(timeToAdd).toJSDate();
  return newEndsAt;
};

type QuestionWithChild = Question & { child: Question | null };

const isQuestionOrphaned = (question: QuestionWithChild) => {
  return !question.parentId && !question.child;
};

const deconstructTestMode = (mode: string): string[] => {
  return mode.split(",");
};

const reconstructTestMode = ({
  tutor,
  timed,
}: {
  tutor?: boolean;
  timed?: boolean;
}) => {
  return [tutor ? "tutor" : "", timed ? "timed" : ""].join(",");
};

export const editTestMode = (
  mode: string,
  { tutor, timed }: { tutor?: boolean; timed?: boolean },
): string => {
  const wasAlreadyTutor = mode.includes("tutor");
  const wasAlreadyTimed = mode.includes("timed");
  return reconstructTestMode({
    tutor: tutor ?? wasAlreadyTutor,
    timed: timed ?? wasAlreadyTimed,
  });
};

export const calculateMinutesForQuestions = (
  questionCount: number,
  minutesPerQuestion = 1.5,
) => questionCount * minutesPerQuestion;

export const calculateTestDueDate = ({
  unsubmittedSlotCount,
  mode,
  minutesPerQuestion = 1.5,
}: {
  unsubmittedSlotCount: number;
  mode: string;
  minutesPerQuestion?: number;
}): Date | null => {
  const isTimed = mode.includes("timed");
  if (!isTimed) return null;
  return DateTime.fromJSDate(new Date())
    .plus({
      minutes: calculateMinutesForQuestions(
        unsubmittedSlotCount,
        minutesPerQuestion,
      ),
    })
    .toJSDate();
};

export const calculateMinutesRemaining = ({
  test,
}: {
  test: {
    endsAt: Date | null;
    suspended: boolean | null;
    submitted: boolean | null;
  };
}) => {
  if (!test.endsAt) return;
  if (test.suspended) return;
  if (test.submitted) return;
  const whenTestEnds = DateTime.fromJSDate(new Date(test.endsAt));
  const result = millisecondsToMinutes(whenTestEnds.diffNow().toMillis());

  return result;
};
