import useQuestionBank from "@/hooks/question-bank-hooks/use-question-bank";
import { QuestionBankIdentifier } from "@/types/question-bank-types";
import { trpc } from "@/utils/trpc";
import { useEffect, useMemo } from "react";
import useAddTests from "./use-add-tests";
import useTests from "./use-tests";
import mergeFlags from "@/utils/common/merge-flags";
import ArrayMapUtils from "@/types/common/ArrayConvertableMap";

const useQuestionBankTests = (args: QuestionBankIdentifier) => {
  const questionBankQuery = useQuestionBank(args);
  const { data: questionBank } = questionBankQuery;
  const query = trpc.test.getQuestionBankTests.useQuery(
    {
      qBankId: questionBank?.id ?? "",
    },
    { enabled: !!questionBank },
  );
  const tests = useTests();
  const addTests = useAddTests();
  const { data, isLoading } = query;

  useEffect(() => {
    if (!!data && !isLoading) {
      //Sorry but I have to ignore the types for this
      //as any date shows as 'string' somehow....
      //@ts-ignore
      addTests(data);
    }
  }, [data, isLoading]);

  const questionBankTests = useMemo(
    () =>
      ArrayMapUtils.filter(tests, (t) => t.questionBankId === questionBank?.id),
    [tests, questionBank],
  );

  return {
    ...mergeFlags({ source: questionBankQuery, destinations: [query] }),
    data: questionBankTests,
  };
};

export default useQuestionBankTests;
