import { QuestionBankIdentifier } from "@/types/question-bank-types";
import useQuestionBankQuestionHeaders from "./use-question-bank-question-headers";
import useTestTestSlots from "@/hooks/test-slot-hooks/use-test-test-slots";
import ArrayMapUtils from "@/types/common/ArrayConvertableMap";
import useTest from "@/hooks/test-hooks/use-test";
import { useMemo } from "react";

const useTestQuestionHeaders = ({ testId }: { testId: number }) => {
  const testQuery = useTest({ testId });
  const { data: test } = testQuery;
  const query = useQuestionBankQuestionHeaders(
    {
      questionBankId: test?.questionBankId ?? "",
    },
    { enabled: !!test },
  );
  const { data: questionBankQuestionHeaders } = query;

  const testSlotsQuery = useTestTestSlots({ testId });

  const { data: slots } = testSlotsQuery;

  const testQuestionHeaders = useMemo(
    () =>
      ArrayMapUtils.filter(questionBankQuestionHeaders, (qh) =>
        ArrayMapUtils.includes(slots, (s) => s.questionId === qh.id),
      ),
    [questionBankQuestionHeaders, slots],
  );

  return { ...query, data: testQuestionHeaders };
};

export default useTestQuestionHeaders;
