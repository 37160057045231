import { ScrollArea as CommonScrollArea } from "@/components/common/ScrollArea";
import { PropsWithClassName } from "@/types";
import { cn } from "@/lib/utils";
import React, { PropsWithChildren } from "react";
export const ScrollArea = React.forwardRef<HTMLDivElement, PropsWithChildren & PropsWithClassName>(({
  children,
  className,
}, ref) => {
  return (
    <CommonScrollArea
      className={"h-full w-full max-h-screen"}
      viewportClassName={cn("pt-6 px-6 h-full w-full max-h-screen", className)}
      thumbClassName={"z-[1000] bg-gray-400 warm:bg-brown-400"}
      scrollbarClassName={
        "data-[orientation=vertical]:w-2.5 hover:bg-gray-300 hover:bg-brown-200 rounded-none bg-transparent"
      }
      ref={ref}
    >
      {children}
    </CommonScrollArea>
  );
});
