import useGetSubjectQuestionHeaders from "./use-get-subject-question-headers";
import useQuestionBankSubjects from "@/hooks/subject-hooks/use-question-bank-subjects";
import { PerformanceReport } from "@/types/statistics/performance-report";
import ArrayMapUtils, { ArrayMap } from "@/types/common/ArrayConvertableMap";
import mergeFlags from "@/utils/common/merge-flags";
import { QuestionBankIdentifier } from "@/types/question-bank-types";
import { useCallback } from "react";
import { QuestionHeader } from "@/models/question-model";
import { Subject, System } from "@/models";
import useQuestionBankSystems from "@/hooks/system-hooks/use-question-bank-systems";

type Opts = {
  filters?: {
    questionHeaders?: ArrayMap<QuestionHeader>;
    subjects?: ArrayMap<Subject>;
    systems?: ArrayMap<System>;
  };
};

/** Quite Literally the only usage of this hook is to get a
 * function that does not require the system performance reports
 * because the system reference reports also references subject
 * performance reports so we need a function that doesn't
 * circularly refers to itself */
const useGetQuestionBankSubjectChildlessPerofrmanceReports = (
  args: QuestionBankIdentifier,
  opts?: Opts,
) => {
  const subjectsQuery = useQuestionBankSubjects(args);
  const { data: subjectsData } = subjectsQuery;
  const systemsQuery = useQuestionBankSystems(args, {
    enabled: !!opts?.filters?.systems,
  });
  const { data: internalSystems } = systemsQuery;
  const internalSubjects = opts?.filters?.subjects ?? subjectsData;
  const getSubjectsQuestionHeadersQuery = useGetSubjectQuestionHeaders(args, {
    filters: { questionHeaders: opts?.filters?.questionHeaders },
  });
  const {
    data: getSubjectQuestionHeaders,
    isLoading: isGetSubjectsQuestionHeadersLoading,
  } = getSubjectsQuestionHeadersQuery;

  const getQuestionBankSubjectPerformanceReports = useCallback(
    (opts?: Opts) => {
      const performanceReports: PerformanceReport[] = [];
      const subjects = opts?.filters?.subjects ?? internalSubjects;
      const systems = opts?.filters?.systems ?? internalSystems;
      if (!!getSubjectQuestionHeaders && !isGetSubjectsQuestionHeadersLoading) {
        ArrayMapUtils.forEach(subjects, (subject) => {
          let total = 0;
          let correct = 0;
          let incorrect = 0;
          let omitted = 0;
          let used = 0;
          const subjectQuestionHeaders = opts?.filters?.questionHeaders
            ? ArrayMapUtils.filter(
                opts?.filters?.questionHeaders,
                (qh) =>
                  qh.subjectId === subject.id &&
                  (systems
                    ? ArrayMapUtils.includes(systems, qh.systemId)
                    : true),
              )
            : getSubjectQuestionHeaders(subject.id);
          ArrayMapUtils.forEach(subjectQuestionHeaders, (questionHeader) => {
            total++;
            if (questionHeader.used) {
              used++;
              switch (questionHeader.used) {
                case "omitted":
                  omitted++;
                  break;
                case "correct":
                  correct++;
                  break;
                case "incorrect":
                  incorrect++;
                  break;
              }
            }
          });
          const performanceReport = {
            id: subject.id,
            name: subject.name,
            total,
            incorrect,
            correct,
            omitted,
            used,
            children: [],
          };
          if (performanceReport.total === 0) return;
          performanceReports.push(performanceReport);
        });
      }
      return performanceReports;
    },
    [
      getSubjectQuestionHeaders,
      isGetSubjectsQuestionHeadersLoading,
      internalSubjects,
      internalSystems,
    ],
  );

  return {
    ...mergeFlags({
      source: subjectsQuery,
      destinations: [getSubjectsQuestionHeadersQuery],
    }),
    data: getQuestionBankSubjectPerformanceReports,
  };
};

export default useGetQuestionBankSubjectChildlessPerofrmanceReports;
