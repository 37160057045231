import { climbToSurface, getCharsBefore } from "./utils";
import { getOcurrence } from "../utils";

export const analyzeSelection = (selection: Selection | null) => {
  if (!selection) return;
  if (!selection.anchorNode || !selection.focusNode) return; //This means that there is no selection.
  if (selection.isCollapsed) return null;
  const { anchorNode, anchorOffset, focusOffset, focusNode } = selection;

  let realBeginningIndex = anchorOffset;
  let realEndingIndex = focusOffset;

  const surfacedAnchorNode = climbToSurface(anchorNode);
  const text = surfacedAnchorNode.textContent;
  realBeginningIndex += getCharsBefore(anchorNode).length;
  realEndingIndex += getCharsBefore(focusNode).length;
  const temp = realBeginningIndex;
  realBeginningIndex = Math.min(temp, realEndingIndex);
  realEndingIndex = Math.max(temp, realEndingIndex);
  const selectionText = text?.slice(realBeginningIndex, realEndingIndex);
  const result = {
    startIndex: realBeginningIndex,
    endIndex: realEndingIndex,
    text,
    selectionText,
    selection,
    ocurrence: getOcurrence(
      selectionText ?? "",
      realBeginningIndex,
      text ?? "",
    ),
  };
  return result;
};
