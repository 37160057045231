import { useCollapsedItems, useNotebookUi } from ".";
import { useNotes, usePages, useTags } from "..";

export const useSearch = () => {
  const pages = usePages();
  const notes = useNotes();
  const tags = useTags();
  const { collapsedItems, setCollapsedItems } = useCollapsedItems();
  const { search, internalSetSearch } = useNotebookUi((state) => ({
    search: state.search,
    internalSetSearch: state.setSearch,
  }));

  const setSearch = (newSearch: string) => {
    internalSetSearch(newSearch);
    if (!newSearch) return;

    const collapsedItems = notes
      .filter((n) => {
        const noteTitleMatches = n.title
          .toLowerCase()
          .includes(newSearch.toLowerCase());

        const noteContentMatches = n.html
          ?.toLowerCase()
          .includes(newSearch.toLowerCase());

        const noteTagsMatch = tags.find((t) =>
          t.title.includes(newSearch.toLowerCase()),
        );

        return noteTitleMatches || noteContentMatches || noteTagsMatch;
      })
      .flatMap((n) => [n.id, n.pageId, n.parentId ?? ""]);
    const collapsedPages = pages
      .filter((p) => p.title.toLowerCase().includes(newSearch.toLowerCase()))
      .map((p) => p.id);

    setCollapsedItems([...collapsedItems, ...collapsedPages]);
    console.log(collapsedItems);
  };

  return [search, setSearch] as const;
};
