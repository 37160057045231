import useAddQuestions from "@/hooks/question-hooks/use-add-questions";
import { Test } from "@/models/test-model";
import { trpc } from "@/utils/trpc";
import useTest from "./use-test";
import { useEffect, useMemo } from "react";
import useTestTestSlots from "@/hooks/test-slot-hooks/use-test-test-slots";
import mergeFlags from "@/utils/common/merge-flags";
import ArrayMapUtils from "@/types/common/ArrayConvertableMap";
import useQuestions from "@/hooks/question-hooks/use-questions";

const useTestQuestions = ({
  testId,
  slots,
}: {
  testId: Test["id"];
  slots?: number[];
}) => {
  const questions = useQuestions();
  const addQuestions = useAddQuestions();
  const testQuery = useTest({ testId });
  const testSlotsQuery = useTestTestSlots({ testId });
  const { data: testSlots } = testSlotsQuery;
  const { data: test } = testQuery;
  const query = trpc.question.getTestQuestions.useQuery(
    {
      testId,
      slots,
    },
    {
      enabled: !!test,
    },
  );
  const { data, isLoading } = query;

  useEffect(() => {
    if (!!data && !isLoading) {
      addQuestions(
        data.map((q) => ({ ...q, createdAt: new Date(q.createdAt) })),
      );
    }
  }, [data, isLoading]);
  const testQuestions = useMemo(
    () =>
      ArrayMapUtils.filter(questions, (q) =>
        ArrayMapUtils.includes(testSlots, (slot) => slot.questionId === q.id),
      ),
    [testSlots, questions],
  );
  return useMemo(
    () => ({
      ...mergeFlags({
        source: testQuery,
        destinations: [testQuery, testSlotsQuery, query],
      }),
      data: testQuestions,
    }),
    [testQuery, testSlotsQuery, query, testQuestions],
  );
};

export default useTestQuestions;
