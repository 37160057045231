import createQuestionChoiceSlice from "./create-question-choice-slice";
import { QuestionChoice } from "@/models";
import { StateCreator } from "zustand";
import { SliceModifiers } from "@/store/types";
import { ArrayMap } from "@/types/common/ArrayConvertableMap";

export type QuestionChoiceSliceActions = {
  addQuestionChoice: (questionChoice: QuestionChoice) => void;
  addQuestionChoices: (questionChoices: QuestionChoice[]) => void;
};
export type QuestionChoiceSliceState = typeof questionChoiceSliceState;

export type QuestionChoiceSlice = QuestionChoiceSliceState &
  QuestionChoiceSliceActions;

export type QuestionChoiceSliceCreator = StateCreator<
  QuestionChoiceSlice,
  [["zustand/immer", never]],
  [],
  QuestionChoiceSlice
>;

export type QuestionChoiceSliceModifiers =
  SliceModifiers<QuestionChoiceSliceCreator>;

export type QuestionChoiceSliceAction = (
  ...modifiers: QuestionChoiceSliceModifiers
) => void;

export const questionChoiceSliceState = {
  questionChoices: {} as ArrayMap<QuestionChoice>,
};

export default createQuestionChoiceSlice;
