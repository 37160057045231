import ArrayMapUtils from "@/types/common/ArrayConvertableMap";
import useQuestionChoices from "./use-question-choices";
import useAddQuestionChoices from "./use-add-question-choices";
import useTestTestSlots from "@/hooks/test-slot-hooks/use-test-test-slots";
import { useEffect, useMemo } from "react";
import { trpc } from "@/utils/trpc";

const useTestQuestionChoices = ({
  testId,
  slots,
}: {
  testId: number;
  slots?: number[];
}) => {
  const addQuestionChoices = useAddQuestionChoices();
  const questionChoices = useQuestionChoices();
  const testSlotQuery = useTestTestSlots({ testId });
  const { data: testSlots } = testSlotQuery;
  const questionChoicesQuery =
    trpc.questionChoice.getTestQuestionChoices.useQuery({
      testId,
      slots,
    });
  const { data, isLoading } = questionChoicesQuery;
  useEffect(() => {
    if (!!data && !isLoading) {
      addQuestionChoices(data);
    }
  }, [data, isLoading]);
  const testQuestionChoices = useMemo(
    () =>
      ArrayMapUtils.filter(questionChoices, (c) =>
        ArrayMapUtils.includes(
          testSlots,
          (ts) => ts.questionId === c.questionId,
        ),
      ),
    [questionChoices, testSlots],
  );

  return useMemo(
    () => ({
      ...questionChoicesQuery,
      data: testQuestionChoices,
    }),
    [questionChoicesQuery, testQuestionChoices],
  );
};
export default useTestQuestionChoices;
