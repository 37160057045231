import { DashboardFooter } from "@/components/DashboardFooter";
import { DashboardHeader } from "@/components/DashboardHeader";
import { DashboardSidebar } from "@/components/pages/dashboard";
import { LoadingPage } from "@/components/LoadingPage";
import { CoursologyErrorPage } from "@/components/CoursologyErrorPage";
import { useDashboardLayoutStore } from "@/utils/stores/dashboardLayoutStore";
import { PropsWithChildren, useEffect, useRef } from "react";
import { twMerge } from "tailwind-merge";
import { useBreakPoint } from "@/utils/hooks/useBreakpoints";
import { DashboardScrollArea } from "./-components";
import { DashboardResetAccountDialog } from "@/components/DashboardResetAccountDialog";
import { useDimensions } from "@/utils/hooks";
import { remToPx } from "@/utils/common/remToPx";
import useQuestionBank from "@/hooks/question-bank-hooks/use-question-bank";
import DashboardContextProvider from "./-components/dashboard-context-provider";
import useCurrentUser from "@/hooks/auth-hooks/use-current-user";
import useQuestionBankQuestionHeaders from "@/hooks/question-hooks/use-question-bank-question-headers";
import useQuestionBankSubjects from "@/hooks/subject-hooks/use-question-bank-subjects";
import useQuestionBankSystems from "@/hooks/system-hooks/use-question-bank-systems";
import useQuestionBankTopics from "@/hooks/topic-hooks/use-question-bank-topics";
import useQuestionBankTests from "@/hooks/test-hooks/use-question-bank-tests";
import {
  createFileRoute,
  Outlet,
  useRouterState,
} from "@tanstack/react-router";

export const Route = createFileRoute(
  "/qbanks/$qbankEndpoint/dashboard/_dashboard",
)({
  component: QBankDashboardLayout,
});

function QBankDashboardLayout() {
  const params = Route.useParams();
  const mainLayoutRef = useRef<HTMLDivElement>(null);
  const dimensions = useDimensions(mainLayoutRef);
  const breakPoint = useBreakPoint();
  const user = useCurrentUser();
  const questionBankQuery = useQuestionBank({
    questionBankEndpoint: params.qbankEndpoint,
  });
  useQuestionBankSubjects({ questionBankEndpoint: params.qbankEndpoint });
  useQuestionBankSystems({ questionBankEndpoint: params.qbankEndpoint });
  useQuestionBankTopics({ questionBankEndpoint: params.qbankEndpoint });
  useQuestionBankTests({ questionBankEndpoint: params.qbankEndpoint });
  const questionBankQuestionHeaders = useQuestionBankQuestionHeaders({
    questionBankEndpoint: params.qbankEndpoint,
  });
  const {
    data: qBank,
    isLoading: isQBankLoading,
    error: qBankError,
  } = questionBankQuery;

  const pathname = useRouterState().location.pathname;

  const pageLayoutMapper: { [k: string]: string } = {
    notebook: "p-0",
  };

  const { showSidebar, hideSidebar, setViewportDimensions } =
    useDashboardLayoutStore((state) => ({
      showSidebar: state.showSidebar,
      hideSidebar: state.hideSidebar,
      setViewportDimensions: state.setViewPortDimensions,
    }));

  useEffect(() => {
    const height = dimensions.height - (remToPx(3.5) + remToPx(2.1));
    const width = dimensions.width;
    setViewportDimensions({ height, width });
  }, [dimensions]);

  //Always show sidebar
  useEffect(() => {
    showSidebar();
  }, [breakPoint, hideSidebar, showSidebar]);

  if (isQBankLoading) {
    return <LoadingPage />;
  }

  if (qBankError && qBankError.data?.code === "NOT_FOUND") {
    return (
      <CoursologyErrorPage>
        <h1 className="font-bold text-2xl">Error 404!</h1>
        <p className="text-gray-900 font-semibold text-xl">
          This QBank does not exist on Coursology
        </p>
      </CoursologyErrorPage>
    );
  }

  if (qBankError && qBankError.data?.code === "FORBIDDEN") {
    return (
      <CoursologyErrorPage>
        <h1 className="font-bold text-2xl">Not Allowed</h1>
        <p className="text-gray-900 font-semibold text-xl">
          {`This QBank is not included in your current subscription (${user?.currentSubscription.name})`}
        </p>
      </CoursologyErrorPage>
    );
  }

  return (
    <DashboardContextProvider
      value={{
        questionBank: questionBankQuery,
        questionHeaders: questionBankQuestionHeaders,
      }}
    >
      <div
        className={twMerge(
          "flex flex-row justify-start items-start min-h-screen h-screen w-full bg-gray-100 warm:bg-brown-50 dark:bg-neutral-950 transition-colors",
        )}
      >
        <DashboardResetAccountDialog />
        <DashboardSidebar />
        <div
          ref={mainLayoutRef}
          className="flex flex-col justify-start items-center w-full h-full"
        >
          <DashboardHeader />
          <DashboardScrollArea
            className="w-full h-full flex-grow"
            //3.5rem for the header and 2.1rem for the footer.
            viewportClassName={twMerge(
              "relative flex flex-col justify-between w-full h-full items-center p-5 ",
              pageLayoutMapper[pathname.split("/").at(-1) ?? ""],
            )}
          >
            <Outlet />
          </DashboardScrollArea>
          <DashboardFooter />
        </div>
      </div>
    </DashboardContextProvider>
  );
}
