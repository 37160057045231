import { TestSlot } from "@/models/testslot-model";
import { Test } from "../test-model-types";
import { DateTime, Duration } from "luxon";
import { calculateTestTimeElapsedIncrementInSeconds } from "@/services/test";

const suspendTestManipulator = ({
  test,
  lastSelectedSlot,
}: {
  test: Pick<
    Test,
    "lastUpdatedTimeAt" | "submitted" | "suspended" | "timeElapsed"
  >;
  lastSelectedSlot?: Pick<TestSlot, "submitted">;
}): Pick<Test, "suspended" | "suspendedAt" | "timeElapsed"> => {
  return {
    suspended: true,
    suspendedAt: new Date(),
    timeElapsed: lastSelectedSlot
      ? DateTime.fromSeconds(test.timeElapsed)
          .plus(
            Duration.fromObject({
              seconds: calculateTestTimeElapsedIncrementInSeconds({
                lastSlotSubmitted: lastSelectedSlot.submitted,
                lastUpdatedTimeAt: test.lastUpdatedTimeAt,
                testSubmitted: test.submitted,
                testSuspended: test.suspended,
              }),
            }),
          )
          .toSeconds()
      : test.timeElapsed,
  };
};

export default suspendTestManipulator;
