import { inferProcedureInput } from "@trpc/server";
import { Action } from "../../types";
import { AppRouter } from "@/types";
import { findTarget } from "@/store/utils";
import { trpcProxyClient } from "@/utils/trpc";
import { toast } from "sonner";

export const changeExtraMonths: Action =
  ([set, get]) =>
  ({
    userId,
    newExtraMonths,
  }: inferProcedureInput<AppRouter["user"]["changeExtraMonths"]>) => {
    const oldUser = findTarget(userId, get().allUsers);
    if (!oldUser) return;
    set((state) => {
      const user = findTarget(userId, state.allUsers)!;
      user.extraMonths = newExtraMonths;
    });
    trpcProxyClient.user.changeExtraMonths
      .mutate({ userId, newExtraMonths })
      .then(() => {
        toast.success(`Successfully changed extra months!`);
      })
      .catch((exc) => {
        toast.success(
          `Could not change user's extra months, an error occurred: ${exc.message}`,
        );
        set((state) => {
          const targetUser = findTarget(userId, state.allUsers)!;
          targetUser.extraMonths = oldUser.extraMonths;
        });
      });
  };
