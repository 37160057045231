import {
  CenterIcon,
  CopyToClipboardIcon,
  RefreshIcon,
  ZoomInIcon,
  ZoomOutIcon,
} from "@/components/icons";
import { WithTooltip } from "@/components/pages/dashboard";
import { Button } from "@/components/ui/button";
import { DownloadIcon } from "@/components/icons";
import useViewerContext from "./hooks/use-viewer-context";

type ViewerToolbarProps = Partial<{
  onCopy: ReturnType<typeof useViewerContext>["onCopy"];
  onDownload: ReturnType<typeof useViewerContext>["onDownload"];
}>;

const ViewerToolbar = ({ onCopy, onDownload }: ViewerToolbarProps) => {
  const context = useViewerContext();
  const viewer = context.ref.current;
  return (
    <div className="flex flex-row flex-wrap justify-center items-center gap-2 py-2 bg-white dark:bg-neutral-800 bg-opacity-40 backdrop-blur w-full shadow-md">
      <WithTooltip tooltip={"Zoom In"}>
        <Button
          variant={"ghost"}
          onClick={() => viewer?.zoomIn()}
          className="flex flex-row justify-center items-center gap-2"
        >
          <ZoomInIcon />
        </Button>
      </WithTooltip>
      <WithTooltip tooltip={"Zoom Out"}>
        <Button
          variant={"ghost"}
          onClick={() => viewer?.zoomOut()}
          className="flex flex-row justify-center items-center gap-2"
        >
          <ZoomOutIcon />
        </Button>
      </WithTooltip>
      <WithTooltip tooltip={"Reset"}>
        <Button
          variant={"ghost"}
          onClick={() => viewer?.resetTransform()}
          className="flex flex-row justify-center items-center gap-2"
        >
          <RefreshIcon />
        </Button>
      </WithTooltip>
      <WithTooltip tooltip={"Center"}>
        <Button variant={"ghost"} onClick={() => viewer?.centerView()}>
          <CenterIcon className="w-5 h-5" />
        </Button>
      </WithTooltip>
      {(onCopy || context.onCopy) && (
        <WithTooltip tooltip={"Copy to Clipboard"}>
          <Button
            variant={"ghost"}
            onClick={() => {
              onCopy?.();
              context.onCopy?.();
            }}
          >
            <CopyToClipboardIcon className="w-4 h-4" />
          </Button>
        </WithTooltip>
      )}
      {(onDownload || context.onDownload) && (
        <WithTooltip tooltip={"Download Image"}>
          <Button
            variant={"ghost"}
            onClick={() => {
              onDownload?.();
              context.onDownload?.();
            }}
          >
            <DownloadIcon />
          </Button>
        </WithTooltip>
      )}
    </div>
  );
};

export default ViewerToolbar;
