import { faChartColumn } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { scaleUnitInRem } from "@/utils/common/scaleUnitInRem";
import { numberToAlphabet } from "@/utils/common/numberToAlphabet";
import { useMemo } from "react";
import { twMerge } from "tailwind-merge";
import { DateTime } from "luxon";
import { faCalendar, faClock } from "@fortawesome/free-regular-svg-icons";
import {
  useNbmeFontSizeFactor,
  useNbmePercentageAnsweredVisible,
} from "@/utils/stores/nbmeLayoutStore";
import { useBreakPoint } from "@/utils/hooks/useBreakpoints";
import { BreakPoints, FontSizes } from "@/types";
import { AnimatePresence, motion } from "framer-motion";
import { fadeAnimation } from "@/assets/framer";
import { NbmeClickToRevealButton } from "./NbmeClickToRevealButton";
import useTestPageTestSelectedTestSlot from "@/routes/tests/$testId/-test-page-hooks/use-test-page-test-selected-test-slot";
import useTestPageTestQuestionChoices from "@/routes/tests/$testId/-test-page-hooks/use-test-page-test-questionchoices";
import ArrayMapUtils from "@/types/common/ArrayConvertableMap";
import useTestPageTestSelectedTestSlotQuestion from "@/routes/tests/$testId/-test-page-hooks/use-test-page-test-selected-test-slot-questiont";
import useTestPageTest from "@/routes/tests/$testId/-test-page-hooks/use-test-page-test";

export const NbmeResultCard = () => {
  const { percentageAnsweredVisible, setPercentageAnsweredVisible } =
    useNbmePercentageAnsweredVisible();
  const breakPoint = useBreakPoint();
  const { fontSizeFactor } = useNbmeFontSizeFactor();
  const { data: slot, isLoading: isSlotLoading } =
    useTestPageTestSelectedTestSlot();
  const { data: testQuestionChoices, isLoading: isQuestionChoicesLoading } =
    useTestPageTestQuestionChoices();
  const { data: question, isLoading: isQuestionLoading } =
    useTestPageTestSelectedTestSlotQuestion();
  const questionChoices = useMemo(
    () =>
      testQuestionChoices && question
        ? testQuestionChoices.filter((qc) => qc.questionId === question.id)
        : [],
    [testQuestionChoices, question],
  );
  const { data: test } = useTestPageTest();
  const mode = test?.mode;

  const correctChoice = questionChoices.find((c) => c.correct);
  const isOmitted = !slot?.chosenId;
  const isCorrect = correctChoice?.id === slot?.chosenId;
  const showResult =
    slot?.submitted &&
    (mode?.includes("tutor") || (!mode?.includes("tutor") && test?.submitted));

  if (
    (isQuestionChoicesLoading && questionChoices.length === 0) ||
    !showResult
  ) {
    return <></>;
  }

  const timeElapsed = DateTime.fromSeconds(slot.timeElapsed);

  const isScreenMedium = breakPoint > BreakPoints.Small;

  return (
    <div
      className={twMerge(
        "px-3 py-5 bg-nbme-primary-50 warm:bg-brown-50/10 dark:bg-nbme-primary-dark-800 border shadow-lg shadow-gray-300 dark:shadow-none rounded-primary md:w-[80%] w-full border-l-4",
        isCorrect && "border-lime-500 dark:border-lime-600",
        !isCorrect && "border-red-500 dark:border-red-600",
        isOmitted && "border-sky-500 dark:border-sky-600",
      )}
    >
      <div className="flex flex-row justify-between items-center w-full">
        <div className="flex flex-col justify-center md:justify-center items-center md:items-start">
          <p
            className={twMerge(
              "font-medium",
              isCorrect && "text-lime-500",
              !isCorrect && "text-red-500",
              isOmitted && "text-sky-500",
            )}
            style={{
              fontSize: scaleUnitInRem(
                isScreenMedium ? FontSizes.Base : FontSizes.Small,
                fontSizeFactor,
              ).resultInRemAsCss,
            }}
          >
            {`${isOmitted ? "Omitted" : isCorrect ? "Correct" : "Incorrect"}`}
          </p>
          {!isCorrect && (
            <>
              <p
                className="font-light hidden md:block text-center md:text-start warm:text-brown-700 dark:text-gray-200"
                style={{
                  fontSize: scaleUnitInRem(FontSizes.ExtraSmall, fontSizeFactor)
                    .resultInRemAsCss,
                }}
              >
                Correct answer
              </p>
              {slot && correctChoice && (
                <p className="font-medium">
                  <span
                    className="inline font-light md:hidden warm:text-brown-950"
                    style={{
                      fontSize: scaleUnitInRem(
                        FontSizes.ExtraSmall,
                        fontSizeFactor,
                      ).resultInRemAsCss,
                    }}
                  >
                    {"Correct is "}
                  </span>
                  {numberToAlphabet(correctChoice.order + 1).toUpperCase()}
                </p>
              )}
            </>
          )}
        </div>
        <div className="flex flex-col md:flex-row justify-center items-center gap-2">
          <FontAwesomeIcon
            icon={faChartColumn}
            className="lg:w-8 lg:h-8 md:w-7 md:h-7 h-6 w-6 text-gray-500 warm:text-brown-950/40 dark:text-gray-50"
          />
          <div className="flex flex-col justify-center items-center md:items-start text-center md:text-start">
            <AnimatePresence mode="wait">
              <motion.p
                {...fadeAnimation}
                key={
                  percentageAnsweredVisible
                    ? "percentage-answered-correctly"
                    : "percentage-answered-correctly-hidden"
                }
                className={twMerge(
                  "text-xs md:text-sm text-center w-full md:text-start md:w-auto",
                )}
                style={{
                  fontSize: scaleUnitInRem(
                    isScreenMedium ? FontSizes.Small : FontSizes.ExtraSmall,
                    fontSizeFactor,
                  ).resultInRemAsCss,
                }}
              >
                {percentageAnsweredVisible ? (
                  `${correctChoice?.uWorldChosenBy}%`
                ) : (
                  <NbmeClickToRevealButton
                    onClick={() => setPercentageAnsweredVisible(true)}
                  />
                )}
              </motion.p>
            </AnimatePresence>
            <p
              className="font-light hidden md:block text-gray-500 warm:text-brown-700 dark:text-gray-200"
              style={{
                fontSize: scaleUnitInRem(FontSizes.ExtraSmall, fontSizeFactor)
                  .resultInRemAsCss,
              }}
            >
              Answered Correctly
            </p>
          </div>
        </div>
        <div className="flex flex-col md:flex-row justify-center items-center gap-2">
          <FontAwesomeIcon
            icon={faClock}
            className="lg:w-8 lg:h-8 md:w-7 md:h-7 h-6 w-6 text-gray-500 warm:text-brown-950/40 dark:text-gray-50"
          />
          <div className="flex flex-col justify-center items-center md:items-start text-center md:text-start">
            <p
              className="text-xs md:text-sm text-center w-full md:text-start md:w-auto"
              style={{
                fontSize: scaleUnitInRem(
                  isScreenMedium ? FontSizes.Small : FontSizes.ExtraSmall,
                  fontSizeFactor,
                ).resultInRemAsCss,
              }}
            >
              {slot.timeElapsed > 60 && (
                <span>{timeElapsed.toFormat("mm 'min'")}</span>
              )}
              <br className="hidden" />
              {timeElapsed.toFormat(" ss 'secs'")}
            </p>
            <p
              className="font-light hidden md:block text-gray-500 warm:text-brown-700 dark:text-gray-200 text-xs"
              style={{
                fontSize: scaleUnitInRem(FontSizes.ExtraSmall, fontSizeFactor)
                  .resultInRemAsCss,
              }}
            >
              Time Spent
            </p>
          </div>
        </div>
        <div className="flex flex-col md:flex-row justify-center items-center gap-2">
          <FontAwesomeIcon
            icon={faCalendar}
            className="lg:w-8 lg:h-8 md:w-7 md:h-7 h-6 w-6 text-gray-500 warm:text-brown-950/40 dark:text-gray-50"
          />
          <div className="flex flex-col justify-center items-center md:items-center text-center md:text-start">
            <p
              style={{
                fontSize: scaleUnitInRem(
                  isScreenMedium ? FontSizes.Small : FontSizes.ExtraSmall,
                  fontSizeFactor,
                ).resultInRemAsCss,
              }}
            >
              {question?.createdAt &&
                DateTime.fromJSDate(new Date(question?.createdAt)).toFormat(
                  "yyyy",
                )}
            </p>
            <p
              className="font-light hidden md:block text-gray-500 warm:text-brown-700 dark:text-gray-200"
              style={{
                fontSize: scaleUnitInRem(FontSizes.ExtraSmall, fontSizeFactor)
                  .resultInRemAsCss,
              }}
            >
              Version
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
