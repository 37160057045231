import SignupCard from "@/components/SignupCard";
import { useTitle } from "@/hooks/common/use-title";
import { useRequiresNoUser } from "@/utils/customHooks";
import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute("/auth/signup/")({
  component: SignupPage,
});

function SignupPage() {
  useTitle("Sign Up");
  useRequiresNoUser();
  return <SignupCard />;
}
