import React, { useEffect } from "react";
import { Input } from "@/components/Input";
import { InputGroup } from "@/components/InputGroup";
import { Label } from "@/components/Label";
import { create } from "zustand";
import { z } from "zod";
import { signinSchema, updateForm } from "@/utils/zod";
import { trpc } from "@/utils/trpc";
import { ButtonWithLoadingSpinner } from "./ButtonWithLoadingSpinner";
import useAuthSlice from "@/hooks/auth-hooks/use-auth-slice";
import { useNavigate } from "@tanstack/react-router";

interface FormState extends z.infer<typeof signinSchema> {
  usernameError: string;
  passwordError: string;
  updateForm: (
    update: string,
    field: keyof this,
    errorField: keyof this,
  ) => void;
  setError: (message: string, errorField: keyof this) => void;
}

const useFormState = create<FormState>()((set) => ({
  username: "",
  password: "",
  usernameError: "",
  passwordError: "",
  updateForm: (update, field, errorField) =>
    set(() => updateForm(update, field, errorField, signinSchema)),
  setError: (message, errorField) =>
    set(() => {
      return { [errorField]: message };
    }),
}));

export const SigninForm = () => {
  const { signIn } = useAuthSlice();

  const { mutate, isPending, isError, error, isSuccess, data } =
    trpc.auth.signin.useMutation();

  const {
    username,
    usernameError,
    password,
    passwordError,
    updateForm,
    setError,
  } = useFormState((state) => state);

  useEffect(() => {
    if (error?.data?.code === "BAD_REQUEST") {
      if (error?.message) {
        const errors = JSON.parse(error?.message) as {
          path: string[];
          message: string;
        }[];
        for (const e of errors) {
          setError(e.message, `${e.path[0]}Error` as keyof FormState);
        }
      }
    } else if (error?.data?.code === "NOT_FOUND") {
      if (error?.message) setError(error.message, "usernameError");
    }
  }, [error, isError, setError]);

  const handleSignin = (signedInUserToken: string) => {
    signIn(signedInUserToken);
    navigate({ to: "/home" });
  };

  useEffect(() => {
    if (data && isSuccess) {
      handleSignin(data);
    }
  }, [isSuccess, data, signIn]);

  const navigate = useNavigate();

  return (
    <form
      className="flex flex-col justify-center items-center gap-9"
      onSubmit={(e) => {
        e.preventDefault();
        mutate({ username, password });
      }}
    >
      <div className="flex flex-col justify-center items-start gap-1 w-full">
        <h1 className="text-xl lg:text-2xl font-bold">Sign in</h1>
        <div className="flex flex-col justify-center items-start">
          <h2 className="text-lg lg:text-xl font-semibold">
            New to Coursology?
          </h2>
          <button
            type="button"
            className="text-primary-500 underline text-sm lg:text-base"
            onClick={() => navigate({ to: "/auth/signup" })}
          >
            Create your account!
          </button>
        </div>
      </div>
      <div className="flex flex-col justify-center items-start w-full gap-4">
        <InputGroup error={usernameError}>
          <Label className="font-medium" htmlFor="username-input">
            Username or Email
          </Label>
          <Input
            disabled={isPending}
            id="username-input"
            value={username}
            placeholder="Username"
            onChange={(e) =>
              updateForm(e.target.value, "username", "usernameError")
            }
          />
        </InputGroup>
        <div className="flex flex-col justify-center items-center w-full gap-1">
          <InputGroup className="" error={passwordError}>
            <Label className="font-medium" htmlFor="password-input">
              Password
            </Label>
            <Input
              disabled={isPending}
              type="password"
              id="password-input"
              value={password}
              placeholder="Password"
              onChange={(e) =>
                updateForm(e.target.value, "password", "passwordError")
              }
            />
          </InputGroup>
        </div>
      </div>
      <ButtonWithLoadingSpinner
        disabled={isPending}
        error={!!usernameError || !!passwordError}
        loading={isPending}
      >
        Sign in
      </ButtonWithLoadingSpinner>
      <div className="flex flex-col justify-center items-center font-medium text-gray-500 text-sm lg:text-base">
        <p>Forgot your password?</p>
        <p className="text-primary-500 underline text-xs lg:text-sm">
          Contact Us
        </p>
      </div>
    </form>
  );
};
