import { useNbmePadding } from "@/utils/stores/nbmeLayoutStore";
import { NbmeSettingsSlider } from "./NbmeSettingsSlider";

export const NbmeSettingsExplanationPaddingControl = () => {
  const [padding, setPadding] = useNbmePadding();

  return (
    <NbmeSettingsSlider
      value={padding.explanation}
      defaultValue={padding.explanation}
      onValueChange={(newValue) =>
        setPadding({ question: padding.question, explanation: newValue })
      }
      max={100}
      min={0}
    />
  );
};
