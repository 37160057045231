import { PropsWithClassName } from "@/types";
import React, { PropsWithChildren } from "react";
import { twMerge } from "tailwind-merge";

interface NbmeSettingsSidebarSegmentProps
  extends PropsWithClassName,
    PropsWithChildren {
  disabled?: boolean;
}

export const NbmeSettingsSidebarSegment = ({
  children,
  className,
  disabled,
}: NbmeSettingsSidebarSegmentProps) => {
  return (
    <div
      className={twMerge(
        "flex flex-row justify-between items-center w-full border-b border-gray-300 dark:border-nbme-primary-dark-300 pb-3 transition-opacity",
        className,
        disabled && "opacity-30",
      )}
    >
      {children}
    </div>
  );
};
