import ArrayMapUtils from "@/types/common/ArrayConvertableMap";
import { TestSlotSliceCreator, testSlotSliceState } from ".";
import { trpcProxyClient } from "@/utils/trpc";
import handleTRPCMutationError from "@/utils/trpc/handle-trpc-mutation-error";
import { calculateSlotTimeElapsedIncrement } from "@/services/test";
import updateTestSlotLastSelectedTimeManipulator from "@/models/testslot-model/testslot-model-manipulators/update-testslot-last-selected-time";
import { TRPCError } from "@trpc/server";
import { TestSlot } from "@/models";

const createTestSlotSlice: TestSlotSliceCreator = (set, get) => {
  return {
    ...testSlotSliceState,
    addTestSlot: (testSlot) =>
      set((state) => {
        ArrayMapUtils.push(state.testSlots, [testSlot]);
      }),
    addTestSlots: (testSlots) =>
      set((state) => {
        ArrayMapUtils.push(state.testSlots, testSlots);
      }),
    chooseTestSlotQuestionChoice: async ({ testSlotId, questionChoiceId }) => {
      const oldState = get();
      const oldTestSlot = ArrayMapUtils.find(oldState.testSlots, testSlotId);
      if (!oldTestSlot || oldTestSlot.submitted) {
        return new Promise<boolean>((resolve) => {
          resolve(false);
        });
      }

      set((state) => {
        ArrayMapUtils.update(state.testSlots, {
          ids: [testSlotId],
          updater: (ts) => ({ ...ts, chosenId: questionChoiceId }),
        });
      });
      const questionChoices = ArrayMapUtils.filter(
        oldState.questionChoices,
        (qc) => qc.questionId === oldTestSlot.questionId,
      );
      const oldChosenChoice = ArrayMapUtils.find(
        questionChoices,
        oldTestSlot.chosenId ?? "",
      );
      const result = trpcProxyClient.testSlot.chooseTestSlotQuestionChoice
        .mutate({
          testSlotId,
          questionChoiceId,
        })
        .catch((err) => {
          const error = err as TRPCError & { data?: TestSlot };
          const isTestSlotSubmitted =
            error?.code === "PRECONDITION_FAILED" &&
            error.data &&
            error.data.submitted;
          if (isTestSlotSubmitted) {
            set((state) => {
              ArrayMapUtils.update(state.testSlots, {
                ids: [testSlotId],
                //We're asserting that it exists because the condition
                //checks so.
                updater: (ts) => error.data!,
              });
            });
            return false;
          }
          return handleTRPCMutationError(
            { mutationDescription: "choose choice" },
            () => {
              const state = get();
              set((state) => {
                ArrayMapUtils.update(state.testSlots, {
                  ids: [testSlotId],
                  updater: (ts) => ({
                    ...ts,
                    chosenId: oldTestSlot.chosenId,
                  }),
                });
              });
              state.switchSelectedTestSlot({
                testId: oldTestSlot.testId,
                slot: oldTestSlot.order,
              });
              return false;
            },
          )(error);
        });
      const newChosenChoice = ArrayMapUtils.find(
        questionChoices,
        questionChoiceId,
      );
      const question = ArrayMapUtils.find(
        oldState.questions,
        (q) => q.id === oldTestSlot.questionId,
      );
      if (oldChosenChoice && newChosenChoice && question) {
        const wasOldChoiceCorrect = oldChosenChoice.correct;
        const isNewChoiceCorrect = newChosenChoice.correct;

        const previousUsageStatus = wasOldChoiceCorrect
          ? "correct"
          : "incorrect";
        const nextUsageStatus = isNewChoiceCorrect ? "correct" : "incorrect";
        const wasQuestionCorrect = previousUsageStatus === "correct";
        const wasQuestionIncorrect = previousUsageStatus === "incorrect";
        const isQuestionNowCorrect = nextUsageStatus === "correct";
        const isQuestionNowIncorrect = nextUsageStatus === "incorrect";
        const isCorrectToIncorrect =
          wasQuestionCorrect && isQuestionNowIncorrect;
        const isIncorrectToCorrect =
          wasQuestionIncorrect && isQuestionNowCorrect;
        const isIncorrectToIncorrect =
          wasQuestionIncorrect && isQuestionNowIncorrect;
        const change = isCorrectToIncorrect
          ? "correctToIncorrect"
          : isIncorrectToIncorrect
            ? "incorrectToIncorrect"
            : "incorrectToCorrect";

        oldState.recordAnswerChange({
          change,
          subjectId: question.subjectId,
          systemId: question.systemId,
          topicId: question.topicId,
          questionBankId: question.questionBankId,
        });
      }
      return result;
    },
    crossTestSlotQuestionChoice: ({ testSlotId, questionChoiceId }) => {
      const oldState = get();
      const oldTestSlot = ArrayMapUtils.find(oldState.testSlots, testSlotId);
      if (!oldTestSlot) return;

      set((state) => {
        ArrayMapUtils.update(state.testSlots, {
          ids: [testSlotId],
          updater: (ts) => {
            const wasCrossed = ts.crossed.includes(questionChoiceId);
            const newCrossed = wasCrossed
              ? ts.crossed.filter((id) => id !== questionChoiceId)
              : [...ts.crossed, questionChoiceId];
            return { ...ts, crossed: newCrossed };
          },
        });
      });

      trpcProxyClient.testSlot.crossTestSlotQuestionChoice
        .mutate({
          testSlotId,
          questionChoiceId,
        })
        .catch(
          handleTRPCMutationError(
            { mutationDescription: "cross choice" },
            () => {
              set((state) => {
                ArrayMapUtils.update(state.testSlots, {
                  ids: [testSlotId],
                  updater: (ts) => {
                    const isCrossed = ts.crossed.includes(questionChoiceId);
                    const newCrossed = isCrossed
                      ? ts.crossed.filter((id) => id !== questionChoiceId)
                      : [...ts.crossed, questionChoiceId];
                    return { ...ts, crossed: newCrossed };
                  },
                });
              });
            },
          ),
        );
    },
    selectTestSlot: ({ testSlotId }) => {
      set((state) => {
        const newlySelectedSlot = ArrayMapUtils.find(
          state.testSlots,
          testSlotId,
        );
        if (!newlySelectedSlot) return;
        ArrayMapUtils.update(state.testSlots, {
          ids: [testSlotId],
          updater: (s) => ({
            ...s,
            ...updateTestSlotLastSelectedTimeManipulator(),
          }),
        });
      });
    },
    submitTestSlot: async ({ testSlotId }, opts) => {
      const oldState = get();
      const oldTestSlot = ArrayMapUtils.find(oldState.testSlots, testSlotId);
      if (!oldTestSlot || oldTestSlot.submitted) return;
      const oldQuestionState = ArrayMapUtils.find(
        oldState.questions,
        oldTestSlot.questionId,
      );
      if (!oldQuestionState) return;
      oldState.evaluateTestSlot({ testSlotId });

      set((state) => {
        ArrayMapUtils.update(state.testSlots, {
          ids: [testSlotId],
          updater: (testSlot) => {
            return {
              ...testSlot,
              submitted: true,
              submittedAt: new Date(),
              timeElapsed:
                testSlot.timeElapsed +
                calculateSlotTimeElapsedIncrement(testSlot),
            };
          },
        });
      });

      if (!opts?.local) {
        await trpcProxyClient.testSlot.submitTestSlot
          .mutate({
            testSlotId,
            chosenChoiceId: oldTestSlot.chosenId,
          })
          .catch(
            handleTRPCMutationError(
              { mutationDescription: "submit question" },
              () => {
                const oldState = get();
                set((state) => {
                  ArrayMapUtils.update(state.testSlots, {
                    ids: [testSlotId],
                    updater: () => {
                      return oldTestSlot;
                    },
                  });
                });
                oldState.switchSelectedTestSlot({
                  testId: oldTestSlot.testId,
                  slot: oldTestSlot.order,
                });
                oldState.setQuestionUsageStatus({
                  questionId: oldQuestionState.id,
                  status: oldQuestionState.used,
                });
              },
            ),
          );
      }
      if (!opts?.preventAutomaticTestSubmission) {
        const state = get();
        const test = ArrayMapUtils.find(state.tests, oldTestSlot.testId);
        const testSlots = ArrayMapUtils.filter(
          state.testSlots,
          (s) => s.testId === test?.id && s.id !== testSlotId,
        );
        let allTestSlotsAreSubmitted = true;
        ArrayMapUtils.forEach(testSlots, (ts) => {
          if (!ts.submitted) allTestSlotsAreSubmitted = false;
        });
        if (allTestSlotsAreSubmitted && !!test) {
          state.submitTest({ testId: test.id }, { local: true });
        }
      }
    },
    evaluateTestSlot: ({ testSlotId, testSlot }) => {
      const oldState = get();
      const questionChoices = oldState.questionChoices;
      const oldTestSlot = testSlotId
        ? ArrayMapUtils.find(oldState.testSlots, testSlotId)
        : testSlot;
      if (!oldTestSlot || oldTestSlot.submitted) return;
      const oldQuestionState = ArrayMapUtils.find(
        oldState.questions,
        oldTestSlot.questionId,
      );
      if (!oldQuestionState) return;
      const isOmitted = !oldTestSlot.chosenId;
      const isCorrect =
        !isOmitted &&
        ArrayMapUtils.find(questionChoices, oldTestSlot.chosenId!)?.correct;
      const isIncorrect =
        !isOmitted &&
        ArrayMapUtils.find(questionChoices, oldTestSlot.chosenId!)?.correct ===
          false;
      const status = isOmitted
        ? "omitted"
        : isCorrect
          ? "correct"
          : isIncorrect
            ? "incorrect"
            : false;

      oldState.setQuestionUsageStatus({
        questionId: oldTestSlot.questionId,
        status,
      });
    },
  };
};

export default createTestSlotSlice;
