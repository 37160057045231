import { PropsWithChildren } from "react";
import DraggableContextProvider from "./draggable-context-provider";
import useDraggableState, { DraggableState } from "./hooks/use-draggable-state";
import { ArgumentTypes } from "@/types";
import { PropsWithClassName } from "@/types";
import { cn } from "@/lib/utils";

type DraggableRootProps = PropsWithChildren &
  PropsWithClassName &
  ArgumentTypes<typeof useDraggableState>[0] & { state?: DraggableState };

const DraggableRoot = ({
  children,
  className,
  state: externalState,
  ...defaults
}: DraggableRootProps) => {
  const internalState = useDraggableState(defaults);
  const state = externalState ?? internalState;
  return (
    <DraggableContextProvider value={state}>
      <div
        className={cn("group/draggable", className)}
        data-is-draggable={state.isDraggable}
        data-is-dragging={state.isDragging}
      >
        {children}
      </div>
    </DraggableContextProvider>
  );
};

export default DraggableRoot;
