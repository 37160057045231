import React from "react";
import { Label } from "./Label";
import Select, { SelectProps } from "./Select";
import { twMerge } from "tailwind-merge";

interface LabeledSelectProps extends SelectProps {
  label: string;
  id: string;
  labelClassName?: string;
  selectClassName?: string;
}

export const LabeledSelect = ({
  label,
  className,
  selectClassName,
  children,
  id,
  labelClassName,
  ...props
}: LabeledSelectProps) => {
  return (
    <div
      className={twMerge(
        "flex flex-row justify-start items-center gap-2",
        className,
      )}
    >
      <Label
        htmlFor={id}
        className={twMerge(
          "text-gray-500 warm:text-brown-500 dark:text-neutral-100",
          labelClassName,
        )}
      >
        {label}
      </Label>
      <Select className={selectClassName} {...props} id={id}>
        {children}
      </Select>
    </div>
  );
};
