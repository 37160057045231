import { Button } from "@/components/Button";
import { Input } from "@/components/Input";
import { useState } from "react";
import { useChangeExtraMonths } from "./hooks";

interface ExtraMonthsControllerProps {
  userId: string;
  userExtraMonths: number;
}
export const ExtraMonthsController = ({
  userExtraMonths,
  userId,
}: ExtraMonthsControllerProps) => {
  const [draft, setDraft] = useState(userExtraMonths?.toString() ?? "");
  const changeExtraMonths = useChangeExtraMonths();

  return (
    <div className="flex flex-col justify-start items-center gap-2">
      <Input
        type="number"
        value={draft ?? ""}
        onChange={(e) => setDraft(e.target.value)}
      />
      {Number(draft) !== userExtraMonths && (
        <Button
          className="w-fit md:text-sm lg:text-sm text-sm"
          onClick={() =>
            changeExtraMonths({ userId, newExtraMonths: Number(draft) })
          }
        >
          Save
        </Button>
      )}
    </div>
  );
};
