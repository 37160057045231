import { BreakPoints, FontSizes } from "@/types";
import { scaleUnitInRem } from "@/utils/common";
import { FilterInterface } from "interweave";

export const choiceFilter = (
  breakPoint: BreakPoints,
  fontSizeFactor: number,
  crossed: boolean,
): FilterInterface => ({
  node(name, node) {
    node.setAttribute("class", crossed ? "line-through" : "");
    if (name === "table") {
      node.setAttribute("style", "");
      node.className = "w-[300px] md:w-[450px] lg:w-[600px]";
    }

    const isScreenMedium = breakPoint > BreakPoints.Small;
    const isScreenLarge = breakPoint > BreakPoints.Medium;
    const baseFontSize = isScreenLarge
      ? FontSizes.Base
      : isScreenMedium
        ? FontSizes.Small
        : FontSizes.ExtraSmall;

    const fontSize = scaleUnitInRem(baseFontSize, fontSizeFactor);

    node.style.fontSize = fontSize.resultInRemAsCss;
    return node;
  },
});
