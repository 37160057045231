import { PropsWithClassName } from "@/types";
import React, { ButtonHTMLAttributes, DetailedHTMLProps } from "react";
import { twMerge } from "tailwind-merge";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Setter } from "@/types";

interface DashboardTableRowCollapseButtonProps
  extends PropsWithClassName,
    Pick<
      DetailedHTMLProps<
        ButtonHTMLAttributes<HTMLButtonElement>,
        HTMLButtonElement
      >,
      "onClick"
    > {
  isCollapsed: boolean;
  onIsCollapsedChange: Setter<boolean>;
}

export const DashboardTableRowCollapseButton = ({
  className,
  isCollapsed,
  onIsCollapsedChange,
  onClick,
  ...props
}: DashboardTableRowCollapseButtonProps) => {
  return (
    <th className={twMerge(className, "w-1 pl-6")}>
      <button
        className="text-gray-500 dark:text-neutral-300"
        onClick={(e) => {
          onIsCollapsedChange(!isCollapsed);
          onClick?.(e);
        }}
        {...props}
      >
        <FontAwesomeIcon icon={isCollapsed ? faMinus : faPlus} />
      </button>
    </th>
  );
};
