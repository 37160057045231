import { useCreateTestPageStore } from "@/utils/stores/createTestPageStore";
import DashboardAccordionSection from "./DashboardAccordionSection";
import { InputGroup } from "./InputGroup";
import { Label } from "./Label";
import { useEffect, useState } from "react";
import { twMerge } from "tailwind-merge";
import { trpc } from "@/utils/trpc";
import { LoadingSpinner } from "./LoadingSpinner";

const CreateTestCustomSection = () => {
  const [localQuestionIdsError, setLocalQuestionIdsError] =
    useState<string>("");
  const [testIdDraft, setTestIdDraft] = useState<string>("");
  const [testId, setTestId] = useState<number | undefined>();
  const [testIdError, setTestIdError] = useState<string>("");
  const [questionIdsDraft, setQuestionIdsDraft] = useState<string>("");
  const {
    isPending,
    isSuccess: isRetrievedIdsSuccess,
    isError: isRetrievedIdsError,
    data: retrievedIds,
    error: retrievedIdsError,
    mutate,
  } = trpc.test.getTestQuestionIds.useMutation();

  const {
    toggledSections,
    toggleSection,
    toggleField,
    questionIds,
    setQuestionIds,
  } = useCreateTestPageStore((state) => ({
    toggleField: state.toggleField,
    toggleSection: state.toggleSection,
    toggledSections: state.toggledSections,
    questionIds: state.questionIds,
    setQuestionIds: state.setQuestionIds,
  }));

  useEffect(() => {
    if (!testIdDraft) {
      setTestIdError("");
      setTestId(undefined);
      return;
    }

    if (!/^[0-9]+$/.test(testIdDraft.trim())) {
      setTestIdError("Test ID has to be number.");
      setTestId(undefined);
      return;
    }
    setTestIdError("");
    setTestId(Number(testIdDraft));
  }, [testIdDraft]);

  useEffect(() => {
    if (retrievedIds && isRetrievedIdsSuccess) {
      setQuestionIds(retrievedIds);
    }
  }, [retrievedIds, isRetrievedIdsSuccess]);

  useEffect(() => {
    if (!retrievedIdsError) return;
    if (!retrievedIdsError.message) {
      setTestIdError("An Error occured, could not retrieve test questions.");
      return;
    }
    setTestIdError(retrievedIdsError?.message);
  }, [retrievedIdsError, isRetrievedIdsError]);

  useEffect(() => {
    if (questionIds.length === 0) return;
    setQuestionIdsDraft(questionIds.join(","));
  }, [questionIds]);

  useEffect(() => {
    if (!questionIdsDraft) {
      setLocalQuestionIdsError("");
      setQuestionIds([]);
      return;
    }
    if (
      !/^[0-9]+(,[0-9]+)*$/.test(
        questionIdsDraft.trim().replaceAll(/[\n ]?/g, ""),
      )
    ) {
      setLocalQuestionIdsError("Please Enter Valid Question Ids");
      setQuestionIds([]);
      return;
    }

    const questionIds = questionIdsDraft
      .split(",")
      .filter((id) => !!id)
      .map((qId) => Number(qId.trim()));

    if (questionIds.length > 40) {
      setLocalQuestionIdsError(
        "You can't have more than 40 questions per block",
      );
      return;
    }
    setLocalQuestionIdsError("");
    setQuestionIds(questionIds);
  }, [questionIdsDraft]);

  return (
    <DashboardAccordionSection
      title="Instructions on using Custom mode"
      value="qmode"
      className=""
      onToggle={() => toggleSection("qmode")}
      toggled={toggledSections.includes("qmode")}
    >
      <div className="flex lg:flex-row flex-col lg:justify-start justify-center items-center gap-5 mt-3">
        <div className="flex flex-col justify-start items-start gap-2 text-gray-700 dark:text-neutral-100 text-sm max-w-4xl">
          <p>
            This mode is intended to allow for faculty/group review; the
            faculty/group leader can designate the question IDs to allow the
            entire group to create the same test within each subscription.
          </p>

          <p>
            If you are a group member (not the faculty/group leader), you will
            need to enter the list of question IDs provided by your group
            leader. Separate each question ID with a comma (,) without spaces or
            other characters. Only unused questions can be utilized to create a
            custom test.
          </p>

          <p>
            If you were not provided a list of question IDs but were given a
            test ID instead, enter the test ID and click “Retrieve Questions” to
            automatically generate the list of question IDs. Only unused
            questions can be utilized to create a custom test.
          </p>
          <p>
            You can enter only numbers or commas (,) in the space provided.
            Please do not enter spaces or other characters as this will result
            in an error prompt.
          </p>

          <p>
            The maximum number of questions you can select is the same as the
            maximum number allowed per block/test.
          </p>

          <p>
            You will not be able to include the question if it has been
            deactivated or if its ID is invalid.
          </p>

          <p>
            Some questions are part of a question set and must be included
            within the same test. In such cases, you must provide the question
            IDs for each question in the set. If you omit one or more question
            IDs in the set, the software will not generate the test. Instead, it
            will provide a list of question IDs that must be entered to complete
            the question set.
          </p>
        </div>
        <div className="flex flex-col justify-start items-center gap-3">
          <InputGroup error={testIdError}>
            <Label
              htmlFor="parent-test-id-input"
              className="text-gray-600 dark:text-neutral-200"
            >
              <p className="text-sm">Retrieve Questions of a Test #</p>
            </Label>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                mutate({ testId: testId! });
              }}
              className="flex flex-row justify-start items-center"
            >
              <input
                id="parent-test-id-input"
                className={twMerge(
                  "border border-gray-300 dark:border-neutral-800 focus:border-primary-500 outline-none py-2 px-3 text-sm rounded-l-primary transition-colors dark:bg-neutral-950  dark:border-r-transparent",
                  testIdError &&
                    "text-red-500 focus:border-red-500 border-red-300",
                )}
                onChange={(e) => setTestIdDraft(e.target.value)}
                value={testIdDraft}
              ></input>
              <div className="flex flex-row justify-start items-center">
                <button
                  type="submit"
                  disabled={isPending || !testId}
                  className={twMerge(
                    "px-4 py-2 font-bold text-white bg-primary-500 dark:bg-neutral-800 border border-primary-500 dark:border-neutral-700 dark:disabled:bg-neutral-500 dark:disabled:border-neutral-600 text-sm rounded-r-primary transition-colors",
                    testIdError &&
                      "bg-red-500 border-red-500 transition-colors",
                  )}
                >
                  Retrieve
                </button>
                <div
                  className={twMerge(
                    "w-12 h-12 invisible opacity-0 transition-opacity",
                    isPending && "visible opacity-100 ",
                  )}
                >
                  <LoadingSpinner />
                </div>
              </div>
            </form>
          </InputGroup>
          <p>OR</p>
          <InputGroup error={localQuestionIdsError}>
            <Label
              htmlFor="question-ids-input"
              className="text-gray-600 dark:text-neutral-200"
            >
              <p className="text-sm">
                Enter Question Ids separated by comma (,)
              </p>
              <p className="text-sm">
                And make sure the text does not end with a comma,
              </p>
              <p className="text-sm">Invalid IDs will simply be ignored..</p>
            </Label>
            <textarea
              className={twMerge(
                "border border-gray-300 dark:border-neutral-900 focus:border-primary-500 data-[error=true] outline-none py-2 px-3 text-sm w-full min-h-[200px] rounded-primary transition-colors dark:bg-neutral-950",
                localQuestionIdsError &&
                  "text-red-500 focus:border-red-500 border-red-300",
              )}
              value={questionIdsDraft}
              onChange={(e) =>
                setQuestionIdsDraft(
                  e.target.value
                    .replace(/[^0-9,]+/, "")
                    .split(",")
                    .map((id) => id.trim())
                    .join(","),
                )
              }
            ></textarea>
          </InputGroup>
        </div>
      </div>
    </DashboardAccordionSection>
  );
};

export default CreateTestCustomSection;
