import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useOnClickOutside } from "@/utils/hooks/useOnClickOutside";
import React, { PropsWithChildren, useRef, useState } from "react";
import { twMerge } from "tailwind-merge";

export interface SelectProps extends PropsWithChildren {
  className?: string;
  id?: string;
  buttonClassName?: string;
  containerClassName?: string;
  caretClassName?: string;
  valueClassName?: string;
  value: string;
  hideItemsOnClick?: boolean;
}

const Select = ({
  className,
  value,
  children,
  buttonClassName,
  containerClassName,
  hideItemsOnClick,
  caretClassName,
  valueClassName,
  id,
}: SelectProps) => {
  const [itemsVisible, setItemsVisible] = useState<boolean>(false);
  const selectRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(selectRef, () => setItemsVisible(false));
  return (
    <div ref={selectRef} className={twMerge("relative", className)}>
      <button
        id={id}
        className={twMerge(
          "border-b warm:border-brown-200 dark:border-neutral-700 flex flex-row justify-between items-center px-2 py-3 gap-3  transition-[border-color] text-start",
          itemsVisible &&
            "border-primary-500 warm:border-brown-400 dark:border-neutral-500",
          buttonClassName,
        )}
        onClick={() => setItemsVisible((prev) => !prev)}
      >
        <p
          className={twMerge(
            "text-xs md:text-sm lg:text-base text-gray-500 warm:text-brown-800 dark:text-neutral-100 transition-colors",
            itemsVisible && "text-primary-500 warm:text-brown-600",
            valueClassName,
          )}
        >
          {value}
        </p>
        <FontAwesomeIcon
          icon={faCaretDown}
          className={twMerge(
            "text-gray-500 warm:text-brown-800 dark:text-neutral-400 transition-colors",
            itemsVisible && "text-primary-500 warm:text-brown-600",
            caretClassName,
          )}
        />
      </button>
      {itemsVisible && (
        <div
          onClick={() => hideItemsOnClick && setItemsVisible(false)}
          className={twMerge(
            "absolute top-[1] translate-y-2 flex flex-col justify-start items-start bg-gray-50 dark:bg-neutral-800 rounded-primary shadow-lg w-full border border-gray-300 dark:border-neutral-700 overflow-clip z-[100]",
            containerClassName,
          )}
        >
          {children}
        </div>
      )}
    </div>
  );
};

export default Select;
