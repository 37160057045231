import Action from "../types/Action";
import Actions from "../types/Actions";

export const setTheme: Action =
  ([set, get]): Actions["setTheme"] =>
  (newValue) => {
    set((state) => {
      state.theme = newValue;
    });
  };
