import { RichTextEditor } from "@mantine/tiptap";
import { BubbleMenu as TipTapBubbleMenu } from "@tiptap/react";
import { useEditor } from "@tiptap/react";

export const BubbleMenu = ({
  editor,
}: {
  editor: ReturnType<typeof useEditor>;
}) => {
  return (
    <>
      {editor && (
        <TipTapBubbleMenu editor={editor}>
          <RichTextEditor.ControlsGroup>
            <RichTextEditor.Bold />
            <RichTextEditor.Italic />
            <RichTextEditor.Underline />
            <RichTextEditor.Strikethrough />
            <RichTextEditor.ClearFormatting />
            <RichTextEditor.Highlight />
          </RichTextEditor.ControlsGroup>
        </TipTapBubbleMenu>
      )}
    </>
  );
};
