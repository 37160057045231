import * as RadixScrollArea from "@radix-ui/react-scroll-area";
import React, { CSSProperties, PropsWithChildren } from "react";
import { twMerge } from "tailwind-merge";

export interface ScrollbarProps extends PropsWithChildren {
  className?: string;
  rootStyle?: CSSProperties;
  viewportClassName?: string;
  viewportStyle?: CSSProperties;
  thumbClassName?: string;
  scrollbarClassName?: string;
  cornerClassName?: string;
  orientation?: "horizontal" | "vertical";
  type?: "scroll" | "auto" | "always" | "hover";
}

export const ScrollArea = React.forwardRef<HTMLDivElement, ScrollbarProps>(({
  rootStyle,
  children,
  className: rootClassName,
  viewportClassName,
  viewportStyle,
  thumbClassName,
  scrollbarClassName,
  cornerClassName,
  orientation = "vertical",
  type = "auto",
}, ref) => {
  return (
    <RadixScrollArea.Root
      style={rootStyle}
      type={type}
      className={twMerge("w-full h-[50rem] overflow-hidden", rootClassName)}
    >
      <RadixScrollArea.Viewport
        style={viewportStyle}
        className={twMerge("w-full h-full rounded", viewportClassName)}
        ref={ref}
      >
        {children}
      </RadixScrollArea.Viewport>
      <RadixScrollArea.Scrollbar
        className={twMerge(
          "flex select-none touch-none p-0.5 bg-transparent rounded-full transition-colors duration-[160ms] ease-out data-[orientation=vertical]:w-2 data-[orientation=horizontal]:flex-col data-[orientation=horizontal]:h-2 bg-gray-500",
          scrollbarClassName,
        )}
        orientation={orientation}
      >
        <RadixScrollArea.Thumb
          className={twMerge(
            "flex-1 rounded-primary bg-red-500 relative before:content-[''] before:absolute before:top-1/2 before:left-1/2 before:-translate-x-1/2 before:-translate-y-1/2 before:w-full before:h-full before:min-w-[44px] before:min-h-[44px]",
            thumbClassName,
          )}
        />
      </RadixScrollArea.Scrollbar>
      <RadixScrollArea.Scrollbar
        className="flex select-none touch-none bg-transparent p-0.5 transition-colors duration-[160ms] ease-out hover:bg-gray-300 data-[orientation=vertical]:w-1 data-[orientation=horizontal]:flex-col data-[orientation=horizontal]:h-3"
        orientation={orientation === "vertical" ? "horizontal" : "vertical"}
      >
        <RadixScrollArea.Thumb
          className={twMerge(
            "flex-1 bg-red-500 rounded-[10px] relative before:content-[''] before:absolute before:top-1/2 before:left-1/2 before:-translate-x-1/2 before:-translate-y-1/2 before:w-full before:h-full before:min-w-[44px] before:min-h-[44px]",
            thumbClassName,
          )}
        />
      </RadixScrollArea.Scrollbar>
      <RadixScrollArea.Corner
        className={twMerge("bg-transparent", cornerClassName)}
      />
    </RadixScrollArea.Root>
  );
});
