import { ComponentProps } from "react";
import ResizableCorner from "../resizable-corner";
import { PropsWithClassName } from "@/types";
import { ResizableContext } from "../resizable-context";

type ResizableCornerInternalProps = ComponentProps<typeof ResizableCorner>;

type Events = Partial<Pick<ResizableContext, "events">>;
type ResizableCornerUserProps = PropsWithClassName & Events;

const createResizableCorner =
  (internalProps: Omit<ResizableCornerInternalProps, "className">) =>
  (userProps: ResizableCornerUserProps) => {
    return <ResizableCorner {...internalProps} {...userProps} />;
  };

export default createResizableCorner;
