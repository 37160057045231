import { DateTime } from "luxon";
import { Test } from "../test-model-types";
import { calculateMinutesForQuestions } from "@/services/test";

const createTestManipulator = ({
  test,
  user,
  testSlotCount,
}: {
  test: Pick<Test, "questionBankId" | "parentId" | "blueprintId"> & {
    mode: Array<string>;
    pool: Array<string>;
    minutesPerQuestion?: number;
    id?: number;
    name?: string;
  };
  user: { id: string };
  testSlotCount: number;
}): Pick<
  Test,
  | "questionBankId"
  | "mode"
  | "pool"
  | "minutesPerQuestion"
  | "userId"
  | "blueprintId"
  | "submitted"
  | "parentId"
  | "startedAt"
  | "endsAt"
  | "name"
  | "id"
> => {
  const newId = test.id ?? Number(DateTime.now().toSeconds().toFixed(0));
  return {
    id: newId,
    questionBankId: test.questionBankId,
    mode: test.mode.join(","),
    pool: test.pool.join(","),
    minutesPerQuestion: test.minutesPerQuestion ?? 1.5,
    userId: user.id,
    name: test.name ?? String(newId),
    blueprintId: test.blueprintId,
    parentId: test.parentId,
    submitted: false,
    startedAt: new Date(),
    endsAt: test.mode.includes("timed")
      ? DateTime.fromJSDate(new Date())
          .plus({
            minutes: calculateMinutesForQuestions(
              testSlotCount,
              test.minutesPerQuestion,
            ),
          })
          .toJSDate()
      : null,
  };
};
export default createTestManipulator;
