import { PropsWithChildren } from "react";
import { SelectItem, SelectItemProps } from "./SelectItem";
import { PropsWithClassName } from "@/types";
import { twMerge } from "tailwind-merge";

interface ClickableSelectItemProps
  extends PropsWithChildren,
    PropsWithClassName,
    Pick<SelectItemProps, "onClick"> {}

export const ClickableSelectItem = ({
  children,
  className,
  onClick,
}: ClickableSelectItemProps) => {
  return (
    <SelectItem
      className={twMerge("cursor-pointer", className)}
      tabIndex={0}
      onClick={onClick}
    >
      {children}
    </SelectItem>
  );
};
