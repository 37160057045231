import { Point } from "@/types";
import { useEffect, useState } from "react";

export const useMousePosition = (events?: {
  onChanged?: (mousePosition: Point) => void;
}) => {
  const [mousePos, setMousePos] = useState<Point>({
    x: 0,
    y: 0,
  });

  useEffect(() => {
    const handleMouseMove = (event: MouseEvent) => {
      const mousePosition = { x: event.clientX, y: event.clientY };
      setMousePos(mousePosition);
      events?.onChanged?.(mousePosition);
    };

    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);
  return mousePos;
};
