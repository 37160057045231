import { cn } from "@/lib/utils";
import React, {
  ButtonHTMLAttributes,
  DetailedHTMLProps,
  PropsWithChildren,
} from "react";

interface ButtonProps
  extends PropsWithChildren,
    DetailedHTMLProps<
      ButtonHTMLAttributes<HTMLButtonElement>,
      HTMLButtonElement
    > {
  error?: boolean;
  NBME?: boolean;
  variant?: "success" | "dashboard";
}

export const Button = ({
  variant,
  children,
  disabled,
  className,
  type = "button",
  onClick,
  error,
  NBME,
  ...props
}: ButtonProps) => {
  return (
    <button
      {...props}
      type={type}
      onClick={onClick}
      disabled={disabled}
      className={cn(
        "bg-primary-500 warm:bg-brown-800 font-bold text-white px-3 py-2 rounded-primary text-base lg:text-lg w-full hover:bg-primary-600 warm:hover:bg-brown-900 outline-primary-800 transition-colors dark:bg-neutral-700 dark:hover:bg-neutral-600 dark:text-neutral-100 dark:hover:text-white",
        NBME &&
          "bg-nbme-secondary-700 font-normal rounded-none transition-none lg:rounded-none lg:text-base py-1 px-6 hover:bg-nbme-secondary-800 warm:hover:bg-brown-900 warm:bg-brown-800 transition-colors disabled:opacity-50",
        disabled &&
          "bg-primary-400 text-gray-50 hover:bg-primary-400 hover:text-gray-50 focus:bg-primary-400 focus:text-gray-50",
        disabled &&
          variant === "dashboard" &&
          "bg-transparent opacity-30 warm:hover:bg-transparent warm:bg-transparent dark:bg-transparent warm:hover:transparent hover:bg-transparent outline-transparent text-primary-300 border-0 focus:bg-transparent hover:text-primary-300",
        error &&
          "bg-red-500 warm:bg-red-500 warm:hover:bg-red-600 dark:bg-red-600 dark:hover:bg-red-700 hover:bg-red-600 outline-red-900",
        variant === "success" &&
          "bg-green-500 warm:bg-green-500 warm:hover:bg-green-500 dark:bg-green-600 dark:hover:bg-green-700 hover:bg-green-600 outline-green-900",
        variant === "dashboard" &&
          "bg-transparent warm:bg-transparent warm:hover:bg-brown-950/10 dark:bg-transparent hover:bg-gray-100 outline-transparent text-primary-500 warm:text-brown-950/80 border-0 focus:bg-gray-100",
        className,
      )}
    >
      {children}
    </button>
  );
};
