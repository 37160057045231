import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { PropsWithChildren } from "react";
import { twMerge } from "tailwind-merge";

interface TagProps extends PropsWithChildren {
  hideRemoveButton?: boolean;
  onRemove?: React.MouseEventHandler<HTMLButtonElement>;
  className?: string;
}

export const Tag = ({
  children,
  onRemove,
  hideRemoveButton,
  className,
}: TagProps) => {
  return (
    <div
      className={twMerge(
        "py-1 px-3 text-gray-600 dark:text-neutral-200 border border-transparent dark:border-neutral-700 bg-gray-200 rounded-full dark:bg-neutral-800 transition-colors dark:hover:bg-neutral-700 dark:focus:bg-neutral-700 flex justify-between items-center gap-10 font-light",
        hideRemoveButton && "justify-center",
        className,
      )}
    >
      {children}
      {!hideRemoveButton && (
        <button
          type="button"
          onClick={onRemove}
          className="dark:text-neutral-400 transition-colors dark:hover:text-neutral-200 dark:focus:text-neutral-200"
        >
          <FontAwesomeIcon icon={faXmark} />
        </button>
      )}
    </div>
  );
};
