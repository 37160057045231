import { inferProcedureOutput } from "@trpc/server";
import { AppRouter, ArrayElement, TableRow } from "@/types";
import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";

interface SearchQuestionsPageState {
  query: string;
  questions: inferProcedureOutput<AppRouter["question"]["findQuestions"]>;
  row: TableRow<ArrayElement<this["questions"]>>;
  isQuestionsLoading: boolean;
  currentPage: number;
  questionsPerPage: 5 | 10 | 15 | 25 | 50 | 100;
}

interface SearchQuestionsPageActions {
  loadQuestions: (questions: SearchQuestionsPageState["questions"]) => void;
  setIsQuestionsLoading: (
    isQuestionsLoading: SearchQuestionsPageState["isQuestionsLoading"],
  ) => void;

  setQuery: (query: SearchQuestionsPageState["query"]) => void;
  setCurrentPage: (
    currentPage: SearchQuestionsPageState["currentPage"],
  ) => void;
  setQuestionsPerPage: (
    questionsPerPage: SearchQuestionsPageState["questionsPerPage"],
  ) => void;
}

export const useSearchQuestionsPageStore = create(
  persist(
    immer<SearchQuestionsPageState & SearchQuestionsPageActions>((set) => {
      const setValue = (
        key: keyof SearchQuestionsPageState,
        value: SearchQuestionsPageState[typeof key],
      ) => {
        set((state) => {
          (state[key] as typeof value) = value;
        });
      };

      return {
        row: {
          collapsible: false,
          columns: [
            {
              name: "ID",
              visible: true,
              content: (question) => question.uWolrdId,
            },
            {
              name: "Subject",
              visible: true,
              content: (question) => question.subject.name,
            },
            {
              name: "System",
              visible: true,
              content: (question) => question.system.name,
            },
            {
              name: "Topic",
              visible: true,
              content: (question) => question.topic.name,
            },
            {
              name: "% Correct",
              visible: true,
              content: (question) => question.choices[0]?.uWorldChosenBy + "%",
            },
            {
              name: "Action",
              visible: true,
              content: (question) => ({
                questionId: question.uWolrdId,
                qBankEndpoint: question.questionBank.endpoint,
              }),
            },
          ],
        },
        questions: [],
        isQuestionsLoading: false,
        loadQuestions: (questions) =>
          set((state) => {
            state.questions = questions;
            state.isQuestionsLoading = false;
          }),

        setIsQuestionsLoading: (isQuestionsLoading) =>
          setValue("isQuestionsLoading", isQuestionsLoading),

        query: "",
        setQuery: (query) => setValue("query", query),

        currentPage: 1,
        setCurrentPage: (currentPage) => setValue("currentPage", currentPage),

        questionsPerPage: 10,
        setQuestionsPerPage: (questionsPerPage) =>
          setValue("questionsPerPage", questionsPerPage),
      };
    }),
    {
      name: "search-questions-page",
      storage: createJSONStorage(() => localStorage),
      partialize: (state) => ({
        query: state.query,
        questionsPerPage: state.questionsPerPage,
      }),
    },
  ),
);

export const useSearchQuestionsPageQuery = () =>
  useSearchQuestionsPageStore((state) => ({
    query: state.query,
    setQuery: state.setQuery,
  }));
