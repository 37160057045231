import {
  Question,
  QuestionHeader,
  QuestionUsageStatus,
} from "@/models/question-model";
import { StateCreator } from "zustand";
import { SliceModifiers } from "@/store/types";
import createQuestionSlice from "./create-question-slice";
import { ArrayMap } from "@/types/common/ArrayConvertableMap";

export type QuestionSliceActions = {
  addQuestion: (question: Question) => void;
  resetQuestionHeaders: (questionHeaderIds: number[]) => void;
  addQuestionHeader: (questionHeader: QuestionHeader) => void;
  addQuestionHeaders: (questionHeader: QuestionHeader[]) => void;
  addQuestions: (questions: Question[]) => void;
  markQuestion: (args: { questionId: Question["id"]; marked: boolean }) => void;
  setQuestionUsageStatus: (
    args: {
      status: QuestionUsageStatus["used"];
    } & (
      | {
          questionId: Question["id"];
          questionIds?: undefined;
        }
      | {
          questionId?: undefined;
          questionIds: Question["id"][];
        }
    ),
  ) => void;
  setQuestionAnswerChange: (args: {
    questionId: Question["id"];
    previousUsageStatus: QuestionUsageStatus["used"];
    nextUsageStatus: QuestionUsageStatus["used"];
  }) => void;
};
export type QuestionSliceState = typeof questionSliceState;

export type QuestionSlice = QuestionSliceState & QuestionSliceActions;

export type QuestionSliceCreator = StateCreator<
  QuestionSlice,
  [["zustand/immer", never]],
  [],
  QuestionSlice
>;

export type QuestionSliceModifiers = SliceModifiers<QuestionSliceCreator>;

export type QuestionSliceAction = (
  ...modifiers: QuestionSliceModifiers
) => void;

export const questionSliceState = {
  questions: {} as ArrayMap<Question>,
  questionHeaders: {} as ArrayMap<QuestionHeader>,
};

export default createQuestionSlice;
