import { faPlayCircle } from "@fortawesome/free-regular-svg-icons";
import { faChartLine, faListCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PropsWithClassName } from "@/types";
import { twMerge } from "tailwind-merge";
import { Buttons } from "./components";
import { Link } from "@tanstack/react-router";

interface PreviousTestsActionRowProps extends PropsWithClassName {
  questionIds: number[];
  qBankEndpoint: string;
  testId: number;
  testName: string;
  isTestSubmitted: boolean;
}

export const PreviousTestsActionRow = ({
  testId,
  className,
  qBankEndpoint,
  isTestSubmitted,
  testName,
  questionIds,
}: PreviousTestsActionRowProps) => {
  return (
    <div
      className={twMerge(
        "flex flex-row justify-start items-center text-primary-500 warm:text-brown-800 dark:text-neutral-300 gap-4",
        className,
      )}
    >
      <Link to={`/tests/$testId`} params={{ testId: String(testId) }}>
        <FontAwesomeIcon icon={faPlayCircle} />
      </Link>
      {isTestSubmitted && (
        <>
          <Link
            to={`/qbanks/${qBankEndpoint}/dashboard/performance/test/${testId}/results`}
          >
            <FontAwesomeIcon icon={faListCheck} />
          </Link>
          <Link
            to={`/qbanks/${qBankEndpoint}/dashboard/performance/test/${testId}/analysis`}
          >
            <FontAwesomeIcon icon={faChartLine} />
          </Link>
        </>
      )}
      <Buttons
        testId={testId}
        testName={testName}
        qBankEndpoint={qBankEndpoint}
        questionIds={questionIds}
      />
    </div>
  );
};
