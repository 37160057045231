import { trpcProxyClient } from "@/utils/trpc";

export const editNote = ({
  id,
  newContent,
}: {
  id: string;
  newContent: string;
}) => {
  return trpcProxyClient.notebook.note.editOne.mutate({
    id,
    newContent,
  });
};
