import { createFileRoute, Outlet } from "@tanstack/react-router";
import { z } from "zod";
import { LoadingPage } from "@/components/LoadingPage";
import React, { PropsWithChildren, useEffect } from "react";
import TestPageProvider from "./-test-page-components/test-page-provider";
import NbmeLayout from "@/components/nbme-layout";
import useResumeTest from "@/hooks/test-hooks/use-resume-test";
import useHighlightMarkers from "@/hooks/highlightmarker-hooks/use-highlightmarkers";
import useQuestionBank from "@/hooks/question-bank-hooks/use-question-bank";
import { NotFoundPage } from "@/components/NotFoundPage";
import { zodValidator } from "@tanstack/zod-adapter";

const testPageOptionsSchema = z.object({ slot: z.number().nullable().optional() });
export const Route = createFileRoute("/tests/$testId")({
  validateSearch: zodValidator(testPageOptionsSchema),
  component: TestLayout,
});

const PureTestPage = React.memo(TestPage);
function TestLayout() {
  const params = Route.useParams();
  const { slot } = Route.useSearch();
  const testId = Number(params.testId);
  const switchSelectedTestSlot = useSwitchSelectedTestSlot();
  const test = useConstructedTest({ testId });
  useTitle(test.data?.name ? `Test: ${test.data?.name}` : "Loading...");

  const questionBank = useQuestionBank({
    questionBankId: test.data?.questionBankId ?? "",
  });
  const highlightMarkers = useHighlightMarkers();
  const resumeTest = useResumeTest();

  useEffect(() => {
    resumeTest({ testId });
  }, [testId]);

  useEffect(() => {
    if (test.data?.id && (slot || slot === 0))
      switchSelectedTestSlot({ testId, slot });
  }, [slot, test.data?.id]);

  if (test.isLoading) return <LoadingPage />;
  if (test.error && test.error.data?.code === "NOT_FOUND") {
    return <NotFoundPage />;
  }

  return (
    <TestPageProvider
      value={{
        highlightMarkers,
        constructedTest: test,
      }}
    >
      <NbmeLayout questionBankEndpoint={questionBank.data?.endpoint ?? ""}>
        <PureTestPage />
      </NbmeLayout>
    </TestPageProvider>
  );
}

/* Message for future you....
 * Everything here has been thought out,
 * please do not change anything you see here, any padding value
 * or anything even when you don't understand it, everything here
 * was made for a reason...*/
import { NbmeExplanation } from "@/components/NbmeExplanation";
import { NbmeQuestion } from "@/components/NbmeQuestion";
import useNbmeKeyboardShortcuts from "@/hooks/nbme/use-nbme-keyboard-shortcuts";
import {
  useNbmeAlignment,
  useNbmeLayoutStore,
  useNbmeLineWidth,
  useNbmePadding,
  useNbmeSplitView,
} from "@/utils/stores/nbmeLayoutStore";
import { ScrollArea as ScrollAreaPrimitive } from "@/components/common/ScrollArea";
import {
  ResizableHandle,
  ResizablePanel as SplitPanel,
  ResizablePanelGroup as SplitPanelGroup,
} from "@/components/ui/resizable";
import { useOnDesktop } from "@/hooks";
import { ComponentProps } from "react";
import { twMerge } from "tailwind-merge";
import { PropsWithClassName } from "@/types";
import { cn } from "@/lib/utils";
import useTestPageTestSelectedTestSlot from "./-test-page-hooks/use-test-page-test-selected-test-slot";
import useTestPageTestSelectedTestSlotQuestion from "./-test-page-hooks/use-test-page-test-selected-test-slot-questiont";
import useTestPageTest from "./-test-page-hooks/use-test-page-test";
import spreadIf from "@/lib/utils/spread-if";
import useConstructedTest from "./-test-page-hooks/use-constructed-test";
import useSwitchSelectedTestSlot from "@/hooks/test-hooks/use-switch-selected-testslot";
import { useTitle } from "@/hooks/common/use-title";

const UnsplitPanel = ({
  children,
  style,
}: PropsWithChildren & Pick<ComponentProps<"div">, "style">) => {
  const [lineWidth] = useNbmeLineWidth();
  const { data: question } = useTestPageTestSelectedTestSlotQuestion();
  const onDesktop = useOnDesktop();
  return (
    <div
      className={cn(question && question.abstract && "lg:max-w-full")}
      style={onDesktop ? { maxWidth: `${lineWidth}%`, ...style } : { ...style }}
    >
      {children}
    </div>
  );
};

const ScrollArea = ({
  children,
  className,
  shouldShowSplitView,
  style,
}: PropsWithChildren &
  PropsWithClassName & { shouldShowSplitView: boolean } & Pick<
    ComponentProps<"div">,
    "style"
  >) => (
  <ScrollAreaPrimitive
    rootStyle={style}
    className={cn("group h-full", className)}
    thumbClassName="bg-gray-300 warm:bg-brown-300 dark:bg-neutral-600 group-focus:bg-gray-400 dark:group-focus:bg-neutral-500 group-hover:bg-gray-400 dark:group-hover:bg-neutral-500 transition-colors"
    scrollbarClassName="bg-gray-100 dark:bg-neutral-800 group-focus:bg-gray-200 dark:group-focus:bg-neutral-700 group-hover:bg-gray-200 warm:group-hover:bg-brown-100 dark:group-hover:bg-neutral-700 transition-colors"
  >
    {children}
  </ScrollAreaPrimitive>
);

const UnsplitPanelGroup = ({
  children,
  shouldShowSplitView,
}: PropsWithChildren & {
  shouldShowSplitView: boolean;
}) => (
  <ScrollArea
    shouldShowSplitView={shouldShowSplitView}
    className={cn("flex flex-col justify-start items-center gap-3 w-full")}
  >
    {children}
  </ScrollArea>
);

export default function TestPage() {
  const [padding] = useNbmePadding();
  useNbmeKeyboardShortcuts();
  const onDesktop = useOnDesktop();
  const [splitView] = useNbmeSplitView();
  const { data: test } = useTestPageTest();
  const { data: selected } = useTestPageTestSelectedTestSlot();
  const { data: question } = useTestPageTestSelectedTestSlotQuestion();

  const mode = test?.mode;
  const submitted = test?.submitted;

  const shouldShowExplanation =
    selected?.submitted &&
    (mode?.includes("tutor") || (!mode?.includes("tutor") && submitted));

  const shouldShowSplitView =
    onDesktop && splitView && shouldShowExplanation && !question?.abstract;

  const PanelGroup = shouldShowSplitView ? SplitPanelGroup : UnsplitPanelGroup;

  const Panel = shouldShowSplitView ? SplitPanel : UnsplitPanel;

  return (
    <PanelGroup
      shouldShowSplitView={shouldShowSplitView ?? false}
      direction={"horizontal"}
      className={twMerge("w-full h-full")}
    >
      <Panel defaultSize={50} order={1}>
        <ScrollArea
          style={{
            paddingTop: `calc(15px + ${padding.question}px)`,
            paddingRight: `calc((15px + ${padding.question}px) / 2)`,
            paddingLeft: `calc(15px + ${padding.question}px)`,
          }}
          shouldShowSplitView={shouldShowSplitView ?? false}
        >
          <NbmeQuestion />
        </ScrollArea>
      </Panel>
      {shouldShowExplanation && (
        <>
          {shouldShowSplitView && <ResizableHandle withHandle={true} />}
          <Panel className="px-5" defaultSize={50} order={2}>
            <ScrollArea
              style={{
                paddingLeft: `calc((15px + ${padding.explanation}px)${shouldShowSplitView ? " / 2" : ""
                  })`,
                paddingRight: `calc(15px + ${padding.explanation}px)`,
                ...spreadIf(shouldShowSplitView, {
                  paddingTop: `calc(15px + ${padding.explanation}px)`,
                }),
              }}
              shouldShowSplitView={shouldShowSplitView ?? false}
            >
              <NbmeExplanation />
            </ScrollArea>
          </Panel>
        </>
      )}
    </PanelGroup>
  );
}
