import ArrayMapUtils from "@/types/common/ArrayConvertableMap";
import useSubscriptionQuestionBanks from "./use-subscription-question-banks";
import { useMemo } from "react";

type useQuestionBankArguments =
  | {
      questionBankEndpoint: string;
      questionBankId?: undefined;
    }
  | { questionBankId: string; questionBankEndpoint?: undefined };

const useQuestionBank = (args: useQuestionBankArguments) => {
  const query = useSubscriptionQuestionBanks();
  const { data: questionBanks } = query;
  const questionBank = useMemo(
    () =>
      ArrayMapUtils.find(questionBanks, (qb) => {
        let searchKey: "id" | "endpoint" = "id";
        let searchCriteria: string = "";
        if (args.questionBankId) {
          searchCriteria = args.questionBankId;
          searchKey = "id";
        }
        if (args.questionBankEndpoint) {
          searchCriteria = args.questionBankEndpoint!;
          searchKey = "endpoint";
        }

        return qb[searchKey] === searchCriteria;
      }),
    [questionBanks, args],
  );

  return useMemo(
    () => ({ ...query, data: questionBank }),
    [query, questionBank],
  );
};

export default useQuestionBank;
