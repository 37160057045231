import ShinyButton from "@/ncomponents/buttons/shiny-button"
import ChatGptIcon from "../icons/chat-gpt-icon"
import { BlurInOut } from '@/ncomponents/utilities/animations/blur-in-out';
import { cn } from '@/lib/utils';
import RippleWaveLoader from '@/ncomponents/loaders/ripple-wave-loader';
import { Spin } from "@/ncomponents/utilities/animations/spin";
import { MouseEventHandler } from "react";
import { AnimatePresence } from "framer-motion";

export const NbmeExplanationGenerateAiSummaryButton = ({ onClick, isLoading, isDisabled }: { onClick: MouseEventHandler, isLoading?: boolean, isDisabled?: boolean }) => {
  return (<ShinyButton disabled={isDisabled} className={cn("focus:outline-transparent dark:focus:outline-transparent warm:focus:outline-transparent")} onClick={onClick}>
    <span className="flex flex-row justify-center items-center gap-2">
      <Spin spinning={!!isLoading} speed={0.2}>
        <ChatGptIcon className={cn("fill-white w-5 h-5")} />
      </Spin>
      <span className='relative'>
        <AnimatePresence>
          {isLoading &&
            <BlurInOut className='absolute left-[50%] top-[50%]' state={'visible'}>
              <RippleWaveLoader rippleClassName='h-3 w-1' className='-translate-x-[50%] -translate-y-[50%]' key={'loader'} />
            </BlurInOut>
          }
        </AnimatePresence>
        <BlurInOut state={isLoading ? 'hidden' : 'visible'} key={'text'}>
          Consult AI Tutor
        </BlurInOut>
      </span>
    </span>
  </ShinyButton>)


}
