import { useEffect, useState } from "react";
import { NbmeNavbarButtonWithIcon } from ".";
import { EnterFullScreenIcon, ExitFullScreenIcon } from "@radix-ui/react-icons";

export const NbmeFullscreenButton = () => {
  const [isFullscreen, setIsFullscreen] = useState<boolean>(false);
  useEffect(() => {
    const handleFullscreen = () => {
      setIsFullscreen(!!document.fullscreenElement);
    };
    document.addEventListener("fullscreenchange", handleFullscreen);
    return () =>
      document.removeEventListener("fullscreenchange", handleFullscreen);
  }, []);

  return (
    <NbmeNavbarButtonWithIcon
      className="w-full md:w-auto"
      icon={
        isFullscreen ? (
          <ExitFullScreenIcon className="w-8 h-8" />
        ) : (
          <EnterFullScreenIcon className="w-8 h-8" />
        )
      }
      label={isFullscreen ? "Exit" : "Full Screen"}
      onClick={() =>
        isFullscreen
          ? document.exitFullscreen()
          : document.documentElement.requestFullscreen()
      }
    />
  );
};
