import { PropsWithChildren } from "react";
import { PropsWithClassName } from "@/types";
import { cn } from "@/lib/utils";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import useViewerContext from "./hooks/use-viewer-context";

type ViewerBodyProps = PropsWithChildren & PropsWithClassName;

const ViewerBody = ({ children, className }: ViewerBodyProps) => {
  const { contentRef, ...props } = useViewerContext();
  return (
    <TransformWrapper {...props} minScale={0.1} centerOnInit>
      <TransformComponent
        wrapperClass={cn("!w-full !h-full bg-white dark:bg-black", className)}
      >
        <div
          ref={contentRef}
          className={cn(
            "relative w-full h-full flex flex-col justify-center items-center",
          )}
        >
          {children}
        </div>
      </TransformComponent>
    </TransformWrapper>
  );
};

export default ViewerBody;
