import { twMerge } from "tailwind-merge";
import { DashboardTableRowProps } from "./DashboardTableRow";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";

interface DashboardMobileTableRowProps<T extends { id: string | number }>
  extends DashboardTableRowProps<T> {
  isFirstItem?: boolean;
}

export const DashboardMobileTableRow = <T extends { id: string | number }>({
  columnContentMapper,
  collapsedItems,
  row,
  item,
  isAllCollapsed,
  isCollapsedItem,
  isFirstItem,
}: DashboardMobileTableRowProps<T>) => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
  return (
    <>
      <div
        className={twMerge(
          "flex flex-col justify-start items-center w-full border border-primary-600 warm:border-brown-600 dark:border-neutral-600 rounded-primary overflow-clip",
          isCollapsedItem && "w-[90%] border-primary-500",
        )}
      >
        <div className="flex flex-col justify-center items-center w-full gap-3 ">
          {row.columns.map((column, index, array) => {
            const isFirstColumn = index === 0;
            const isLastColumn = index === array.length - 1;
            return (
              <div
                key={column.name}
                className={twMerge(
                  "flex flex-row justify-between items-center w-full border-b border-gray-300 warm:border-brown-300 dark:border-neutral-700 gap-5 px-5 pb-2 text-sm",
                  isFirstColumn && (isFirstItem || isCollapsedItem) && "pt-2",
                  isFirstColumn &&
                    "bg-primary-600 warm:bg-brown-800 dark:bg-neutral-700 font-semibold pt-2",
                  isFirstColumn &&
                    isCollapsedItem &&
                    "bg-primary-500 font-normal",
                  isLastColumn && "border-b-0 pb-4",
                )}
              >
                <p
                  className={twMerge(
                    "font-semibold text-gray-700 warm:text-brown-900 dark:text-neutral-200",
                    isFirstColumn && "text-white warm:text-white",
                  )}
                >
                  {column.name}:
                </p>
                <div
                  className={twMerge(
                    "text-gray-700 dark:text-neutral-200 warm:text-brown-900",
                    isFirstColumn && "text-white warm:text-white",
                  )}
                >
                  {columnContentMapper
                    ? columnContentMapper(column, column.content?.(item))
                    : column.content?.(item)}
                </div>
              </div>
            );
          })}
        </div>
        {row.collapsible && (
          <button
            onClick={() => setIsCollapsed((prev) => !prev)}
            className="flex flex-row justify-center items-center w-full border-t border-primary-600 dark:border-neutral-600 py-3 text-gray-700 warm:text-brown-800 dark:text-neutral-300 dark:bg-neutral-700"
          >
            <FontAwesomeIcon icon={isCollapsed ? faChevronUp : faChevronDown} />
          </button>
        )}
      </div>
      {(isAllCollapsed || isCollapsed) &&
        collapsedItems?.(item).map((collapsedItem, index) => {
          return (
            <DashboardMobileTableRow
              isCollapsedItem
              key={item.id}
              isFirstItem={index === 0}
              item={collapsedItem}
              row={{ columns: row.collapsedColumns!, collapsible: false }}
              // columnContentMapper={(column, content) => {
              //   if (column.name === "Name") return content.name;
              //   return content;
              // }}
            />
          );
        })}
    </>
  );
};
