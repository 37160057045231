import { TopicSliceCreator, topicSliceState } from ".";
import ArrayMapUtils from "@/types/common/ArrayConvertableMap";

const createTopicSlice: TopicSliceCreator = (set) => {
  return {
    ...topicSliceState,
    addTopic: (topic) =>
      set((state) => {
        ArrayMapUtils.push(state.topics, [topic]);
      }),
    addTopics: (topics) =>
      set((state) => {
        ArrayMapUtils.push(state.topics, topics);
      }),
  };
};

export default createTopicSlice;
