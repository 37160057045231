import React, {
  ButtonHTMLAttributes,
  CSSProperties,
  DetailedHTMLProps,
} from "react";
import { NbmeNavbarButtonBase } from "./NbmeNavbarButtonBase";
import { twMerge } from "tailwind-merge";

interface NbmeIconNavbarButtonProps
  extends DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  icon: JSX.Element;
  label: string;
  labelClassName?: string;
  labelStyle?: CSSProperties;
  isLoading?: boolean;
}

export const NbmeIconNavbarButton = ({
  icon,
  label,
  labelStyle,
  labelClassName,
  ...props
}: NbmeIconNavbarButtonProps) => {
  return (
    <NbmeNavbarButtonBase {...props}>
      <div className="flex flex-col justify-between items-center text-white min-h-full px-1">
        {icon}
        <p
          style={labelStyle}
          className={twMerge("text-xs mt-0.5", labelClassName)}
        >
          {label}
        </p>
      </div>
    </NbmeNavbarButtonBase>
  );
};
