import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import { Actions, State } from "./types";
import { state } from "./state";
import { actions } from "./actions";

export const useNotebookUi = create<State & Actions>()(
  immer((set) => ({
    ...state,
    ...actions(set),
  })),
);
