"use client";
import React, { useEffect } from "react";
import { Input } from "./Input";
import { Label } from "./Label";
import { InputGroup } from "./InputGroup";
import { CountryCombobox } from "./CountryCombobox";
import { z } from "zod";
import { signupSchema, updateForm, UpdateFormType } from "@/utils/zod";
import { create } from "zustand";
import { trpc } from "@/utils/trpc";
import { ButtonWithLoadingSpinner } from "./ButtonWithLoadingSpinner";
import useSignIn from "@/hooks/auth-hooks/use-sign-in";
import { useNavigate } from "@tanstack/react-router";

interface SignupFormState extends z.infer<typeof signupSchema> {
  nameError: string;
  usernameError: string;
  passwordError: string;
  countryError: string;
  emailError: string;
  phoneNumberError: string;
  updateForm: UpdateFormType<this>;
  setError: (message: string, field: keyof this) => void;
}

const useFormState = create<SignupFormState>()((set) => ({
  name: "",
  nameError: "",

  username: "",
  usernameError: "",

  password: "",
  passwordError: "",

  country: "",
  countryError: "",

  email: "",
  emailError: "",

  phoneNumber: "",
  phoneNumberError: "",

  updateForm: (update, field, errorField) =>
    set(() => updateForm(update, field, errorField, signupSchema)),
  setError: (message: string, errorField: keyof SignupFormState) =>
    set(() => ({ [errorField]: message })),
}));

export const SignupForm = () => {
  const signIn = useSignIn();
  const {
    mutate: signup,
    error: signupError,
    isPending,
    data: newUser,
    isSuccess: signupSuccess,
  } = trpc.auth.signup.useMutation();
  const {
    username,
    usernameError,
    name,
    nameError,
    updateForm,
    email,
    emailError,
    password,
    passwordError,
    phoneNumber,
    phoneNumberError,
    country,
    countryError,
    setError,
  } = useFormState();

  const handleSubmit: React.FormEventHandler = (e) => {
    e.preventDefault();
    signup({ username, password, email, country, phoneNumber, name });
  };

  useEffect(() => {
    if (signupError?.data?.code === "BAD_REQUEST") {
      if (signupError?.message) {
        const error = JSON.parse(signupError.message) as {
          path: string[];
          message: string;
        }[];
        for (const e of error) {
          setError(e.message, `${e.path[0]}Error` as keyof SignupFormState);
        }
      }
    } else if (signupError?.data?.code === "CONFLICT") {
      if (signupError?.message) {
        for (const ef of ["usernameError", "emailError"]) {
          setError(signupError.message, ef as keyof SignupFormState);
        }
      }
    }
  }, [signupError, setError]);

  useEffect(() => {
    if (signupSuccess && newUser) {
      signIn(newUser);
    }
  }, [signupSuccess, newUser, signIn]);

  const navigate = useNavigate();

  return (
    <form
      className="flex flex-col justify-center items-center gap-6"
      onSubmit={handleSubmit}
    >
      <div className="flex flex-col justify-center items-start gap-2 w-full">
        <h1 className="text-2xl font-bold">Sign up</h1>
        <h2 className="text-xl font-semibold">
          Already have an account?{" "}
          <button
            type="button"
            className="text-primary-500 underline font-medium text-base"
            onClick={() => navigate({ to: "/auth/signin" })}
          >
            Sign in
          </button>
        </h2>
      </div>
      <div className="flex flex-col justify-center items-start w-full gap-3">
        <InputGroup error={nameError}>
          <Label className="font-medium" htmlFor="name-input">
            Name
          </Label>
          <Input
            id="name-input"
            value={name}
            placeholder="Name"
            onChange={(e) => updateForm(e.target.value, "name", "nameError")}
          />
        </InputGroup>
        <InputGroup error={usernameError}>
          <Label className="font-medium" htmlFor="username-input">
            Username
          </Label>
          <Input
            id="username-input"
            value={username}
            placeholder="Username"
            onChange={(e) =>
              updateForm(e.target.value, "username", "usernameError")
            }
          />
        </InputGroup>
        <InputGroup error={emailError}>
          <Label className="font-medium" htmlFor="email-input">
            Email Address
          </Label>
          <Input
            id="email-input"
            value={email}
            placeholder="myemail@example.com"
            onChange={(e) => updateForm(e.target.value, "email", "emailError")}
          />
        </InputGroup>
        <InputGroup error={passwordError}>
          <Label className="font-medium" htmlFor="password-input">
            Password
          </Label>
          <Input
            type="password"
            id="password-input"
            value={password}
            placeholder="Password"
            onChange={(e) =>
              updateForm(e.target.value, "password", "passwordError")
            }
          />
        </InputGroup>
        <InputGroup error={phoneNumberError}>
          <Label className="font-medium" htmlFor="phonenumber-input">
            Phone Number
          </Label>
          <Input
            id="phonenumber-input"
            value={phoneNumber}
            placeholder="+20123512345"
            onChange={(e) =>
              updateForm(e.target.value, "phoneNumber", "phoneNumberError")
            }
          />
        </InputGroup>
        <InputGroup error={countryError}>
          <Label className="font-medium" htmlFor="country-input">
            Country
          </Label>
          <CountryCombobox
            className="z-[3]"
            onCountrySelect={(country) =>
              updateForm(country.id, "country", "countryError")
            }
          />
        </InputGroup>
      </div>
      <div className="w-full mt-3">
        <ButtonWithLoadingSpinner
          error={
            !!nameError ||
            !!usernameError ||
            !!emailError ||
            !!passwordError ||
            !!phoneNumberError ||
            !!countryError
          }
          loading={isPending}
        >
          Sign up
        </ButtonWithLoadingSpinner>
      </div>
    </form>
  );
};
