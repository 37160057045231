import { immer } from "zustand/middleware/immer";
import { createJSONStorage, persist } from "zustand/middleware";
import * as slices from "./slices";
import { create } from "zustand";
import { mountSlices } from "./utils";
import { Store } from "./types";

let initialState: Partial<Store> = {};
export const useAppStore = create<Store>()(
  persist(
    immer((...a) => {
      initialState = {
        ...mountSlices(slices, a),
      };
      return {
        ...initialState,
        resetApplication: () => {
          a[0]((state) => initialState);
        },
      } as Store;
    }),
    {
      name: "coursology-app-store",
      version: 5,
      partialize: (state) => ({
        theme: state.theme,
        currentUser: state.currentUser,
      }),
      storage: createJSONStorage(() => localStorage),
    },
  ),
);
