import React, { PropsWithChildren } from "react";
import QuestionCountBadge from "./QuestionCountBadge";
import { Separator } from "./Separator";
import { twMerge } from "tailwind-merge";
import { ReactNode } from "@tanstack/react-router";

type DashboardStatsSectionProps = {
  title: ReactNode;
  className?: string;
  isLoading?: boolean;
} & ({

  items: { text: string | JSX.Element; count: number }[];
  children?: never;

} | {
  items?: never;
  children: PropsWithChildren['children'];
})

const DashboardStatsSection = ({
  title,
  items,
  className,
  isLoading,
  children,
}: DashboardStatsSectionProps) => {
  return (
    <div
      className={twMerge(
        "flex flex-col justify-start items-start w-full gap-4",
        className,
      )}
    >
      <h1 className="font-medium text-base">{title}</h1>
      <div className="flex flex-col justify-start items-start w-full gap-2">
        {items && items.map((item, index) => (
          <div
            key={index}
            className="flex flex-col justify-start items-start w-full gap-1"
          >
            <div className="flex flex-row justify-between items-center w-full">
              <p className="text-gray-500 dark:text-neutral-200 transition-colors text-sm">
                {item.text}
              </p>
              <QuestionCountBadge
                count={item.count}
                className="text-gray-600 bg-gray-200"
                loading={isLoading}
                spinnerClassName="fill-gray-500"
              />
            </div>
            <Separator
              orientation="horizontal"
              className="bg-gray-200 h-[1px]"
            />
          </div>
        ))}
        {children}
      </div>
    </div>
  );
};

export default DashboardStatsSection;
