import { ArgumentTypes } from "@/types";
import createResizableCorner from "./utils/create-resizable-corner";
import {
  ResizableLowerLeftCorner,
  ResizableLowerRightCorner,
  ResizableUpperLeftCorner,
  ResizableUpperRightCorner,
} from ".";

const ResizableCorners = ({
  ...props
}: ArgumentTypes<ReturnType<typeof createResizableCorner>>[0]) => {
  return (
    <>
      <ResizableUpperLeftCorner {...props} />
      <ResizableUpperRightCorner {...props} />
      <ResizableLowerLeftCorner {...props} />
      <ResizableLowerRightCorner {...props} />
    </>
  );
};

export default ResizableCorners;
