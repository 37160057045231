import { QuestionBankIdentifier } from "@/types/question-bank-types";
import useGetQuestionBankQuestionHeaderWithArticles from "./use-get-question-bank-question-header-with-articles";
import { useMemo } from "react";

const useQuestionBankQuestionHeaderWithArticles = (
  args: QuestionBankIdentifier & { questionId: number },
) => {
  const getQuestionBankQuestionHeaderQuery =
    useGetQuestionBankQuestionHeaderWithArticles(args);
  const { data: getQuestionHeaderWithArticles } =
    getQuestionBankQuestionHeaderQuery;

  return {
    ...getQuestionBankQuestionHeaderQuery,
    data: useMemo(
      () => getQuestionHeaderWithArticles(args.questionId),
      [getQuestionHeaderWithArticles],
    ),
  };
};

export default useQuestionBankQuestionHeaderWithArticles;
