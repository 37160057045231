/** Paginates an array and returns the page with the corresponding
 *  number given the page size.
 *  @returns A copy of a section in the array.
 */
export const paginateArray = <T>(opts: {
  array: Array<T>;
  pageSize: number;
  pageNumber: number;
}): Array<T> => {
  const { array, pageSize, pageNumber } = opts;
  return array.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
};
