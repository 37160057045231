import React from "react";
import { NbmeIconNavbarButton } from "./NbmeIconNavbarButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMessage } from "@fortawesome/free-regular-svg-icons";
import { faMessage as faSolidMessage } from "@fortawesome/free-solid-svg-icons";
import { useNbmeLayoutStore } from "@/utils/stores/nbmeLayoutStore";

export const NbmeFeedbackButton = () => {
  const { setFeedbackVisible, feedbackVisible } = useNbmeLayoutStore(
    (state) => ({
      feedbackVisible: state.feedbackVisible,
      setFeedbackVisible: state.setFeedbackVisible,
    }),
  );

  return (
    <NbmeIconNavbarButton
      className="w-full md:w-auto"
      icon={
        <FontAwesomeIcon
          icon={feedbackVisible ? faSolidMessage : faMessage}
          className="w-6 h-6"
        />
      }
      label="Feedback"
      onClick={() => setFeedbackVisible(!feedbackVisible)}
    />
  );
};
