import React from "react";
import { twMerge } from "tailwind-merge";

interface LoadingSpinnerProps {
  className?: string;
}

export const LoadingSpinner = ({ className }: LoadingSpinnerProps) => {
  return (
    <svg
      className={twMerge(
        "bg-transparent block m-auto w-full h-full fill-primary-500 warm:fill-brown-700 dark:fill-neutral-50",
        className,
      )}
      xmlns="http://www.w3.org/2000/svg"
      style={{ shapeRendering: "auto" }}
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
    >
      <g transform="rotate(0 50 50)">
        <rect x="47" y="24" rx="3" ry="3.72" width="6" height="12">
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="2.127659574468085s"
            begin="-1.9503546099290778s"
            repeatCount="indefinite"
          />
        </rect>
      </g>
      <g transform="rotate(30 50 50)">
        <rect x="47" y="24" rx="3" ry="3.72" width="6" height="12">
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="2.127659574468085s"
            begin="-1.7730496453900708s"
            repeatCount="indefinite"
          />
        </rect>
      </g>
      <g transform="rotate(60 50 50)">
        <rect x="47" y="24" rx="3" ry="3.72" width="6" height="12">
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="2.127659574468085s"
            begin="-1.5957446808510636s"
            repeatCount="indefinite"
          />
        </rect>
      </g>
      <g transform="rotate(90 50 50)">
        <rect x="47" y="24" rx="3" ry="3.72" width="6" height="12">
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="2.127659574468085s"
            begin="-1.4184397163120566s"
            repeatCount="indefinite"
          />
        </rect>
      </g>
      <g transform="rotate(120 50 50)">
        <rect x="47" y="24" rx="3" ry="3.72" width="6" height="12">
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="2.127659574468085s"
            begin="-1.2411347517730495s"
            repeatCount="indefinite"
          />
        </rect>
      </g>
      <g transform="rotate(150 50 50)">
        <rect x="47" y="24" rx="3" ry="3.72" width="6" height="12">
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="2.127659574468085s"
            begin="-1.0638297872340425s"
            repeatCount="indefinite"
          />
        </rect>
      </g>
      <g transform="rotate(180 50 50)">
        <rect x="47" y="24" rx="3" ry="3.72" width="6" height="12">
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="2.127659574468085s"
            begin="-0.8865248226950354s"
            repeatCount="indefinite"
          />
        </rect>
      </g>
      <g transform="rotate(210 50 50)">
        <rect x="47" y="24" rx="3" ry="3.72" width="6" height="12">
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="2.127659574468085s"
            begin="-0.7092198581560283s"
            repeatCount="indefinite"
          />
        </rect>
      </g>
      <g transform="rotate(240 50 50)">
        <rect x="47" y="24" rx="3" ry="3.72" width="6" height="12">
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="2.127659574468085s"
            begin="-0.5319148936170213s"
            repeatCount="indefinite"
          />
        </rect>
      </g>
      <g transform="rotate(270 50 50)">
        <rect x="47" y="24" rx="3" ry="3.72" width="6" height="12">
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="2.127659574468085s"
            begin="-0.35460992907801414s"
            repeatCount="indefinite"
          />
        </rect>
      </g>
      <g transform="rotate(300 50 50)">
        <rect x="47" y="24" rx="3" ry="3.72" width="6" height="12">
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="2.127659574468085s"
            begin="-0.17730496453900707s"
            repeatCount="indefinite"
          />
        </rect>
      </g>
      <g transform="rotate(330 50 50)">
        <rect x="47" y="24" rx="3" ry="3.72" width="6" height="12">
          <animate
            attributeName="opacity"
            values="1;0"
            keyTimes="0;1"
            dur="2.127659574468085s"
            begin="0s"
            repeatCount="indefinite"
          />
        </rect>
      </g>
    </svg>
  );
};
