import { trpc } from "@/utils/trpc";
import { useEffect, useMemo } from "react";
import useAddStatRecords from "./use-add-statrecords";
import useStatRecords from "./use-statrecords";
import ArrayMapUtils from "@/types/common/ArrayConvertableMap";
import useCurrentUser from "../auth-hooks/use-current-user";

const useQuestionBankStatRecords = ({
  questionBankId,
}: {
  questionBankId: string;
}) => {
  const query = trpc.statRecord.getQuestionBankStatRecords.useQuery({
    qBankId: questionBankId,
  });
  const currentUser = useCurrentUser();
  const statRecords = useStatRecords();
  const addStatRecords = useAddStatRecords();
  const { data, isSuccess } = query;
  useEffect(() => {
    if (data && isSuccess) {
      //@ts-ignore
      addStatRecords(data);
    }
  }, [data, isSuccess]);

  const questionBankStatRecords = useMemo(
    () =>
      ArrayMapUtils.filter(
        statRecords,
        (sr) =>
          sr.questionBankId === questionBankId && sr.userId === currentUser.id,
      ),
    [statRecords],
  );

  return { ...query, data: questionBankStatRecords };
};

export default useQuestionBankStatRecords;
