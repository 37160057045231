import { NbmeSettingsBarSection } from "@/components/NbmeSettingsBarSection";
import { NbmeSettingsSegment } from "@/components/NbmeSettingsSegment";
import { NbmeSettingsSwitch } from "@/components/NbmeSettingsSwitch";
import { useNbmeWindowsSettings } from "@/utils/stores/nbmeLayoutStore";

const NbmeSettingsWindowsSection = () => {
  const [settings, setSettings] = useNbmeWindowsSettings();
  return (
    <NbmeSettingsBarSection title={"Windows"}>
      <NbmeSettingsSegment>
        <p className="text-xs">Allow windows to go off screen</p>
        <NbmeSettingsSwitch
          checked={settings?.allowOffScreen}
          onCheckedChange={(newValue) =>
            setSettings({ ...settings, allowOffScreen: newValue })
          }
        />
      </NbmeSettingsSegment>
    </NbmeSettingsBarSection>
  );
};
export default NbmeSettingsWindowsSection;
