import React, { ButtonHTMLAttributes, DetailedHTMLProps } from "react";
import { NbmeIconNavbarButton } from "./NbmeIconNavbarButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import { twMerge } from "tailwind-merge";

interface NbmeNextPreviousButtonProps
  extends DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  direction: "next" | "previous";
}

export const NbmeNextPreviousButton = ({
  direction,
  ...props
}: NbmeNextPreviousButtonProps) => {
  return (
    <NbmeIconNavbarButton
      {...props}
      icon={
        <FontAwesomeIcon
          icon={faPlay}
          className={twMerge(
            "w-6 h-6 px-3",
            direction === "previous" && "scale-x-[-100%]",
          )}
        />
      }
      label={direction === "next" ? "Next" : "Previous"}
      labelClassName="md:block hidden"
    />
  );
};
