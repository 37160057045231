import useAddTest from "@/hooks/test-hooks/use-add-test";
import useTests from "@/hooks/test-hooks/use-tests";
import ArrayMapUtils from "@/types/common/ArrayConvertableMap";
import { trpc } from "@/utils/trpc";
import { useEffect, useMemo } from "react";
import { GetTestInput } from "@/api/routers";

const useTest = (args: GetTestInput) => {
  const tests = useTests();
  const test = useMemo(
    () => ArrayMapUtils.find(tests, args.testId),
    [tests, args.testId],
  );
  const addTest = useAddTest();
  const query = trpc.test.getTest.useQuery(args, { enabled: !test });
  const { data, isLoading } = query;
  useEffect(() => {
    if (!!data && !isLoading) {
      //I hope all these ts-ignore don't bite me
      //back in the future...
      //ts-ignore done for date types...
      // @ts-ignore
      addTest(data);
    }
  }, [data, isLoading]);
  return useMemo(
    () => ({
      ...query,
      data: test,
    }),
    [query, test],
  );
};

export default useTest;
