
import { titlize } from "@/utils";
import { PropsWithClassName } from "@coursology/types";
import { motion } from "framer-motion";
import { PropsWithChildren } from "react";

export function StepAsideInOut({ children, className, by, from = "top", stepAside }: PropsWithChildren & PropsWithClassName & { by: number, from?: "top" | "bottom" | "left" | "right", stepAside: boolean }) {
  const marginProperty = `margin${titlize(from)}`

  return (
    <motion.div
      initial={{ [marginProperty]: `0px` }}
      animate={stepAside ? { [marginProperty]: `${by}px` } : { [marginProperty]: `0px` }}
      exit={{ [marginProperty]: `0px` }}
      transition={{ duration: 1.5, bounce: 0.3, type: "spring" }}
      className={className}
    >
      {children}
    </motion.div>
  );
}
