import useClearTestHighlights from "@/hooks/highlight-hooks/use-clear-test-highlights";
import { useCallback } from "react";
import useTestPageTest from "./use-test-page-test";

const useTestPageClearTestHighlights = () => {
  const clearTestHighlights = useClearTestHighlights();
  const { data: test } = useTestPageTest();
  return useCallback(() => {
    if (test) clearTestHighlights({ testId: test.id });
  }, [clearTestHighlights, test]);
};

export default useTestPageClearTestHighlights;
