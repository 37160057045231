import useTestQuestionHeaders from "@/hooks/question-hooks/use-test-question-headers";
import { Test } from "@/models/test-model";
import ArrayMapUtils from "@/types/common/ArrayConvertableMap";
import useTest from "@/hooks/test-hooks/use-test";
import useQuestionBankSubjects from "@/hooks/subject-hooks/use-question-bank-subjects";
import useQuestionBankSystems from "@/hooks/system-hooks/use-question-bank-systems";
import useQuestionBankTopics from "@/hooks/topic-hooks/use-question-bank-topics";
import useTestQuestionChoices from "@/hooks/question-choice-hooks/use-test-question-choices";
import useTestTestSlots from "@/hooks/test-slot-hooks/use-test-test-slots";
import mergeFlags from "@/utils/common/merge-flags";
import { useMemo } from "react";

const useTestResult = ({ testId }: { testId: Test["id"] }) => {
  const testQuery = useTest({ testId });
  const { data: test } = testQuery;
  const testQuestionHeadersQuery = useTestQuestionHeaders({ testId });
  const { data: questionHeaders } = testQuestionHeadersQuery;
  const testQuestionChoicesQuery = useTestQuestionChoices({ testId });
  const { data: testQuestionChoices } = testQuestionChoicesQuery;
  const testTestSlotsQuery = useTestTestSlots({ testId });
  const { data: testSlots } = testTestSlotsQuery;
  const subjectsQuery = useQuestionBankSubjects(
    {
      questionBankId: test?.questionBankId ?? "",
    },
    { enabled: !!test },
  );
  const { data: subjects } = subjectsQuery;
  const systemsQuery = useQuestionBankSystems(
    {
      questionBankId: test?.questionBankId ?? "",
    },
    { enabled: !!test },
  );
  const { data: systems } = systemsQuery;
  const topicsQuery = useQuestionBankTopics(
    {
      questionBankId: test?.questionBankId ?? "",
    },
    { enabled: !!test },
  );
  const { data: topics } = topicsQuery;

  const populatedQuestionHeaders = useMemo(
    () =>
      ArrayMapUtils.sort(
        ArrayMapUtils.map(questionHeaders, (qh) => {
          return {
            ...qh,
            subject: ArrayMapUtils.find(subjects, qh.subjectId)?.name ?? "",
            system: ArrayMapUtils.find(systems, qh.systemId)?.name ?? "",
            topic: ArrayMapUtils.find(topics, qh.topicId)?.name ?? "",
            othersCorrect:
              ArrayMapUtils.find(
                testQuestionChoices,
                (tqc) => tqc.questionId === qh.id && !!tqc.correct,
              )?.uWorldChosenBy ?? 0,
            timeSpent:
              ArrayMapUtils.find(testSlots, (ts) => ts.questionId === qh.id)
                ?.timeElapsed ?? 0,
            slotOrder:
              ArrayMapUtils.find(testSlots, (s) => s.questionId === qh.id)
                ?.order ?? 0,
          };
        }),
        (q) => q.slotOrder,
      ),
    [
      questionHeaders,
      subjects,
      systems,
      topics,
      testQuestionChoices,
      testSlots,
    ],
  );
  return {
    ...mergeFlags({
      source: testQuery,
      destinations: [
        testQuestionHeadersQuery,
        testQuestionChoicesQuery,
        testTestSlotsQuery,
        subjectsQuery,
        systemsQuery,
        topicsQuery,
      ],
    }),
    data: populatedQuestionHeaders,
  };
};

export default useTestResult;
