import { useNbmeNightMode, useNbmeTheme } from "@/utils/stores/nbmeLayoutStore";
import React from "react";

export const NbmeSettingsThemeControl = () => {
  const { nightMode } = useNbmeNightMode();
  const [_, setTheme] = useNbmeTheme();
  return (
    <div className="flex flex-row justify-center items-center gap-2">
      <button
        disabled={nightMode.on}
        className="w-8 h-8 rounded-full bg-white border-2 border-gray-300 focus:border-primary-400 enabled:hover:border-primary-400 transition-colors"
        onClick={() => setTheme("light")}
      ></button>
      <button
        disabled={nightMode.on}
        className={
          "w-8 h-8 rounded-full bg-black border-2 border-gray-300 focus:border-primary-400 enabled:hover:bordery-primary-400 transition-colors"
        }
        onClick={() => setTheme("dark")}
      ></button>
      <button
        disabled={nightMode.on}
        className={
          "w-8 h-8 rounded-full bg-brown-100 border-2 border-gray-300 focus:border-primary-400 enabled:hover:bordery-primary-400 transition-colors"
        }
        onClick={() => setTheme("warm")}
      ></button>
    </div>
  );
};
