import { PeepingDrawer } from "@/components/common/PeepingDrawer";
import { ComponentProps, PropsWithChildren } from "react";

export const MobileDrawer = ({
  children,
  ...props
}: ComponentProps<typeof PeepingDrawer> & PropsWithChildren) => {
  return (
    <PeepingDrawer {...props} shouldScaleBackground>
      {children}
    </PeepingDrawer>
  );
};
