import { Tabs } from "@/components/Tabs";
import { usePerformanceReportsPageTabs } from "../-hooks";
import { titlize } from "@/utils/common/titlize";

export const PerformanceReportsPageTabs = () => {
  const { tabs, setCurrentTab, currentTab } = usePerformanceReportsPageTabs();

  return (
    <Tabs
      variant="dashboard"
      tabs={tabs.map((t) => titlize(t))}
      currentTab={titlize(currentTab)}
      // 'as' here to put the typescript compiler to sleep
      onCurrentTabChange={(t) =>
        setCurrentTab(t.toLowerCase() as "subjects" | "systems" | "topics")
      }
    />
  );
};
