import {
  useTestPageSwitchToNextSlot,
  useTestPageSwitchToPreviousSlot,
} from "@/routes/tests/$testId/-test-page-hooks/use-test-page-switch-selected-slot";
import useNbmeSidebarVisible from "@/components/NbmeSidebar/hooks/use-nbme-sidebar-visible";
import { useGesture } from "@use-gesture/react";
import { useNbmeSettingsShowSidebarOnHover } from "@/utils/stores/nbmeLayoutStore";

import { useSwipeable } from "react-swipeable";

const useNbmeGestures = () => {
  const [sidebarVisible, setSidebarVisible] = useNbmeSidebarVisible();
  const [showSidebarOnHover] = useNbmeSettingsShowSidebarOnHover();
  const nextSlot = useTestPageSwitchToNextSlot();
  const previousSlot = useTestPageSwitchToPreviousSlot();

  const bind = useGesture({
    onMouseMove: ({ event }) => {
      if (event.clientX < 5) {
        if (!showSidebarOnHover) return;
        setSidebarVisible(true);
      }
    },
  });

  const swipeHandler = useSwipeable({
    swipeDuration: 200,
    onSwiped: ({ initial, dir, deltaX }) => {
      const [initialX] = initial;
      if (Math.abs(deltaX) < 150) return;
      const SIDEBAR_GESTURE_THRESHOLD = 100;
      if (dir === "Right") {
        if (initialX > SIDEBAR_GESTURE_THRESHOLD) {
          previousSlot();
        } else setSidebarVisible(true);
        return;
      }
      if (dir === "Left") {
        if (initialX > SIDEBAR_GESTURE_THRESHOLD) {
          nextSlot();
        } else sidebarVisible && setSidebarVisible(false);
        return;
      }
    },
  });

  return () => ({ ...bind(), ...swipeHandler });
};

export default useNbmeGestures;
