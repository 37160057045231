import { ComponentProps } from "react";
import { IconButton } from ".";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { cn } from "@/lib/utils";

interface CheckButtonProps extends ComponentProps<typeof IconButton> {}
export const CheckButton = ({ onClick, className }: CheckButtonProps) => {
  return (
    <IconButton
      onClick={onClick}
      className={cn(
        "text-gray-400 dark:text-neutral-300 hover:enabled:text-gray-500 dark:hover:enabled:text-neutral-100 text-base",
        className,
      )}
    >
      <FontAwesomeIcon icon={faCheck} />
    </IconButton>
  );
};
