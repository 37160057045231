import { toast } from "sonner";
import { Action } from "../../types";
import { fetchUsers } from "./queries";
import { fixDates } from "./utils";

export const initialize: Action = ([set]) => {
  const stopLoading = () =>
    set((state) => {
      state.areUsersLoading = false;
    });

  fetchUsers()
    .then((users) => {
      set((state) => {
        const allUsers = fixDates(users);
        state.allUsers = allUsers;
        state.filteredUsers = allUsers;
      });
      stopLoading();
    })
    .catch((error) => {
      stopLoading();
    });
};
