import { NbmeNavbarButtonWithIcon } from ".";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMessage } from "@fortawesome/free-regular-svg-icons";
import { faMessage as faSolidMessage } from "@fortawesome/free-solid-svg-icons";

interface NbmeFeedbackNavbarButtonProps {
  isFeedbackVisible: boolean;
  onIsFeedbackVisibleChange: (v: boolean) => void;
}

export const NbmeFeedbackNavbarButton = ({
  isFeedbackVisible,
  onIsFeedbackVisibleChange,
}: NbmeFeedbackNavbarButtonProps) => {
  return (
    <NbmeNavbarButtonWithIcon
      className="w-full md:w-auto"
      icon={
        <FontAwesomeIcon
          icon={isFeedbackVisible ? faSolidMessage : faMessage}
          className="w-6 h-6"
        />
      }
      label="Feedback"
      onClick={() => onIsFeedbackVisibleChange(!isFeedbackVisible)}
    />
  );
};
