import createHighlightSlice from "./create-highlight-slice";
import { Highlight } from "@/models";
import { StateCreator } from "zustand";
import { SliceModifiers } from "@/store/types";
import { ArrayMap } from "@/types/common/ArrayConvertableMap";
import {
  ChangeHighlightHighlightMarkerInput,
  ClearQuestionHighlightsInput,
  ClearTestHighlightsInput,
  CreateHighlightInput,
  DeleteHighlightInput,
} from "@/api/routers/highlight-router";
import { AuthSlice } from "../auth-slice";
import { SliceActionOpts } from "@/types/common/SliceActionOpts";
import { QuestionSlice } from "../question-slice";
import { HighlightMarkerSlice } from "../highlightmarker-slice";
import { TestSlotSlice } from "../test-slot-slice";
import { TestSlice } from "../test-slice";
import { QuestionExplanationSlice } from "../questionexplanation-slice";

export type HighlightSliceActions = {
  addHighlight: (highlight: Highlight) => void;
  addHighlights: (highlights: Highlight[]) => void;
  deleteHighlight: (args: DeleteHighlightInput, opts?: SliceActionOpts) => void;
  deleteHighlights: (args: { highlightIds: string[] }) => void;
  createHighlight: (args: CreateHighlightInput) => void;
  resetHighlights: (args: { highlightIds: Highlight["id"][] }) => void;
  changeHighlightHighlightMarker: (
    args: ChangeHighlightHighlightMarkerInput,
    opts?: SliceActionOpts,
  ) => void;
  clearQuestionHighlights: (args: ClearQuestionHighlightsInput) => void;
  clearTestHighlights: (args: ClearTestHighlightsInput) => void;
};
export type HighlightSliceState = typeof highlightSliceState;

export type HighlightSlice = HighlightSliceState & HighlightSliceActions;

export type HighlightSliceCreator = StateCreator<
  HighlightSlice &
    AuthSlice &
    QuestionSlice &
    HighlightMarkerSlice &
    TestSlotSlice &
    TestSlice &
    QuestionExplanationSlice,
  [["zustand/immer", never]],
  [],
  HighlightSlice
>;

export type HighlightSliceModifiers = SliceModifiers<HighlightSliceCreator>;

export type HighlightSliceAction = (
  ...modifiers: HighlightSliceModifiers
) => void;

export const highlightSliceState = {
  highlights: {} as ArrayMap<Highlight>,
  deletedHighlights: {} as ArrayMap<Highlight>,
};

export default createHighlightSlice;
