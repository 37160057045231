import createTestSlice from "./create-test-slice";
import { Test, TestWithArticles } from "@/models/test-model/test-model-types";
import { StateCreator } from "zustand";
import { SliceModifiers } from "@/store/types";
import { ArrayMap } from "@/types/common/ArrayConvertableMap";
import { inferProcedureInput } from "@trpc/server";
import {
  CreateTestInput,
  GetTestInput,
  ResumeTestInput,
  SetTestModeInput,
  SubmitTestInput,
  SuspendTestInput,
  TestRouter,
} from "@/api/routers";
import { TestSlotSlice } from "../test-slot-slice";
import { QuestionSlice } from "../question-slice";
import { TestBlueprintSlice } from "../testblueprint-slice";
import { SliceActionOpts } from "@/types/common/SliceActionOpts";
import { AuthSlice } from "../auth-slice";
import { ArrayElement } from "@/types";
import { QuestionBankSlice } from "../question-bank-slice";
import { SystemSlice } from "../system-slice";
import { SubjectSlice } from "../subject-slice";
import { TopicSlice } from "../topic-slice";
import { QuestionChoiceSlice } from "../question-choice-slice";

export type TestSliceActions = {
  renameTest: (args: inferProcedureInput<TestRouter["renameTest"]>) => void;
  deleteTest: (
    args: inferProcedureInput<TestRouter["deleteTest"]>,
    opts?: SliceActionOpts,
  ) => void;
  addTest: (test: TestWithArticles) => void;
  addTests: (tests: TestWithArticles[]) => void;
  switchSelectedTestSlot: (
    args: { testId: TestWithArticles["id"]; slot: number },
    opts?: SliceActionOpts,
  ) => void;
  setTestMode: (args: SetTestModeInput) => void;
  suspendTest: (args: SuspendTestInput, opts?: SliceActionOpts) => void;
  resumeTest: (args: ResumeTestInput) => void;
  submitTest: (args: SubmitTestInput, opts?: SliceActionOpts) => void;
  incrementTestElapsedTime: (args: GetTestInput) => void;
  resetTests: (args: { testIds: Test["id"][] }) => void;
  createTest: (args: {
    test: Omit<CreateTestInput["test"], "id">;
    testSlots: Omit<ArrayElement<CreateTestInput["testSlots"]>, "id">[];
  }) => Promise<Pick<TestWithArticles, "id"> | undefined>;
};
export type TestSliceState = {
  tests: ArrayMap<TestWithArticles>;
  deletedTests: ArrayMap<TestWithArticles>;
};

export type TestSlice = TestSliceState & TestSliceActions;

export type TestSliceCreator = StateCreator<
  TestSlice &
    QuestionSlice &
    TestSlotSlice &
    TestBlueprintSlice &
    AuthSlice &
    QuestionChoiceSlice &
    QuestionBankSlice &
    SystemSlice &
    SubjectSlice &
    TopicSlice,
  [["zustand/immer", never]],
  [],
  TestSlice
>;

export type TestSliceModifiers = SliceModifiers<TestSliceCreator>;

export type TestSliceAction = (...modifiers: TestSliceModifiers) => void;

export const testSliceState: TestSliceState = {
  tests: {},
  deletedTests: {},
};

export default createTestSlice;
