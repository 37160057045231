import { useState } from "react";
import { DashboardMobileTableRow } from "./DashboardMobileTableRow";
import { DashboardTableProps } from "./DashboardTable";
import { paginateArray } from "@/utils/common/paginateArray";

interface DashboardMobileTableProps<T extends { id: string | number }>
  extends DashboardTableProps<T> {}

export const DashboardMobileTable = <T extends { id: string | number }>({
  items,
  columns,
  isLoading,
  collapsedItems,
  columnContentMapper,
  itemsPerPage,
  currentPage,
}: DashboardMobileTableProps<T>) => {
  const [isAllCollapsed, setIsAllCollapsed] = useState<boolean>(false);
  return (
    <div className="flex flex-col justify-center items-center w-full gap-8 px-5">
      {items &&
        !isLoading &&
        paginateArray({
          array: items,
          pageNumber: currentPage,
          pageSize: itemsPerPage,
        }).map((item, index) => (
          <DashboardMobileTableRow
            key={item.id}
            isAllCollapsed={isAllCollapsed}
            collapsedItems={collapsedItems}
            isFirstItem={index === 0}
            item={item}
            row={columns}
            columnContentMapper={columnContentMapper}
          />
        ))}
    </div>
  );
};
