import { useTimer } from "use-timer";
import useNbmeSidebarVisible from "./use-nbme-sidebar-visible";
import { useEffect } from "react";
import { useNbmeSettingsAutoHideSidebar } from "@/utils/stores/nbmeLayoutStore";
import useTestPageTest from "@/routes/tests/$testId/-test-page-hooks/use-test-page-test";

const useNbmeSidebarAutoHide = () => {
  const [autoHide] = useNbmeSettingsAutoHideSidebar();
  const { data: test } = useTestPageTest();
  const slot = test?.selectedSlot;
  const [sidebarVisible, setSidebarVisible] = useNbmeSidebarVisible();
  const timer = useTimer({
    endTime: 1,
    onTimeOver: () => {
      setSidebarVisible(false);
    },
  });
  useEffect(() => {
    if (!autoHide) return;
    if (!sidebarVisible) return;
    if (timer.status === "RUNNING") timer.reset();
    else timer.start();
  }, [sidebarVisible, slot, autoHide]);
  return autoHide
    ? timer
    : ({
        start: () => undefined,
        pause: () => undefined,
        reset: () => undefined,
        status: "STOPPED",
        time: 0,
        advanceTime: () => undefined,
      } as typeof timer);
};

export default useNbmeSidebarAutoHide;
