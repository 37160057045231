import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { useEffect, useRef, useState } from "react";
import { twMerge } from "tailwind-merge";
import { AnimatePresence, motion } from "framer-motion";
import { fadeAnimation } from "@/assets/framer";
import { Button } from "@/components/Button";
import { getNumberOfLines } from "@/utils/common/getNumberOfLines";
import { useBreakPoint } from "@/utils/hooks";
import { BreakPoints } from "@/types";
import { Popover } from "@/components/common/Popover";
import useQuestionBankQuestionHeaderWithArticles from "@/hooks/question-hooks/use-question-bank-question-header-with-articles";
import { Skeleton } from "@/components/ui/skeleton";
import { Link } from "@tanstack/react-router";

interface NotesPageNoteCardProps {
  questionId: number;
  text: string;
  onNoteEdited: (newText: this["text"]) => void;
  onNoteDeleted: () => void;
  qbankEndpoint: string;
}

const NotesPageNoteCard = ({
  questionId,
  text,
  onNoteEdited,
  onNoteDeleted,
  qbankEndpoint,
}: NotesPageNoteCardProps) => {
  const { data: question, isLoading: isQuestionLoading } =
    useQuestionBankQuestionHeaderWithArticles({
      questionId,
      questionBankEndpoint: qbankEndpoint,
    });
  const cardRef = useRef<HTMLDivElement>(null);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const breakPoint = useBreakPoint();
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [isDeleteToolTipVisible, setIsDeleteToolTipVisible] =
    useState<boolean>(false);

  const [textDraft, setTextDraft] = useState<string>(text);

  useEffect(() => {
    if (isEditMode) textAreaRef.current?.focus();
  }, [textAreaRef, isEditMode]);

  useEffect(() => {
    setTextDraft(text);
  }, [text]);

  const handleCancelEdit = () => {
    setTextDraft(text);
    setIsEditMode(false);
  };

  const handleSaveEdit = () => {
    setIsEditMode(false);
    onNoteEdited(textDraft);
  };

  const handleDeleteClicked = () => {
    setIsDeleteToolTipVisible((prev) => !prev);
  };

  const handleDeleteConfirmed = () => {
    setIsDeleteToolTipVisible(false);
    onNoteDeleted();
  };

  const handleDeleteCancelled = () => {
    setIsDeleteToolTipVisible(false);
  };

  // useOnClickOutside(cardRef, () => {
  //   handleCancelEdit();
  // });

  const isScreenSmall = breakPoint === BreakPoints.Small;
  const isScreenMedium = breakPoint > BreakPoints.Small;
  const assumedLineWidthInNumberOfCharacters = isScreenSmall
    ? 30
    : isScreenMedium
      ? 100
      : 50;

  return (
    <div
      className={twMerge(
        "relative flex flex-col justify-start items-center border border-gray-300 dark:border-neutral-700 rounded-primary w-full transition-colors overflow-visible",
        isEditMode && "border-primary-400 dark:border-neutral-600 border",
      )}
      ref={cardRef}
    >
      <Popover
        isVisible={isDeleteToolTipVisible}
        className="absolute right-0 -translate-x-3 translate-y-[-50%] md:translate-y-[-80%] rounded-primary bg-white dark:bg-neutral-800 border border-gray-300 dark:border-neutral-700 flex flex-col gap-3 
        justify-start items-center w-[15rem] p-3"
      >
        <p className="font-semibold md:text-sm text-xs">
          Are you sure you want to delete this note?
        </p>
        <div className="w-full flex flex-row justify-between items-center gap-6">
          <Button
            onClick={() => handleDeleteConfirmed()}
            error
            className="p-1 w-1/2"
          >
            <p className="text-xs">Yes</p>
          </Button>
          <Button
            onClick={() => handleDeleteCancelled()}
            className="text-xs w-1/2 p-1"
          >
            <p className="text-xs">No</p>
          </Button>
        </div>
      </Popover>

      <div className="flex flex-row justify-between items-center rounded-t-primary bg-gray-100 warm:bg-brown-50 dark:bg-neutral-800 py-4 px-2 w-full text-gray-700 warm:text-brown-700 dark:text-neutral-300 text-sm md:text-base transition-colors">
        <div className="flex md:flex-row flex-col justify-start items-start md:items-center gap-1 md:gap-2">
          <Link
            from={"/qbanks/$qbankEndpoint/dashboard/notes"}
            to={"/qbanks/$qbankEndpoint/questions/$questionId"}
            params={(p) => ({
              ...p,
              questionId: String(question?.uWolrdId ?? 0),
            })}
            className="font-semibold text-primary-500 warm:text-brown-900 dark:text-neutral-300 underline"
          >
            Question ID: {question?.uWolrdId}
          </Link>{" "}
          {isQuestionLoading ? (
            <Skeleton className="w-12 h-4 rounded-full" />
          ) : (
            <p className="">
              {question?.subject.name} - {question?.system.name}
            </p>
          )}
        </div>
        <AnimatePresence mode="wait">
          <motion.div
            {...fadeAnimation}
            className="flex flex-row justify-between items-center self-end"
            key={isEditMode ? "confirm-cancel" : "edit-delete"}
          >
            <button
              className="border-r border-transparent px-2  text-primary-400 warm:text-brown-600 dark:text-neutral-300 dark:enabled:hover:text-neutral-50 enabled:hover:text-primary-500 warm:enabled:hover:text-brown-700 transition-[color,_transform] text-xs md:text-sm hover:enabled:scale-105"
              onClick={() => {
                isEditMode ? handleSaveEdit() : setIsEditMode(true);
              }}
              key={"save-button"}
            >
              {isEditMode ? (
                <p>Save</p>
              ) : (
                <FontAwesomeIcon icon={faPenToSquare} />
              )}
            </button>
            <button
              className="border-l border-gray-500 px-2 text-primary-400 dark:text-neutral-300 dark:enabled:hover:text-neutral-50 enabled:hover:text-primary-500 transition-[color,_transform] text-xs md:text-sm warm:text-brown-600 hover:enabled:text-brown-700 hover:enabled:scale-105"
              onClick={() => {
                isEditMode ? handleCancelEdit() : handleDeleteClicked();
              }}
              key={"cance-button"}
            >
              {isEditMode ? (
                <p>Cancel</p>
              ) : (
                <FontAwesomeIcon icon={faTrashAlt} />
              )}
            </button>
          </motion.div>
        </AnimatePresence>
      </div>
      <div className={twMerge("w-full")}>
        <textarea
          ref={textAreaRef}
          readOnly={!isEditMode}
          disabled={!isEditMode}
          rows={getNumberOfLines(
            assumedLineWidthInNumberOfCharacters,
            textDraft,
          )}
          className="py-4 px-2 text-gray-600 dark:text-neutral-200 resize-none w-full h-auto outline-none transition-colors text-sm md:text-base bg-white warm:bg-white dark:bg-neutral-900"
          value={textDraft}
          onChange={(e) => setTextDraft(e.target.value)}
        />
      </div>
    </div>
  );
};

export default NotesPageNoteCard;
