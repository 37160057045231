import { faChartColumn, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { scaleUnitInRem } from "@/utils/common/scaleUnitInRem";
import { numberToAlphabet } from "@/utils/common/numberToAlphabet";
import React from "react";
import { twMerge } from "tailwind-merge";
import { DateTime } from "luxon";
import { faCalendar, faClock } from "@fortawesome/free-regular-svg-icons";
import { useBreakPoint } from "@/utils/hooks/useBreakpoints";
import { BreakPoints, FontSizes } from "@/types";
import { AnimatePresence, motion } from "framer-motion";
import { fadeAnimation } from "@/assets/framer";
import { NbmeClickToRevealButton } from "./NbmeClickToRevealButton";
import { Question, QuestionChoice, TestSlot } from "@/api/types";

interface NbmeResultCardProps<T = "test" | "question"> {
  variant: T;
  isPercentageAnsweredVisible: boolean;
  onIsPercentageAnsweredVisibleChange: (v: boolean) => void;
  fontSizeFactor: number;
  question: Question & { choices: QuestionChoice[] };
  isTestTutor?: T extends "test" ? boolean : undefined;
  currentSlot?: T extends "test"
    ? TestSlot & { chosen: QuestionChoice }
    : undefined;
}

export const NbmeResultCard = <T = "question" | "test",>({
  variant,
  isPercentageAnsweredVisible,
  onIsPercentageAnsweredVisibleChange,
  fontSizeFactor,
  question,
  isTestTutor,
  currentSlot,
}: NbmeResultCardProps<T>) => {
  const breakPoint = useBreakPoint();
  const correctChoice = question.choices.find((c) => c.correct);
  const isCorrect = correctChoice?.id === currentSlot?.chosen?.id;
  const showResult =
    variant === "test"
      ? currentSlot?.submitted &&
        (isTestTutor || (!isTestTutor && currentSlot?.submitted))
      : true;
  if (!showResult) return <></>;

  const timeElapsed =
    variant === "test"
      ? DateTime.fromSeconds(currentSlot!.timeElapsed)
      : undefined;

  const isScreenMedium = breakPoint > BreakPoints.Small;

  return (
    <div
      className={twMerge(
        "px-3 py-5 bg-nbme-primary-50 dark:bg-nbme-primary-dark-800 border shadow-lg shadow-gray-300 dark:shadow-none rounded-primary md:w-[80%] w-full border-l-4",
        isCorrect && "border-lime-500 dark:border-lime-600",
        !isCorrect && "border-red-500 dark:border-red-600",
        variant === "question" && "border-lime-500 dark:border-lime-600",
      )}
    >
      <div className="flex flex-row justify-between items-center w-full">
        <div className="flex flex-col justify-center md:justify-center items-center md:items-start">
          <p
            className={twMerge(
              "font-medium",
              isCorrect && "text-lime-500",
              !isCorrect && "text-red-500",
              variant === "question" && "text-lime-500",
            )}
            style={{
              fontSize: scaleUnitInRem(
                isScreenMedium ? FontSizes.Base : FontSizes.Small,
                fontSizeFactor,
              ).resultInRemAsCss,
            }}
          >
            {`${variant === "test" ? (isCorrect ? "Correct" : "Incorrect") : ""}`}
          </p>
          {!isCorrect && (
            <>
              <p
                className="font-light hidden md:block text-center md:text-start dark:text-gray-200"
                style={{
                  fontSize: scaleUnitInRem(FontSizes.ExtraSmall, fontSizeFactor)
                    .resultInRemAsCss,
                }}
              >
                Correct answer
              </p>
              {correctChoice && (
                <p className="font-medium">
                  <span
                    className="inline font-light md:hidden"
                    style={{
                      fontSize: scaleUnitInRem(
                        FontSizes.ExtraSmall,
                        fontSizeFactor,
                      ).resultInRemAsCss,
                    }}
                  >
                    {"Correct is "}
                  </span>
                  {numberToAlphabet(correctChoice.order + 1).toUpperCase()}
                </p>
              )}
            </>
          )}
        </div>
        <div className="flex flex-col md:flex-row justify-center items-center gap-2">
          <FontAwesomeIcon
            icon={faChartColumn}
            className="lg:w-8 lg:h-8 md:w-7 md:h-7 h-6 w-6 text-gray-500 dark:text-gray-50"
          />
          <div className="flex flex-col justify-center items-center md:items-start text-center md:text-start">
            <AnimatePresence mode="wait">
              <motion.p
                {...fadeAnimation}
                key={
                  isPercentageAnsweredVisible
                    ? "percentage-answered-correctly"
                    : "percentage-answered-correctly-hidden"
                }
                className={twMerge(
                  "text-xs md:text-sm text-center w-full md:text-start md:w-auto",
                )}
                style={{
                  fontSize: scaleUnitInRem(
                    isScreenMedium ? FontSizes.Small : FontSizes.ExtraSmall,
                    fontSizeFactor,
                  ).resultInRemAsCss,
                }}
              >
                {isPercentageAnsweredVisible ? (
                  `${correctChoice?.uWorldChosenBy}%`
                ) : (
                  <NbmeClickToRevealButton
                    fontSizeFactor={fontSizeFactor}
                    onClick={() => onIsPercentageAnsweredVisibleChange(true)}
                  />
                )}
              </motion.p>
            </AnimatePresence>
            <p
              className="font-light hidden md:block text-gray-500 dark:text-gray-200"
              style={{
                fontSize: scaleUnitInRem(FontSizes.ExtraSmall, fontSizeFactor)
                  .resultInRemAsCss,
              }}
            >
              Answered correctly
            </p>
          </div>
        </div>
        {variant === "test" && (
          <div className="flex flex-col md:flex-row justify-center items-center gap-2">
            <FontAwesomeIcon
              icon={faClock}
              className="lg:w-8 lg:h-8 md:w-7 md:h-7 h-6 w-6 text-gray-500 dark:text-gray-50"
            />
            <div className="flex flex-col justify-center items-center md:items-start text-center md:text-start">
              <p
                className="text-xs md:text-sm text-center w-full md:text-start md:w-auto"
                style={{
                  fontSize: scaleUnitInRem(
                    isScreenMedium ? FontSizes.Small : FontSizes.ExtraSmall,
                    fontSizeFactor,
                  ).resultInRemAsCss,
                }}
              >
                {currentSlot!.timeElapsed > 60 && (
                  <span>
                    {DateTime.fromMillis(currentSlot!.timeElapsed).toFormat(
                      "mm 'min'",
                    )}
                  </span>
                )}
                <br className="hidden" />
                {DateTime.fromMillis(currentSlot!.timeElapsed).toFormat(
                  " ss 'secs'",
                )}
              </p>
              <p
                className="font-light hidden md:block text-gray-500 dark:text-gray-200 text-xs"
                style={{
                  fontSize: scaleUnitInRem(FontSizes.ExtraSmall, fontSizeFactor)
                    .resultInRemAsCss,
                }}
              >
                Time Spent
              </p>
            </div>
          </div>
        )}
        <div className="flex flex-col md:flex-row justify-center items-center gap-2">
          <FontAwesomeIcon
            icon={faCalendar}
            className="lg:w-8 lg:h-8 md:w-7 md:h-7 h-6 w-6 text-gray-500 dark:text-gray-50"
          />
          <div className="flex flex-col justify-center items-center md:items-center text-center md:text-start">
            <p
              style={{
                fontSize: scaleUnitInRem(
                  isScreenMedium ? FontSizes.Small : FontSizes.ExtraSmall,
                  fontSizeFactor,
                ).resultInRemAsCss,
              }}
            >
              {DateTime.fromJSDate(new Date(question.createdAt)).toFormat(
                "yyyy",
              )}
            </p>
            <p
              className="font-light hidden md:block text-gray-500 dark:text-gray-200"
              style={{
                fontSize: scaleUnitInRem(FontSizes.ExtraSmall, fontSizeFactor)
                  .resultInRemAsCss,
              }}
            >
              Version
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
