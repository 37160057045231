import { PropsWithChildren } from "react";

interface NbmeSettingsSidebarSectionProps extends PropsWithChildren {
  title: string | JSX.Element;
  note?: string | JSX.Element;
}

export const NbmeSettingsSidebarSection = ({
  title,
  note,
  children,
}: NbmeSettingsSidebarSectionProps) => {
  const isTitleString = typeof title === "string";
  const isNoteString = typeof note === "string";

  return (
    <div className="flex flex-col justify-start items-start gap-2 w-full">
      <div className="flex flex-col justify-start items-start w-full">
        {isTitleString ? (
          <p className="text-gray-500 text-sm lg:text-xs ml-1 font-semibold w-full">
            {title}
          </p>
        ) : (
          title
        )}
        {isNoteString ? (
          <p className="text-gray-500 text-xs font-normal ml-1 w-full">
            {note}
          </p>
        ) : (
          note
        )}
      </div>
      <div className="bg-white p-2 rounded-lg min-w-full">
        <div className="flex flex-col justify-start items-center w-full gap-2">
          {children}
        </div>
      </div>
    </div>
  );
};
