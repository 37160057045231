import createTestSlotSlice from "./create-test-slot-slice";
import { TestSlot } from "@/models/testslot-model";
import { StateCreator } from "zustand";
import { SliceModifiers } from "@/store/types";
import { ArrayMap } from "@/types/common/ArrayConvertableMap";
import {
  ChooseTestSlotQuestionChoiceInput,
  CrossTestSlotQuestionChoiceInput,
  SubmitTestSlotInput,
} from "@/api/routers/test-slot-router";
import { QuestionChoiceSlice } from "../question-choice-slice";
import { QuestionSlice } from "../question-slice";
import { SliceActionOpts } from "@/types/common/SliceActionOpts";
import { TestSlice } from "../test-slice";
import { StatRecordSlice } from "../statrecord-slice";

export type TestSlotSliceActions = {
  addTestSlot: (test: TestSlot) => void;
  addTestSlots: (tests: TestSlot[]) => void;
  chooseTestSlotQuestionChoice: (
    args: ChooseTestSlotQuestionChoiceInput,
  ) => Promise<boolean>;
  crossTestSlotQuestionChoice: (args: CrossTestSlotQuestionChoiceInput) => void;
  submitTestSlot: (
    args: SubmitTestSlotInput,
    opts?: SliceActionOpts & { preventAutomaticTestSubmission?: boolean },
  ) => Promise<void>;
  selectTestSlot: (args: { testSlotId: TestSlot["id"] }) => void;
  evaluateTestSlot: (
    args:
      | { testSlotId: TestSlot["id"]; testSlot?: undefined }
      | {
          testSlotId?: undefined;
          testSlot: TestSlot;
        },
  ) => void;
};
export type TestSlotSliceState = { testSlots: ArrayMap<TestSlot> };

export type TestSlotSlice = TestSlotSliceState & TestSlotSliceActions;

export type TestSlotSliceCreator = StateCreator<
  TestSlotSlice &
    QuestionChoiceSlice &
    QuestionSlice &
    TestSlice &
    StatRecordSlice,
  [["zustand/immer", never]],
  [],
  TestSlotSlice
>;

export type TestSlotSliceModifiers = SliceModifiers<TestSlotSliceCreator>;

export type TestSlotSliceAction = (
  ...modifiers: TestSlotSliceModifiers
) => void;

export const testSlotSliceState = {
  testSlots: {},
};

export default createTestSlotSlice;
