import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PropsWithClassName } from "@/types";
import { twMerge } from "tailwind-merge";
import { IconButton } from ".";

interface PlusButtonProps extends PropsWithClassName {
  onClick?: () => void;
}

export const PlusButton = ({ className, onClick }: PlusButtonProps) => {
  return (
    <IconButton onClick={onClick} className={className}>
      <FontAwesomeIcon icon={faPlus} />
    </IconButton>
  );
};
