"use client";
import { PropsWithClassName } from "@/types";
import { BreakPoints } from "@/types";
import { useBreakPoint } from "@/utils/hooks/useBreakpoints";
import { useDashboardLayoutStore } from "@/utils/stores/dashboardLayoutStore";
import useDashboardQuestionBank from "@/routes/qbanks/$qbankEndpoint/dashboard/-dashboard-hooks/use-dashboard-question-bank";
import { fadeAnimation } from "@/assets/framer";
import { Skeleton } from "@/components/ui/skeleton";
import { AnimatePresence, motion } from "framer-motion";
import React, { MouseEventHandler, PropsWithChildren } from "react";
import { twMerge } from "tailwind-merge";
import { Link, useRouterState } from "@tanstack/react-router";

interface DashboardSidebarItemProps
  extends PropsWithChildren,
    PropsWithClassName {
  href?: string;
  onClick?: MouseEventHandler<HTMLElement>;
  hideSidebarOnClick?: boolean;
}

export const Button = ({
  children,
  href,
  onClick,
  className,
  hideSidebarOnClick = true,
}: DashboardSidebarItemProps) => {
  const { data: questionBank, isLoading } = useDashboardQuestionBank();
  const breakPoint = useBreakPoint();
  const hideSidebar = useDashboardLayoutStore((state) => state.hideSidebar);
  const pathname = useRouterState().location.pathname;
  const selected = href && pathname.split("/").includes(href);

  const ButtonElement = href ? Link : "button";

  return (
    <li
      onClick={onClick}
      className={twMerge(
        "w-full font-medium hover:bg-primary-600 warm:hover:bg-brown-800 dark:hover:bg-neutral-700 focus:bg-primary-600 dark:focus:bg-neutral-700 transition-colors rounded-primary",
        selected && "bg-primary-600 warm:bg-brown-800 dark:bg-neutral-700",
        className,
      )}
    >
      <AnimatePresence mode="wait">
        {isLoading && (
          <motion.div {...fadeAnimation}>
            <Skeleton className="w-full h-10 my-2" />
          </motion.div>
        )}
        {questionBank && !isLoading && (
          <motion.div key={"visible"}>
            <ButtonElement
              className="flex flex-row justify-start items-center gap-2 w-full py-3.5 pr-14 pl-8"
              href={
                href
                  ? `/qbanks/${questionBank?.endpoint}/dashboard/${href}`
                  : ""
              }
              onClick={() => {
                hideSidebarOnClick &&
                  breakPoint < BreakPoints.Large &&
                  hideSidebar();
              }}
            >
              {children}
            </ButtonElement>
          </motion.div>
        )}
      </AnimatePresence>
    </li>
  );
};
