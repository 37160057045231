import { twMerge } from "tailwind-merge";
import RadioGroupItem from "./RadioGroupItem";
import { Label } from "./Label";

interface RadioGroupItemWithLabelProps {
  id: string;
  label: string;
  value: string;
  className?: string;
  itemClassName?: string;
  labelClassName?: string;
}

export const RadioGroupItemWithLabel = ({
  className,
  value,
  id,
  itemClassName,
  label,
  labelClassName,
}: RadioGroupItemWithLabelProps) => {
  return (
    <div
      className={twMerge(
        "flex flex-row justify-start items-center gap-3",
        className,
      )}
    >
      <RadioGroupItem id={id} value={value} className={itemClassName} />
      <Label htmlFor={id} className={labelClassName}>
        {label}
      </Label>
    </div>
  );
};
