import { SubscriptionCombobox } from "./components";

interface SubscriptionControllerProps {
  userId: string;
  currentSubscriptionId: string;
}
export const SubscriptionController = ({
  currentSubscriptionId,
  userId,
}: SubscriptionControllerProps) => {
  return (
    <div className="flex flex-row justify-between items-center gap-3 text-gray-100 border border-neutral-600 rounded-primary w-full">
      <SubscriptionCombobox
        userId={userId}
        currentSubscriptionId={currentSubscriptionId}
      />
    </div>
  );
};
