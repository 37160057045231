import { DashboardTable } from "@/components/DashboardTable";
import { useSearchQuestionsPageStore } from "../-store";
import { paginateArray } from "@/utils/common";
import { ArrayElement, TableRowContentMapper } from "@/types";
import { SearchQuestionsPageViewButton } from "./SearchQuestionsPageViewButton";

export const SearchQuestionsPageTable = () => {
  const { questions, questionsPerPage, row, currentPage, isQuestionsLoading } =
    useSearchQuestionsPageStore((state) => ({
      questions: state.questions,
      questionsPerPage: state.questionsPerPage,
      currentPage: state.currentPage,
      row: state.row,
      isQuestionsLoading: state.isQuestionsLoading,
    }));

  const columnContentMapper: TableRowContentMapper<
    ArrayElement<typeof questions>
  > = (column, content) => {
    switch (column.name) {
      case "Action": {
        //@ts-ignore
        const typedContent = content as {
          questionId: number;
          qBankEndpoint: string;
        };

        return (
          <SearchQuestionsPageViewButton
            qBankEndpoint={typedContent.qBankEndpoint}
            questionId={typedContent.questionId}
          />
        );
      }
    }
    return content;
  };

  return (
    <DashboardTable
      items={questions}
      currentPage={currentPage}
      itemsPerPage={questionsPerPage}
      columns={row}
      isLoading={isQuestionsLoading}
      columnContentMapper={columnContentMapper}
    />
  );
};
