import { PropsWithClassName } from "@/types";
import { cn } from "@/lib/utils";
import { PropsWithChildren } from "react";

type HollowSolidTransitionRootProps = PropsWithChildren & PropsWithClassName;
const HollowSolidTransitionRoot = ({
  children,
  className,
}: HollowSolidTransitionRootProps) => {
  return (
    <div
      className={cn(
        "relative inline-flex flex-row justify-center items-center group/hollow-solid-transition text-white",
        className,
      )}
    >
      {children}
    </div>
  );
};

export default HollowSolidTransitionRoot;
