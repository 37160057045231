import { cn } from "@/lib/utils";
import { PropsWithClassName } from "@coursology/types";
import { ComponentProps, PropsWithChildren } from "react";

interface NbmeHighlightMarkerMenuButton
  extends PropsWithChildren,
    PropsWithClassName,
    ComponentProps<"button"> {
  onClick?: () => void;
}

export const NbmeHighlightMarkerMenuButton = ({
  onClick,
  children,
  className,
  ...props
}: NbmeHighlightMarkerMenuButton) => {
  const handleClick = () => {
    onClick?.();
  };

  return (
    <button
      onClick={handleClick}
      className={cn(
        "flex flex-row justify-start items-center gap-2 outline-none group bg-nbme-primary-700 warm:bg-brown-700 hover:bg-nbme-primary-800 warm:hover:bg-brown-800 transition-colors w-full text-center p-2 dark:bg-nbme-primary-dark-800 dark:hover:bg-nbme-primary-dark-700 overflow-x-hidden",
        className,
      )}
      {...props}
    >
      {children}
    </button>
  );
};
