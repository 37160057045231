export const formatPerformanceSelectItems = (options: string[]) => {
  switch (options.length) {
    case 0:
      return "None";
    case 1:
      return `${options[0]} Only`;
    case 2:
      return [...options].sort().join(", ");
    case 3:
      return [...options].sort().join(", ");
    case options.length:
      return "All";
    default:
      return "All";
  }
};
