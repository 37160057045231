import React from "react";
import {
  NbmeCalculatorButton,
  NbmeCalculatorButtonProps,
} from "./NbmeCalculatorButton";
import { twMerge } from "tailwind-merge";

interface NbmeCalculatorPrimaryButtonProps extends NbmeCalculatorButtonProps {}

export const NbmeCalculatorPrimaryButton = ({
  children,
  className,
  ...props
}: NbmeCalculatorPrimaryButtonProps) => {
  return (
    <NbmeCalculatorButton
      className={twMerge(
        "bg-amber-500 dark:bg-amber-600 border-0 text-white transition-colors",
        className,
      )}
      {...props}
    >
      {children}
    </NbmeCalculatorButton>
  );
};
