import ArrayMapUtils from "@/types/common/ArrayConvertableMap";
import { TestBlueprintSliceCreator, testBlueprintSliceState } from ".";

const createTestBlueprintSlice: TestBlueprintSliceCreator = (set, get) => {
  return {
    ...testBlueprintSliceState,
    addTestBlueprint: (testBlueprint) =>
      set((state) => {
        ArrayMapUtils.push(state.testBlueprints, [testBlueprint]);
      }),
    addTestBlueprints: (blueprints) => {
      const state = get();
      for (const blueprint of blueprints) {
        state.addTestBlueprint(blueprint);
      }
    },
    setBlueprintUseStatus: ({ blueprintId, used }) => {
      set((state) => {
        const testBlueprint = ArrayMapUtils.find(
          state.testBlueprints,
          blueprintId,
        );
        if (!testBlueprint) return;
        ArrayMapUtils.update(state.testBlueprints, {
          updater: (bp) => ({ ...bp, used }),
          ids: [blueprintId],
        });
      });
    },
    useBlueprint: (args) => {
      const state = get();
      state.setBlueprintUseStatus({ ...args, used: true });
    },
    unuseBlueprint: (args) => {
      const state = get();
      state.setBlueprintUseStatus({ ...args, used: false });
    },
  };
};

export default createTestBlueprintSlice;
