import { QuestionBank } from "@/models";
import { StateCreator } from "zustand";
import { SliceModifiers } from "@/store/types";
import createQuestionBankSlice from "./create-question-bank-slice";
import { ArrayMap } from "@/types/common/ArrayConvertableMap";

export type QuestionBankSliceActions = {
  addQuestionBank: (QuestionBank: QuestionBank) => void;
  addQuestionBanks: (QuestionBanks: QuestionBank[]) => void;
};
export type QuestionBankSliceState = typeof questionBankSliceState;

export type QuestionBankSlice = QuestionBankSliceState &
  QuestionBankSliceActions;

export type QuestionBankSliceCreator = StateCreator<
  QuestionBankSlice,
  [["zustand/immer", never]],
  [],
  QuestionBankSlice
>;

export type QuestionBankSliceModifiers =
  SliceModifiers<QuestionBankSliceCreator>;

export type QuestionBankSliceAction = (
  ...modifiers: QuestionBankSliceModifiers
) => void;

export const questionBankSliceState = {
  questionBanks: {} as ArrayMap<QuestionBank>,
};

export default createQuestionBankSlice;
