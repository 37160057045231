import createQuestionExplanationSlice from "./create-questionexplanation-slice";
import { QuestionExplanation } from "@/models";
import { StateCreator } from "zustand";
import { SliceModifiers } from "@/store/types";
import { ArrayMap } from "@/types/common/ArrayConvertableMap";

export type QuestionExplanationSliceActions = {
  addQuestionExplanation: (questionExplanation: QuestionExplanation) => void;
  addQuestionExplanations: (
    questionExplanations: QuestionExplanation[],
  ) => void;
};
export type QuestionExplanationSliceState =
  typeof questionExplanationSliceState;

export type QuestionExplanationSlice = QuestionExplanationSliceState &
  QuestionExplanationSliceActions;

export type QuestionExplanationSliceCreator = StateCreator<
  QuestionExplanationSlice,
  [["zustand/immer", never]],
  [],
  QuestionExplanationSlice
>;

export type QuestionExplanationSliceModifiers =
  SliceModifiers<QuestionExplanationSliceCreator>;

export type QuestionExplanationSliceAction = (
  ...modifiers: QuestionExplanationSliceModifiers
) => void;

export const questionExplanationSliceState = {
  questionExplanations: {} as ArrayMap<QuestionExplanation>,
};

export default createQuestionExplanationSlice;
