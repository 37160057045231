"use client";
import { PropsWithChildren } from "react";
import { twMerge } from "tailwind-merge";

interface NbmeNavbarBaseProps extends PropsWithChildren {
  className?: string;
}

export const NbmeNavbarBase = ({
  children,
  className,
}: NbmeNavbarBaseProps) => {
  return (
    <nav
      className={twMerge(
        "flex flex-row items-center justify-between min-w-full bg-nbme-primary-700 py-0.5 px-2 z-[900]",
        "dark:bg-nbme-primary-dark-800 transition-colors warm:bg-brown-800",
        className,
      )}
    >
      {children}
    </nav>
  );
};
