import { QuestionNote } from "@/api/types";
import { AppRouter } from "@/api/routers/app";
import { inferProcedureOutput } from "@trpc/server";
import { trpcProxyClient } from "@/utils/trpc";
import { toast } from "sonner";

import { create } from "zustand";
import { immer } from "zustand/middleware/immer";

interface QuestionPageState {
  question:
    | (Omit<
        inferProcedureOutput<AppRouter["question"]["getQuestionById"]>,
        "markedBy" | "notes"
      > & {
        marked: boolean;
        note: QuestionNote | undefined;
      })
    | undefined;
}

interface QuestionPageActions {
  loadQuestion: (
    question: inferProcedureOutput<AppRouter["question"]["getQuestionById"]>,
  ) => void;
  setMark: (v: boolean) => void;
}

type QuestionPageStore = QuestionPageState & QuestionPageActions;

export const useQuestionPageStore = create(
  immer<QuestionPageStore>((set) => {
    return {
      question: undefined,
      loadQuestion: (question) =>
        set((state) => {
          state.question = {
            ...question,
            marked: question.markedBy.length > 0,
            note: question.notes[0],
          };
        }),
      setMark: (v) => {
        const question = useQuestionPageStore.getState().question;
        if (!question) return;
        set((state) => {
          if (!state.question) return;
          state.question.marked = v;
        });
        trpcProxyClient.question.markQuestion
          .mutate({
            questionId: question.id,
            marked: v,
          })
          .then(() => {
            toast.success(
              `Question ${v ? "marked" : "unmarked"} successfully...`,
            );
          })
          .catch(() => {
            toast.error(
              `Could not ${v ? "mark" : "unmark"} question on the server...`,
            );
            set((state) => {
              if (!state.question) return;
              state.question.marked = question.marked;
            });
          });
      },
    };
  }),
);
