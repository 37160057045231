import { Combobox } from "@/components/Combobox";
import { useChangeSubscription, useSubscriptions } from "./hooks";
import { findSubscription } from "./utils";
import { useState } from "react";
interface SubscriptionComboboxProps {
  userId: string;
  currentSubscriptionId: string;
}

export const SubscriptionCombobox = ({
  currentSubscriptionId,
  userId,
}: SubscriptionComboboxProps) => {
  const changeSubscription = useChangeSubscription();
  const subscriptions = useSubscriptions();
  const subscription = findSubscription(currentSubscriptionId, subscriptions);
  const [query, setQuery] = useState<string>("");

  const filteredSubscriptions =
    query === ""
      ? subscriptions
      : subscriptions.filter((subscription) =>
          subscription.name.toLowerCase().includes(query.toLowerCase()),
        );

  return (
    <Combobox
      itemFields={{ name: "name", key: "name" }}
      onChange={(newSubscription) => {
        changeSubscription({
          userId,
          newSubscriptionId: (newSubscription as typeof subscription)!.id,
        });
      }}
      onInputChange={(e) => setQuery(e.target.value)}
      value={subscription}
      displayValue={(sub) => sub?.name ?? "None"}
      items={filteredSubscriptions}
    />
  );
};
