import { PropsWithClassName } from "@/types";
import React, { MouseEventHandler, PropsWithChildren } from "react";
import { twMerge } from "tailwind-merge";

export interface SelectItemProps extends PropsWithChildren, PropsWithClassName {
  onClick?: MouseEventHandler<HTMLDivElement>;
  tabIndex?: number;
}

export const SelectItem = ({
  children,
  className,
  onClick,
  tabIndex,
}: SelectItemProps) => {
  return (
    <div
      className={twMerge(
        "p-2 px-3 w-full hover:bg-gray-100 dark:hover:bg-neutral-700 transition-colors",
        className,
      )}
      onClick={onClick}
      tabIndex={tabIndex}
    >
      {children}
    </div>
  );
};
