import { PropsWithClassName } from "@coursology/types";
import { motion, Variants } from "framer-motion";
import React, { PropsWithChildren } from "react";

export const BlurInOut = React.forwardRef<HTMLDivElement, PropsWithChildren & PropsWithClassName & { state?: "visible" | "hidden", delay?: number }>(({ children, className, state, delay }, ref) => {
  const variants1: Variants = {
    hidden: { filter: "blur(10px)", opacity: 0, y: '-10px', pointerEvents: "none" },
    visible: { filter: "blur(0px)", opacity: 1, y: '0px', pointerEvents: 'auto' },
  };
  return (
    <motion.div
      ref={ref}
      initial="hidden"
      animate={state}
      exit={'hidden'}
      transition={{ duration: 0.5, staggerChildren: 0.1, delay }}
      variants={variants1}
      className={className}
    >
      {children}
    </motion.div>
  );
});
