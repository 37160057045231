import { Button } from "./Button";
import useSignOut from "@/hooks/auth-hooks/use-sign-out";
import { useNavigate } from "@tanstack/react-router";

interface AdminNavbarProps {
  user: string;
}

export const AdminNavbar = ({ user }: AdminNavbarProps) => {
  const navigate = useNavigate();
  const signOut = useSignOut();
  return (
    <nav className="sticky top-0 bg-neutral-800 bg-opacity-50 backdrop-blur w-full flex flex-row justify-between items-center px-5 py-2 border-b border-neutral-700 z-[999]">
      <div className="">
        <img
          className="w-32"
          src={"/coursology_logo_admin.svg"}
          alt="coursology logo"
        />
      </div>
      {
        // <div className="flex flex-row justify-between gap-10 text-black">
        //   <div
        //     className={twMerge("rounded-full bg-white font-semibold px-4 py-2")}
        //   >
        //     <p>QBanks</p>
        //   </div>
        //   <div
        //     className={twMerge("rounded-full bg-white font-semibold px-4 py-2")}
        //   >
        //     <p>Subscriptions</p>
        //   </div>
        //   <div
        //     className={twMerge("rounded-full bg-white font-semibold px-4 py-2")}
        //   >
        //     <p>Users</p>
        //   </div>
        // </div>
      }
      <div className="flex flex-row justify-center items-center gap-3 w-[25%]">
        <p className="font-bold text-white w-full text-md">{user}</p>
        <Button className="text-md" onClick={() => signOut()}>
          Sign Out
        </Button>
        <Button
          onClick={() => {
            navigate({ to: "/home" });
          }}
          className="dark:bg-primary-500 dark:hover:bg-primary-700"
        >
          Go Home
        </Button>
      </div>
    </nav>
  );
};
