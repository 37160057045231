"use client";
import React, { useState } from "react";
import { NbmeNavbarBase } from "../NbmeNavbarBase";
import { NbmeNotebookButton } from "../NbmeNotebookButton";
import { NbmeFlashcardsButton } from "../NbmeFlashcardsButton";
import { NbmeFeedbackButton } from "../NbmeFeedbackButton";
import { NbmeSuspendButton } from "../NbmeSuspendButton";
import { NbmeEndTestButton } from "../NbmeEndTestButton";
import { twMerge } from "tailwind-merge";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { BreakPoints } from "@/types";
import { useBreakPoint } from "@/utils/hooks/useBreakpoints";
import { EditTestModeButton } from "./components";
import useTestPageTest from "@/routes/tests/$testId/-test-page-hooks/use-test-page-test";
import TestTimer from "./components/test-timer";

export const NbmeBottomNavbar = () => {
  const { data: test } = useTestPageTest();
  const timed = test?.mode.includes("timed");
  const tutor = test?.mode.includes("tutor");
  const [buttonsVisible, setButtonsVisible] = useState<boolean>(false);
  const breakPoint = useBreakPoint();

  return (
    <NbmeNavbarBase className="flex-[5] min-h-[60px] z-[900] md:z-[900] lg:z-[900]">
      <div className="flex flex-col justify-center items-start font-bold md:text-sm text-xs text-white gap-1">
        <div className="flex flex-row justify-between items-center gap-2">
          {!test?.submitted && <EditTestModeButton />}
          <div className="flex flex-col justify-center items-start font-bold md:text-sm text-xs text-white gap-1">
            {test?.submitted &&
              <p>Test ID: {test.id}</p>}
            <div className="flex flex-row justify-start items-center gap-1">
              {test?.submitted &&
                <p>REVIEW -</p>
              }
              <TestTimer key="test-timer" testId={test?.id ?? 0} />
            </div>
            {!test?.submitted &&
              <p className="text-xs">
                {tutor ? "TUTOR" : "UNTUTORED"}
                {timed && ",TIMED"}
              </p>
            }
          </div>
        </div>
      </div>
      <div
        className={twMerge(
          "flex flex-col-reverse md:flex-row justify-start md:justify-center items-center gap-2 md:relative md:right-auto md:bottom-auto fixed right-0 bottom-3  z-[999] min-w-[5.7rem] md:min-w-0",
        )}
      >
        <button
          type="button"
          className="text-nbme-primary-500 dark:text-nbme-primary-dark-900 bg-white px-2 py-1 rounded-full md:hidden"
          onClick={() => setButtonsVisible((prev) => !prev)}
        >
          <FontAwesomeIcon
            icon={faChevronUp}
            className={twMerge(
              "transition-transform",
              buttonsVisible && "rotate-180",
            )}
          />
        </button>
        <div
          className={twMerge(
            "flex flex-col md:flex-row justify-start md:justify-center items-center gap-2 bg-nbme-primary-700 warm:bg-brown-800 dark:bg-nbme-primary-dark-800 rounded-t-primary overflow-hidden transition-colors",
            !buttonsVisible &&
            breakPoint < BreakPoints.Medium &&
            "hidden bg-transparent pointer-events-none",
          )}
        >
          <NbmeNotebookButton />
          <NbmeFlashcardsButton />
          <NbmeFeedbackButton />
          <NbmeSuspendButton />
          <NbmeEndTestButton />
        </div>
      </div>
    </NbmeNavbarBase>
  );
};
