import { usePreviousTestsPageStore } from "@/utils/stores/previousTestsPageStore";
import React, { MouseEventHandler } from "react";
import { DateTime } from "luxon";
import { titlize } from "@/utils/common/titlize";
import { TableRowContentMapper } from "@/types";
import { PreviousTestsPageTestName } from "../PreviousTestsPageTestName";
import { TestWithArticles } from "@/models/test-model/test-model-types";
import { Badge } from "@/components/Badge";
import { PreviousTestsActionRow } from "@/components/PreviousTestsActionRow";
import { DashboardLink } from "@/components/DashboardLink";
import row from "./row";
import TestSubjects from "../previous-tests-table/test-subjects";
import TestSystems from "../previous-tests-table/test-systems";
import { Skeleton } from "@/components/ui/skeleton";
import TestScoreBadge from "@/components/TestScoreBadge";

interface PreviousTestMobileProps {
  testId: number;
  onTestRenameClick: (testId: number) => void;
  testName: string;
  qBankEndpoint: string;
  questionIds: number[];
  test: TestWithArticles;
}

const PreviousTestMobile = ({
  testId,
  onTestRenameClick,
  qBankEndpoint,
  testName,
  test,
}: PreviousTestMobileProps) => {
  if (!test) return <></>;

  const startedAt = DateTime.fromJSDate(new Date(test?.startedAt || 0));
  const mapColumnContent: TableRowContentMapper<typeof test> = (
    column,
    content,
  ) => {
    const startedAt =
      column.name === "Date" &&
      DateTime.fromJSDate(new Date((content as Date) || 0));

    switch (column.name) {
      case "Score":
        return (
          <Badge className="lg:w-12 lg:h-12 md:w-10 md:h-10 p-0">
            {content as string}%
          </Badge>
        );
      case "Name":
        return (
          <PreviousTestsPageTestName
            onRenameButtonClick={onTestRenameClick}
            testName={content.name}
            testId={content.id}
          />
        );
      case "Date":
        return (
          <td>
            {DateTime.fromJSDate(new Date(content as Date)).toFormat(
              "dd MMMM,yyyy",
            )}
          </td>
        );
      case "Mode":
        return (
          <td>
            {test?.mode &&
              test.mode
                .split(",")
                .map((m) => titlize(m))
                .join(", ")}
          </td>
        );
      case "Q. Pool":
        return (
          <td>
            {test?.pool
              .split(",")
              .map((m) => titlize(m))
              .join(", ")}
          </td>
        );
      case "Subjects":
        return (
          <TestSubjects testId={test.id} questionBankId={test.questionBankId} />
        );
      case "Systems":
        return (
          <TestSystems testId={test.id} questionBankId={test.questionBankId} />
        );
      case "# Qs":
        return <td>{test && test.slots.length}</td>;
      case "Actions":
        return (
          <td>
            <PreviousTestsActionRow
              testName={content.name as string}
              qBankEndpoint={qBankEndpoint}
              testId={content.id as number}
              isTestSubmitted={content.submitted}
              questionIds={content.questions}
            />
          </td>
        );
    }
  };
  return (
    <div className="flex flex-col justify-start items-center w-full bg-gray-50 dark:bg-neutral-900 rounded-primary border border-gray-300 dark:border-neutral-700 dark:text-neutral-300 transition-colors">
      <div className="flex justify-between items-center w-full border-b border-b-gray-300 dark:border-b-neutral-700 dark:bg-neutral-800 rounded-t-primary px-3 py-2 transition-colors">
        {!test?.submitted ? (
          <DashboardLink
            href={`/tests/${test?.id}`}
            className="dark:text-neutral-200 dark:hover:text-neutral-100"
          >
            Resume
          </DashboardLink>
        ) : (
          <TestScoreBadge score={test.score} isTestSubmitted={test.submitted} />
        )}
        <PreviousTestsPageTestName
          onRenameButtonClick={onTestRenameClick}
          testName={test?.name}
          testId={test?.id}
        />
        <PreviousTestsActionRow
          questionIds={test.questions}
          className="text-sm gap-3"
          testName={testName}
          qBankEndpoint={qBankEndpoint}
          testId={testId}
          isTestSubmitted={test?.submitted ?? false}
        />
      </div>
      <div className="flex flex-col justify-start items-center w-full p-3">
        <table className="w-full text-gray-700 dark:text-neutral-200 text-sm transition-colors">
          <tbody>
            {row.columns.map((c) =>
              !["Name", "Score"].includes(c.name) ? (
                <tr key={c.name}>
                  <td className="font-medium text-gray-800 dark:text-neutral-300 transition-colors delay-75">
                    {c.name}:
                  </td>
                  {c.content && mapColumnContent(c, c.content(test))}
                </tr>
              ) : undefined,
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PreviousTestMobile;
