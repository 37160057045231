import * as RadixRadioGroup from "@radix-ui/react-radio-group";
import { CSSProperties, PropsWithChildren } from "react";
import { twMerge } from "tailwind-merge";

interface RadioGroupProps extends PropsWithChildren {
  className?: string;
  onValueChange: (value: string) => void;
  value: string;
  orientation?: "vertical" | "horizontal";
  ariaLabel: string;
  disabled?: boolean;
  style?: CSSProperties;
}

const RadioGroup = ({
  className,
  children,
  orientation,
  ariaLabel,
  onValueChange,
  value,
  disabled,
  style,
}: RadioGroupProps) => (
  <RadixRadioGroup.Root
    style={style}
    value={value}
    onValueChange={onValueChange}
    disabled={disabled}
    className={twMerge(
      "flex justify-start items-start gap-2.5",
      orientation === "vertical" && "flex-col",
      orientation === "horizontal" && "flex-row",
      className,
    )}
    aria-label={ariaLabel}
  >
    {children}
  </RadixRadioGroup.Root>
);

export default RadioGroup;
