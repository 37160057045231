import { cn } from '@/lib/utils'
import { motion } from 'framer-motion'
import React, { ComponentProps } from 'react'

const ShinyButton = React.forwardRef<HTMLButtonElement, ComponentProps<'button'>>(({ children, onClick, disabled }, ref) => {
  return (
    <motion.button
      ref={ref}
      className={cn("inline-flex overflow-hidden rounded-lg bg-[linear-gradient(120deg,#4054a4_calc(var(--shimmer-button-x)-25%),#f6f6f6_var(--shimmer-button-x),#4054a4_calc(var(--shimmer-button-x)+25%))] dark:bg-[linear-gradient(120deg,#3f3f46_calc(var(--shimmer-button-x)-25%),#fff0f6_var(--shimmer-button-x),#3f3f46_calc(var(--shimmer-button-x)+25%))] warm:bg-[linear-gradient(120deg,#513e32_calc(var(--shimmer-button-x)-25%),#fff0f6_var(--shimmer-button-x),#513e32_calc(var(--shimmer-button-x)+25%))] [--shimmer-button-x:0%]", disabled && 'opacity-30 pointer-events-none')}
      initial={
        {
          scale: 1,
          //@ts-expect-error custom property
          '--shimmer-button-x': '-100%',
        }
      }
      animate={
        {
          //@ts-expect-error custom property
          '--shimmer-button-x': disabled ? '-100%' : '200%',
        }
      }
      transition={{
        stiffness: 300,
        damping: 20,
        type: 'spring',
        '--shimmer-button-x': {
          duration: 3,
          repeat: Infinity,
          repeatType: "mirror",
          ease: [0.445, 0.05, 0.55, 0.95],
        },
      }}
      whileTap={{
        scale: 0.95,
      }}
      whileHover={{
        scale: 1.05,
      }}
      onClick={onClick}
      disabled={disabled}
    >
      <span className="m-[0.125rem] font-semibold rounded-[calc(0.5rem-0.125rem)] bg-nbme-primary-600 warm:bg-brown-800 dark:bg-nbme-primary-dark-800 px-4 py-1 text-sm text-[#f6f6f6] backdrop-blur-sm">
        {children}
      </span>
    </motion.button>
  )
})

export default ShinyButton
