import { QuestionBankIdentifier } from "@/types/question-bank-types";
import { Skeleton } from "@/components/ui/skeleton";
import useQuestionBankTestSystems from "@/hooks/system-hooks/use-question-bank-test-systems";
import ArrayMapUtils from "@/types/common/ArrayConvertableMap";
import { Test } from "@/models/test-model";
import { useMemo } from "react";

type SystemProps = { testId: Test["id"] } & QuestionBankIdentifier;
const TestSystems = (args: SystemProps) => {
  const { data: systemsArrayMap, isLoading } = useQuestionBankTestSystems(args);
  const systems = useMemo(
    () => ArrayMapUtils.toArray(systemsArrayMap),
    [systemsArrayMap],
  );
  return (
    <>
      {isLoading && <Skeleton className="w-20 h-5 rounded-full" />}
      {!isLoading && (
        <span>
          {systems.length > 1 ? "Multiple" : (systems[0]?.name ?? "Not Found")}
        </span>
      )}
    </>
  );
};

export default TestSystems;
