import { toast } from "sonner";
import { Action, Args } from "./types";
import { findTarget } from "./utils";
import { renewSubscriptionMutation } from "./mutations";

export const renewSubscription: Action =
  ([set, get]) =>
  (args: Args) => {
    const { userId } = args;

    const oldUsers = get().allUsers;
    const oldFilteredUsers = get().filteredUsers;
    const targetUser = findTarget(userId, oldUsers);
    if (!targetUser) {
      toast.error("This user does not exist.");
      return;
    }
    set((state) => {
      findTarget(targetUser.id, state.allUsers)!.lastRenewal = new Date();
      const targetInFilteredUsers = findTarget(
        targetUser.id,
        state.filteredUsers,
      );
      if (targetInFilteredUsers) {
        targetInFilteredUsers.lastRenewal = new Date();
      }
    });
    renewSubscriptionMutation(args)
      .catch((error) => {
        toast.error(
          `Could not renew user subscription, an error occurred: ${error.message}`,
        );
        set((state) => {
          state.allUsers = oldUsers;
          state.filteredUsers = oldFilteredUsers;
        });
      })
      .then(() => {
        toast.success("Successfully renewd subscription for user");
      });
  };
