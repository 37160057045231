import ArrayMapUtils from "@/types/common/ArrayConvertableMap";
import useQuestionBankStatRecords from "./use-questionbank-statrecords";
import { StatRecord } from "@/models";
import useCurrentUser from "../auth-hooks/use-current-user";

const useAggregateQuestionBankStatRecord = ({
  questionBankId,
}: {
  questionBankId: string;
}) => {
  const currentUser = useCurrentUser();
  const query = useQuestionBankStatRecords({ questionBankId });
  const { data: statRecords } = query;
  let aggregateStatRecord: Omit<
    StatRecord,
    "id" | "systemId" | "topicId" | "subjectId" | "createdAt"
  > = {
    questionBankId,
    userId: currentUser.id,
    testsCreated: 0,
    testsSubmitted: 0,
    correctToIncorrect: 0,
    incorrectToCorrect: 0,
    incorrectToIncorrect: 0,
  };
  ArrayMapUtils.forEach(statRecords, (sr) => {
    for (const key of Object.keys(sr)) {
      if (typeof sr[key] === "number") {
        aggregateStatRecord[key] += sr[key];
      }
    }
  });
  return { ...query, data: aggregateStatRecord };
};

export default useAggregateQuestionBankStatRecord;
