import { DashboardLink } from "@/components/DashboardLink";

interface SearchQuestionsPageViewButtonProps {
  questionId: number;
  qBankEndpoint: string;
}

export const SearchQuestionsPageViewButton = ({
  questionId,
  qBankEndpoint,
}: SearchQuestionsPageViewButtonProps) => {
  return (
    <DashboardLink href={`/qbanks/${qBankEndpoint}/questions/${questionId}`}>
      View
    </DashboardLink>
  );
};
