import ArrayMapUtils from "@/types/common/ArrayConvertableMap";
import { QuestionSliceCreator, questionSliceState } from ".";
import { trpcProxyClient } from "@/utils/trpc";
import handleTRPCMutationSuccess from "@/utils/trpc/handle-trpc-mutation-success";
import handleTRPCMutationError from "@/utils/trpc/handle-trpc-mutation-error";
import { AnswerChange } from "@/models";

const createQuestionSlice: QuestionSliceCreator = (set, get) => {
  return {
    ...questionSliceState,
    addQuestion: (question) =>
      set((state) => {
        ArrayMapUtils.push(state.questions, [question]);
      }),
    addQuestions: (questions) => {
      const state = get();
      for (const question of questions) {
        state.addQuestion(question);
      }
    },
    addQuestionHeader: (questionHeader) =>
      set((state) => {
        ArrayMapUtils.push(state.questionHeaders, [questionHeader]);
      }),
    addQuestionHeaders: (questionHeaders) =>
      set((state) => {
        ArrayMapUtils.push(state.questionHeaders, questionHeaders);
      }),
    resetQuestionHeaders: (questionHeaderIds) =>
      set((state) => {
        ArrayMapUtils.update(state.questionHeaders, {
          ids: questionHeaderIds,
          updater: (qh) => ({ ...qh, used: false as false, marked: false }),
        });
      }),
    markQuestion: ({ questionId, marked }) => {
      set((state) => {
        ArrayMapUtils.update(state.questions, {
          ids: [questionId],
          updater: (q) => ({ ...q, marked }),
        });
        ArrayMapUtils.update(state.questionHeaders, {
          ids: [questionId],
          updater: (q) => ({ ...q, marked }),
        });
      });
      trpcProxyClient.question.markQuestion
        .mutate({ questionId, marked })
        .catch(
          handleTRPCMutationError(
            { mutationDescription: "mark question" },
            () => {
              set((state) => {
                ArrayMapUtils.update(state.questions, {
                  ids: [questionId],
                  updater: (q) => ({ ...q, marked: !marked }),
                });
                ArrayMapUtils.update(state.questionHeaders, {
                  ids: [questionId],
                  updater: (q) => ({ ...q, marked: !marked }),
                });
              });
            },
          ),
        );
    },

    setQuestionUsageStatus: ({ questionId, questionIds, status }) => {
      const state = get();
      set((state) => {
        ArrayMapUtils.update(state.questionHeaders, {
          ids: questionId ? [questionId] : questionIds,
          updater: (q) => ({ ...q, used: status }),
        });
      });
      if (!questionId) return;
      const oldQuestionState = ArrayMapUtils.find(
        state.questionHeaders,
        questionId,
      );
      if (!oldQuestionState) return;
      state.setQuestionAnswerChange({
        questionId,
        previousUsageStatus: oldQuestionState?.used,
        nextUsageStatus: status,
      });
    },
    setQuestionAnswerChange: ({
      questionId,
      nextUsageStatus,
      previousUsageStatus,
    }) => {
      set((state) => {
        ArrayMapUtils.update(state.questionHeaders, {
          ids: [questionId],
          updater: (q) => {
            const wasQuestionCorrect = previousUsageStatus === "correct";
            const wasQuestionIncorrect = previousUsageStatus === "incorrect";
            const isQuestionNowCorrect = nextUsageStatus === "correct";
            const isQuestionNowIncorrect = nextUsageStatus === "incorrect";

            const isCorrectToIncorrect =
              wasQuestionCorrect && isQuestionNowIncorrect;
            const isIncorrectToCorrect =
              wasQuestionIncorrect && isQuestionNowCorrect;
            const isIncorrectToIncorrect =
              wasQuestionIncorrect && isQuestionNowIncorrect;

            const change: AnswerChange = isCorrectToIncorrect
              ? "c2i"
              : isIncorrectToCorrect
                ? "i2c"
                : isIncorrectToIncorrect
                  ? "i2i"
                  : null;

            return { ...q, change };
          },
        });
      });
    },
  };
};

export default createQuestionSlice;
