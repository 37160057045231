"use client";
import { useCreateTestPageStore } from "@/utils/stores/createTestPageStore";
import DashboardAccordionSection from "./DashboardAccordionSection";
import { Input } from "./Input";
import QuestionCountBadge from "./QuestionCountBadge";
import { useEffect, useMemo } from "react";
import useCreateTestPageContext from "@/routes/qbanks/$qbankEndpoint/dashboard/_dashboard.create-test/-hooks/use-create-test-page-context";
import useQuestionBankQuestionHeaders from "@/hooks/question-hooks/use-question-bank-question-headers";
import ArrayMapUtils from "@/types/common/ArrayConvertableMap";

const CreateTestQNumSection = () => {
  const { questionBankEndpoint } = useCreateTestPageContext();
  const {
    toggleSection,
    toggledSections,
    questionCount,
    setQuestionCount,
    selectedSubjects,
    selectedSystems,
    selectedTopics,
    unused,
    incorrect,
    correct,
    marked,
    omitted,
  } = useCreateTestPageStore();
  const { data: questionHeaders, isLoading: isQuestionHeadersLoading } =
    useQuestionBankQuestionHeaders(
      { questionBankEndpoint },
      {
        filters: {
          stats: [
            ...(unused ? ["unused"] : []),
            ...(incorrect ? ["incorrect"] : []),
            ...(correct ? ["correct"] : []),
            ...(marked ? ["marked"] : []),
            ...(omitted ? ["omitted"] : []),
          ] as ("unused" | "correct" | "incorrect" | "marked" | "omitted")[],
          subjects: selectedSubjects.map((s) => s.id),
          systems: selectedSystems.map((s) => s.id),
          topics: selectedTopics.map((t) => t.id),
        },
      },
    );

  const progressLoading = isQuestionHeadersLoading;

  const absoluteMaxQCount = useMemo(
    () => ArrayMapUtils.getLength(questionHeaders),
    [questionHeaders],
  );

  const clampedMaxQCount = Math.min(absoluteMaxQCount, 40);

  useEffect(() => {
    if (Number(questionCount) > clampedMaxQCount) {
      setQuestionCount(clampedMaxQCount.toString());
    }
  }, [questionCount]);

  return (
    <DashboardAccordionSection
      title="No. of Questions"
      value="qnum"
      className=""
      onToggle={() => toggleSection("qnum")}
      toggled={toggledSections.includes("systems")}
    >
      <div className="ml-8 flex flex-row justify-start items-center gap-3  pb-5 pt-2">
        <div className="w-16">
          <Input
            className="text-sm md:text-sm lg:text-sm py-2 text-center rounded-sm bg-gray-200 border-gray-300 outline-gray-400 text-gray-500"
            min={0}
            max={clampedMaxQCount}
            type="number"
            value={Number(questionCount) < 0 ? "0" : questionCount}
            onChange={(e) => setQuestionCount(e.target.value)}
          />
        </div>
        <p className="flex-grow text-sm text-gray-500 dark:text-neutral-400">
          Max Allowed per block
        </p>
        <QuestionCountBadge
          className="bg-gray-200 border border-gray-300 text-gray-500 no-spinners"
          count={clampedMaxQCount}
          loading={progressLoading}
        />
      </div>
    </DashboardAccordionSection>
  );
};

export default CreateTestQNumSection;
