import { useDashboardLayoutStore } from "@/utils/stores";
import Dialog from "./Dialog";
import { Button } from "./Button";
import { InputGroup } from "./InputGroup";
import { Label } from "./Label";
import { Input } from "./Input";
import { useState } from "react";
import useAuthSlice from "@/hooks/auth-hooks/use-auth-slice";

export const DashboardResetAccountDialog = () => {
  const [password, setPassword] = useState<string>("");
  const { currentUser: user, reset } = useAuthSlice();
  const { visible, setVisible } = useDashboardLayoutStore((state) => ({
    visible: state.isResetAccountDialogVisible,
    setVisible: state.setIsResetAccountDialogVisible,
  }));

  if (!user) return <></>;

  const handleCancel = () => {
    setVisible(false);
  };

  const handleReset = () => {
    setVisible(false);
    reset(password);
  };

  if (!visible) return <></>;

  return (
    <Dialog
      title="Reset Account?"
      description="Are you sure you want to reset your account? All your data will be lost including, notes, highlights, and questions, you can only do this once for a single account."
      onOpenChange={setVisible}
      open={visible}
    >
      <div className="flex flex-col justify-start items-start w-full gap-5">
        <p className="text-red-500 font-bold rounded-primary border border-red-500 px-2 py-1 mt-3 md:text-lg">
          {"This action can't be reversed by you or us."}
        </p>
        <form className="flex flex-row justify-between items-end w-full">
          <InputGroup>
            <Label htmlFor="password-reset-confirmation-input">
              Enter your password to confirm:
            </Label>
            <Input
              id="password-reset-confirmation-input"
              className="w-auto text-base py-2"
              placeholder="Password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </InputGroup>
          <div className="flex flex-row justify-start items-center gap-2">
            <Button variant="dashboard" onClick={handleCancel}>
              Cancel
            </Button>
            <Button type="submit" error onClick={handleReset}>
              Reset
            </Button>
          </div>
        </form>
      </div>
    </Dialog>
  );
};
