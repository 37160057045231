import { createFileRoute } from "@tanstack/react-router";
import DashboardLoading from "../-loading";
import OverallStatsPage from "@/components/OverallStatsPage";
import { useTitle } from "@/hooks/common/use-title";
import useDashboardQuestionBank from "../-dashboard-hooks/use-dashboard-question-bank";

export const Route = createFileRoute(
  "/qbanks/$qbankEndpoint/dashboard/_dashboard/welcome",
)({
  component: DashboardWelcomePage,
  pendingComponent: DashboardLoading,
});

function DashboardWelcomePage() {
  const { data: questionBank } = useDashboardQuestionBank();
  useTitle(`Welcome ${questionBank?.name}`);
  return <OverallStatsPage />;
}
