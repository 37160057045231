import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { Switch } from "@/components/ui/switch";
import { TrashIcon } from "@/components/icons/hollow";
import { Button, buttonVariants } from "@/components/ui/button";
import useDeleteTest from "@/hooks/test-hooks/use-delete-test";
import { useState } from "react";

export const DeleteTestButton = ({
  testName,
  testId,
  qBankEndpoint,
}: {
  testName: string;
  testId: number;
  qBankEndpoint: string;
}) => {
  const [resetQuestions, setResetQuestions] = useState<boolean>(false);
  const deleteTest = useDeleteTest();

  return (
    <AlertDialog>
      <AlertDialogTrigger>
        <Button
          className="p-0 px-0 w-auto hover:text-primary-400"
          variant={"ghost"}
          size={"icon"}
        >
          <TrashIcon />
        </Button>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>
            Are you sure you want to delete block: {testName}
          </AlertDialogTitle>
          <AlertDialogDescription className="flex flex-col justify-start items-start w-full gap-2">
            <p className="text-red-500 underline font-semibold">
              This action cannot be undone.
            </p>
            <p>
              Unless you specify otherwise, all the questions within this block
              will remain in their current state (omitted, correct, incorrect,
              etc...), just in case they were used in other blocks.
            </p>
          </AlertDialogDescription>
          <div className="flex flex-row justify-start items-center w-full text-gray-700 dark:text-neutral-200 text-sm gap-3">
            <p>
              {
                'Reset all questions within this block to "Unused" (even if used in other blocks)?'
              }
            </p>
            <Switch onCheckedChange={setResetQuestions} />
          </div>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <AlertDialogAction
            asChild
            className={buttonVariants({ variant: "destructive" })}
          >
            <Button
              variant={"destructive"}
              onClick={() => deleteTest({ testId, resetQuestions })}
            >
              Delete
            </Button>
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
