"use client";
import * as RadixSeparator from "@radix-ui/react-separator";
import { twMerge } from "tailwind-merge";

interface SeparatorProps {
  className?: string;
  orientation?: "vertical" | "horizontal";
}

export const Separator = ({
  orientation = "vertical",
  className,
}: SeparatorProps) => {
  return (
    <RadixSeparator.Root
      className={twMerge(
        "bg-primary-400 flex-grow dark:bg-neutral-700",
        orientation === "vertical" && "w-[2px] h-full",
        orientation === "horizontal" && "h-[2px] w-full",
        className,
      )}
    />
  );
};
