"use client";
import React, { useEffect, useMemo, useRef } from "react";
import { NbmeQuestionText } from "../NbmeQuestionText";
import { twMerge } from "tailwind-merge";
import { NbmeChoiceContainer } from "../NbmeChoiceContainer";
import { NbmeChoices } from "../NbmeChoices";
import { Button } from "../Button";
import { useTestStore } from "@/utils/stores/testStore";
import { Interweave } from "interweave";
import { trpc } from "@/utils/trpc";
import { toast } from "sonner";
import { NbmeResultCard } from "../NbmeResultCard";
import {
  audioFilter,
  imageFilter,
  linkFilter,
  listFilter,
  paragraphFilter,
  tableFilter,
  uWorldImageFilter,
  vignetteFilter,
} from "@/utils/interweave/filters";
import { LoadingSpinner } from "../LoadingSpinner";
import {
  highlightTransformer,
  imageTransformer,
} from "@/utils/interweave/transformers";
import { analyzeSelection } from "@/utils/highlights/analyzeSelection";
import { scaleUnitInRem } from "@/utils/common/scaleUnitInRem";
import {
  useNbmeAlignment,
  useNbmeConfirmAnswerOmission,
  useNbmeFontSizeFactor,
  useNbmeLayoutStore,
} from "@/utils/stores/nbmeLayoutStore";
import { FontSizes } from "@/types";
import { useBreakPoint } from "@/utils/hooks/useBreakpoints";
import { useHighlightMarker } from "./hooks";
import { cn } from "@/lib/utils";
import combineInterweaveTransformers from "@/lib/utils/combine-interweave-transformers";
import useTestPageTest from "@/routes/tests/$testId/-test-page-hooks/use-test-page-test";
import useTestPageTestSelectedTestSlot from "@/routes/tests/$testId/-test-page-hooks/use-test-page-test-selected-test-slot";
import useTestPageTestSelectedTestSlotQuestion from "@/routes/tests/$testId/-test-page-hooks/use-test-page-test-selected-test-slot-questiont";
import useTestPageSubmitTestSlot from "@/routes/tests/$testId/-test-page-hooks/use-test-page-submit-test-slot";
import NbmeQuestionSkeleton from "./nbme-question-skeleton";
import useCreateHighlight from "@/hooks/highlight-hooks/use-create-highlight";
import useTestPageSelectedTestSlotQuestionHints from "@/routes/tests/$testId/-test-page-hooks/use-test-page-test-selected-testslot-question-hints";
import ArrayMapUtils from "@/types/common/ArrayConvertableMap";
import { hintTransformer } from "@/utils/interweave/transformers/hintTransformer";

export const NbmeQuestion = () => {
  const { setConfirmAnswerOmissionDialogVisible } = useNbmeLayoutStore(
    (state) => ({
      setConfirmAnswerOmissionDialogVisible:
        state.setConfirmAnswerOmissionDialogVisible,
    }),
  );
  const { confirmAnswerOmission } = useNbmeConfirmAnswerOmission();
  const { fontSizeFactor } = useNbmeFontSizeFactor();
  const breakPoint = useBreakPoint();
  const addHighlight = useCreateHighlight();
  const { data: test } = useTestPageTest();
  const mode = test?.mode;
  const { data: selectedSlot } = useTestPageTestSelectedTestSlot();
  const selectedMarker = useTestStore((state) => state.selectedHighlightMarker);
  const { data: question, isLoading: isQuestionLoading } =
    useTestPageTestSelectedTestSlotQuestion();

  const doesQuestionHaveAudio = !!question?.audioId;

  const {
    data: questionAudio,
    isSuccess: isQuestionAudioSuccess,
    isError: isQuestionAudioError,
    error: questionAudioError,
    isLoading: isQuestionAudioLoading,
  } = trpc.audio.getById.useQuery(
    { id: question?.audioId! },
    {
      enabled: doesQuestionHaveAudio,
    },
  );

  const questionTextRef = useRef<HTMLDivElement>(null);
  const highlightedHtml = useHighlightMarker(
    question?.id ?? 0,
    question?.html ?? "",
  );
  const [alignment] = useNbmeAlignment();
  const submitSelectedSlot = useTestPageSubmitTestSlot();

  const memoizedParagraphFilter = useMemo(
    () => paragraphFilter(fontSizeFactor, alignment),
    [fontSizeFactor, alignment],
  );
  const memoizedTableFilter = useMemo(
    () => tableFilter(breakPoint, fontSizeFactor),
    [breakPoint, fontSizeFactor],
  );
  const memoizedAudioFilter = useMemo(
    () => audioFilter(questionAudio?.dataUrl),
    [questionAudio?.dataUrl],
  );
  const memoizedCombinedTransformer = useMemo(
    () =>
      combineInterweaveTransformers(
        highlightTransformer,
        imageTransformer(),
        hintTransformer({ questionSource: question?.source ?? "" }),
      ),
    [
      highlightTransformer,
      imageTransformer,
      hintTransformer,
      question?.source,
    ],
  );
  const memoizedInterweaveFilters = useMemo(
    () => [
      listFilter,
      linkFilter,
      vignetteFilter,
      memoizedParagraphFilter,
      memoizedTableFilter,
    ],
    [
      listFilter,
      linkFilter,
      vignetteFilter,
      memoizedParagraphFilter,
      memoizedTableFilter,
    ],
  );
  const { data: questionHints } = useTestPageSelectedTestSlotQuestionHints();
  if (isQuestionLoading && !question) return <NbmeQuestionSkeleton />;
  return (
    <div
      className={cn(
        "flex flex-col md:flex-row justify-center md:justify-start items-center md:items-start w-full gap-4 lg:p-3 p-4",
        alignment === "center" && "md:justify-center md:items-center",
        alignment === "right" && "md:justify-end md:items-end",
      )}
      style={{
        fontSize: scaleUnitInRem(FontSizes.Base, fontSizeFactor)
          .resultInRemAsCss,
      }}
    >
      {question?.abstract && (
        <div className="flex-[50%]">
          <Interweave
            blockList={["br"]}
            content={question?.abstract}
            filters={memoizedInterweaveFilters}
          />
        </div>
      )}

      <div
        id={`${question?.id}text`}
        ref={questionTextRef}
        className={twMerge(
          "flex w-full flex-col items-center justify-start gap-4 lg:items-start ",
          alignment === "center" && "lg:items-center",
          alignment === "right" && "lg:items-end",
          question?.abstract && "flex-[50%]",
        )}
      >
        {question && question.html ? (
          <div
            id={`question-text`}
            key={`question-${question.id}-text`}
            ref={questionTextRef}
            onMouseUp={handleHighlight}
            onTouchEnd={handleHighlight}
          >
            <Interweave
              content={highlightedHtml}
              filters={memoizedInterweaveFilters}
              transform={memoizedCombinedTransformer}
            />
          </div>
        ) : (
          <NbmeQuestionText>{question?.text}</NbmeQuestionText>
        )}
        {question &&
          question.mediaHtml &&
          (doesQuestionHaveAudio ? (
            isQuestionAudioLoading ? (
              <div className="flex w-full flex-row items-center justify-center md:w-auto">
                <div className="w-10 md:w-12 lg:w-14">
                  <LoadingSpinner />
                </div>
              </div>
            ) : (
              <Interweave
                content={question.mediaHtml}
                filters={[memoizedAudioFilter]}
              />
            )
          ) : (
            <Interweave content={question.mediaHtml} />
          ))}
        <NbmeChoiceContainer>
          <NbmeChoices />
        </NbmeChoiceContainer>
        {selectedSlot?.submitted ? (
          <NbmeResultCard />
        ) : (
          mode?.split(",").includes("tutor") && (
            <Button
              NBME
              type="button"
              className="w-min"
              onClick={() => {
                if (confirmAnswerOmission && !selectedSlot?.chosenId) {
                  setConfirmAnswerOmissionDialogVisible(true);
                  return;
                }
                submitSelectedSlot();
              }}
            >
              Submit
            </Button>
          )
        )}
      </div>
    </div>
  );

  function handleHighlight() {
    const windowSelection = window.getSelection();
    const analysis = analyzeSelection(windowSelection);
    if (!analysis) return;
    if (!selectedMarker) return;
    if (!analysis.selectionText) return;
    if (analysis.selectionText === " " || analysis.selectionText.length === 0) {
      return;
    }
    //If the selection is one character long we ignore it...
    //just to be on the safe side....
    if (analysis.selectionText.length === 1) return;
    const newHighlight = {
      text: analysis.selectionText!,
      ocurrence: analysis.ocurrence,
      questionId: question?.id ?? null,
      questionExplanationId: null,
      start: analysis.startIndex,
      end: analysis.endIndex,
      markerId: selectedMarker.id ?? "",
    };

    addHighlight({ highlight: newHighlight });
    windowSelection?.removeAllRanges();
  }
};
