import { useAppStore } from "@/store";

export const usePages = () => {
  const { pages, notes } = useAppStore((state) => ({
    pages: state.pages,
    notes: state.notes,
  }));
  const recursivelyAssignChildren = (
    children: Array<any>,
    parentId: string | null,
  ): { parentId: string | null; children: Array<any>; id: string }[] => {
    if (children.length === 0) return [];
    const childrenOfChildren = children.filter(
      (note) => note.parentId === parentId,
    );

    return childrenOfChildren.map((child) => ({
      ...child,
      children: recursivelyAssignChildren(notes, child.id),
    }));
  };
  const result = pages.map((p) => ({
    ...p,
    notes: notes
      .filter((n) => n.pageId === p.id)
      .map((n) => ({
        ...n,
        children: recursivelyAssignChildren(notes, n.id),
      })),
  }));
  return result;
};
