"use client";
import * as Accordion from "@radix-ui/react-accordion";
import { PropsWithChildren } from "react";

interface AccordionItemProps extends PropsWithChildren {
  value: string;
  disabled?: boolean;
  className?: string;
  rootClassName?: string;
}

const AccordionItem = ({
  value,
  disabled,
  children,
  className,
  rootClassName,
}: AccordionItemProps) => {
  return (
    <Accordion.Item value={value} disabled={disabled} className={rootClassName}>
      <Accordion.Content className={className}>{children}</Accordion.Content>
    </Accordion.Item>
  );
};

export default AccordionItem;
