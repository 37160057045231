import { findTarget } from "@/store/utils";
import { Action } from "../../types";
import { mutation } from "./mutation";
import { toast } from "sonner";
import { useAppStore } from "@/store";

import { tagIsSavedOnServer } from "../../utils";

export const connectTagToNote: Action =
  ([set]) =>
  ({ noteId, tagId }: { noteId: string; tagId: string }) => {
    const oldTags = useAppStore.getState().tags;
    const targetTag = findTarget(tagId, oldTags);
    if (!targetTag) return;

    set((state) => {
      findTarget(tagId, state.tags)!.notes.push({ id: noteId });
    });

    tagIsSavedOnServer(targetTag) &&
      mutation({ noteId, tagId }).catch((error) => {
        toast.error(
          `Could not add tag to note, an error occurred: ${error.message}`,
        );
        set((state) => {
          state.tags = oldTags;
        });
      });
  };
