import { useAppTheme } from "@/hooks";
import { cn } from "@/lib/utils";
import {
  useNbmeFontSizeFactor,
  useNbmeHighlightSettings,
} from "@/utils/stores/nbmeLayoutStore";
import Color from "color";
import { scaleUnitInRem } from "@/utils/common/scaleUnitInRem";
import { FontSizes } from "@/types";
import useDeleteHighlight from "@/hooks/highlight-hooks/use-delete-highlight";
import useHighlight from "@/hooks/highlight-hooks/use-highlight";
import { useTriggerNbmeContextMenu } from "@/ncomponents/nbme/context-menu";

interface NbmeHighlightProps {
  text: string;
  color: string;
  id: string;
  questionId?: number;
  markerId: string;
  explanationId?: string;
  annotation?: string;
}

export const NbmeHighlight = ({
  text,
  color,
  id,
  markerId,
  questionId,
  explanationId: questionExplanationId,
  annotation,
}: NbmeHighlightProps) => {
  const [theme] = useAppTheme();
  const [settings] = useNbmeHighlightSettings();
  const deleteHighlight = useDeleteHighlight();
  const { fontSizeFactor } = useNbmeFontSizeFactor();
  const darkMode = theme === "dark";
  const highlight = useHighlight(id);

  let programmaticColor: Color;
  try {
    programmaticColor = Color(color);
  } catch {
    programmaticColor = Color("black");
  }
  const backgroundTextContrast = Color("black").contrast(programmaticColor);

  const textColor =
    backgroundTextContrast <= 10 ? Color("white") : Color("black");

  if (darkMode) programmaticColor = programmaticColor.desaturate(0.2);

  const lighterVersionColor = Color(
    `${programmaticColor.rgb().string().slice(0, -1)} / ${settings?.shineIntensity ?? 30
    }%)`,
  )
    .rgb()
    .string();

  const shadowColor = Color(
    `${Color("black").rgb().string().slice(0, -1)} / ${settings?.shadowIntensity ?? 50
    }%)`,
  ).rgb().string();
  const trigger = useTriggerNbmeContextMenu();

  return (
    <mark
      style={{
        backgroundColor: programmaticColor.rgb().string(),
        ...(darkMode && settings?.shine
          ? { boxShadow: `0px 0px 20px ${lighterVersionColor}` }
          : {}),
        ...(!darkMode && settings?.shadow
          ? {
            boxShadow: `3px 3px 10px ${shadowColor}`,
          }
          : {}),
        color: textColor.toString(),
      }}
      className={cn(
        "!inline cursor-pointer duration-300 rounded-sm transition-none",
      )}
      onClick={(e) => {
        e.preventDefault()
        trigger([{ type: "change-highlight-marker", currentHighlightMarkerId: markerId, highlightId: id }, { type: "copy-to-clipboard", textToCopy: highlight?.text }, { type: "delete-highlight", highlightId: id }])

      }}
    >
      <span
        style={{
          ...((settings?.bold ?? true)
            ? { textShadow: `0px 0px 0.9px ${textColor.toString()}` }
            : {}),
          fontSize: scaleUnitInRem(FontSizes.Base, fontSizeFactor)
            .resultInRemAsCss,
        }}
      >
        {text}
      </span>
    </mark>
  );
};
