import SubjectCheckbox from "./SubjectCheckbox";
import useCreateTestPageContext from "@/routes/qbanks/$qbankEndpoint/dashboard/_dashboard.create-test/-hooks/use-create-test-page-context";
import ArrayMapUtils from "@/types/common/ArrayConvertableMap";
import useGetSubjectQuestionHeaders from "@/hooks/question-hooks/use-get-subject-question-headers";
import useCreateTestPageSubjects from "@/routes/qbanks/$qbankEndpoint/dashboard/_dashboard.create-test/-hooks/use-create-test-page-subjects";
import useCreateTestPageQuestionHeaders from "@/routes/qbanks/$qbankEndpoint/dashboard/_dashboard.create-test/-hooks/use-create-test-page-questions";
import { useMemo } from "react";
import splitArray from "@/lib/utils/split-array";
import { useOnDesktop, useOnMediumScreen, useOnMobile } from "@/hooks";

const CreateTestSubjects = () => {
  const { questionBankEndpoint } = useCreateTestPageContext();
  const { data: subjectsArrayMap } = useCreateTestPageSubjects();
  const subjects = useMemo(
    () => ArrayMapUtils.toArray(subjectsArrayMap),
    [subjectsArrayMap],
  );
  const { data: questionHeaders, isLoading: progressLoading } =
    useCreateTestPageQuestionHeaders();
  const { data: getSubjectQuestionHeaders } = useGetSubjectQuestionHeaders(
    {
      questionBankEndpoint,
    },
    {
      filters: {
        questionHeaders: questionHeaders,
      },
    },
  );
  const onMediumScreen = useOnMediumScreen();
  const onLargeScreen = useOnDesktop();
  const onSmallScreen = useOnMobile();

  //Sorts array alphabetically
  const orderedSubjects = subjects.sort((a, b) => a.name.localeCompare(b.name));
  const subjectGroupCount = onLargeScreen ? 2 : onMediumScreen ? 1 : 1;
  const subjectGroups = splitArray(orderedSubjects, subjectGroupCount);

  return (
    <div className="flex flex-col md:flex-row gap-1 justify-start items-start lg:gap-8">
      {subjectGroups.map((sg, index) => {
        return (
          <div
            key={index}
            className="flex flex-col justify-start items-center lg:items-start gap-y-2 w-full"
          >
            {sg &&
              sg.map((s) => (
                <SubjectCheckbox
                  key={s.id}
                  subject={s}
                  questionCount={ArrayMapUtils.getLength(
                    getSubjectQuestionHeaders(s.id),
                  )}
                  loading={progressLoading}
                />
              ))}
          </div>
        );
      })}
    </div>
  );
};

export default CreateTestSubjects;
