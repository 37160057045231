import { NbmeHintTrigger } from "@/components/NbmeHintTrigger";
import { TransformCallback } from "interweave";
export const hintTransformer =
  ({ questionSource }: { questionSource: string }): TransformCallback =>
  (node: HTMLElement) => {
    if (questionSource !== "medicospira") return;
    const isHint = node.tagName === "A" && node.getAttribute("href");
    const id = node.getAttribute("data-id") ?? "";
    const text = node.textContent ?? "";
    if (!isHint) return;

    return <NbmeHintTrigger hint={{ text, id }} key={id} />;
  };
