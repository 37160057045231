import Dialog from "@/components/Dialog";
import React, { useEffect, useState } from "react";
import { Button } from "@/components/Button";
import { Input } from "@/components/Input";
import { DialogProps } from "@radix-ui/react-dialog";
import { Setter } from "@/types";
interface PreviousTestsPageTestRenameDialogProps extends DialogProps {
  testName: string;
  onTestNameChange: Setter<string>;
}

export const PreviousTestsPageTestRenameDialog = ({
  testName,
  open,
  onOpenChange,
  onTestNameChange,
}: PreviousTestsPageTestRenameDialogProps) => {
  const [draft, setDraft] = useState<string>("");
  useEffect(() => {
    setDraft(testName);
  }, [testName]);
  return (
    <Dialog
      open={open}
      onOpenChange={onOpenChange}
      title={`Rename Test ${testName}`}
      description={`What do you want to rename test: "${testName}" to?`}
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onTestNameChange(draft);
        }}
        className="w-full flex flex-col justify-start items-end gap-3"
      >
        <Input value={draft} onChange={(e) => setDraft(e.target.value)} />
        <div className="w-[50%] flex flex-row justify-end items-end gap-4">
          <Button variant="dashboard" onClick={() => onOpenChange?.(false)}>
            Cancel
          </Button>
          <Button type="submit">Rename</Button>
        </div>
      </form>
    </Dialog>
  );
};
