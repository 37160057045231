import useTestPageTestSelectedTestSlot from "./use-test-page-test-selected-test-slot";

const useTestPageSelectedTestSlotQuestionChoices = () => {
  const selectedTestSlotQuery = useTestPageTestSelectedTestSlot();
  const { data: selectedTestSlot } = selectedTestSlotQuery;
  return {
    ...selectedTestSlotQuery,
    data: selectedTestSlot?.question.choices,
  };
};

export default useTestPageSelectedTestSlotQuestionChoices;
