import ArrayMapUtils from "@/types/common/ArrayConvertableMap";
import { StatRecordSliceCreator, statRecordSliceState } from ".";
import dates from "@/lib/dates";
import gen from "@/lib/gen";
import { StatRecord } from "@/models";

const createQuestionBankSlice: StatRecordSliceCreator = (set, get) => {
  return {
    ...statRecordSliceState,
    addStatRecord: (statRecord) =>
      set((state) => {
        ArrayMapUtils.push(state.statRecords, [statRecord]);
      }),
    addStatRecords: (statRecords) =>
      set((state) => {
        ArrayMapUtils.push(state.statRecords, statRecords);
      }),
    createNewStatRecord: ({ questionBankId, systemId, topicId, subjectId }) => {
      const state = get();
      const currentUser = state.currentUser;
      const today = dates.today();
      if (!currentUser) return;
      const newStatRecord = {
        userId: currentUser.id,
        id: gen.cuid(),
        createdAt: today,
        testsSubmitted: 0,
        incorrectToIncorrect: 0,
        incorrectToCorrect: 0,
        correctToIncorrect: 0,
        testsCreated: 0,
        questionBankId: questionBankId ?? null,
        subjectId: subjectId ?? null,
        systemId: systemId ?? null,
        topicId: topicId ?? null,
      };
      set((state) => {
        ArrayMapUtils.push(state.statRecords, [newStatRecord]);
      });
      return newStatRecord;
    },
    getTodaysStatRecord: ({ questionBankId, topicId, systemId, subjectId }) => {
      const state = get();
      const id = questionBankId ?? topicId ?? systemId ?? subjectId ?? "";
      const idKey:
        | "questionBankId"
        | "topicId"
        | "systemId"
        | "subjectId"
        | "" = questionBankId
        ? "questionBankId"
        : topicId
          ? "topicId"
          : systemId
            ? "systemId"
            : subjectId
              ? "subjectId"
              : "";
      if (!id) return;
      let result = ArrayMapUtils.find(state.statRecords, (sr) => {
        const isToday = sr.createdAt.toString() === dates.today().toString();
        const hasSameId = sr[idKey] === id;
        return isToday && hasSameId;
      });
      if (!result) {
        //@ts-ignore
        result = state.createNewStatRecord({ [idKey]: id });
      }
      return result;
    },
    recordAnswerChange: ({
      change,
      questionBankId,
      topicId,
      systemId,
      subjectId,
    }) => {
      const state = get();
      const questionBankStatRecord = state.getTodaysStatRecord({
        questionBankId,
      });
      const subjectStatRecord = state.getTodaysStatRecord({ subjectId });
      const systemStatRecord = state.getTodaysStatRecord({ systemId });
      const topicStatRecord = state.getTodaysStatRecord({ topicId });
      set((state) => {
        ArrayMapUtils.update(state.statRecords, {
          ids: [
            questionBankStatRecord?.id ?? "",
            subjectStatRecord?.id ?? "",
            systemStatRecord?.id ?? "",
            topicStatRecord?.id ?? "",
          ],
          updater: (sr) => {
            return { ...sr, [change]: sr[change] + 1 };
          },
        });
      });
    },
    resetStatRecords: () => {
      set((state) => {
        state.statRecords = {};
      });
    },
  };
};

export default createQuestionBankSlice;
