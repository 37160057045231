import useClearQuestionHighlights from "@/hooks/highlight-hooks/use-clear-question-highlights";
import useTestPageTestSelectedTestSlotQuestion from "./use-test-page-test-selected-test-slot-questiont";
import { useCallback } from "react";

const useTestPageClearQuestionHighlights = () => {
  const clearHighlights = useClearQuestionHighlights();
  const { data: selectedQuestion } = useTestPageTestSelectedTestSlotQuestion();
  return useCallback(() => {
    selectedQuestion && clearHighlights({ questionId: selectedQuestion.id });
  }, [clearHighlights, selectedQuestion]);
};
export default useTestPageClearQuestionHighlights;
