import { useCallback, useEffect, useMemo, useState } from "react";
import { NbmeSerumLabValues } from "./NbmeSerumLabValues";
import { twMerge } from "tailwind-merge";
import { NbmeCerebrospinalLabValues } from "./NbmeCerebrospinalLabValues";
import { NbmeBloodLabValues } from "./NbmeBloodLabValues";
import { NbmeUrineAndBMILabValues } from "./NbmeUrineAndBMILabValues";
import { FilterInterface } from "interweave";
import { useNbmeShortcutsEnabled } from "@/utils/stores/nbmeLayoutStore";
import NbmeSidePanel from "./nbme-side-panel";
import { SearchInput } from "./SearchInput";
import { Button } from "./ui/button";
import useClampedState from "@/hooks/use-clamped-state";

const labValueFilter: FilterInterface = {
  node(name, node) {
    if (name === "table") {
      node.setAttribute("style", "");
      node.className = "min-w-0 w-auto table-auto";
    }
    return node;
  },
};
import { NbmeIconNavbarButton } from "./NbmeIconNavbarButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFlaskVial } from "@fortawesome/free-solid-svg-icons";
import { useNbmeLayoutStore } from "@/utils/stores/nbmeLayoutStore";
import useTestPageTestSelectedTestSlot from "@/routes/tests/$testId/-test-page-hooks/use-test-page-test-selected-test-slot";

export const NbmeLabValuesButton = () => {
  const { labVisible, setLabVisible, splitView } = useNbmeLayoutStore(
    (state) => ({
      labVisible: state.labVisbile,
      setLabVisible: state.setLabVisible,
      splitView: state.settings.splitView,
    }),
  );
  const [_, setShortcutsEnabled] = useNbmeShortcutsEnabled();
  const { data: selectedSlot } = useTestPageTestSelectedTestSlot();

  const tabs = ["Serum", "Cerebrospinal", "Blood", "Urine and BMI"];
  const [activeTab, setActiveTab] = useState<string>("serum");
  const [query, setQuery] = useState("");
  const [matchingTabs, setMatchingTabs] = useState<string[]>([]);
  const clampMatchingIndex = useCallback(
    (index: number | undefined) => {
      if (index === undefined) return undefined;
      return index;
      // const newIndex = index >= matchingTabs.length - 1 ? 0 : index;
      // return newIndex;
    },
    [matchingTabs],
  );

  const [currentMatchingTabIndex, setCurrentMatchingTabIndex] = useClampedState<
    number | undefined
  >(undefined, clampMatchingIndex);
  const addMatchingTab = (tab: string) => {
    setMatchingTabs((prev) => {
      if (prev.includes(tab)) return prev;
      else return [...prev, tab];
    });
  };
  useEffect(() => {
    if (matchingTabs.length) {
      setCurrentMatchingTabIndex(0);
    }
  }, [matchingTabs]);

  useEffect(() => {
    if (currentMatchingTabIndex === undefined) return;
    const matchingTab = matchingTabs[currentMatchingTabIndex];
    if (!matchingTab) return;
    setActiveTab(matchingTab);
  }, [query, currentMatchingTabIndex]);

  const memoizedInterweaveFilters = useMemo(
    () => [labValueFilter],
    [labValueFilter],
  );
  const onSerumLabValuesUnMatch = useCallback(
    () => setMatchingTabs((prev) => prev.filter((tab) => tab !== "serum")),
    [setMatchingTabs],
  );
  const onSerumLabValuesMatch = useCallback(
    () => addMatchingTab("serum"),
    [addMatchingTab],
  );

  return (
    <NbmeSidePanel.Root
      emergeFrom={splitView && selectedSlot?.submitted ? "left" : "right"}
      open={labVisible}
      onOpenChanged={setLabVisible}
      closeOnClickOutside={false}
      setCloseOnClickOutside={() => false}
      draggable={false}
    >
      <NbmeSidePanel.Trigger>
        <NbmeIconNavbarButton
          className="w-full md:w-auto"
          icon={<FontAwesomeIcon icon={faFlaskVial} className="w-8 h-8" />}
          label="Lab Values"
          onClick={() => setLabVisible(!labVisible)}
        />
      </NbmeSidePanel.Trigger>
      <NbmeSidePanel.Body className="lg:max-w-3xl lg:w-[33.3vw] md:max-w-[50vw] md:w-[50vw] max-w-none w-[100vw] p-0 max-h-full gap-0">
        <div className="flex flex-col justify-start items-center w-full">
          <div className="sticky top-0 flex flex-col justify-start items-center w-full z-[5]">
            <NbmeSidePanel.Header className="px-3 py-3 dark:border-b-neutral-400 bg-neutral-100 border-b-0 flex flex-col justify-center items-center gap-3">
              <NbmeSidePanel.Title>Lab Values</NbmeSidePanel.Title>
              <NbmeSidePanel.CloseButton />
            </NbmeSidePanel.Header>
            <div className="flex flex-col justify-start items-start bg-nbme-primary-600 warm:bg-brown-800 dark:bg-neutral-100 gap-3 w-full pt-3 px-7 pb-0 transition-colors border-b dark:border-b-neutral-500">
              <form
                className="flex flex-row justify-start items-center gap-3"
                onSubmit={(e) => {
                  e.preventDefault();
                  setCurrentMatchingTabIndex((prev) => {
                    if (prev === undefined) return prev;
                    return prev + 1;
                  });
                }}
              >
                <SearchInput
                  onFocus={() => setShortcutsEnabled(false)}
                  onBlur={() => setShortcutsEnabled(true)}
                  value={query}
                  onChange={(e) => setQuery(e.target.value)}
                  iconClassName="text-white"
                  className="dark:bg-white dark:text-neutral-800 dark:border-neutral-600 dark:outline-none"
                />
                <Button
                  type="submit"
                  variant={"ghost"}
                  className="text-white warm:hover:text-white dark:bg-neutral-700 dark:text-white"
                >
                  Search
                </Button>
              </form>
              <div className="flex flex-row justify-between items-end gap-3 w-full">
                {tabs.map((tab) => {
                  const tabName = tab.toLowerCase().replace(/\ /g, "");
                  const active = activeTab === tabName;
                  return (
                    <button
                      type="button"
                      key={tab}
                      className={twMerge(
                        "text-white dark:text-neutral-500 rounded-primary p-1 font-bold bg-nbme-primary-600 warm:bg-brown-800 dark:bg-neutral-100 rounded-b-none hover:text-nbme-primary-600 warm:hover:text-brown-950 warm:hover:bg-brown-100 hover:bg-white dark:hover:text-white dark:hover:bg-neutral-500",
                        active &&
                          "text-nbme-primary-600 bg-white warm:bg-brown-100 warm:text-brown-800 dark:bg-neutral-500 dark:text-white",
                        "transition-colors",
                      )}
                      onClick={() => setActiveTab(tabName)}
                    >
                      {tab.replace("and", "&")}
                    </button>
                  );
                })}
              </div>
            </div>
          </div>

          <div className="relative overflow-auto p-4 text-sm md:text-base w-full transition-colors text-neutral-800">
            <NbmeSerumLabValues
              query={query}
              onMatch={onSerumLabValuesMatch}
              onUnMatch={onSerumLabValuesUnMatch}
              filters={memoizedInterweaveFilters}
              className={twMerge(
                "hidden h-full",
                activeTab === "serum" && "block",
              )}
            />
            <NbmeCerebrospinalLabValues
              query={query}
              onMatch={() => addMatchingTab("cerebrospinal")}
              onUnMatch={() =>
                setMatchingTabs((prev) =>
                  prev.filter((tab) => tab !== "cerebrospinal"),
                )
              }
              filters={memoizedInterweaveFilters}
              className={twMerge(
                "hidden h-full",
                activeTab === "cerebrospinal" && "block",
              )}
            />
            <NbmeBloodLabValues
              query={query}
              onMatch={() => addMatchingTab("blood")}
              onUnMatch={() =>
                setMatchingTabs((prev) => prev.filter((tab) => tab !== "blood"))
              }
              filters={memoizedInterweaveFilters}
              className={twMerge(
                "hidden h-full",
                activeTab === "blood" && "block",
              )}
            />
            <NbmeUrineAndBMILabValues
              query={query}
              onMatch={() => addMatchingTab("urineandbmi")}
              onUnMatch={() =>
                setMatchingTabs((prev) =>
                  prev.filter((tab) => tab !== "urineandbmi"),
                )
              }
              filters={memoizedInterweaveFilters}
              className={twMerge(
                "hidden h-full",
                activeTab === "urineandbmi" && "block",
              )}
            />
          </div>
        </div>
      </NbmeSidePanel.Body>
    </NbmeSidePanel.Root>
  );
};
