import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LabeledSelect } from "./LabeledSelect";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { clamp } from "@/utils/common/clamp";
import { getPageCount } from "@/utils/common/getPageCount";
import { titlize } from "@/utils/common/titlize";
import { useEffect, useState } from "react";

interface PaginatorProps {
  /**This is for the id of the element*/
  itemSingularName: string;
  numberOfItems: number;

  itemsPerPage: 5 | 10 | 15 | 25 | 50 | 100;
  onItemsPerPageChange: (value: this["itemsPerPage"]) => void;

  currentPage: number;
  onCurrentPageChange: (currentPage: this["currentPage"]) => void;
}

export const Paginator = ({
  numberOfItems,
  itemsPerPage,
  onItemsPerPageChange,
  currentPage,
  itemSingularName,
  onCurrentPageChange,
}: PaginatorProps) => {
  const [numberOfPages, setNumberOfPages] = useState<number>(1);

  useEffect(() => {
    const newPageCount = getPageCount(numberOfItems, itemsPerPage);
    setNumberOfPages(newPageCount);
    if (currentPage > newPageCount) onCurrentPageChange(newPageCount);
  }, [numberOfItems, itemsPerPage]);

  const clampCurrentPage = (currentPage: number) =>
    clamp({ min: 1, max: numberOfPages, num: currentPage });

  return (
    <div className="flex justify-start items-center gap-6">
      <LabeledSelect
        label={`${titlize(itemSingularName)}s per page:`}
        id={`${itemSingularName}-per-page`}
        value={itemsPerPage.toString()}
        containerClassName="z-[1000] bottom-0"
        hideItemsOnClick
      >
        {Array.from([5, 10, 25, 50, 100]).map((n) => {
          const typedN = n as typeof itemsPerPage;
          return (
            <button
              key={typedN}
              className="w-full py-1 px-3 hover:bg-gray-100 dark:hover:bg-neutral-600 text-gray-700 dark:text-neutral-300 transition-colors"
              onClick={() => onItemsPerPageChange(typedN)}
            >
              {n}
            </button>
          );
        })}
      </LabeledSelect>
      <div className="flex justify-start items-center text-gray-500 dark:text-neutral-400 gap-3">
        <p>
          {currentPage} of {numberOfPages}
        </p>
        <div className="flex flex-row justify-between items-center gap-3">
          <button
            onClick={() =>
              onCurrentPageChange(clampCurrentPage(currentPage - 1))
            }
          >
            <FontAwesomeIcon icon={faAngleLeft} />
          </button>
          <button
            onClick={() =>
              onCurrentPageChange(clampCurrentPage(currentPage + 1))
            }
          >
            <FontAwesomeIcon icon={faAngleRight} />
          </button>
        </div>
      </div>
    </div>
  );
};
