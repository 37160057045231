import { QuestionBankIdentifier } from "@/types/question-bank-types";
import useQuestionBankQuestionHeaders from "./use-question-bank-question-headers";
import { QuestionHeader } from "@/models";
import { CustomEqualitySet } from "@/classes";
import { useCallback } from "react";
import useUnravelQuestionVignette from "./use-unravel-question-vignettes";
const isQuestionOrphaned = (question: QuestionHeader) => {
  return !question.parentId && !question.childId;
};

const useAccountForQuestionBankQuestionVignettes = (
  args: QuestionBankIdentifier,
) => {
  const unravelVignette = useUnravelQuestionVignette(args);
  return useCallback(
    (
      {
        questionHeaders: questions,
      }: {
        questionHeaders: QuestionHeader[];
      },
      opts?: { removeExcess?: boolean },
    ) => {
      //Keep the original length as our baseline
      const originalLength = questions.length;
      const orphans: QuestionHeader[] = [];
      //Create result array.
      const result = new CustomEqualitySet<QuestionHeader>((q) => q.id);
      // Loop through each question one by one
      for (let i = 0; i < questions.length; i++) {
        const question = questions[i]!;
        // if it's a question that has no parents or children,
        // add to the result array.
        if (isQuestionOrphaned(question)) {
          result.push(question);
          orphans.push(question);
          continue;
        }
        // Otherwise we check to see if the current question
        // has been already included when we unraveled
        // one of the vignettes earlier, (to prevent
        // duplicate vignettes from hapenning)
        if (result.has(question)) continue;

        // if it has't been included before, then we
        // unravel the question into the full vignette
        // that it's a part of in the correct order...
        result.push(...unravelVignette(question));
      }

      const resultArray = result.items();
      if (!opts?.removeExcess) return resultArray;

      //Start removing Excess questions after including vignettes
      const questionsToBeDeleted: number[] = [];
      let excess = resultArray.length - originalLength;
      if (excess === 0) return resultArray;

      for (let i = 0; i < resultArray.length; i++) {
        if (excess === 0) break;
        const question = resultArray[i]!;
        //If we couldn't find the question within the orphans, don't remove it.
        if (!orphans.find((o) => o.id === question.id)) continue;
        questionsToBeDeleted.push(question.id);
        excess -= 1;
      }

      const resultArrayWithoutExcess = resultArray.filter(
        (q) => !questionsToBeDeleted.includes(q.id),
      );

      return resultArrayWithoutExcess;
    },
    [unravelVignette, args],
  );
};

export default useAccountForQuestionBankQuestionVignettes;
