import { PropsWithClassName } from "@/types";
import { FilterInterface, Interweave } from "interweave";
import React from "react";

export const NbmeCerebrospinalLabValues = ({
  className,
  filters,
}: PropsWithClassName & { filters?: FilterInterface[] }) => {
  return (
    <Interweave
      filters={filters}
      className={className}
      content={`<table id= \"CSTable\"  border= \"0\" cellpadding= \"2\" cellspacing= \"0\" width= \"100%\"><tbody><tr><td ><b>Cerebrospinal Fluid</b></td><td ><b>Reference Range</b></td><td ><b>SI Reference Interval</b></td></tr><tr><td width= \"100%\" colspan= \"3\"><hr></td></tr><tr><td >Cell count</td><td >0-5/mm<sup>3</sup></td><td >0-5 x 10<sup>6</sup>/L</td></tr><tr><td width= \"100%\" colspan= \"3\"><hr></td></tr><tr><td >Chloride</td><td >118-132 mEq/L</td><td >118-132 mmol/L</td></tr><tr><td width= \"100%\" colspan= \"3\"><hr></td></tr><tr><td >Gamma globulin</td><td >3%-12% total proteins</td><td >0.03-0.12 </td></tr><tr><td width= \"100%\" colspan= \"3\"><hr></td></tr><tr><td >Glucose</td><td >40-70 mg/dL</td><td >2.2-3.9 mmol/L</td></tr><tr><td width= \"100%\" colspan= \"3\"><hr></td></tr><tr><td >Pressure</td><td >70-180 mm H<sub>2</sub>O</td><td >70-180 mm H<sub>2</sub>O</td></tr><tr><td width= \"100%\" colspan= \"3\"><hr></td></tr><tr><td >Proteins, total</td><td >&lt;40 mg/dL</td><td >&lt;0.40 g/L</td></tr><tr><td width= \"100%\" colspan= \"3\"><hr></td></tr></tbody></table>`}
    />
  );
};
